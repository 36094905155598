import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

export const CsvStatus = ( { onChangeActiveCsv, iqamaTimes: { isCsv } } ) => {
    const { t } = useTranslation()
    return (
        <div className="container-fluid elementToFadeInAndOut">
            <div className="inner-body">
                <div className="row row-sm" >
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <div class="alert alert-warning" role="alert">
                                <strong>{ t( 'autoMessage.warning' ) }!</strong> { t( 'autoMessage.csvWarning' ) }
                            </div>
                        </div>
                        { isCsv ? <div className="bg-white box-shadow custom-card card">
                            <div className="alert text-center fade show p-3">
                                <i className="fe fe-upload-cloud fs-50 text-success"></i>
                                <h5 className="mt-2 mb-1">{ t( "common.activte" ) }</h5>
                                <p className="mb-3 mb-3 tx-inverse"> { t( "website.csvStatusInfoA" ) }</p>
                                <a className="btn ripple btn-success" href="#" onClick={ ( e ) => { e.preventDefault(); onChangeActiveCsv() } }>{ t( "common.desactive" ) }</a>
                            </div>
                        </div> :

                            <div className="bg-white box-shadow custom-card card">
                                <div className="alert text-center fade show p-3">
                                    <i className="fe fe-download-cloud fs-50 text-danger"></i>
                                    <h5 className="mt-2 mb-1">{ t( "common.desactive" ) }</h5>
                                    <p className="mb-3 mb-3 tx-inverse">{ t( "website.csvStatusInfoD" ) }</p>
                                    <a className="btn ripple btn-danger" href="#" onClick={ ( e ) => { e.preventDefault(); onChangeActiveCsv() } }>{ t( "common.activte" ) }</a>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>

        </div>
    )
}

CsvStatus.propTypes = {
    props: PropTypes
}

const mapStateToProps = ( state ) => ( {

} )



export default connect( mapStateToProps, {} )( CsvStatus )
