import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../utils/device';
import OwlCarousel from 'react-owl-carousel';
import { useHashFragment } from "../../utils/hooks";
import saoudi from '../../assets/flags/saoudi.png';
import england from '../../assets/flags/england.png';
import french from '../../assets/flags/french.png';
import pakistan from '../../assets/flags/pakistan.png';
import bangladesh from '../../assets/flags/bangladesh.jpeg';
import './css/icofont.min.css'
import './css/owl.carousel.min.css'
import './css/bootstrap.min.css'
import './css/aos.min.css'
import './css/responsive.min.css'
import './css/magnific-popup.min.css'
import './css/responsive - Copy.css'
import './css/style.min.css'

export const Index = ( { props } ) => {
    const { t, i18n } = useTranslation();
    const sectionArrary = [ 1, 2, 3, 4, 5 ];
    useHashFragment();
    const onChangeLanguage = ( language ) => {
        i18n.changeLanguage( language )
        localStorage.setItem( "lang", language )
    }
    return (
        <div className="page_wrapper">
            <div id="preloader">

                <div id="loader">
                    <img alt="avatar" src="https://general.eu-central-1.linodeobjects.com/basics/platforms/moonode_logo.png" style={ { height: '130px', width: '130px' } } />
                </div>
            </div>
            <header>

                <div className="container">

                    <nav className="navbar navbar-expand-lg">
                        <a className="navbar-brand" href="#">
                            <img src="https://general.eu-central-1.linodeobjects.com/basics/platforms/moonode_logo.png" style={ { height: isMobile() ? '45px' : '30px', width: isMobile() ? '45px' : '30px' } } alt="image" />
                        </a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon">
                                <div className="toggle-wrap">
                                    <span className="toggle-bar"></span>
                                </div>
                            </span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item active">
                                    <a className="nav-link" href="/">{ t( 'application.home' ) }</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/aboutus">{ t( 'common.about' ) }</a>
                                </li>
                                <li className="nav-item has_dropdown">
                                    <a className="nav-link" href="#" onClick={ ( e ) => e.preventDefault() }>{ t( 'profile.language' ) }</a>
                                    <span className="drp_btn">⤵</span>
                                    <div className={ `${ isMobile() ? "" : "sub_menu" }` }>
                                        <ul>
                                            <li> <a style={ { cursor: "pointer" } } onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'ar' ) } } className="dropdown-item d-flex ">
                                                <span className="avatar  mr-3 align-self-center bg-transparent"><img src={ saoudi } alt="img" /></span>
                                                <div className="d-flex">
                                                    <span className="mt-2">{ t( "website.arabic" ) }</span>
                                                </div>
                                            </a></li>
                                            <li> <a style={ { cursor: "pointer" } } onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'en' ) } } className="dropdown-item d-flex">
                                                <span className="avatar  mr-3 align-self-center bg-transparent"><img src={ england } alt="img" /></span>
                                                <div className="d-flex">
                                                    <span className="mt-2">{ t( "website.english" ) }</span>
                                                </div>
                                            </a></li>
                                            <li> <a style={ { cursor: "pointer" } } onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'fr' ) } } className="dropdown-item d-flex">
                                                <span className="avatar mr-3 align-self-center bg-transparent"><img src={ french } alt="img" /></span>
                                                <div className="d-flex">
                                                    <span className="mt-2">{ t( "website.french" ) }</span>
                                                </div>
                                            </a></li>
                                            <li> <a style={ { cursor: "pointer" } } onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'ur' ) } } className="dropdown-item d-flex">
                                                <span className="avatar mr-3 align-self-center bg-transparent"><img src={ pakistan } alt="img" /></span>
                                                <div className="d-flex">
                                                    <span className="mt-2">{ t( "website.urdu" ) }</span>
                                                </div>
                                            </a></li>
                                            <li> <a style={ { cursor: "pointer" } } onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'bn' ) } } className="dropdown-item d-flex">
                                                <span className="avatar mr-3 align-self-center bg-transparent"><img src={ bangladesh } alt="img" /></span>
                                                <div className="d-flex">
                                                    <span className="mt-2">{ t( "website.bengali" ) }</span>
                                                </div>
                                            </a></li>
                                        </ul>
                                    </div>
                                </li>

                                <li className="nav-item has_dropdown">
                                    <a className="nav-link" href="#" onClick={ ( e ) => e.preventDefault() }>{ t( 'landingSite.blog' ) }</a>
                                    <span className="drp_btn">⤵</span>
                                    <div className={ `${ isMobile() ? "" : "sub_menu" }` }>
                                        <ul>
                                            <li><a style={ { marginLeft: isMobile() ? "30px" : "" } } href="/raspberry">Raspberry</a></li>
                                        </ul>
                                    </div>
                                </li>


                                <li className="nav-item">
                                    <a className="nav-link" href="/contactus">{ t( 'application.contactUs' ) }</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link dark_btn yellow_btn_guardian" href="/signin" style={ { color: 'black' } }>{ t( 'landing.signin' ) }</a>
                                </li>
                            </ul>
                        </div>
                    </nav>

                </div>

            </header>
            <section className="banner_section">
                <div className="container">
                    <div className="row">
                        <span className="banner_shape1"> <img src={ require( '../../assets/img/mashaAllah-actif.png' ) } style={ { height: '100px', width: "100px" } } alt="image" /> </span>
                        <span className="banner_shape2"> <img src={ require( '../../assets/img/mashaAllah-actif.png' ) } style={ { height: '100px', width: "100px" } } alt="image" /> </span>
                        <span className="banner_shape3"> <img src={ require( '../../assets/img/mashaAllah-actif.png' ) } style={ { height: '100px', width: "100px" } } alt="image" /> </span>

                        <div className="col-lg-8 col-md-12 mx-auto" data-aos="fade-right" data-aos-duration="1500">

                            <div className="banner_text">

                                <h1 style={ { color: "#000", marginLeft: isMobile() ? '40%' : null } }>{ t( 'landing.title1' ) }</h1>

                                <h4 style={ { color: "#000", marginLeft: isMobile() ? '40%' : null } }>{ t( 'landing.description1' ) }
                                </h4>
                            </div>


                            {/* <div className="yt_video" data-aos="fade-in" data-aos-duration="1500">
                                <div className="thumbnil">
                                    <a className="popup-youtube play-button" data-url="https://www.youtube.com/watch?v=PEln6a4DZqc?autoplay=1&mute=1" data-toggle="modal" data-target="#myModal" title="XJj2PbenIsU">
                                        <span className="play_btn">
                                            <i className="fas fa-star"></i>
                                            <div className="waves-block">
                                                <div className="waves wave-1"></div>
                                                <div className="waves wave-2"></div>
                                                <div className="waves wave-3"></div>
                                            </div>
                                        </span>
                                    </a>
                                </div>
                            </div> */}


                        </div>

                    </div>

                </div>



                <div className="container-fluid" data-aos="fade-in" data-aos-duration="1500">
                    <div className="banner_images">
                        <div className="row d-flex justify-content-center">
                            { isMobile() ? null : <div className="col-md-2 col-lg-2 col-sm-4 col-xs-4" style={ { visibility: "hidden" } }>
                                <div className="banner_screen ">
                                    <img className="moving_animation" src={ require( './images/moonodePage.png' ) } alt="image" />
                                </div>
                            </div> }
                            <div className="col-md-6 col-sm-6 col-xs-6" style={ { marginTop: '-10%', marginRight: isMobile() ? '' : '20%' } }>
                                <div className="banner_screen screen3">
                                    <img className="moving_animation" src={ require( './images/MoonodeTv.gif' ) }
                                        alt="image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>



            <section className="row_am download_section">

                <div className="container">

                    <ul className="app_btn" data-aos="fade-in" data-aos-duration="1500">
                        <li>
                            <a href="https://apps.apple.com/ca/app/moonode/id1508859104" className="app_store" target="_blank">
                                <img className="blue_img" src={ require( "./images/appstore_blue.png" ) } alt="image" />
                                <img className="white_img" src={ require( "./images/appstore_white.png" ) } alt="image" />
                            </a>
                        </li>
                        <li>
                            <a href="https://play.google.com/store/apps/details?id=com.moonode.moonode&hl=en&gl=US" target="_blank">
                                <img className="blue_img" src={ require( './images/googleplay_blue.png' ) } alt="image" />
                                <img className="white_img" src={ require( "./images/googleplay_white.png" ) } alt="image" />
                            </a>
                        </li>
                    </ul>

                </div>

            </section>



            <section className="row_am statistic_section">
                <div className="container">
                    <ul className="app_statstic" id="counter" >
                        <div className="col-md-3 col-lg-3 col-sm-6 col-xs-12" data-aos="fade-up" data-aos-duration="1000">
                            <li>
                                <div className="icon">
                                    <img src={ require( "./images/followers.png" ) } alt="image" />
                                </div>
                                <div className="text">
                                    <p><span className="counter-value" data-count="10700"></span><span>+</span></p>
                                    <p>{ t( 'landingSite.users' ) }</p>
                                </div>
                            </li>
                        </div>

                        <div className="col-md-3 col-lg-3 col-sm-6 col-xs-12 " data-aos="fade-up" data-aos-duration="1200">
                            <li>

                                <div className="icon">
                                    <img src={ require( "./images/download.png" ) } alt="image" />
                                </div>
                                <div className="text">
                                    <p><span className="counter-value" data-count="15"></span><span>K+</span></p>
                                    <p>{ t( 'landingSite.downloads' ) }</p>
                                </div>
                            </li>
                        </div>

                        <div className="col-md-3 col-lg-3 col-sm-6 col-xs-12 " data-aos="fade-up" data-aos-duration="1400">
                            <li>
                                <div className="icon">
                                    <img src={ require( "./images/reviews.png" ) } alt="image" />
                                </div>
                                <div className="text">
                                    <p><span className="counter-value" data-count="2300">1500</span><span>+</span></p>
                                    <p>{ t( 'landingSite.reviews' ) }</p>
                                </div>
                            </li>
                        </div>

                        <div className="col-md-3 col-lg-3 col-sm-6 col-xs-12 " data-aos="fade-up" data-aos-duration="1600">
                            <li>
                                <div className="icon">
                                    <img src={ require( './images/mosques.png' ) } alt="image" />
                                </div>
                                <div className="text">
                                    <p><span className="counter-value" data-count="60"></span><span>+</span></p>
                                    <p>{ t( 'application.mosques' ) }</p>
                                </div>
                            </li>
                        </div>
                    </ul>
                </div>
            </section>




            <section className="row_am features_section" id="features">

                <div className="container">
                    <div className="features_inner">
                        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">

                            <h2><span>{ t( 'landingSite.features' ) }</span>{ " " } { t( 'landingSite.differencesTitle' ) }</h2>

                            <p>{ t( 'landingSite.differencesBody' ) }</p>
                        </div>


                        <div className="features_block">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="feature_box" data-aos="fade-up" data-aos-duration="1500">
                                        <div className="image">
                                            <img src={ require( './images/features1.png' ) } alt="image" />
                                        </div>
                                        <div className="text">
                                            <h4>{ t( 'landingSite.feature1Title' ) }</h4>
                                            <p>{ t( 'landingSite.feature1Body' ) }</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="feature_box" data-aos="fade-up" data-aos-duration="1700">
                                        <div className="image">
                                            <img src={ require( "./images/features2.png" ) } alt="image" />
                                        </div>
                                        <div className="text">
                                            <h4>{ t( 'landingSite.feature2Title' ) }</h4>
                                            <p>{ t( 'landingSite.feature2Body' ) }</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="feature_box" data-aos="fade-up" data-aos-duration="1900">
                                        <div className="image">
                                            <img src={ require( "./images/features3.png" ) } alt="image" />

                                        </div>
                                        <div className="text">
                                            <h4>{ t( 'landingSite.feature3Title' ) }</h4>
                                            <p>{ t( 'landingSite.feature3Body' ) }</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </section>



            <section className="row_am about_app_section" style={ { marginBottom: '10%' } }>

                <div className="container">

                    <div className="row">
                        <div className="col-lg-6">


                            <div className="about_img" data-aos="fade-in" data-aos-duration="1500" style={ { marginTop: isMobile() ? '50%' : null } }>
                                <div className="frame_img">
                                    <img className="moving_position_animatin" src={ require( "./images/about-frame.png" ) } alt="image" />
                                </div>
                                <div className="screen_img">
                                    <img className="moving_animation" src={ require( "./images/about-screen.png" ) } alt="image" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">


                            <div className="about_text">
                                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                                    <h2>{ t( 'landingSite.feature4Title' ) } <span>{ t( 'landingSite.feature4Title2' ) }</span></h2>
                                    <p>
                                        { t( 'landingSite.feature4Body' ) }
                                    </p>
                                    <ul className="list" style={ { marginTop: '-50px' } }>
                                        <li data-aos="fade-up" data-aos-duration="1500">
                                            <i className="ti-minus sidemenu-icon"></i>{ " " }{ t( 'landingSite.moreThanApp1' ) }
                                        </li>
                                        <li data-aos="fade-up" data-aos-duration="1500">
                                            <i className="ti-minus sidemenu-icon"></i>{ " " }{ t( 'landingSite.moreThanApp2' ) }
                                        </li>
                                        <li data-aos="fade-up" data-aos-duration="1500">
                                            <i className="ti-minus sidemenu-icon"></i>{ " " }{ t( 'landingSite.moreThanApp3' ) }
                                        </li>
                                        <li data-aos="fade-up" data-aos-duration="1500">
                                            <i className="ti-minus sidemenu-icon"></i>{ " " }{ t( 'landingSite.moreThanApp4' ) }
                                        </li>
                                        <li data-aos="fade-up" data-aos-duration="1500">
                                            <i className="ti-minus sidemenu-icon"></i>{ " " }{ t( 'landingSite.moreThanApp5' ) }
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </section>



            <section className="row_am modern_ui_section" style={ { marginTop: '-15%' } }>

                <div className="container">

                    <div className="row">
                        <div className="col-lg-6" style={ { marginBottom: '20%' } }>

                            <div className="ui_text">
                                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                                    <h2>{ t( 'landingSite.tv1Title' ) } <span>{ t( 'landingSite.tv1Title2' ) }</span></h2>
                                    <p>
                                        { t( 'landingSite.tv1Body' ) }
                                    </p>
                                </div>
                                <ul className="design_block">
                                    <li data-aos="fade-up" data-aos-duration="1500">
                                        <h4>{ t( 'landingSite.tv2Title' ) }</h4>
                                        <p>{ t( 'landingSite.tv2Body' ) }</p>
                                    </li>
                                    <li data-aos="fade-up" data-aos-duration="1500">
                                        <h4>{ t( 'landingSite.tv3Title' ) }</h4>
                                        <p>{ t( 'landingSite.tv3Body' ) }</p>
                                    </li>
                                    <li data-aos="fade-up" data-aos-duration="1500">
                                        <h4>{ t( 'landingSite.tv4Title' ) }</h4>
                                        <p>{ t( 'landingSite.tv4Body' ) }</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6" >
                            <div className="testimonial_block" data-aos="fade-in" data-aos-duration="1500">
                                <OwlCarousel id="screen_slider" className="owl-carousel owl-theme" items={ 1 }>
                                    <div className="item">
                                        <div className="screen_frame_img">
                                            <img
                                                className="moving_position_animatin" style={ { borderRadius: "10px" } } src={ require( "./images/TV1.png" ) } alt="image" />
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="screen_frame_img">
                                            <img
                                                className="moving_position_animatin" style={ { borderRadius: "10px" } } src={ require( "./images/Tv2.png" ) } alt="image" />
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="screen_frame_img">
                                            <img
                                                className="moving_position_animatin" style={ { borderRadius: "10px" } } src={ require( "./images/Tv3.png" ) } alt="image" />

                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="screen_frame_img">
                                            <img
                                                className="moving_position_animatin" style={ { borderRadius: "10px" } } src={ require( "./images/Tv4.png" ) } alt="image" />

                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="screen_frame_img">
                                            <img
                                                className="moving_position_animatin" style={ { borderRadius: "10px" } } src={ require( "./images/Tv5.png" ) } alt="image" />

                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="screen_frame_img">
                                            <img
                                                className="moving_position_animatin" style={ { borderRadius: "10px" } } src={ require( "./images/Tv6.png" ) } alt="image" />

                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="screen_frame_img">
                                            <img
                                                className="moving_position_animatin" style={ { borderRadius: "10px" } } src={ require( "./images/Tv7.png" ) } alt="image" />

                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="screen_frame_img">
                                            <img
                                                className="moving_position_animatin" style={ { borderRadius: "10px" } } src={ require( "./images/Tv8.png" ) } alt="image" />

                                        </div>
                                    </div>
                                </OwlCarousel>

                            </div>
                            {/* <div className="ui_images" data-aos="fade-in" data-aos-duration="1500">
                                <div className="left_img">
                                    <img className="moving_position_animatin" style={ { objectFit: 'cover', borderRadius: "10px" } } src={ require( "./images/modern03.png" ) } alt="image" />
                                    <img className="moving_position_animatin" style={ { objectFit: 'cover', borderRadius: "10px" } } src={ require( "./images/modern01.png" ) } alt="image" />
                                </div>
                                <div className="right_img">
                                    <img className="moving_position_animatin" src={ require( './images/offline.png' ) } alt="image" />
                                </div>
                            </div> */}
                        </div>
                    </div>

                </div>

            </section>



            <section className="row_am how_it_works" id="how_it_work">

                <div className="container">
                    <div className="how_it_inner">
                        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">

                            <h2><span>{ t( "landingSite.howItWork" ) }</span> - { t( "landingSite.howItWork2" ) }</h2>

                            <p>{ t( "landingSite.howItWork3" ) }</p>
                        </div>
                        <div className="step_block" style={ { marginTop: isMobile() ? '25%' : '8%' } }>
                            <ul>
                                <li style={ { paddingLeft: isMobile() ? '38%' : '30px' } }>
                                    <div className="step_text" data-aos={ isMobile() ? "fade-right" : "fade-left" } data-aos-duration="1500">
                                        <h4>{ t( "landingSite.howItWorks1Title" ) }</h4>
                                        <span>{ t( "landingSite.howItWorks1SubTitle" ) }</span>
                                        <p>{ t( "landingSite.howItWorks1Body" ) }</p>
                                    </div>
                                    <div className="step_number">
                                        <h3>01</h3>
                                    </div>
                                    <div className="step_img" data-aos={ isMobile() ? "fade-right" : "fade-left" } data-aos-duration="1500">
                                        <img src={ require( "./images/download_app.png" ) } alt="image" />
                                    </div>
                                </li>
                                {/* ti-flag-alt */ }
                                <li style={ { paddingLeft: isMobile() ? '38%' : '30px' } }>
                                    <div className="step_text" data-aos="fade-right" data-aos-duration="1500">
                                        <h4>{ t( 'landingSite.howItWorks2Title' ) }</h4>
                                        <span>{ t( 'landingSite.howItWorks2SubTitle' ) }</span>
                                        <p>{ t( 'landingSite.howItWorks2Body' ) }</p>
                                    </div>

                                    <div className="step_number">
                                        <h3>02</h3>
                                    </div>
                                    <div className="step_img" data-aos="fade-right" data-aos-duration="1500">
                                        <img src={ require( "./images/create_account.png" ) } alt="image" />
                                    </div>
                                </li>


                                <li style={ { paddingLeft: isMobile() ? '38%' : '30px' } }>

                                    <div className="step_text" data-aos="fade-right" data-aos-duration="1500">
                                        <h4>{ t( 'landingSite.howItWorks3Title' ) }</h4>
                                        <div className="app_icon">
                                            <small>{ t( 'landingSite.howItWorks3SubTitle' ) }</small><br />
                                            <a href="https://play.google.com/store/apps/details?id=com.moonode.moonode&hl=en&gl=US" target="_blank"><i className="ti-android sidemenu-icon"></i></a>
                                            <a href="https://apps.apple.com/ca/app/moonode/id1508859104" target="_blank"><i className="ti-apple sidemenu-icon"></i></a>
                                            <a href={ null }><i className="ti-window sidemenu-icon"></i></a><br />
                                        </div>
                                        <p>{ t( 'landingSite.howItWorks3Body' ) }</p>

                                    </div>
                                    <div className="step_number" >
                                        <h3>03</h3>
                                    </div>
                                    <div className="step_img" data-aos="fade-right" data-aos-duration="1500">
                                        <img src={ require( "./images/enjoy_app.png" ) } alt="image" />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>

            </section>


            {/* <section className="row_am testimonial_section">

                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">

                        <h2>What our <span>customer say</span></h2>

                        <p>Lorem Ipsum is simply dummy text of the printing and typese tting <br /> indus orem Ipsum has beenthe
                            standard dummy.</p>
                    </div>
                    <div className="testimonial_block" data-aos="fade-in" data-aos-duration="1500">
                        <div id="testimonial_slider" className="owl-carousel owl-theme">

                            <div className="item">
                                <div className="testimonial_slide_box">
                                    <div className="rating">
                                        <span><i className="icofont-star"></i></span>
                                        <span><i className="icofont-star"></i></span>
                                        <span><i className="icofont-star"></i></span>
                                        <span><i className="icofont-star"></i></span>
                                        <span><i className="icofont-star"></i></span>
                                    </div>
                                    <p className="review">
                                        “ Lorem Ipsum is simply dummy text of the printing and typese tting us orem Ipsum has been lorem
                                        beenthe standar dummy. ”
                                    </p>
                                    <div className="testimonial_img">
                                        <img src={ require( "./images/testimonial_user1.png" ) } alt="image" />
                                    </div>
                                    <h3>Shayna John</h3>
                                    <span className="designation">Careative inc</span>
                                </div>
                            </div>


                            <div className="item">
                                <div className="testimonial_slide_box">
                                    <div className="rating">
                                        <span><i className="icofont-star"></i></span>
                                        <span><i className="icofont-star"></i></span>
                                        <span><i className="icofont-star"></i></span>
                                        <span><i className="icofont-star"></i></span>
                                        <span><i className="icofont-star"></i></span>
                                    </div>
                                    <p className="review">
                                        “ Lorem Ipsum is simply dummy text of the printing and typese tting us orem Ipsum has been lorem
                                        beenthe standar dummy. ”
                                    </p>
                                    <div className="testimonial_img">
                                        <img src={ require( "./images/testimonial_user2.png" ) } alt="image" />
                                    </div>
                                    <h3>Willium Den</h3>
                                    <span className="designation">Careative inc</span>
                                </div>
                            </div>


                            <div className="item">
                                <div className="testimonial_slide_box">
                                    <div className="rating">
                                        <span><i className="icofont-star"></i></span>
                                        <span><i className="icofont-star"></i></span>
                                        <span><i className="icofont-star"></i></span>
                                        <span><i className="icofont-star"></i></span>
                                        <span><i className="icofont-star"></i></span>
                                    </div>
                                    <p className="review">
                                        “ Lorem Ipsum is simply dummy text of the printing and typese tting us orem Ipsum has been lorem
                                        beenthe standar dummy. ”
                                    </p>
                                    <div className="testimonial_img">
                                        <img src={ require( "./images/testimonial_user3.png" ) } alt="image" />
                                    </div>
                                    <h3>Cyrus Stephen</h3>
                                    <span className="designation">Careative inc</span>
                                </div>

                            </div>
                        </div>


                        <div className="total_review">
                            <div className="rating">
                                <span><i className="ti-star sidemenu-icon"></i></span>
                                <span><i className="ti-star sidemenu-icon"></i></span>
                                <span><i className="ti-star sidemenu-icon"></i></span>
                                <span><i className="ti-star sidemenu-icon"></i></span>
                                <span><i className="ti-star sidemenu-icon"></i></span>
                                <p>5.0 / 5.0</p>
                            </div>
                            <h3>2578</h3>
                            <a href="#">TOTAL USER REVIEWS <i className="icofont-arrow-right"></i></a>
                        </div>


                        <div className="avtar_faces">
                            <img src={ require( "./images/avtar_testimonial.png" ) } alt="image" />
                        </div>
                    </div>
                </div>

            </section> */}



            <section className="row_am trusted_section">

            </section>

            <section className="row_am faq_section" id="faq">

                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                        <h2><span>{ t( "landingSite.qa" ) }</span> - { t( "landingSite.qaSubtitle" ) }</h2>
                        <p>{ t( "landingSite.qaBody" ) }</p>
                    </div>
                    <div className="faq_panel">
                        <div className="accordion" id="accordionExample">
                            <div className="card" data-aos="fade-up" data-aos-duration="1500">
                                <div className="card-header" id="headingOne">
                                    <h2 className="mb-0">
                                        <button type="button" className="btn btn-link active" data-toggle="collapse" data-target="#collapseOne">
                                            <i className="icon_faq fab fa-sistrix"></i>{ t( "landingSite.qa1" ) }</button>
                                    </h2>
                                </div>
                                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p>{ t( "landingSite.qaAnswer1" ) }.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card" data-aos="fade-up" data-aos-duration="1500">
                                <div className="card-header" id="headingTwo">
                                    <h2 className="mb-0">
                                        <button type="button" className="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseTwo"><i className="icon_faq fab fa-sistrix"></i>{ t( "landingSite.qa2" ) } </button>
                                    </h2>
                                </div>
                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p>{ t( "landingSite.qaAnswer2" ) }.</p>

                                    </div>
                                </div>
                            </div>
                            <div className="card" data-aos="fade-up" data-aos-duration="1500">
                                <div className="card-header" id="headingThree">
                                    <h2 className="mb-0">
                                        <button type="button" className="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseThree"><i className="icon_faq fab fa-sistrix"></i>{ t( "landingSite.qa3" ) }
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p>{ t( "landingSite.qaAnswer3" ) }.</p>

                                    </div>
                                </div>
                            </div>
                            <div className="card" data-aos="fade-up" data-aos-duration="1500">
                                <div className="card-header" id="headingFour">
                                    <h2 className="mb-0">
                                        <button type="button" className="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseFour"><i className="icon_faq fab fa-sistrix"></i>{ t( "landingSite.qa4" ) }
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p>{ t( "landingSite.qaAnswer4" ) }</p>

                                    </div>
                                </div>
                            </div>
                            <div className="card" data-aos="fade-up" data-aos-duration="1500">
                                <div className="card-header" id="headingThree">
                                    <h2 className="mb-0">
                                        <button type="button" className="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseFive"><i className="icon_faq fab fa-sistrix"></i>{ t( "landingSite.qa5" ) }
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapseFive" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p>{ t( "landingSite.qaAnswer5" ) }</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card" data-aos="fade-up" data-aos-duration="1500">
                                <div className="card-header" id="headingFour">
                                    <h2 className="mb-0">
                                        <button type="button" className="btn btn-link collapsed" data-toggle="collapse"
                                            data-target="#collapseSix"><i className="icon_faq fab fa-sistrix"></i>{ t( "landingSite.qa6" ) }
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapseSix" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p>{ t( "landingSite.qaAnswer6" ) }.</p>

                                    </div>
                                </div>
                            </div>
                        </div >
                    </div >
                </div >

            </section >



            <section className="row_am interface_section">

                <div className="container-fluid" style={ { marginLeft: isMobile() ? "" : "70px" } }>
                    <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300" style={ { marginBottom: "5%" } }>

                        <h2>{ t( "landingSite.appTitle" ) } <span>{ t( "landingSite.appTitle1" ) }</span></h2>

                        <p>
                            { t( "landingSite.appBody" ) }
                        </p>
                    </div>


                    <div className="screen_slider" >
                        <OwlCarousel id="screen_slider" className="owl-carousel">
                            <div className="item">
                                <div className="screen_frame_img">
                                    <img src={ require( "./images/app1.png" ) } alt="image" />
                                </div>
                            </div>
                            <div className="item">
                                <div className="screen_frame_img">
                                    <img src={ require( "./images/app2.png" ) } alt="image" />
                                </div>
                            </div>
                            <div className="item">
                                <div className="screen_frame_img">
                                    <img src={ require( "./images/app3.png" ) } alt="image" />
                                </div>
                            </div>
                            <div className="item">
                                <div className="screen_frame_img">
                                    <img src={ require( "./images/app5.png" ) } alt="image" />
                                </div>
                            </div>
                            <div className="item">
                                <div className="screen_frame_img">
                                    <img src={ require( "./images/app6.png" ) } alt="image" />
                                </div>
                            </div>
                            <div className="item">
                                <div className="screen_frame_img">
                                    <img src={ require( "./images/app7.png" ) } alt="image" />
                                </div>
                            </div>
                            <div className="item">
                                <div className="screen_frame_img">
                                    <img src={ require( "./images/app4.png" ) } alt="image" />
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>

                </div>

            </section>



            <section className="row_am free_app_section" id="getstarted">
                <div className="container">
                    <div className="free_app_inner" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="free_text">
                                    <div className="section_title">
                                        <h2>{ t( 'landingSite.downloadTitle' ) }</h2>
                                        <p>{ t( "landingSite.downloadBody" ) }</p>
                                    </div>
                                    <ul className="app_btn">
                                        <li>
                                            <a href="https://apps.apple.com/ca/app/moonode/id1508859104" target="_blank">
                                                <img src={ require( "./images/appstore_blue.png" ) } alt="image" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://play.google.com/store/apps/details?id=com.moonode.moonode&hl=en&gl=US" target="_blank">
                                                <img src={ require( "./images/googleplay_blue.png" ) } alt="image" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="free_img">
                                    <img src={ require( "./images/download-screen01.png" ) } alt="image" />
                                    <img className="mobile_mockup" src={ require( "./images/download-screen02.png" ) } alt="image" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </section>



            <section class="row_am latest_story blog_list_story" id="blog">
                <div class="container">
                    <div class="section_title" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
                        <h2>{ t( 'landingSite.rgb23' ) } <span>{ t( 'landingSite.raspberry' ) }</span></h2>
                    </div>
                    <div class="row">


                        <div class="col-md-4" style={ { visibility: "hidden" } }>
                            <div class="story_box" data-aos="fade-up" data-aos-duration="1500">
                                <div class="story_img">
                                    <img src={ require( "./images/story06.png" ) } alt="image" />
                                    <span></span>
                                </div>
                                <div class="story_text">
                                    <h3></h3>
                                    <p></p>
                                    <a href=""></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4" >

                            <div class="story_box" data-aos="fade-up" data-aos-duration="1500">
                                <a href="/raspberry" style={ { cursor: "pointer" } }>

                                    <div class="story_img">
                                        <img src={ require( "./images/story08.png" ) } alt="image" />
                                    </div>
                                    <div class="story_text">
                                        <h3>{ t( 'landingSite.howItWork' ) }</h3>
                                        <p>{ t( 'landingSite.raspberryBody' ) }</p>
                                        <a > { t( 'common.seemore' ) } </a>
                                    </div>
                                </a>

                            </div>

                        </div>

                        <div class="col-md-4" style={ { visibility: "hidden" } }>
                            <div class="story_box" data-aos="fade-up" data-aos-duration="1500">
                                <div class="story_img">
                                    <img src={ require( "./images/story08.png" ) } alt="image" />
                                    <span></span>
                                </div>
                                <div class="story_text">
                                    <h3>{ t( 'landingSite.howItWork' ) }</h3>
                                    <p>{ t( 'landingSite.raspberryBody' ) }</p>
                                    <a href="blog-single.html"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section className="newsletter_section">

                <div className="container">
                    <div className="newsletter_box">
                        <div className="form-group">
                            <img src={ require( "./images/discord.png" ) } style={ { width: "100px", height: "100px", objectFit: "contain", borderRadius: "100px" } } alt="image" />
                        </div>
                        <div className="section_title" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
                            <h2>{ t( 'landingSite.discordTitle' ) }</h2>
                            <p>{ t( 'landingSite.discordBody' ) }</p>
                        </div>
                        <form action="" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
                            <div className="form-group" style={ { marginLeft: "20%" } }>
                                <a className="btn" href="https://discord.gg/GnBVVEEcYr" target="_blank" style={ { paddingTop: '9%' } }>  Discord</a>
                            </div>

                        </form>
                    </div>
                </div>

            </section>
            <footer>
                <div className="top_footer" id="contact">

                    <div className="container">

                        <div className="row">

                            <span className="banner_shape1"> <img src={ require( '../../assets/img/mashaAllah-actif.png' ) } alt="image" /> </span>
                            <span className="banner_shape2"> <img src={ require( '../../assets/img/mashaAllah-actif.png' ) } alt="image" /> </span>
                            <span className="banner_shape3"> <img src={ require( '../../assets/img/mashaAllah-actif.png' ) } alt="image" /> </span>


                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="abt_side">
                                    <div className="logo"> <img src="https://general.eu-central-1.linodeobjects.com/basics/platforms/moonode_logo.png" alt="image" /></div>
                                    <ul>
                                        <li><a href="mailto:admin@moonode.com" style={ { color: "#3A3A3A" } } >admin@moonode.com</a></li>
                                        <li><a href="https://wa.me/qr/ZEHYBW5XNPG5B1" style={ { color: "#3A3A3A" } } > WhatsApp</a></li>
                                    </ul>
                                    <ul className="social_media">
                                        <a href="https://www.facebook.com/Moonodeapp/" target="_blank" ><i className="fab fa-facebook" style={ { color: "#3A3A3A", backgroundColor: "transparent", fontSize: "2.89em" } }></i></a>
                                        <a href="https://www.youtube.com/channel/UCRJpB4Cr1lsHua1pbhfDQRQ" target="_blank"  ><i className="fab fa-youtube" style={ { color: "#3A3A3A", backgroundColor: "transparent", fontSize: "2.89em" } }></i></a>
                                        <a href="https://twitter.com/moonode_app" target="_blank" ><i className="fab fa-twitter" style={ { color: "#3A3A3A", backgroundColor: "transparent", fontSize: "2.89em" } }></i></a>
                                        <a href="https://www.instagram.com/moonode.app/?hl=en" target="_blank" ><i className="fab fa-instagram" style={ { color: "#3A3A3A", backgroundColor: "transparent", fontSize: "2.89em" } }></i></a>
                                    </ul>
                                </div>
                            </div>


                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="links">
                                    <h3>{ t( "landingSite.usefulLink" ) }</h3>
                                    <ul>
                                        <li><a href="/" style={ { color: "#3A3A3A" } }>Home</a></li>
                                        <li><a href="/aboutus" style={ { color: "#3A3A3A" } }>About us</a></li>
                                        <li><a href="/contactus" style={ { color: "#3A3A3A" } }>Contact us</a></li>
                                    </ul>
                                </div>
                            </div>


                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="links">
                                    <h3>{ t( "landingSite.helpNsupport" ) }</h3>

                                    <ul>
                                        <li><a href="/terms" style={ { color: "#3A3A3A" } }>Terms & conditions</a></li>
                                        <li><a href="/terms" style={ { color: "#3A3A3A" } }>Privacy policy</a></li>
                                    </ul>
                                </div>
                            </div>


                            <div className="col-lg-2 col-md-6 col-12">
                                <div className="try_out">
                                    <h3>Moonode</h3>
                                    <ul className="app_btn">
                                        <li>
                                            <a href="https://apps.apple.com/ca/app/moonode/id1508859104" target="_blank">
                                                <img src={ require( "./images/appstore_blue.png" ) } alt="image" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://play.google.com/store/apps/details?id=com.moonode.moonode&hl=en&gl=US" target="_blank">
                                                <img src={ require( "./images/googleplay_blue.png" ) } alt="image" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="bottom_footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <p>© Copyrights { new Date().getUTCFullYear() }. All rights reserved.</p>
                            </div>
                            <div className="col-md-6">
                                <p className="developer_text">{ t( 'landingSite.designedWith' ) } <a href="https://en.wikipedia.org/wiki/Montreal" target="blank">Montreal</a></p>
                            </div>
                        </div>

                    </div>

                </div>


                <div className="go_top">
                    <span><img src={ require( "./images/go_top.png" ) } alt="image" /></span>
                </div>
            </footer>



            <div className="modal fade youtube-video" id="myModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <button id="close-video" type="button" className="button btn btn-default text-right" data-dismiss="modal">
                            <i className="icofont-close-line-circled"></i>
                        </button>
                        <div className="modal-body">
                            <div id="video-container" className="video-container">
                                <iframe id="youtubevideo" src="" width="640" height="360" frameBorder="0" allowFullScreen></iframe>
                            </div>
                        </div>
                        <div className="modal-footer">
                        </div>
                    </div>
                </div>
            </div>

            <div className="purple_backdrop"></div>

        </div >
    )
}

const mapStateToProps = ( state ) => ( {

} )

const mapDispatchToProps = {

}

export default connect( mapStateToProps, mapDispatchToProps )( Index )
