import axios from "axios";
import { setAlert } from "./alert";

import {
    GET_PROFILE,
    GET_USER_INFO
} from "./types";

const config = { headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "http://localhost:3000" } };

export const getUserProfile = ( user ) => async dispatch => {
    try {
        const res = await axios.get( `/native/m/application/profile/user/to/get/${ user }` );
        dispatch( {
            type: GET_PROFILE,
            payload: res.data
        } );

    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};

export const removeEducation = id => async dispatch => {
    try {
        const res = await axios.delete( `/native/m/application/profile/robocop/education/${ id }` );
        dispatch( {
            type: GET_PROFILE,
            payload: res.data
        } );
    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};

export const updateProfile = profile => async dispatch => {

    try {
        const res = await axios.post( "/native/m/application/profile/robocop/", profile );
        dispatch( {
            type: GET_PROFILE,
            payload: res.data
        } );
    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
}

export const addEducation = ( education, navigation ) => async dispatch => {
    try {
        const res = await axios.post( "/native/m/application/profile/robocop/education",
            education
        );
        dispatch( {
            type: GET_PROFILE,
            payload: res.data
        } );
        navigation.navigate( 'Profile' );
        return true;
    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};
export const getUserInfo = ( userId ) => async dispatch => {
    try {
        const res = await axios.get( `/tapy/userProfile/get/info/${ userId }` );
        dispatch( {
            type: GET_USER_INFO,
            payload: res.data.response
        } );
    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};

export const updateUserInfo = ( userInfo ) => async dispatch => {
    const body = JSON.stringify( userInfo );
    try {
        const res = await axios.post( `/api/profile/changeInfo/user/and/handle`, body, config );
        window.location.reload();
    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};
export const addPictureToProfile = ( data ) => async dispatch => {

    try {
        let url = "/native/m/application/profile/robocop/change/profilePicture"
        let res = await axios.post( url, data, { headers: { 'Content-type': 'application/x-www-form-urlencoded' } } )
        return true;

    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};
export const getPictureFromInternet = ( url ) => async dispatch => {

    try {
        const response = await axios.get( url );
        const blob = response.data;
        const reader = new FileReader();
        reader.readAsDataURL( blob );
        console.log( blob )
        // return await new Promise( resolve => {
        //     reader.onloadend = () => resolve( reader.result );
        // } );


    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};


