import {
    GET_SEARCH,
    SET_LOADING_SEARCH
} from "../action/types";

const initialState = {
    result: [],
    searchLoading: false,
};

export default function ( state = initialState, action ) {
    const { type, payload } = action;

    switch ( type ) {
        case SET_LOADING_SEARCH:
            return {
                ...state,
                searchLoading: payload
            };
        case GET_SEARCH:
            return {
                ...state,
                result: payload
            };
        default:
            return state;
    }
}
