import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'


export const Notice = ( { onChange, tvSettings } ) => {
    const { t } = useTranslation();
    function checkStringLength ( str ) {
        if ( str.length > 100 ) {
            return true;
        }
        return false;
    }
    return (

        <div className="container-fluid elementToFadeInAndOut">
            <div className="inner-body">
                <div className="row row-sm" >
                    <div className="col-lg-12 col-md-12">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div>
                                    <h6 className="main-content-label mb-1">{ t( "website.tvNotice" ) }</h6>
                                    <p className="text-muted card-sub-title">{ t( "website.tvNoticeInfo" ) } </p>
                                </div>
                                <div className="form-group">
                                    <select
                                        className="form-control select-lg select2"
                                        name="isActivated"
                                        value={ tvSettings.isActivated }
                                        onChange={ ( e ) => onChange( e ) }>
                                        <option value={ false }>{ t( "common.activte" ) }</option>
                                        <option value={ true }>{ t( "common.desactive" ) }</option>
                                    </select>
                                </div>
                            </div>
                            <div className="card-body">
                                <div>
                                    <h6 className="main-content-label mb-1">{ t( "website.tvDirection" ) } </h6>
                                    <p className="text-muted card-sub-title">{ t( "website.tvDirectionInfo" ) }</p>
                                </div>
                                <div className="form-group">
                                    <select
                                        className="form-control select-lg select2"
                                        name="direction"
                                        value={ tvSettings.direction }
                                        onChange={ ( e ) => onChange( e ) }>
                                        <option value={ "rtl" }>{ t( "website.rightToLeft" ) }</option>
                                        <option value={ "ltr" }>{ t( "website.leftToRight" ) }</option>
                                    </select>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="form-group mb-0">
                                    <p className="mg-b-10">{ t( "website.notice" ) }</p>
                                    <textarea className="form-control" onChange={ ( e ) => onChange( e ) }
                                        name="message"
                                        rows="3"
                                        value={ tvSettings.message }
                                        placeholder={ "بسم الله الرحمن الرحيم الحمد لله رب العالمين، والصلاة والسلام على سيدنا محمد وعلى آله وصحبه أجمعين" } ></textarea>
                                    { checkStringLength( tvSettings.message ) &&
                                        <small style={ { color: "red" } }>{ t( 'website.tvIstooLong' ) }</small> }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>



    )
}

const mapStateToProps = ( state ) => ( {

} )

const mapDispatchToProps = {

}


export default connect( mapStateToProps, mapDispatchToProps )( Notice )