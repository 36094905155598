import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Mosques from '../../components/mosques/Index';
import { setRoute, disableRoute } from '../../action/routes'

export const Index = ( { setRoute, disableRoute } ) => {
    useEffect( () => {
        setRoute( 'user/mosques' );
        return () => {
            disableRoute()
        }

    }, [] )
    return (
        <Mosques />
    )
}

const mapStateToProps = ( state ) => ( {

} )



export default connect( mapStateToProps, { setRoute, disableRoute } )( Index )
