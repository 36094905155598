import React from 'react'
import { connect } from 'react-redux'

import './style.css'


export const TvRole = ( props ) => {
    return (

        <div className="container-fluid elementToFadeInAndOut">
            <div className="inner-body">
                <div className="row row-sm" >
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div className="table-responsive border userlist-table">
                                    <div className="row row-sm" >
                                        <div className="col-lg-12 col-md-12">
                                            <div className="card custom-card">
                                                <div className="card-body">
                                                    <div>
                                                        <h6 className="main-content-label mb-1">Courriel input</h6>
                                                        <p className="text-muted card-sub-title">Le courriel entré ici doit etre un courriel existant sur Moonode. Entre autre, l'utilisateur que vous devez ajouter doit etre inscrit sur l'application Moonode et ce avant l'ajout. </p>
                                                    </div>
                                                    <div className="input-group telephone-input">
                                                        <input type="tel" id="mobile-number" style={ { width: "30%" } } placeholder="abou_foulane@exemple.com" />
                                                        <span className="input-group-btn">
                                                            <button className="btn ripple btn-primary" type="button">Ajouter</button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>



    )
}

const mapStateToProps = ( state ) => ( {

} )

const mapDispatchToProps = {

}

export default connect( mapStateToProps, mapDispatchToProps )( TvRole )
