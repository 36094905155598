import {
    GET_COMMENTS,
    GET_POST_ENTITY,
    GET_SPECIAL_POST_ENTITY
} from "../action/types";

const initialState = {
    posts: [],
    post: null,
    id: null,
    postComments: [],
    postEntity: [],
    postEntityId: null,
    postEnd: false,
    loading: true,
    idComments: null,
    theLoadingComment: false,
    specialPostLoading: true,
    specialPostsAudio: [],
    specialPostsVideo: [],
    specialPostsLink: [],
    specialPostsPdf: [],
    specialPostsImage: [],
    specialPostsTv: [],
    error: {}
};

export default function ( state = initialState, action ) {
    const { type, payload } = action;
    switch ( type ) {
        case GET_COMMENTS:
            return {
                ...state,
                postComments: payload,
                idComments: payload._id,
                loading: false
            };
        case GET_POST_ENTITY:
            return {
                ...state,
                postEntity: payload.posts.posts,
                postEntityId: payload.id,
                postEnd: payload.posts.end,
                loading: false
            };
        case GET_SPECIAL_POST_ENTITY:
            return {
                ...state,
                specialPostsAudio: payload.posts.specialPosts.audio,
                specialPostsVideo: payload.posts.specialPosts.video,
                specialPostsPdf: payload.posts.specialPosts.pdf,
                specialPostsTv: payload.posts.specialPosts.tv,
                specialPostsLink: payload.posts.specialPosts.link,
                specialPostsImage: payload.posts.specialPosts.image,
                postEntityId: payload.id,
                postEnd: payload.posts.end,
                specialPostLoading: false
            };
        default: {
            return {
                ...state
            }
        }
    }
}
