import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { isMobile, isTablet } from '../../utils/device';
import saoudi from '../../assets/flags/saoudi.png';
import england from '../../assets/flags/england.png';
import french from '../../assets/flags/french.png';
import pakistan from '../../assets/flags/pakistan.png';
import bangladesh from '../../assets/flags/bangladesh.jpeg';
import { sendEmailToMoonode } from '../../action/auth';
import './css/icofont.min.css'
import './css/owl.carousel.min.css'
import './css/bootstrap.min.css'
import './css/aos.min.css'
import './css/responsive.min.css'
import './css/style.min.css'

export const Index = ( { sendEmailToMoonode } ) => {
    const [ inputData, setInputData ] = useState( {
        name: "",
        email: "",
        subject: "",
        message: ""
    } );
    const [ success, setSuccess ] = useState( {
        name: true,
        email: true,
        message: true,
        subject: true,
    } );
    const onChange = e => {
        setInputData( { ...inputData, [ e.target.name ]: e.target.value } );
    };

    const onSubmit = () => {
        if ( inputData.name == '' || inputData.email == '' || inputData.message == '' || inputData.subject == '' ) {
            setSuccess( {
                ...success,
                email: inputData.email != '' ? true : false,
                name: inputData.name != '' ? true : false,
                message: inputData.message != '' ? true : false,
                subject: inputData.subject != '' ? true : false,
            } );
        } else {
            sendEmailToMoonode( inputData.name, inputData.email, inputData.subject, inputData.message );
            setTimeout( () => {
                window.location.replace( '/' )
            }, 4000 );
        }
    };

    const { t, i18n } = useTranslation();

    const onChangeLanguage = ( language ) => {
        i18n.changeLanguage( language )
        localStorage.setItem( "lang", language )
    }
    return (
        <div className="page_wrapper">
            <div className="page_wrapper">
                <div id="preloader">
                    <div id="loader">
                        <img alt="avatar" src="https://general.eu-central-1.linodeobjects.com/basics/platforms/moonode_logo.png" style={ { height: '130px', width: '130px' } } />
                    </div>
                </div>

                <header>
                    <div className="container">
                        <nav className="navbar navbar-expand-lg">
                            <a className="navbar-brand" href="#">
                                <img src="https://general.eu-central-1.linodeobjects.com/basics/platforms/moonode_logo.png" style={ { height: isMobile() ? '45px' : '30px', width: isMobile() ? '45px' : '30px' } } alt="image" />
                            </a>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon">
                                    <div className="toggle-wrap">
                                        <span className="toggle-bar"></span>
                                    </div>
                                </span>
                            </button>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item active">
                                        <a className="nav-link" href="/">{ t( 'application.home' ) }</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/aboutus">{ t( 'common.about' ) }</a>
                                    </li>
                                    <li className="nav-item has_dropdown">
                                        <a className="nav-link" href="#">{ t( 'profile.language' ) }</a>
                                        <span className="drp_btn">⤵</span>
                                        <div className={ `${ isMobile() ? "" : "sub_menu" }` }>
                                            <ul>
                                                <li> <a style={ { cursor: "pointer" } } onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'ar' ) } } className="dropdown-item d-flex ">
                                                    <span className="avatar  mr-3 align-self-center bg-transparent"><img src={ saoudi } alt="img" /></span>
                                                    <div className="d-flex">
                                                        <span className="mt-2">{ t( "website.arabic" ) }</span>
                                                    </div>
                                                </a></li>
                                                <li> <a style={ { cursor: "pointer" } } onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'en' ) } } className="dropdown-item d-flex">
                                                    <span className="avatar  mr-3 align-self-center bg-transparent"><img src={ england } alt="img" /></span>
                                                    <div className="d-flex">
                                                        <span className="mt-2">{ t( "website.english" ) }</span>
                                                    </div>
                                                </a></li>
                                                <li> <a style={ { cursor: "pointer" } } onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'fr' ) } } className="dropdown-item d-flex">
                                                    <span className="avatar mr-3 align-self-center bg-transparent"><img src={ french } alt="img" /></span>
                                                    <div className="d-flex">
                                                        <span className="mt-2">{ t( "website.french" ) }</span>
                                                    </div>
                                                </a></li>
                                                <li> <a style={ { cursor: "pointer" } } onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'ur' ) } } className="dropdown-item d-flex">
                                                    <span className="avatar mr-3 align-self-center bg-transparent"><img src={ pakistan } alt="img" /></span>
                                                    <div className="d-flex">
                                                        <span className="mt-2">{ t( "website.urdu" ) }</span>
                                                    </div>
                                                </a></li>
                                                <li> <a style={ { cursor: "pointer" } } onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'bn' ) } } className="dropdown-item d-flex">
                                                    <span className="avatar mr-3 align-self-center bg-transparent"><img src={ bangladesh } alt="img" /></span>
                                                    <div className="d-flex">
                                                        <span className="mt-2">{ t( "website.bengali" ) }</span>
                                                    </div>
                                                </a></li>
                                            </ul>
                                        </div>
                                    </li>

                                    <li className="nav-item has_dropdown">
                                        <a className="nav-link" href="#">{ t( 'landingSite.blog' ) }</a>
                                        <span className="drp_btn">⤵</span>
                                        <div className={ `${ isMobile() ? "" : "sub_menu" }` }>
                                            <ul>
                                                <li><a style={ { marginLeft: isMobile() ? "30px" : "" } } href="/raspberry">Raspberry</a></li>

                                            </ul>
                                        </div>
                                    </li>


                                    <li className="nav-item">
                                        <a className="nav-link" href="/contactus">{ t( 'application.contactUs' ) }</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link dark_btn" href="/signin">{ t( 'landing.signin' ) }</a>
                                    </li>
                                </ul>
                            </div>
                        </nav>

                    </div>

                </header>

                <div className="bred_crumb">
                    <div className="container">
                        <span className="banner_shape1"> <img src={ require( '../../assets/img/mashaAllah-actif.png' ) } style={ { width: "40px", height: "40px" } } alt="image" /> </span>
                        <span className="banner_shape2"> <img src={ require( '../../assets/img/mashaAllah-actif.png' ) } style={ { width: "40px", height: "40px" } } alt="image" /> </span>
                        <span className="banner_shape3"> <img src={ require( '../../assets/img/mashaAllah-actif.png' ) } style={ { width: "40px", height: "40px" } } alt="image" /> </span>

                        <div className="bred_text">
                            <h1>{ t( "application.aboutUs" ) }</h1>
                            <ul>
                                <li><a href="/">{ t( "application.home" ) }</a></li>
                                <li><span>»</span></li>
                                <li><a href="/aboutus">{ t( "application.aboutUs" ) }</a></li>
                            </ul>
                        </div>
                    </div>
                </div>


                <br />
                <br />
                <br />
                {/* <!-- Contact Us Section Start --> */ }
                <section className="contact_page_section">
                    <div className="container">
                        <div className="contact_inner">
                            <div className="contact_form">
                                <div className="section_title">
                                    <h2>{ t( 'application.contactUs' ) } <span>{ t( '' ) }</span></h2>
                                    <p> { t( 'common.about' ).toLowerCase() } { t( 'application.ourServices' ).toLowerCase() }  </p>
                                </div>
                                <form action="">
                                    <div className="form-group">
                                        <input name="name" type="text" placeholder={ t( 'common.name' ) } onChange={ ( e ) => onChange( e ) } className="form-control" />
                                        { !success.name && <small style={ { color: "red" } }> { t( 'common.add' ) } { " " } { t( 'common.name' ).toLowerCase() }</small> }
                                    </div>
                                    <div className="form-group">
                                        <input name="email" type="email" placeholder={ t( 'common.email' ) } onChange={ ( e ) => onChange( e ) } className="form-control" />
                                        { !success.email && <small style={ { color: "red" } }> { t( 'common.add' ) } { " " } { t( 'common.email' ).toLowerCase() }</small> }

                                    </div>
                                    <div className="form-group">
                                        <input name="subject" type="subject" placeholder={ t( "application.subjectnewsletter" ) } onChange={ ( e ) => onChange( e ) } className="form-control" />
                                        { !success.subject && <small style={ { color: "red" } }> { t( "application.subjectnewsletter" ).toLowerCase() }</small> }

                                    </div>
                                    <div className="form-group">
                                        <textarea name="message" className="form-control" onChange={ ( e ) => onChange( e ) } placeholder={ t( "feed.write" ) }></textarea>
                                        { !success.message && <small style={ { color: "red" } }> { t( "feed.write" ).toLowerCase() }</small> }

                                    </div>

                                    <div className="nav flex-column flex-md-row align-items-center justify-content-center">
                                        <a href="https://discord.gg/GnBVVEEcYr" target="_blank" className="btn btn-outline-warning btn-lg py-3 px-4 bold"
                                            onClick={ ( e ) => { e.preventDefault(); onSubmit() } }
                                        >{ t( 'application.send' ) } </a>
                                    </div>
                                </form>
                            </div>
                            <div className="contact_info">
                                <div className="icon"><img src={ require( "./images/contact_message_icon.png" ) } alt="image" /></div>
                                <div className="section_title">
                                    <h2>{ t( 'landingSite.whyandwebsite' ) } & <span>{ t( 'landingSite.whyandwebsite7' ) }?</span></h2>
                                    <p>{ t( 'landingSite.contactUsP' ) }</p>
                                </div>
                                <ul className="contact_info_list">
                                    <li>
                                        <div className="img">
                                            <img src={ require( "./images/mail_icon.png" ) } alt="image" />
                                        </div>
                                        <div className="text">
                                            <span>{ t( 'common.email' ) }</span>
                                            <a href="mailto:example@gmail.com">admin@moonode.com</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img src={ require( "./images/call_icon.png" ) } alt="image" />
                                        </div>
                                        <div className="text">
                                            <span>WhatsApp</span>
                                            <a href="https://wa.me/qr/ZEHYBW5XNPG5B1" target={ "_blank" }>{ t( 'landingSite.contact3' ) }</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img src={ require( "./images/location_icon.png" ) } alt="image" />
                                        </div>
                                        <div className="text">
                                            <span>Discord</span>
                                            <a href="https://discord.gg/GnBVVEEcYr" target="_blank">Moonode Page</a>

                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Contact Us Section End --> */ }
                <footer>
                    <div className="top_footer" id="contact">

                        <div className="container">

                            <div className="row">

                                <span className="banner_shape1"> <img src={ require( '../../assets/img/mashaAllah-actif.png' ) } alt="image" /> </span>
                                <span className="banner_shape2"> <img src={ require( '../../assets/img/mashaAllah-actif.png' ) } alt="image" /> </span>
                                <span className="banner_shape3"> <img src={ require( '../../assets/img/mashaAllah-actif.png' ) } alt="image" /> </span>


                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="abt_side">
                                        <div className="logo"> <img src="https://general.eu-central-1.linodeobjects.com/basics/platforms/moonode_logo.png" alt="image" /></div>
                                        <ul>
                                            <li><a href="mailto:admin@moonode.com" style={ { color: "#3A3A3A" } } >admin@moonode.com</a></li>
                                            <li><a href="https://wa.me/qr/ZEHYBW5XNPG5B1" style={ { color: "#3A3A3A" } } > WhatsApp</a></li>
                                        </ul>
                                        <ul className="social_media">
                                            <a href="https://www.facebook.com/Moonodeapp/" target="_blank" ><i className="fab fa-facebook" style={ { color: "#3A3A3A", backgroundColor: "transparent", fontSize: "2.89em" } }></i></a>
                                            <a href="https://www.youtube.com/channel/UCRJpB4Cr1lsHua1pbhfDQRQ" target="_blank"  ><i className="fab fa-youtube" style={ { color: "#3A3A3A", backgroundColor: "transparent", fontSize: "2.89em" } }></i></a>
                                            <a href="https://twitter.com/moonode_app" target="_blank" ><i className="fab fa-twitter" style={ { color: "#3A3A3A", backgroundColor: "transparent", fontSize: "2.89em" } }></i></a>
                                            <a href="https://www.instagram.com/moonode.app/?hl=en" target="_blank" ><i className="fab fa-instagram" style={ { color: "#3A3A3A", backgroundColor: "transparent", fontSize: "2.89em" } }></i></a>
                                        </ul>
                                    </div>
                                </div>


                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="links">
                                        <h3>{ t( "landingSite.usefulLink" ) }</h3>
                                        <ul>
                                            <li><a href="/" style={ { color: "#3A3A3A" } }>Home</a></li>
                                            <li><a href="/aboutus" style={ { color: "#3A3A3A" } }>About us</a></li>
                                            <li><a href="/contactus" style={ { color: "#3A3A3A" } }>Contact us</a></li>
                                        </ul>
                                    </div>
                                </div>


                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="links">
                                        <h3>{ t( "landingSite.helpNsupport" ) }</h3>

                                        <ul>
                                            <li><a href="/terms" style={ { color: "#3A3A3A" } }>Terms & conditions</a></li>
                                            <li><a href="/terms" style={ { color: "#3A3A3A" } }>Privacy policy</a></li>
                                        </ul>
                                    </div>
                                </div>


                                <div className="col-lg-2 col-md-6 col-12">
                                    <div className="try_out">
                                        <h3>Moonode</h3>
                                        <ul className="app_btn">
                                            <li>
                                                <a href="https://apps.apple.com/ca/app/moonode/id1508859104" target="_blank">
                                                    <img src={ require( "./images/appstore_blue.png" ) } alt="image" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://play.google.com/store/apps/details?id=com.moonode.moonode&hl=en&gl=US" target="_blank">
                                                    <img src={ require( "./images/googleplay_blue.png" ) } alt="image" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="bottom_footer">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <p>© Copyrights { new Date().getUTCFullYear() }. All rights reserved.</p>
                                </div>
                                <div className="col-md-6">
                                    <p className="developer_text">{ t( 'landingSite.designedWith' ) } <a href="https://en.wikipedia.org/wiki/Montreal" target="blank">Montreal</a></p>
                                </div>
                            </div>

                        </div>

                    </div>


                    <div className="go_top">
                        <span><img src={ require( "./images/go_top.png" ) } alt="image" /></span>
                    </div>
                </footer>
            </div>
        </div>
    )
}

const mapStateToProps = ( state ) => ( {

} )



export default connect( mapStateToProps, { sendEmailToMoonode } )( Index )
