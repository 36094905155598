
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export default function App ( { children, modalIsOpen, openModal, closeModal, isTv } ) {
    const { t } = useTranslation();
    return (
        <Modal isOpen={ modalIsOpen } toggle={ closeModal }>
            <ModalHeader toggle={ closeModal }> { isTv ? t( 'entity.posttv' ) : t( 'entity.post' ) }</ModalHeader>
            <ModalBody>
                { children }
            </ModalBody>
        </Modal>
    );
}
