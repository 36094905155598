import { formatDistanceToNow } from 'date-fns'
import React from 'react'
import FeedMedia from '../media/FeedMedia'
import KindPost from '../media/KindPost'
import ShortComment from '../media/ShortComment'
import { enCA } from 'date-fns/locale'

function Index ( { postComments, show = false } ) {
    return (
        <div>
            <div class="main-content pt-0">

                <div class="container">
                    <div class="inner-body">

                        <div class="page-header">
                            <div>
                                <h2 class="main-content-title tx-24 mg-b-5">Post</h2>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Shared</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">{ postComments.name }</li>
                                </ol>
                            </div>
                        </div>



                        <div class="row row-sm" >
                            <div class="col-xl-12 col-lg-12 col-md-12">
                                <div class="card custom-card overflow-hidden">
                                    <div class="px-4 pt-4">
                                        <div className="media-user me-2" onClick={ () => window.location.replace( `/entity/${ postComments.url }` ) } style={ { cursor: "pointer" } }>
                                            <div className="main-img-user avatar-md"><img alt="" className="rounded-circle" src={ postComments.picture } /></div>
                                        </div>
                                        <div className="media-body" onClick={ () => window.location.replace( `/entity/${ postComments.url }` ) } style={ { cursor: "pointer" } }>
                                            <h6 className="mb-0 mg-t-2 ms-2"> { " " } { postComments.name }</h6><span className="text-primary ms-2">{ formatDistanceToNow( new Date( postComments.date ), { locale: enCA } ) }</span> </div>
                                    </div>
                                    <div class="card-body">
                                        <FeedMedia data={ postComments } />
                                        <div class="item-card-desc d-md-flex mb-3" style={ { visibility: "hidden" } }>
                                            <a href="#" class="d-flex me-3 mb-2"><span class="fe fe-calendar text-muted me-2 tx-18"></span><div class="mt-0 mt-0 text-dark"></div></a>
                                            <a href="#" class="d-flex mb-2"><span class="fe fe-user text-muted me-2 tx-18"></span><div class="mt-0 mt-0 text-dark"></div></a>
                                            <div class="ms-auto mb-2">
                                                <a class="me-0 d-flex" href="#"><span class="fe fe-message-square text-muted me-2 tx-18"></span><div class="mt-0 mt-0 text-dark"></div></a>
                                            </div>
                                        </div>
                                        <KindPost kindPost={ postComments.kindPost } />
                                        <ShortComment comment={ postComments.text } length={ postComments.text.length } />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index
