import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Adkar from '../../utils/adkhar/sabah.json'
import Prayer from '../../containers/dashboard/prayerWdiget/Index';
import { useTranslation } from 'react-i18next';
import { setRoute, disableRoute } from '../../action/routes'
import { isMobile, isTablet } from '../../utils/device';

function Adhkarm ( { setRoute, disableRoute } ) {
    const { t } = useTranslation();
    useEffect( () => {
        setRoute( 'user/adkharm' );
        return () => {
            disableRoute()
        }

    }, [] )

    return (
        <div className="main-content side-content pt-0">
            <div className="container-fluid" style={ { marginLeft: isMobile() ? "" : "70px" } } >
                <div className="inner-body justify-content-center">
                    <div className="page-header">
                        <div>
                            <h2 className="main-content-title tx-24 mg-b-5">{ "Adkhar" }</h2>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Adkhar</a></li>
                                <li className="breadcrumb-item active" aria-current="page">{ t( "application.adkarsabah" ) }</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row row-sm" >
                        <div className="col-sm-12 col-lg-12 col-xl-8">
                            <div>
                                { Adkar.slice( 0, 100 ).map( ( i, index ) => (
                                    <div key={ index } style={ { margin: "-1rem" } }>
                                        <div className="main-content-body  tab-pane border-top-0" id="timeline" style={ { marginBottom: "18px" } }>
                                            <div className="">
                                                <div className="main-content-body main-content-body-profile">
                                                    <div className="main-profile-body p-0">
                                                        <div className="row row-sm" >
                                                            <div className="col-12">
                                                                <div className="card mg-b-20 border" >
                                                                    <div class="card custom-card">
                                                                        <div class="card-header custom-card-header border-bottom-0">
                                                                            <h5 class="main-content-label tx-dark tx-medium mb-0">{ i.repetition } { " " }X</h5>
                                                                        </div>
                                                                        <div class="card-body">
                                                                            <blockquote class="blockquote mb-0">
                                                                                <br />
                                                                                <p>{ i.dhikr }</p>
                                                                                <footer class="blockquote-footer">{ i.dhikrArFr } </footer>
                                                                            </blockquote>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) ) }
                            </div>
                        </div>
                        <div className="sticky-top" style={ { marginLeft: "10px", alignContent: "center", height: "45rem", width: "28%", top: "5rem", zIndex: -1 } }>
                            <div className="card custom-card" style={ { alignItems: "center", width: "100%", height: "70%", borderRadius: "2rem" } }>
                                <div style={ { width: "90%", height: "50rem", } }>
                                    <Prayer show={ false } isText={ true } text={ "Adhan Montreal" } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

const mapStateToProps = ( state ) => ( {

} )

export default connect( mapStateToProps, { setRoute, disableRoute } )( Adhkarm )

