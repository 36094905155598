import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import CommentUi from '../../components/comments/feedComments';

//action
import { getComment, postCommentFeed, deleteComment } from "../../action/post";


export const FeedComments = ( { feed, getComment, postCommentFeed, deleteComment, post: { postComments, loading, idComments }, open, auth: { user } } ) => {

    const [ value, onChangeText ] = useState( '' );

    const [ state, setstate ] = useState( {
        theId: feed.postsId,
        theNbr: feed.postNbr,
    } )

    useEffect( () => {
        getComment( state.theId, state.theNbr );

    }, [ open ] );


    const onSendComment = () => {
        let isPositive = postCommentFeed( value, feed.postsId, feed.postNbr );
        if ( isPositive ) getComment( feed.postsId, feed.postNbr )

    }
    const onChange = e => {
        onChangeText( e.target.value );
    };
    // setTimeout(() => setLoad(false), 900);

    return (

        loading ? <div className="spinner-grow" style={ { width: "5rem", height: "5rem", marginLeft: "40%" } } role="status">
            <span className="sr-only">Loading...</span>
        </div> : <CommentUi
            postComments={ postComments }
            loading={ loading }
            idComments={ idComments }
            open={ open }
            user={ user }
            deleteComment={ deleteComment }
            idComments={ idComments }
            onSendComment={ onSendComment }
            onChange={ onChange }
        />
    )
}

const mapStateToProps = ( state ) => ( {
    post: state.post,
    auth: state.auth

} )



export default connect( mapStateToProps, { getComment, postCommentFeed, deleteComment } )( FeedComments )
