import React, { useMemo, useState } from 'react'
import { connect } from 'react-redux'
import TextField from "@material-ui/core/TextField";
import { Switch, FormControlLabel } from '@material-ui/core';
import '../style.css'

import Dhuhr from "../../../../../assets/img/dhuhr.png";
import Asr from "../../../../../assets/img/asr.png";
import { useTranslation } from 'react-i18next';


export const B = ( {
    iqamaTimes,
    onChange,
    onJumua2,
    onJumua3,
    onJumua4,
} ) => {

    const languages = [
        { value: 'العربية', label: 'العربية' },
        { value: 'English', label: 'English' },
        { value: 'Français', label: 'Français' },
        { value: 'اردو', label: 'اردو' },
        { value: 'বাংলা', label: 'বাংলা' },
        { value: 'Türkçe', label: 'Türkçe' },
        { value: 'فارسی', label: 'فارسی' },
        { value: 'Indonesia', label: 'Indonesia' },
        { value: 'Melayu', label: 'Melayu' },
        { value: 'هَوُسَ', label: 'هَوُسَ' },
        { value: 'پښتو', label: 'پښتو' },
    ];

    const options = useMemo( () => {
        return languages.map( ( language ) => ( {
            value: language.value,
            label: language.label,
        } ) );
    }, [ languages ] );

    const [ selectedLanguage, setSelectedLanguage ] = useState( '' );


    const { t } = useTranslation();
    return (

        <div className="container-fluid elementToFadeInAndOut">
            <div className="inner-body">
                <div className="row row-sm" >
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
                        <div className="card custom-card">

                            <div className="card-body">
                                <div className="table-responsive border userlist-table">
                                    <table className="table card-table table-striped table-vcenter text-nowrap mb-0">
                                        <thead>
                                            <tr>
                                                <th className="wd-lg-8p"><span>{ t( "common.language" ) }     </span></th>
                                                <th className="wd-lg-20p"><span>{ t( "common.prayer" ) }</span></th>
                                                <th className="wd-lg-20p"><span>{ t( "website.fixedIqama" ) }</span></th>
                                                <th className="wd-lg-20p"><span>{ t( "common.activte" ) }\{ t( "common.desactive" ) }</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>

                                                    <select value={ iqamaTimes.JumuaLang } onChange={ onChange } name="JumuaLang">
                                                        <option value=""></option>
                                                        { options.map( ( option ) => (
                                                            <option key={ option.value } value={ option.value }>
                                                                { option.label }
                                                            </option>
                                                        ) ) }
                                                    </select>
                                                </td>
                                                <td className="text-black">{ t( "entity.jumua" ) }</td>

                                                <td>
                                                    { " " }
                                                    <TextField
                                                        onChange={ e => onChange( e ) }
                                                        id="Jumua"
                                                        value={ iqamaTimes.Jumua || '00:00' }
                                                        name="Jumua"
                                                        type="time"
                                                        InputLabelProps={ {
                                                            shrink: true
                                                        } }
                                                    />
                                                </td>
                                                <td>

                                                </td>

                                            </tr>

                                            <tr>
                                                <td>
                                                    <select value={ iqamaTimes.Jumua2Lang } onChange={ onChange } name="Jumua2Lang">
                                                        <option value=""></option>
                                                        { options.map( ( option ) => (
                                                            <option key={ option.value } value={ option.value }>
                                                                { option.label }
                                                            </option>
                                                        ) ) }
                                                    </select>
                                                </td>
                                                <td className="text-black">{ t( "entity.jumua" ) } 2</td>
                                                <td>
                                                    { " " }
                                                    <TextField
                                                        onChange={ e => onChange( e ) }
                                                        id="Jumua2"
                                                        name="Jumua2"
                                                        value={ iqamaTimes.Jumua2 || '00:00' }
                                                        type="time"
                                                        min="09:00"
                                                        max="18:00"
                                                        InputLabelProps={ {
                                                            shrink: true
                                                        } }
                                                    />
                                                </td>
                                                <td>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                size="small"
                                                                color="secondary"
                                                                checked={ iqamaTimes.toggleJumua }
                                                                value={ iqamaTimes.toggleJumua || false }
                                                                onChange={ () => onJumua2() } />
                                                        }
                                                        label={ iqamaTimes.toggleJumua ? "Activé" : "Désactivé" }
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <select value={ iqamaTimes.Jumua3Lang } onChange={ onChange } name="Jumua3Lang">
                                                        <option value=""></option>
                                                        { options.map( ( option ) => (
                                                            <option key={ option.value } value={ option.value }>
                                                                { option.label }
                                                            </option>
                                                        ) ) }
                                                    </select>
                                                </td>
                                                <td className="text-black">{ t( "entity.jumua" ) } 3</td>
                                                <td>
                                                    { " " }
                                                    <TextField
                                                        onChange={ e => onChange( e ) }
                                                        id="Jumua3"
                                                        name="Jumua3"
                                                        value={ iqamaTimes.Jumua3 || '00:00' }
                                                        type="time"
                                                        min="09:00"
                                                        max="18:00"
                                                        InputLabelProps={ {
                                                            shrink: true
                                                        } }
                                                    />
                                                </td>
                                                <td>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                size="small"
                                                                color="secondary"
                                                                checked={ iqamaTimes.toggleJumua2 }
                                                                value={ iqamaTimes.toggleJumua2 || false }
                                                                onChange={ () => onJumua3() } />
                                                        }
                                                        label={ iqamaTimes.toggleJumua2 ? "Activé" : "Désactivé" }
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <select value={ iqamaTimes.Jumua4Lang } onChange={ onChange } name="Jumua4Lang">
                                                        <option value=""></option>
                                                        { options.map( ( option ) => (
                                                            <option key={ option.value } value={ option.value }>
                                                                { option.label }
                                                            </option>
                                                        ) ) }
                                                    </select>
                                                </td>
                                                <td className="text-black">{ t( "entity.jumua" ) } 4</td>
                                                <td>
                                                    { " " }
                                                    <TextField
                                                        onChange={ e => onChange( e ) }
                                                        id="Jumua4"
                                                        name="Jumua4"
                                                        value={ iqamaTimes.Jumua4 || '00:00' }
                                                        type="time"
                                                        min="09:00"
                                                        max="18:00"
                                                        InputLabelProps={ {
                                                            shrink: true
                                                        } }
                                                    />
                                                </td>
                                                <td>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                size="small"
                                                                color="secondary"
                                                                checked={ iqamaTimes.toggleJumua3 }
                                                                value={ iqamaTimes.toggleJumua3 || false }
                                                                onChange={ () => onJumua4() } />
                                                        }
                                                        label={ iqamaTimes.toggleJumua3 ? "Activé" : "Désactivé" }
                                                    />
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    )
}

const mapStateToProps = ( state ) => ( {

} )

const mapDispatchToProps = {

}

export default connect( mapStateToProps, mapDispatchToProps )( B )
