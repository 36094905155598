import axios from "axios";
import { setAlert } from "./alert";
import {

    GET_COMMENTS,
    GET_POST_ENTITY,


} from "./types";

const config = { headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "http://localhost:3000" } };


//get comment
export const getComment = ( postParentId, postChildId ) => async dispatch => {
    try {

        const res = await axios.get( `/api/comment/${ postParentId }/${ postChildId }` );
        dispatch( {
            type: GET_COMMENTS,
            payload: res.data
        } );

        return true;

    } catch ( error ) {

        if ( error.response ) {

            const errors = error.response.data;

            if ( errors ) {

                if ( errors.message ) {

                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );

                } else {

                    dispatch( setAlert( errors, "register" ) );

                }
            }

        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {
            console.log( ' // Some other errors', error.message );
        }

        return false;
    }
};

//delete comment
export const deleteComment = ( id, commentId ) => async dispatch => {

    try {
        const res = await axios.delete(
            `native/m/application/posts/flyinthesky/delete/comment/${ id }/${ commentId }`,
            {
                codeType: "LAN-3"
            }
        );

        dispatch( {
            type: GET_COMMENTS,
            payload: res.data
        } );

    } catch ( error ) {
        if ( error.response ) {

            const errors = error.response.data;

            if ( errors ) {

                if ( errors.message ) {

                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );

                } else {

                    dispatch( setAlert( errors, "register" ) );

                }
            }

        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {
            console.log( ' // Some other errors', error.message );
        }

        return false;
    }
};

//post comment
export const postCommentFeed = ( comment, postId, id ) => async dispatch => {

    try {

        const res = await axios.post(
            `native/m/application/posts/flyinthesky/comment/feed/${ postId }/${ id }`, {
            codeType: "LAN-3",
            text: comment
        } );

        dispatch( {
            type: GET_COMMENTS,
            payload: res.data
        } );

        return true;

    } catch ( error ) {
        if ( error.response ) {

            const errors = error.response.data;

            if ( errors ) {

                if ( errors.message ) {

                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );

                } else {

                    dispatch( setAlert( errors, "register" ) );

                }
            }

        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {
            console.log( ' // Some other errors', error.message );
        }

        return false;
    }
};

//likes
export const addLike = ( postId, id ) => async dispatch => {

    try {

        const res = await axios.post( `native/m/application/posts/flyinthesky/like/${ postId }/${ id }`,
            {
                codeType: "LAN-3",
            }
        );

        await axios.get( `native/m/application/comment/khabib/${ postId }/${ id }` );
        return true;
    } catch ( error ) {
        if ( error.response ) {

            const errors = error.response.data;

            if ( errors ) {

                if ( errors.message ) {

                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );

                } else {

                    dispatch( setAlert( errors, "register" ) );

                }
            }

        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {
            console.log( ' // Some other errors', error.message );
        }

        return false;
    }
};


//tvChanges
export const tvChange = ( id, postId ) => async dispatch => {


    try {
        const res = await axios.post(
            `/api/posts/privacy/change/tv/${ id }/${ postId }`,

            config
        );

        setTimeout( () => {
            window.location.reload();
        }, 2000 );
        return true;
    } catch ( error ) {
        if ( error.response ) {

            const errors = error.response.data;

            if ( errors ) {

                if ( errors.message ) {

                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );

                } else {

                    dispatch( setAlert( errors, "register" ) );

                }
            }

        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {
            console.log( ' // Some other errors', error.message );
        }

        return false;
    }
};

//privacy
export const privacyChange = ( id, postId ) => async dispatch => {

    try {
        const res = await axios.post(
            `/api/posts/privacy/change/${ id }/${ postId }`,
            config
        );
        setTimeout( () => {
            window.location.reload();
        }, 2000 );
        return true;
    } catch ( error ) {
        if ( error.response ) {

            const errors = error.response.data;

            if ( errors ) {

                if ( errors.message ) {

                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );

                } else {

                    dispatch( setAlert( errors, "register" ) );

                }
            }

        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {
            console.log( ' // Some other errors', error.message );
        }

        return false;
    }
};

//deletepost
export const deletePost = ( id, postId ) => async dispatch => {

    try {
        const res = await axios.delete(
            `/api/posts/delete/${ id }/${ postId }`,
            config
        );
        setTimeout( () => {
            window.location.reload();
        }, 2000 );
    } catch ( error ) {
        if ( error.response ) {

            const errors = error.response.data;

            if ( errors ) {

                if ( errors.message ) {

                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );

                } else {

                    dispatch( setAlert( errors, "register" ) );

                }
            }

        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {
            console.log( ' // Some other errors', error.message );
        }

        return false;
    }
};

//add posts
export const addPosts = ( postId, bool, fileData ) => async dispatch => {
    let data = {};
    const body = JSON.stringify( postId );
    try {
        const res = await axios.post(
            `/api/posts/${ postId.postTypeId }/${ postId.type }`,
            body,
            config
        );

        let res2;

        const { posts, id } = res.data;
        if ( res.status === 200 && bool ) {
            res2 = await axios.post(
                `/api/posts/data/${ id }/${ posts.posts[ 0 ]._id }/${ postId.codeType }`,
                fileData,
                {
                    headers: {
                        accept: "application/json",
                        "Accept-Language": "en-US,en;q=0.8",
                        "Content-Type": `multipart/form-data; boundary=${ fileData._boundary }`
                    }
                }
            );
        }
        if ( bool ) {
            data.posts = res2.data;
            data.id = res2.data._id;
            dispatch( {
                type: GET_POST_ENTITY,
                payload: data
            } );
        } else {
            dispatch( {
                type: GET_POST_ENTITY,
                payload: res.data
            } );
        }

        dispatch( setAlert( { error: "Publication envoyée" }, "success" ) );

        return true;
    } catch ( error ) {
        if ( error.response ) {

            const errors = error.response.data;

            if ( errors ) {

                if ( errors.message ) {

                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );

                } else {

                    dispatch( setAlert( errors, "register" ) );

                }
            }

        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {
            console.log( ' // Some other errors', error.message );
        }

        return false;
    }
};
