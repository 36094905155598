import React from 'react'
import PropTypes from 'prop-types'
import ReCAPTCHA from "react-google-recaptcha";

const SignUpUi = ( {
    t,
    onChange,
    onSubmit,
    onChangeCheck,
    setcaptcha,
    isCaptcha,
    loading
} ) => {

    function onChangeCapcha ( value ) {
        setcaptcha( value );
    }
    return (
        <div className="page main-signin-wrapper">
            <div className="row signpages text-center">
                <div className="col-md-12">
                    <div className="card">
                        <div className="row row-sm" >
                            <div className="col-lg-6 col-xl-5 d-none d-lg-block " style={ { backgroundColor: "#3a3a3a" } }>
                                <div className="mt-5 pt-5 p-2 pos-absolute">

                                    <div className="clearfix"></div>
                                    <img src="https://general.eu-central-1.linodeobjects.com/basics/platforms/moonode_logo.png" className="ht-100 mb-0" alt="user" />
                                    <br />
                                    <h5 className="mt-4 text-white">{ t( 'landing.signup' ) }</h5>
                                    <hr />

                                    <span className="tx-white-6 tx-13 mb-5 mt-xl-0"> { t( 'landing.description2' ) }</span>
                                    <br />
                                    <br />
                                    <span className="tx-white-6 tx-13 mb-5 mt-xl-0"> { t( 'landing.description3' ) }</span>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-7 col-xs-12 col-sm-12 login_form ">
                                <div className="">
                                    <div className="row row-sm" >
                                        <div className="card-body mt-2 mb-2">

                                            <div className="clearfix"></div>
                                            <h5 className="text-start mb-2">{ t( 'landing.signup' ) }</h5>

                                            <br />
                                            <form onSubmit={ ( e ) => { e.preventDefault(); onSubmit( e ) } } noValidate>
                                                <div className="form-group text-start">
                                                    <label>{ t( 'common.handle' ) }</label>
                                                    <input className="form-control"
                                                        placeholder={ t( 'common.handle' ) }
                                                        type="text"
                                                        name="handle"
                                                        id="handle"
                                                        onChange={ onChange }
                                                    />
                                                </div>
                                                <div className="form-group text-start">
                                                    <label>{ t( 'common.name' ) }</label>
                                                    <input
                                                        className="form-control"
                                                        placeholder={ t( 'common.name' ) }
                                                        type="text"
                                                        name="name"
                                                        id="name"
                                                        onChange={ onChange }
                                                    />
                                                </div>
                                                <div className="form-group text-start">
                                                    <label>{ t( 'common.email' ) }</label>
                                                    <input
                                                        className="form-control"
                                                        placeholder={ t( 'common.email' ) }
                                                        type="text"
                                                        name="email"
                                                        id="email"
                                                        onChange={ onChange }
                                                    />
                                                </div>
                                                <div className="form-group text-start">
                                                    <label>{ t( 'common.password' ) }</label>
                                                    <input
                                                        className="form-control"
                                                        placeholder={ t( 'common.password' ) }
                                                        name="password"
                                                        type="password"
                                                        id="password"
                                                        onChange={ onChange }
                                                    />
                                                </div>

                                                <label className="custom-control custom-radio">
                                                    <input type="radio" className="custom-control-input" name="example-radios" value="option2" onChange={ ( e ) => onChangeCheck() } />
                                                    <span className="custom-control-label" href="/terms" >{ t( 'common.accept' ) }</span>
                                                </label>
                                                <a className="" href="/terms" >{ t( 'common.conditions' ) }</a>
                                                <ReCAPTCHA
                                                    // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                                    sitekey="6Lea--oZAAAAAJ0LXBBM-05O8GqgPrvedtUg9O--"
                                                    onChange={ onChangeCapcha }
                                                    ref={ el => isCaptcha( el ) }

                                                />
                                                { loading ? <div className="spinner-grow" style={ { width: "5rem", height: "5rem", marginLeft: "40%" } } role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div> : <button className="btn ripple btn-main-primary btn-block">{ t( 'landing.signup' ) }</button> }
                                            </form>


                                            <div className="text-start mt-5 ms-0">
                                                <p className="mb-0">{ t( 'common.account' ) } <a href="/signin">{ t( 'landing.signin' ) }</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="mb-4 text-muted tx-13 ml-0 text-left">{ t( 'common.poweredBy' ) }</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

SignUpUi.propTypes = {

}

export default SignUpUi
