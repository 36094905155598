import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import mashaAllahClicked from '../../assets/img/mashaAllah-actif.png'
import mashaAllahShowed from '../../assets/img/mashaAllah-off.png'
import mashaAllahClickedCircle from '../../assets/img/mashaAllah-count.png'
import FeedComment from '../../containers/comments/feedComments';
import FeedMedia from '../media/FeedMedia'
import ShortComment from '../media/ShortComment'
import { formatDistanceToNow } from 'date-fns'
import { enCA } from 'date-fns/locale'
import KindPost from '../media/KindPost';
import Share from '../share/Index';

function ifThereIsLike ( data, user ) {
    return user && ( 0 === data.likes.map( item => item.user.toString() ).indexOf( user.id ) );
}

export const Post = ( { feed, show, onChangeLike, index, user } ) => {

    const [ modal, setmodal ] = useState( false )
    const [ fakelike, setFakeLike ] = useState( 0 )
    const [ sharedLink, setSharedLink ] = useState( "" )
    const [ open, setOpen ] = useState( false );


    const [ liked, setliked ] = useState( ifThereIsLike( feed, user ) )

    useEffect( () => {
        setliked( ifThereIsLike( feed, user ) )

    }, [ feed ] );
    const onLike = () => {
        setliked( !liked )
        fakelike > 0 ? setFakeLike( 0 ) : setFakeLike( 1 );
    }

    const openModal = () => {
        setmodal( !modal );
        setSharedLink( `https://www.moonode.com/shared/${ feed.postId }/${ feed.postNbr }` )

    }

    return (
        <div className="main-content-body  tab-pane border-top-0" id="timeline" style={ { marginBottom: "18px" } }>
            <div className="">
                <div className="main-content-body main-content-body-profile">
                    <div className="main-profile-body p-0">
                        <div className="row row-sm" >
                            <div className="col-12">
                                <div className="card mg-b-20 border" >
                                    <div className="card-header p-4">
                                        <div className="media" >
                                            <div className="media-user me-2" onClick={ () => window.location.replace( `/entity/${ feed.url }` ) } style={ { cursor: "pointer" } }>
                                                <div className="main-img-user avatar-md"><img alt="" className="rounded-circle" src={ feed.picture } /></div>
                                            </div>
                                            <div className="media-body" onClick={ () => window.location.replace( `/entity/${ feed.url }` ) } style={ { cursor: "pointer" } }>
                                                <h6 className="mb-0 mg-t-2 ms-2"> { " " } { feed.name }</h6><span className="text-primary ms-2">{ formatDistanceToNow( new Date( feed.date ), { locale: enCA } ) }</span> </div>
                                            { show && <div className="ms-auto">
                                                <div className="dropdown show" onClick={ () => { onLike(); onChangeLike( index, feed.postsId, feed.postNbr ) } }>
                                                    { liked ? <img src={ mashaAllahClicked } height="50px" width="50px" style={ { cursor: "pointer" } } /> : <img src={ mashaAllahShowed } height="50px" width="50px" style={ { cursor: "pointer" } } /> }
                                                </div>
                                            </div> }
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <KindPost kindPost={ feed.kindPost } />
                                        <ShortComment comment={ feed.text } length={ feed.text.length } key={ index } />
                                        <FeedMedia data={ feed } />
                                        <br />
                                        { show && <div className="media mg-t-15 profile-footer">
                                            <div className="media-body">
                                                <p style={ { fontSize: "15px" } }>{ feed.likes.length > 0 ? ( feed.likes.length ) : null }   { ( feed.likes.length ) > 0 ? <img style={ { height: "1.5rem", paddingRight: "0.5rem" } } src={ mashaAllahClickedCircle } alt="" title="ما شاء الله" /> : null }</p>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="dropdown show">
                                                    { feed.isComment && <a onClick={ ( e ) => { e.preventDefault(); setOpen( !open ) } } className="new" style={ { cursor: "pointer" } }>
                                                        { feed.comment.length > 0 ? ( feed.comment.length + " " ) : null }
                                                        <i className="far fa-comment me-3" style={ { cursor: "pointer" } }></i></a> }

                                                    <a className="new" onClick={ ( e ) => { e.preventDefault(); openModal() } } style={ { cursor: "pointer" } } ><i className="far fa-share-square" ></i></a> </div>
                                            </div>
                                        </div> }
                                    </div>
                                    { open && <FeedComment feed={ feed } open={ open } setOpen={ setOpen } /> }
                                    { modal && <Share open={ modal } sharedLink={ sharedLink } /> }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    );

}


const mapStateToProps = ( state ) => ( {

} )



export default connect( mapStateToProps, {} )( Post )