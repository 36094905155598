import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux'
import { getPrayerEntityTvWithId } from "../../action/admin";
import MobilePrayer from '../../components/Prayer/Widget/Ui';
import { calculeNextIqama, iqamaCalcul } from '../../utils/processingDate&Time';


export const Index = ( { match, location, getPrayerEntityTvWithId, entity: { tvPrayer, loadingCsv } } ) => {
    const [ flyerScreenState, setFlyerScreenState ] = useState( null );


    useEffect( () => {
        const queryParams = new URLSearchParams( location.search );
        const flyerScreenValue = queryParams.get( 'FlyerScreen' );
        setFlyerScreenState( flyerScreenValue );
        getPrayerEntityTvWithId( match.params.token )
        return () => {
        }
    }, [] )
    return (

        ( !loadingCsv ) && <MobilePrayer
            flyerScreenState={ !flyerScreenState }
            tvPrayer={ tvPrayer }
            apiPrayerTime={ tvPrayer.apiPrayerTime }
            iqamaTimes={ tvPrayer.iqamaTime }
            calculeNextIqama={ calculeNextIqama }
            color={ tvPrayer.prayerTime.widgetColor }
            entity={ tvPrayer.entity }
            iqamaCalcul={ iqamaCalcul }
            prayerTime={ tvPrayer.prayerTime }
            tomorrowIqamaTime={ tvPrayer.tomorrowIqamaTime }
            tomorrowApiPrayerTime={ tvPrayer.tomorrowApiPrayerTime }
            highlighted={ tvPrayer.highlighted }
        />

    )
}

const mapStateToProps = ( state ) => ( {
    entity: state.entity
} )

export default connect( mapStateToProps, { getPrayerEntityTvWithId } )( Index )
