import { SET_ROUTE } from '../action/types';

const initialState = {
    isRoute: false,
    actualRoute: ''

};

export default function ( state = initialState, action ) {

    const { type, payload } = action
    switch ( type ) {
        case SET_ROUTE:
            return {
                ...state,
                isRoute: true,
                actualRoute: payload,
            }
        default:
            return state;
    }
}