import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useHistory } from 'react-router-dom';


const MyMapComponent = ( props ) => {
    const mapRef = useRef( null );
    const history = useHistory();
    let lastClickedId = null;
    const { entities, onToggleOpen } = props;
    console.log( entities[ 0 ] )
    // Using the coordinates of the first entity as the default center
    const defaultCenter = [ entities[ 0 ].entity.lat, entities[ 0 ].entity.lng ];

    useEffect( () => {
        if ( mapRef.current ) {
            const map = L.map( mapRef.current ).setView( defaultCenter, 12 );

            L.tileLayer( 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            } ).addTo( map );

            entities.forEach( ( entityItem ) => {
                const { entity } = entityItem;  // Now you have the entire entityItem and its destructured entity object

                if ( entity.lat && entity.lng ) {
                    const position = [ entity.lat, entity.lng ];

                    const marker = L.marker( position, {
                        icon: L.divIcon( {
                            className: 'my-icon-class',
                            html: `<img src="${ entity.logo }" style="border-radius: 50%; width: 35px; height: 35px;"/>`,
                            iconSize: [ 55, 55 ]
                        } ),
                    } ).addTo( map );

                    marker.bindPopup( `Mosquée ${ entity.name }<br />${ entity.address }` );

                    marker.on( 'click', () => {
                        if ( lastClickedId === entity._id ) {
                            window.location.replace( `/entity/${ entityItem.codeEntity }` );
                        } else {
                            onToggleOpen( entity.lat, entity.lng, entity._id );
                            lastClickedId = entity._id;
                        }
                    } );
                }
            } );
        }
    }, [ entities, onToggleOpen ] );

    return (
        <div ref={ mapRef } style={ { height: "100vh", width: "100%" } }>
            { props.children }
        </div>
    );
};

export default MyMapComponent;
