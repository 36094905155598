export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const SET_ROUTE = "SET_ROUTE";
export const REMOVE_ROUTE = "REMOVE_ROUTE";
export const GET_ENTITY = "GET_ENTITY";
export const GET_ENTITY_CSV = "GET_ENTITY_CSV";
export const GET_RANDOM_HADITH = "GET_RANDOM_HADITH";
export const AUTH_ERROR = "AUTH_ERROR";
export const USER_LOGIN = "USER_LOGIN";
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_FEED = "GET_FEED";
export const GET_COMMENTS = "GET_COMMENTS";
export const GET_PRAYER_MTL = "GET_PRAYER_MTL";
export const GET_ALL_IMAMS_AND_MOSQUES = "GET_ALL_IMAMS_AND_MOSQUES";
export const GET_POST_ENTITY = "GET_POST_ENTITY";
export const GET_SPECIAL_POST_ENTITY = "GET_SPECIAL_POST_ENTITY";
export const GET_QUESTIONS = "GET_QUESTIONS";
export const GET_ALL = "GET_ALL";
export const GET_GEO_LOCALISATION = "GET_GEO_LOCALISATION";
export const SET_LOADING_ENTITIES = "SET_LOADING_ENTITIES";
export const GET_SEARCH = "GET_SEARCH";
export const SET_LOADING_SEARCH = "SET_LOADING_SEARCH";
export const GET_USER_NOTIFICATION = "GET_USER_NOTIFICATION";
export const LOADING_USER_NOTIFICATION = "LOADING_USER_NOTIFICATION";
export const GET_PROFILE = "GET_PROFILE";
export const GET_USER_INFO = "GET_USER_INFO";
export const GET_ADMIN_PRAYER_TV = "GET_ADMIN_PRAYER_TV";
export const CREATE_ENTITY = "CREATE_ENTITY";
export const GET_ENTITY_VISUAL = "GET_ENTITY_VISUAL";