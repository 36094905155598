import React from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import SummerNote from './SummerNote';

export const Contacts = ( {
    extractEmails,
    formData,
    onChange,
    entityContacts,
    removeContact,
} ) => {
    const { t } = useTranslation();


    return (

        <div className="container-fluid elementToFadeInAndOut">
            <div className="inner-body">
                <div className="row row-sm" >
                    <div className="col-lg-12 col-md-12">
                        <div className="card custom-card">
                            <div className="row row-sm" >
                                <div className="col-lg-12 col-md-12">
                                    <div className="card custom-card">
                                        <div className="card-body">


                                            <div className="card custom-card">

                                                <div className="card-body">
                                                    <div>
                                                        <h6 className="main-content-label mb-1">{ t( "website.newsLetterContactTitle" ) }</h6>
                                                        <p className="text-muted card-sub-title">{ t( "website.newsLetterContactInfo" ) }</p>
                                                        <input type="search"
                                                            name="text"
                                                            className="form-control rounded-0"
                                                            placeholder={ t( "application.addemails" ) }
                                                            value={ formData.text }
                                                            onChange={ ( e ) => onChange( e ) }
                                                        />
                                                        <button type="button" className="btn btn-primary my-2 btn-icon-text" onClick={ () => extractEmails( formData.text ) }>
                                                            <i className="fe fe-plus mr-2"></i> { t( "common.add" ) }
                                                        </button>
                                                    </div>
                                                    <div className="table-responsive border userlist-table">
                                                        <table className="table card-table table-striped table-vcenter text-nowrap mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th className="wd-lg-8p"><span>User</span></th>
                                                                    <th className="wd-lg-20p"><span>{ t( "website.status" ) }</span></th>
                                                                    <th className="wd-lg-20p"><span>{ t( "common.email" ) }</span></th>
                                                                    <th className="wd-lg-20p">{ t( "common.delete" ) }</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                { entityContacts.length > 0 ? entityContacts.map( ( user, index ) => <tr>
                                                                    <td>
                                                                        <img alt="avatar" className="rounded-circle avatar-md mr-2" src="https://general.eu-central-1.linodeobjects.com/basics/platforms/moonode_logo.png" />
                                                                    </td>
                                                                    <td>{ user }</td>

                                                                    <td className="text-center">
                                                                        <span className="label text-success d-flex"><span className="dot-label bg-success mr-1"></span>Active</span>

                                                                    </td>

                                                                    <td>
                                                                        <a href="#" className="btn btn-sm btn-danger" onClick={ ( e ) => { e.preventDefault(); removeContact( index ) } } >
                                                                            <i className="fe fe-trash"></i>
                                                                        </a>
                                                                    </td>
                                                                </tr> ) : null }

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>



    )
}

const mapStateToProps = ( state ) => ( {

} )

const mapDispatchToProps = {

}

export default connect( mapStateToProps, mapDispatchToProps )( Contacts )
