import React from 'react'

function Index () {
    return (
        <div class="card custom-card" >
            <div class="card-header border-bottom-0">
                <label class="main-content-label mt-3">Imam</label>
            </div>
            <div class="card-body p-3">
                <div class="d-flex"><img src="../../assets/img/svgs/crypto-currencies/btc.svg" class="wd-30 ht-30 me-3 my-auto" alt="img" />
                    <div class="mt-1">
                        <h5 class="mb-2 tx-16">Mujeed</h5>
                        <span class="mb-0 tx-18 text-dark font-weight-normal">Rahman</span>
                    </div>
                </div>
                <div class="row no-gutters mt-3">
                    <div class="col"> <a class="btn btn-light btn-block btn-square" href="#">Ask question</a></div>
                </div>
            </div>

        </div>
    )
}

export default Index
