import React from 'react'
import PropTypes from 'prop-types'
import { refreshTokenSetup } from '../../utils/refreshToken';
import Google from './gl.png';
import { useGoogleLogin } from 'react-google-login';
import { isMobile } from '../../utils/device';

const clientId =
    '869233916898-cpptjttf8ptl6cbr836kn9dd5a8a3sqn.apps.googleusercontent.com';
const SignInUi = ( {
    translate,
    setcaptcha,
    isCaptcha,
    name,
    password,
    email,
    onChange,
    loginUserGoogle,
    isAuthenticated,
    loading,
    onSubmit,
} ) => {

    const onSuccess = ( res ) => {
        loginUserGoogle( res )
        res.disconnect();
        refreshTokenSetup( res );
    };

    const onFailure = ( res ) => {
        alert(
            `Google Auth not available | Google auth pas dissponible. `
        );
    };

    // function onChangeCapcha ( value ) {
    //     setcaptcha( value );
    // }

    const { signIn } = useGoogleLogin( {
        onSuccess,
        onFailure,
        clientId,
        isSignedIn: true,
        accessType: 'offline',
        // responseType: 'code',
        // prompt: 'consent',
    } );

    return (
        <div className="page main-signin-wrapper">
            <div className="row signpages text-center">
                <div className="col-md-12">
                    <div className="card">
                        <div className="row row-sm" >
                            <div className="col-lg-6 col-xl-5 d-none d-lg-block text-center" style={ { backgroundColor: "#3a3a3a" } }>
                                <div className="mt-5 pt-4 p-2 pos-absolute">
                                    {/* <img src="../../assets/img/brand/logo-light.png" className="header-brand-img mb-4" alt="logo" /> */ }
                                    <div className="clearfix"></div>
                                    <img src="https://general.eu-central-1.linodeobjects.com/basics/platforms/moonode_logo.png" className="ht-100 mb-0" alt="user" />
                                    <h5 className="mt-4 text-white"></h5>
                                    <span className="tx-white-6 tx-13 mb-5 mt-xl-0">{ translate( 'landing.description1' ) }</span>
                                </div>
                            </div>

                            <div className="col-lg-6 col-xl-7 col-xs-12 col-sm-12 login_form ">
                                <div className="container-fluid" >
                                    <div className="row row-sm" >
                                        <div className="card-body mt-2 mb-2">
                                            <img src="https://general.eu-central-1.linodeobjects.com/basics/platforms/moonode_logo.png" className=" d-lg-none header-brand-img text-start float-start mb-4" alt="logo" />

                                            <div className="clearfix"></div>

                                            <form>
                                                <h5 className="text-start mb-2">{ translate( 'landing.signin' ) }</h5>
                                                <div className="form-group text-start">
                                                    <label>{ translate( 'common.email' ) }</label>
                                                    <input className="form-control"
                                                        placeholder={ translate( 'common.email' ) }
                                                        type="text"
                                                        id="email"
                                                        name="email"
                                                        autoComplete="email"
                                                        onChange={ onChange }
                                                    />
                                                </div>
                                                <div className="form-group text-start">
                                                    <label>{ translate( 'common.password' ) }</label>
                                                    <input className="form-control" placeholder={ translate( 'common.password' ) } type="password"
                                                        id="password"
                                                        name="password"
                                                        autoComplete="password"
                                                        onChange={ onChange }
                                                    />
                                                </div>




                                                { loading ?
                                                    <div className="spinner-grow" style={ { width: "5rem", height: "5rem" } } role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                    : <button className="btn ripple btn-main-primary btn-block"
                                                        onClick={ ( e ) => onSubmit( e ) }
                                                    >{ translate( 'landing.signin' ) }</button> }
                                                <button type="button" class="btn btn-light btn-lg btn-block" onClick={ ( e ) => signIn( e ) }>
                                                    <h6 className="buttonText" >
                                                        <img src={ Google } alt="google login" className="icon" style={ { width: "40px", height: "40px" } } />
                                                        { translate( 'landing.signinwithgoogle' ) }
                                                    </h6>
                                                </button>
                                            </form>
                                            <div className="text-start mt-5 ms-0">
                                                <div className="mb-1"><a href="/resetpassword">{ translate( 'common.forgot' ) }</a></div>
                                                <div>{ translate( 'common.noaccount' ) }<a href="/signup">{ " " }{ translate( 'landing.signup' ) }</a></div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                                <p className="mb-4 text-muted tx-13 ml-0 text-left">{ translate( 'common.poweredBy' ) }</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

SignInUi.propTypes = {

}

export default SignInUi
