import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import SignInUi from '../../components/login/SignInUi';
import { loginUser, loginUserGoogle } from '../../action/auth';

export const SignIn = ( {
    history,
    loginUser,
    isAuthenticated = false,
    loginUserGoogle
} ) => {
    const { t } = useTranslation();

    const [ loading, setLoading ] = useState( false )
    const [ captcha, setcaptcha ] = useState( 'xxx' )
    const [ capp, isCaptcha ] = useState( 'xxx' )


    const [ state, setState ] = useState( {
        codeType: "LAN-3",
        email: "",
        password: "",
        remember: false,
    } );

    const onSubmit = async e => {
        setLoading( true );

        e.preventDefault();

        let user = {
            codeType: state.codeType,
            email: state.email,
            password: state.password,
            remember: state.remember,
            captcha
        };

        let response = await loginUser( user, history );

        if ( !response ) {
            setLoading( false );
            // capp.reset();
        }
    }
    const onChange = e => {
        setState( { ...state, [ e.target.name ]: e.target.value } );
    };

    return (
        <SignInUi

            translate={ t }
            setcaptcha={ setcaptcha }
            isCaptcha={ isCaptcha }
            name={ state.name }
            loading={ loading }
            password={ state.password }
            email={ state.email }
            onChange={ onChange }
            isAuthenticated={ isAuthenticated }
            onSubmit={ onSubmit }
            loginUserGoogle={ loginUserGoogle }


        />
    )
}


const mapStateToProps = state => ( {
    isAuthenticated: state.auth.isAuthenticated
} );


export default connect( mapStateToProps, { loginUser, loginUserGoogle } )( SignIn );