import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'


export const Index = ( { handleImageClick, selectedImage } ) => {

    const youTubeVideosPrayer =
        [ 'https://youtu.be/1nnS4jZ9lvA', // en
            'https://youtu.be/cwvfWZMuIME', // fr
            'https://youtu.be/s02xWQ35LJU', // ar
            'https://youtu.be/KtXBfdY6hZw', // Ur
            'https://youtu.be/qLftp95Q5yk'  // bn
        ]
    const youTubeVideosJumuas =
        [ 'https://youtu.be/8sRU6mNjLLQ', // en
            'https://youtu.be/bvGK28m5RK4', // fr
            'https://youtu.be/Nu3TjqKXuEw', // ar
            'https://youtu.be/im98mXEzPx8', // Ur
            'https://youtu.be/W6bQpiq0B6I'  // bn
        ]
    const youTubeVideosAdhan =
        [ 'https://youtu.be/Vv_oOJdECxk', // en
            'https://youtu.be/wN1szWKOxNo', // fr
            'https://youtu.be/3w0YidqWT2s', // ar
            'https://youtu.be/j0rG3v0RzNw', // Ur
            'https://youtu.be/3LdqOMSYkUk'  // bn
        ]
    const youTubeVideosCsv =
        [ 'https://youtu.be/TWPCPsWQkBE', // en
            'https://youtu.be/GV7u-4xxPr0', // fr
            'https://youtu.be/3w0YidqWT2s', // ar
            'https://youtu.be/mKIEv9KYm_A', // Ur
            'https://youtu.be/Vj90afXTV-Y'  // bn
        ]
    const youTubeVideosWidget =
        [ 'https://youtu.be/4qy6yTtvn4k', // en
            'https://youtu.be/MJqzJ-mFGrg', // fr
            'https://youtu.be/mL4_QkNqaWg', // ar
            'https://youtu.be/bTI3WnuJwi8', // Ur
            'https://youtu.be/B0rsa1KQxOg'  // bn
        ]
    const { t } = useTranslation();



    const [ setPrayerVideo, setsetPrayerVideo ] = useState( youTubeVideosPrayer[ 0 ] );
    const [ setJumuaVideo, setsetJumuaVideo ] = useState( youTubeVideosJumuas[ 0 ] );
    const [ setCsvVideo, setsetCsvVideo ] = useState( youTubeVideosCsv[ 0 ] );
    const [ setAdhanVideo, setsetAdhanVideo ] = useState( youTubeVideosAdhan[ 0 ] );
    const [ setWidgetVideo, setsetWidgetVideo ] = useState( youTubeVideosWidget[ 0 ] );

    const [ active, setActive ] = useState( {
        en: true,
        fr: false,
        ar: false,
        ur: false,
        bn: false
    } );
    const [ video, setVideo ] = useState( {
        prayer: true,
        jumua: false,
        csv: false,
        adhan: false,
        widget: false
    } );
    const changeSubOptionsPrayer = ( active ) => {
        switch ( active ) {
            case 'english':
                setActive( { ...active, en: true } );
                setsetPrayerVideo( youTubeVideosPrayer[ 0 ] )
                setsetJumuaVideo( youTubeVideosJumuas[ 0 ] )
                setsetAdhanVideo( youTubeVideosAdhan[ 0 ] )
                setsetCsvVideo( youTubeVideosCsv[ 0 ] )
                setsetWidgetVideo( youTubeVideosWidget[ 0 ] )
                break;
            case 'french':
                setActive( { ...active, fr: true } );
                setsetPrayerVideo( youTubeVideosPrayer[ 1 ] )
                setsetJumuaVideo( youTubeVideosJumuas[ 1 ] )
                setsetAdhanVideo( youTubeVideosAdhan[ 1 ] )
                setsetCsvVideo( youTubeVideosCsv[ 1 ] )
                setsetWidgetVideo( youTubeVideosWidget[ 1 ] )
                break;
            case 'arabic':
                setActive( { ...active, ar: true } );
                setsetPrayerVideo( youTubeVideosPrayer[ 2 ] )
                setsetJumuaVideo( youTubeVideosJumuas[ 2 ] )
                setsetAdhanVideo( youTubeVideosAdhan[ 2 ] )
                setsetCsvVideo( youTubeVideosCsv[ 2 ] )
                setsetWidgetVideo( youTubeVideosWidget[ 2 ] )
                break;
            case 'urdu':
                setActive( { ...active, ur: true } );
                setsetPrayerVideo( youTubeVideosPrayer[ 3 ] )
                setsetJumuaVideo( youTubeVideosJumuas[ 3 ] )
                setsetAdhanVideo( youTubeVideosAdhan[ 3 ] )
                setsetCsvVideo( youTubeVideosCsv[ 3 ] )
                setsetWidgetVideo( youTubeVideosWidget[ 3 ] )
                break;
            case 'bengali':
                setActive( { ...active, bn: true } );
                setsetPrayerVideo( youTubeVideosPrayer[ 4 ] )
                setsetJumuaVideo( youTubeVideosJumuas[ 4 ] )
                setsetAdhanVideo( youTubeVideosAdhan[ 4 ] )
                setsetCsvVideo( youTubeVideosCsv[ 4 ] )
                setsetWidgetVideo( youTubeVideosWidget[ 4 ] )
                break;

            default:
                break;
        }

    }

    //change the tutorial video
    const changeWhichVideo = ( params ) => {
        switch ( params ) {
            case 'prayer':
                setVideo( {
                    prayer: true,
                    jumua: false,
                    csv: false,
                    adhan: false,
                    widget: false
                } );
                break;
            case 'jumua':
                setVideo( {
                    prayer: false,
                    jumua: true,
                    csv: false,
                    adhan: false,
                    widget: false
                } );
                break;
            case 'csv':
                setVideo( {
                    prayer: false,
                    jumua: false,
                    csv: true,
                    adhan: false,
                    widget: false
                } );
                break;
            case 'adhan':
                setVideo( {
                    prayer: false,
                    jumua: false,
                    csv: false,
                    adhan: true,
                    widget: false
                } );
                break;
            case 'widget':
                setVideo( {
                    prayer: false,
                    jumua: false,
                    csv: false,
                    adhan: false,
                    widget: true
                } );
                break;
            default:
                setVideo( {
                    prayer: true,
                    jumua: false,
                    csv: false,
                    adhan: false,
                    widget: false
                } );
                break;
        }
    }
    const linkifyYouTubeURLs = ( text ) => {
        var re = /https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*?[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/ig;
        return text.toString().replace( re, 'http://www.youtube.com/embed/$1' );
    }
    // get the video with the appropriate language
    const theVideo = () => {
        let url = "https://www.youtube.com/@moonode4724";
        if ( video.prayer ) {
            url = setPrayerVideo;
        }
        if ( video.adhan ) {
            url = setAdhanVideo;
        }
        if ( video.csv ) {
            url = setCsvVideo;

        }
        if ( video.widget ) {
            url = setWidgetVideo;

        }
        if ( video.jumua ) {
            url = setJumuaVideo;

        }

        return linkifyYouTubeURLs( url );

    }
    const imageUrls = [
        'https://general.eu-central-1.linodeobjects.com/basics/calendar/madewitLOVE.png',
        "https://general.eu-central-1.linodeobjects.com/basics/calendar/calendar2.png",
        'https://general.eu-central-1.linodeobjects.com/basics/calendar/moonode_calendar.png',
        'https://general.eu-central-1.linodeobjects.com/basics/calendar/calendar4.png',
        'https://general.eu-central-1.linodeobjects.com/basics/calendar/calendar5.png',
        'https://general.eu-central-1.linodeobjects.com/basics/calendar/calendar6.png'
    ];

    function ImageList ( { handleImageClick, selectedImage, imageUrls } ) {

        const imageStyle = {
            width: '130px',
            height: '120px',
            margin: '10px',
            border: '3px solid transparent',
            borderRadius: '10px',
        };

        const selectedImageStyle = {
            ...imageStyle,
            borderColor: '#18A1FD',
        };



        const rows = imageUrls.reduce( ( rows, image, index ) => {
            if ( index % 3 === 0 ) {
                rows.push( [ image ] );
            } else {
                rows[ rows.length - 1 ].push( image );
            }
            return rows;
        }, [] ).map( ( row, rowIndex ) => (
            <tr key={ rowIndex }>
                { row.map( ( image, columnIndex ) => (
                    <td key={ columnIndex }>
                        <img
                            src={ image }
                            alt={ `Image ${ rowIndex * 2 + columnIndex + 1 }` }
                            style={ selectedImage === image ? selectedImageStyle : imageStyle }

                            onClick={ () => handleImageClick( image, rowIndex * 3 + columnIndex ) }
                        />
                    </td>
                ) ) }
            </tr>
        ) );

        return (
            <table>
                <tbody>
                    { rows }
                </tbody>
            </table>
        );
    }

    return (
        <div className="col-xl-4 col-lg-12 d-none d-xl-block custom-leftnav" >
            <div className="main-content-left-components">
                <div className="card custom-card">
                    <div className="card-body component-item">
                        <nav className="nav flex-column">
                            <a className={ `nav-link ${ video.prayer ? "active" : "" }` } onClick={ ( e ) => { e.preventDefault(); changeWhichVideo( 'prayer' ) } } href="!#">{ t( "website.tvTemplates" ) }</a>
                        </nav>
                    </div>
                </div>
                <div className="card custom-card" style={ { width: "100%" } } >
                    <div className="card-header custom-card-header border-bottom-0">
                        {/* <h6 className="main-content-label mb-0">{ t( "website.templates" ) }</h6> */ }
                    </div>
                    <div className="card-body">
                        <div className="border">
                            {/* <img style={ { width: "317px", height: "50%" } } src={ require( '../subSections/madewitLOVE.png' ) } /> */ }
                            <ImageList imageUrls={ imageUrls } handleImageClick={ handleImageClick } selectedImage={ selectedImage } />
                            <small style={ { backgroundColor: 'red', color: 'white', padding: 5, borderRadius: 5, marginLeft: 3 } }>New</small>
                        </div>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">How to use:</h5>
                        <ul>
                            <li><strong>Step 1:</strong> Select the year for your calendar from the dropdown menu.</li>
                            <li><strong>الخطوة 1:</strong> حدد السنة للتقويم الخاص بك من القائمة المنسدلة.</li>

                            <li><strong>Step 2:</strong> Select the month for your calendar from the dropdown menu.</li>
                            <li><strong>الخطوة 2:</strong> حدد الشهر للتقويم الخاص بك من القائمة المنسدلة.</li>

                            <li><strong>Step 3:</strong> If you wish, you can change the title of your calendar in the provided text field.</li>
                            <li><strong>الخطوة 3:</strong> إذا كنت ترغب، يمكنك تغيير عنوان التقويم الخاص بك في الحقل النصي المقدم.</li>

                            <li><strong>Step 4:</strong> Choose your preferred image for your calendar from our selection. Click on the image to select it.</li>
                            <li><strong>الخطوة 4:</strong> اختر الصورة المفضلة لك للتقويم الخاص بك من اختيارنا. انقر على الصورة لتحديدها.</li>

                            <li><strong>Step 5:</strong> Once you're happy with your selections, click the 'Download' button to get your custom calendar.</li>
                            <li><strong>الخطوة 5:</strong> بمجرد أن تكون سعيدًا بالاختيارات الخاصة بك، انقر على الزر 'تحميل' للحصول على التقويم المخصص الخاص بك.</li>
                        </ul>
                        <p>Enjoy creating your personalized calendar!</p>
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = ( state ) => ( {

} )

const mapDispatchToProps = {

}

export default connect( mapStateToProps, mapDispatchToProps )( Index )
