import {
    GET_QUESTIONS,

} from "../action/types";

const initialState = {
    questions: [],
    imamsAndMosques: [],
    questionNotifications: [],
    loadingQuestion: true,
};

export default function ( state = initialState, action ) {
    const { type, payload } = action;

    switch ( type ) {
        case GET_QUESTIONS:
            return {
                ...state,
                questions: payload,
                loadingQuestion: false
            };
        default:
            return state;
    }
}
