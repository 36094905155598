import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next';
import { isMobile, isTablet } from '../../../utils/device';
import { useHistory } from 'react-router-dom'; // new import

function Index ( {
    children,
    entity,
    entityProfil,
    section,
    loading,
    setSections,
    onChangePostsType,
    followEntity,
    inputEl,
    onButtonClick,
    fileChangedHandler,
    user } ) {
    let history = useHistory(); // new line
    // new useEffect hook to listen for changes in the URL
    useEffect( () => {
        const handleHashChange = () => {
            const currentHash = window.location.hash.slice( 1 );
            setSections( currentHash );
            onChangePostsType( currentHash );
        }
        window.addEventListener( 'hashchange', handleHashChange );
        return () => {
            window.removeEventListener( 'hashchange', handleHashChange );
        }
    }, [] );

    // new function to handle clicks on the tabs
    const handleTabClick = ( e, section, postsType ) => {
        e.preventDefault();
        setSections( section );
        onChangePostsType( postsType );
        history.push( `#${ section }` );
    };

    const { t } = useTranslation();
    const { followers } = entityProfil;
    const { id } = user.user;
    let valid;
    followers.filter( fol => {
        if ( fol.user._id == id ) {
            valid = true;
        }
    } );
    if ( !valid ) {
        valid = false;
    }
    const [ isFollowing, setfollower ] = useState( valid ? true : false );
    const changeFollower = () => {
        followEntity( { entity: entity.entity._id } );
        setfollower( !isFollowing );
    };
    const masjidBackround = "https://moonode.s3.ca-central-1.amazonaws.com/utils/masjed.png"

    return (
        <div class="col-lg-7">
            {/* col-xl-12 col-lg-12 col-md-12 */ }
            <div class="card custom-card">
                <div class="card-body">
                    <div class="panel profile-cover" style={ { marginBottom: "50px" } }>
                        <div class="profile-cover__img" onClick={ ( e ) => { e.preventDefault(); onButtonClick( "logo" ) } }>
                            <img

                                src={ entity.entity.logo } alt="img"

                            />
                            <h3 class="h3">{ entity.entity.name }</h3>
                        </div>
                        <br />
                        <br />
                        <div class="btn-profile">
                            <button href="!#" className="btn btn-rounded btn-danger"
                                onClick={ ( e ) => {
                                    e.preventDefault();
                                    changeFollower();
                                } }
                            >    { isFollowing ? (
                                <i className="fa fa-check" aria-hidden="true" style={ { fontSize: "16px" } }></i>
                            ) : (
                                <i className="fa fa-plus" aria-hidden="true" style={ { fontSize: "16px" } }></i>
                            ) }
                                { isFollowing ? (

                                    t( 'entity.following' )

                                ) : (
                                    t( 'entity.follow' )
                                ) }
                            </button>
                        </div>
                        <div class="profile-cover__action" height='226px' width='1194px' style={ { marginTop: "-50px" } } onClick={ ( e ) => { e.preventDefault(); onButtonClick( "pic" ) } }>
                            <img

                                src={ entityProfil.pictures.length > 0 ? entityProfil.pictures[ 0 ] : masjidBackround } style={ { height: '200px', width: '1000px', objectFit: "cover" } } />
                        </div>
                        <div class="profile-cover__info">
                            <ul class="nav">
                                <div class="media mg-t-15 profile-footer" style={ { marginTop: ( isMobile() || isTablet() ) ? "20%" : null } }>
                                    { entityProfil.followers.length > 0 && <a class="btn btn-light btn-block btn-square" style={ { cursor: "pointer" } } onClick={ ( e ) => { e.preventDefault(); onChangePostsType( 'users' ); setSections( "users" ) } }>
                                        <div class="media-user me-2" >
                                            <div class="demo-avatar-group">
                                                <div class="demo-avatar-group main-avatar-list-stacked">
                                                    { entityProfil.followers.slice( 0, 5 ).map( user =>
                                                        <div class="main-img-user"><img alt="" class="rounded-circle" src={ user.user.picture } /></div>
                                                    ) }
                                                    <div class="main-avatar" style={ { fontSize: "10px" } }>  { entityProfil.followers.length > 5 ? "+" + entityProfil.followers.length - 5 : null }  </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a> }
                                    <div class="media-body">
                                        <br />
                                        { entityProfil.followers.length > 0 && <small class="mb-0 mg-t-10"> { t( 'entity.following' ) } </small> } </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                    <div class="p-3 bg-light">
                        <nav class="nav nav-pills flex-column flex-md-row" style={ { fontSize: "2vh" } }>
                            <a class={ `nav-link ${ section === "0" ? "active" : "" }` } style={ { cursor: "pointer" } } data-bs-toggle="tab" onClick={ ( e ) => { e.preventDefault(); setSections( "0" ); onChangePostsType( "" ) } }>{ t( 'entity.publications' ) }</a>
                            <a class={ `nav-link ${ section === "1" ? "active" : "" }` } style={ { cursor: "pointer" } } data-bs-toggle="tab" onClick={ ( e ) => { e.preventDefault(); setSections( "1" ); onChangePostsType( "tvAds" ) } }>{ t( 'entity.tvAds' ) }</a>
                            <a class={ `nav-link ${ section === "2" ? "active" : "" }` } style={ { cursor: "pointer" } } data-bs-toggle="tab" onClick={ ( e ) => { e.preventDefault(); setSections( "2" ); onChangePostsType( "image" ) } }>{ t( "entity.images" ) }</a>
                            <a class={ `nav-link ${ section === "3" ? "active" : "" }` } style={ { cursor: "pointer" } } data-bs-toggle="tab" onClick={ ( e ) => { e.preventDefault(); setSections( "3" ); onChangePostsType( "video" ) } }>{ t( "entity.videos" ) }</a>
                            <a class={ `nav-link ${ section === "4" ? "active" : "" }` } style={ { cursor: "pointer" } } data-bs-toggle="tab" onClick={ ( e ) => { e.preventDefault(); setSections( "4" ); onChangePostsType( "pdf" ) } }>{ t( "entity.Documents" ) }</a>
                            <a class={ `nav-link ${ section === "5" ? "active" : "" }` } style={ { cursor: "pointer" } } data-bs-toggle="tab" onClick={ ( e ) => { e.preventDefault(); setSections( "5" ); onChangePostsType( "audio" ) } }>{ t( "entity.Audios" ) }</a>
                            <a class={ `nav-link ${ section === "6" ? "active" : "" }` } style={ { cursor: "pointer" } } data-bs-toggle="tab" onClick={ ( e ) => { e.preventDefault(); setSections( "6" ); onChangePostsType( "about" ) } }>{ t( "common.about" ) }</a>
                        </nav>
                    </div>


                </div>
            </div>
            { !loading && section === "0" ? children[ 0 ] : null }
            { !loading && ( section === "1" || section === "2" || section === "3" || section === "4" || section === "5" ) ? children[ 1 ] : null }
            { !loading && section === "6" ? children[ 2 ] : null }
            { !loading && section === "users" ? children[ 3 ] : null }
            { children[ 4 ] }
            <input
                type="file"
                id="files"
                name="files"
                style={ { display: "none" } }
                onChange={ fileChangedHandler }
                ref={ inputEl }
            />
        </div>
    )
}

export default Index
