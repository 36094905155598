import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getPosts } from '../../../action/entity'
import EntityPost from '../../../components/posts/entityPosts';
import { addLike, tvChange, privacyChange, deletePost } from '../../../action/post';
import EmptyPosts from '../../../components/empty/emptyPosts';
export const Index = ( { getPosts, tvChange, isAdmin, deletePost, privacyChange, post: { postEntity, postEntityId }, codeEntity, auth: { user }, addLike, picture, name, postsType } ) => {


    const [ dynamicPosts, setdynamicPosts ] = useState( postEntity )

    useEffect( () => {
        // setdynamicPosts( postEntity )
        getPosts( codeEntity );
    }, [ getPosts ] );


    const onChangeLike = async ( index, postsId, postNbr ) => {
        let x = {};
        const newPosts = [ ...postEntity ];
        let idx = newPosts[ index ].likes.map( item => item.user.toString() ).indexOf( user.user.id );
        if ( idx >= 0 ) {
            newPosts[ index ].likes.splice( idx, 1 );
        } else {
            newPosts[ index ].likes.unshift( { id: "fakeide", user: user.user.id } );
        }
        await addLike( postsId, postNbr, x );
        // setTheLikes( newPosts );
        getPosts( codeEntity );
        // setdynamicPosts( newPosts );

    };

    const onChangeTv = ( index, postsId, postNbr ) => {
        const newPosts = [ ...postEntity ];
        newPosts[ index ].isTv = false;
        // setdynamicPosts( newPosts );
    };

    return (
        postEntity.length > 0 ? postEntity && postEntity.map( ( i, index ) => (
            <div key={ index } style={ { margin: "-1rem" } }>
                { ( !i.deleted ) && ( i.fileType === postsType ) && <EntityPost
                    picture={ picture }
                    name={ name }
                    isAdmin={ isAdmin }
                    feed={ i } show={ true }
                    deletePost={ deletePost }
                    postEntityId={ postEntityId }
                    onChangeLike={ onChangeLike }
                    index={ index }
                    privacyChange={ privacyChange }
                    tvChange={ tvChange }
                    onChangeTv={ onChangeTv }
                    user={ user }
                >
                </EntityPost> }
            </div>
        ) ) : <EmptyPosts image={ "https://moonode-profile.s3.ca-central-1.amazonaws.com/website/nodata.png" } />
    )
}

const mapStateToProps = ( state ) => ( {
    post: state.post,
    auth: state.auth
} )


export default connect( mapStateToProps, { getPosts, addLike, tvChange, privacyChange, deletePost } )( Index )
