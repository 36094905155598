import React, { useRef, useState, useEffect, useContext } from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { UserProfileContext } from "../../../Context.js";
import NewsLetter from '../../../components/admin/NewsLetter/Index';
import { sendNewsLetter, sendContacts, deleteContact } from '../../../action/admin';
import { getEntity } from "../../../action/entity";
import { useTranslation } from 'react-i18next';

export const Index = ( { match, sendNewsLetter, getEntity, sendContacts, deleteContact, entity: { entity } } ) => {
    let data = new FormData();
    const inputEl = useRef( null );

    const { t } = useTranslation();
    const { userProfile } = useContext( UserProfileContext );

    useEffect( () => {
        if ( userProfile ) {
            const { codeEntity, profile } = userProfile;
            const { token } = match.params;
            if ( ( encodeURI( codeEntity ).localeCompare( token ) === 0 ) && ( profile.isRole || profile.isAdmin ) ) {
                getEntity( codeEntity );
            } else {
                alert( "🟥" )
                window.location.replace( "/dashboard" );
            }
        }

    }, [ userProfile ] );


    const [ formData, setformData ] = useState( {
        title: "",
        content: "",
        text: "",
    } )

    const [ loading, setLoading ] = useState( false )

    const onChange = ( e ) => {
        setformData( { ...formData, [ e.target.name ]: e.target.value } )
    }

    const onChangeContent = ( content ) => {

        setformData( { ...formData, content } );

    }

    const [ files, setFileNames ] = useState( [] );

    const handleDrop = acceptedFiles =>
        setFileNames( acceptedFiles.map( file => file.name ) );

    const onButtonClick = () => {
        inputEl.current.click();
    };

    const onSubmit = async e => {
        const { codeEntity } = userProfile;

        e.preventDefault();
        setLoading( true );
        var option = new Blob( [ files[ 0 ] ] );

        for ( let index = 0; index < files.length; index++ ) {
            var option = await new Blob( [ files[ index ] ] );
            data.append( "file", option, files[ index ].name );
        }

        data.append( "title", JSON.stringify( formData.title.replace( /['"]+/g, '' ) ) );
        data.append( "content", JSON.stringify( formData.content.replace( /['"]+/g, '' ) ) );
        let valids = await sendNewsLetter( formData, codeEntity, data, t );

        if ( valids ) {
            setLoading( false );
        }
    };

    const extractEmails = ( text ) => {
        let contacts = text.match( /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi );
        if ( contacts ) {
            onSendContact( contacts )
        } else {
            alert( "les contacts ne sont pas valide" );
        }
    }


    const onSendContact = ( contact ) => {
        const { codeEntity } = userProfile;

        sendContacts( contact, codeEntity )
    };

    const removeContact = ( index ) => {
        const { codeEntity } = userProfile;

        deleteContact( codeEntity, index )
    }

    return (
        entity && <NewsLetter
            onChange={ onChange }
            formData={ formData }
            onChangeContent={ onChangeContent }
            extractEmails={ extractEmails }
            setFileNames={ setFileNames }
            fileNames={ files }
            entityContacts={ entity.entity.contacts }
            handleDrop={ handleDrop }
            setformData={ setformData }
            removeContact={ removeContact }
            onSubmit={ onSubmit }
            loading={ loading }
            onButtonClick={ onButtonClick }
            inputEl={ inputEl }
        />
    )
}

Index.propTypes = {
    props: PropTypes
}

const mapStateToProps = ( state ) => ( {
    entity: state.entity,
    // admin: state.admin/
} )


export default connect( mapStateToProps, { getEntity, sendContacts, deleteContact, sendNewsLetter } )( Index )
