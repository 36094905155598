import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import Mosque from '../../components/entity/mosque/Index';
import { getEntity, followEntity, postEntityProfilePicture } from "../../action/entity";
import { getUserProfile } from '../../action/auth';
import { setRoute, disableRoute } from '../../action/routes'


export const Index = ( { match, setRoute, disableRoute, auth: { user, userProfile, isEntity, isItAdmin, isItRole }, postEntityProfilePicture, getEntity, followEntity, entity: { entity, entityProfil, loading }, history } ) => {
    let data = new FormData();
    const [ show, setshow ] = useState( false );
    const [ files, setfiles ] = useState( "" );

    useEffect( () => {
        getUserProfile( user.id );
        getEntity( match.params.token, history );
        if ( userProfile && match.params.token === userProfile.codeEntity ) {
            setRoute( 'user/mymosque' );

        }
        return () => {
            disableRoute()
        }
    }, [ getEntity ] );

    useEffect( () => {
        if ( userProfile && match.params.token === userProfile.codeEntity ) {
            setRoute( 'user/mymosque' );

        }
        return () => {
            disableRoute()
        }
    }, [ userProfile ] );
    const inputEl = useRef( null );

    const [ whichType, setWhichType ] = useState( "" );

    const fileChangedHandler = async e => {
        let files;
        if ( e ) {
            files = await e.target.files;
            await setfiles( files[ 0 ] );
            var option = await new Blob( [ files[ 0 ] ] );
            await data.append( "file", option, files[ 0 ].name );
            let valid = await postEntityProfilePicture(
                data,
                whichType,
                entity.codeEntity
            );

            if ( valid ) {
                setfiles( "" );
                setshow( false );
            }
        }
    };
    const onButtonClick = args => {
        if ( ( ( isItRole || isItAdmin ) && ( encodeURI( userProfile.codeEntity ).localeCompare( match.params.token ) === 0 ) ) || ( user.user.id === "5ea4b50e2c7bba00178e5ca9" ) ) {
            setWhichType( args );
            inputEl.current.click();
        }

    };


    return (
        !loading && entity && userProfile ?
            <Mosque
                userProfile={ userProfile }
                entity={ entity }
                entityProfil={ entityProfil }
                loading={ loading }
                followEntity={ followEntity }
                user={ user }
                codeEntity={ match.params.token }
                isItAdmin={ isItAdmin }
                isItRole={ isItRole }
                adminEntityName={ userProfile.codeEntity }
                onButtonClick={ onButtonClick }
                fileChangedHandler={ fileChangedHandler }
                inputEl={ inputEl }

            /> : <div className="spinner-grow" style={ { width: "5rem", height: "5rem", marginLeft: "40%" } } role="status">
                <span className="sr-only">Loading...</span>
            </div>
    )
}

const mapStateToProps = ( state ) => ( {
    entity: state.entity,
    auth: state.auth
} )



export default connect( mapStateToProps, { getEntity, followEntity, postEntityProfilePicture, setRoute, disableRoute } )( Index )
