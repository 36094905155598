import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux'
// import { useDropzone } from 'react-dropzone';
import CsvMonths from './CsvMonths/Index';
import { format, getMonth } from 'date-fns'
import { TableHeader } from './CsvMonths/TableHeader';
import './style.css'
import Dropzone from "react-dropzone";
import { useTranslation } from 'react-i18next';


export const CovidRole = ( { csv, replaceLine,
    files,
    setfiles,
    isFileLoading,
    onSubmitFile,
    onButtonClick,
    inputEl,
    isCsv,
    download
} ) => {

    const { t } = useTranslation();
    const [ theMonth, setTheMonth ] = useState( getMonth( new Date(), 'yyyy-MM-dd' ) );
    const [ theMonthSection, setTheMonthSection ] = useState( getMonth( new Date(), 'yyyy-MM-dd' ) );

    const onClickChangeMonth = ( month, section ) => {
        setTheMonth( month );
        setTheMonthSection( section );
    }
    const [ fileNames, setFileNames ] = useState( [] );
    const fileChangedHandler = async e => {
        if ( e ) {
            setfiles( e.target.files );

        }
    };

    return (

        <div className="container-fluid elementToFadeInAndOut" >
            <div className="inner-body" >
                <div className="row row-sm"  >
                    <div className="col-lg-12 col-md-12">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div>
                                    <h6 className="main-content-label mb-1">{ t( "website.csvUploadFile0" ) }</h6>
                                    <p className="text-muted card-sub-title">{ t( "website.csvUploadFile" ) }</p>
                                </div>
                                { isFileLoading ?
                                    <div className="spinner-grow" style={ { width: "5rem", height: "5rem", marginLeft: "40%" } } role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    :
                                    <button className='dropzone'
                                        onClick={ () => onButtonClick() }
                                    >{ t( "website.csvUploadFile1" ) }</button>
                                }

                                { files.length > 0 && <div>
                                    <strong>📁 : { files[ 0 ].name } </strong>
                                    { files.length > 1 && <bold style={ { color: "red" } }> 🟥‼️ </bold> }
                                </div> }

                                { files.length > 0 ? (
                                    <div className="justify-content-center" >
                                        <a className="btn btn-warning my-2 btn-icon-text" style={ { borderRadius: "10px", color: "#fff" } } onClick={ ( e ) => { e.preventDefault(); onSubmitFile( e ) } } download>
                                            <i className="fa fa-download mr-2"></i> { t( "common.add" ) }
                                        </a>
                                    </div>
                                ) : null }
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div>
                                    <h6 className="main-content-label mb-1">{ t( "website.csvDownloadFile" ) } </h6>
                                    <p className="text-muted card-sub-title">{ t( "website.csvDownloadFile1" ) }  </p>
                                </div>
                                <div className="justify-content-center">
                                    <a href="https://dinscreen.eu-central-1.linodeobjects.com/default/example_mn.csv" className="btn btn-primary my-2 btn-icon-text" style={ { borderRadius: "10px" } } download>
                                        <i className="fe fe-download-cloud mr-2"></i> { t( "website.csvDownloadFile" ) }
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    { isCsv && <div className="col-lg-12 col-md-12">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div>
                                    <h6 className="main-content-label mb-1">{ t( "website.csvDownloadYourFile" ) } </h6>
                                </div>
                                <div className="justify-content-center">
                                    <a href={ `${ download }` } className="btn btn-secondary my-2 btn-icon-text" style={ { borderRadius: "10px" } } download>
                                        <i className="fe fe-download-cloud mr-2"></i> { t( "website.csvDownload" ) }
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> }
                </div>
                <div className="row row-sm"  >
                    <div className="col-sm-12 col-md-12">
                        <div className="card custom-card accordion-faq">
                            <div className="card-body">
                                <div>
                                    <h6 className="main-content-label mb-1">{ t( "website.csvFile" ) }</h6>
                                    <p className="text-muted card-sub-title">{ t( "website.csvDownloadFileInfo" ) } </p>
                                </div>
                                <div className="form-group m-0">
                                    <div className="d-flex">
                                        <div className="">
                                            <small className="mb-2" > { "I. " + t( "common.iqama" ) } { " " } </small>
                                            <label className="colorinput" style={ { backgroundColor: "#fcd45c" } }>
                                                <input name="color" type="checkbox" value="azure" className="colorinput-input" checked />
                                                <span className="colorinput-color" ></span>
                                            </label>
                                        </div>
                                        <div className="">
                                            <div className="d-flex">
                                                <div className="">
                                                    <small className="mb-2"> { "A. " + t( "common.adhan" ) } { " " }</small>
                                                    <label className="colorinput">
                                                        <input name="color" type="checkbox" value="azure" className="colorinput-input" checked />
                                                        <span className="colorinput-color bg-warning"></span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div aria-multiselectable="true" className="accordion" id="accordion" role="tablist">
                                    <div className="card">
                                        <div className="card-header" id="headingOne" role="tab" onClick={ ( e ) => { e.preventDefault(); onClickChangeMonth( 0, 0 ) } }>
                                            <a aria-controls="collapseOne" aria-expanded={ `${ theMonth === 0 && theMonthSection === 0 ? "true" : "false" }` } className={ `${ theMonth === 0 && theMonthSection === 0 ? "" : "collapsed" }` } href="#collapseOne">{ t( "website.january" ) }</a>
                                        </div>
                                        <div aria-labelledby="headingOne" class={ `${ theMonth === 0 && theMonthSection === 0 ? "collapse show" : "collapse" }` } data-parent="#accordion" id="collapseOne" role="tabpanel">
                                            <TableHeader>
                                                <CsvMonths csv={ csv } replaceLine={ replaceLine } theMonth={ theMonth } />
                                            </TableHeader>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne" role="tab" onClick={ ( e ) => { e.preventDefault(); onClickChangeMonth( 1, 1 ) } }>
                                            <a aria-controls="collapseOne" aria-expanded={ `${ theMonth === 1 && theMonthSection === 1 ? "true" : "false" }` } className={ `${ theMonth === 1 && theMonthSection === 1 ? "" : "collapsed" }` } data-toggle="collapse" href="#collapseOne">{ t( "website.februrary" ) }</a>
                                        </div>
                                        <div aria-labelledby="headingOne" class={ `${ theMonth === 1 && theMonthSection === 1 ? "collapse show" : "collapse" }` } data-parent="#accordion" id="collapseOne" role="tabpanel">
                                            <TableHeader>
                                                <CsvMonths csv={ csv } replaceLine={ replaceLine } theMonth={ theMonth } />
                                            </TableHeader>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne" role="tab" onClick={ ( e ) => { e.preventDefault(); onClickChangeMonth( 2, 2 ) } }>
                                            <a aria-controls="collapseOne" aria-expanded={ `${ theMonth === 2 && theMonthSection === 2 ? "true" : "false" }` } className={ `${ theMonth === 2 && theMonthSection === 2 ? "" : "collapsed" }` } data-toggle="collapse" href="#collapseOne">{ t( "website.mars" ) }</a>
                                        </div>
                                        <div aria-labelledby="headingOne" class={ `${ theMonth === 2 && theMonthSection === 2 ? "collapse show" : "collapse" }` } data-parent="#accordion" id="collapseOne" role="tabpanel">
                                            <TableHeader>
                                                <CsvMonths csv={ csv } replaceLine={ replaceLine } theMonth={ theMonth } />
                                            </TableHeader>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne" role="tab" onClick={ ( e ) => { e.preventDefault(); onClickChangeMonth( 3, 3 ) } }>
                                            <a aria-controls="collapseOne" aria-expanded={ `${ theMonth === 3 && theMonthSection === 3 ? "true" : "false" }` } className={ `${ theMonth === 3 && theMonthSection === 3 ? "" : "collapsed" }` } data-toggle="collapse" href="#collapseOne">{ t( "website.april" ) }</a>
                                        </div>
                                        <div aria-labelledby="headingOne" class={ `${ theMonth === 3 && theMonthSection === 3 ? "collapse show" : "collapse" }` } data-parent="#accordion" id="collapseOne" role="tabpanel">
                                            <TableHeader>
                                                <CsvMonths csv={ csv } replaceLine={ replaceLine } theMonth={ theMonth } />
                                            </TableHeader>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne" role="tab" onClick={ ( e ) => { e.preventDefault(); onClickChangeMonth( 4, 4 ) } }>
                                            <a aria-controls="collapseOne" aria-expanded={ `${ theMonth === 4 && theMonthSection === 4 ? "true" : "false" }` } className={ `${ theMonth === 4 && theMonthSection === 4 ? "" : "collapsed" }` } data-toggle="collapse" href="#collapseOne">{ t( "website.may" ) }</a>
                                        </div>
                                        <div aria-labelledby="headingOne" class={ `${ theMonth === 4 && theMonthSection === 4 ? "collapse show" : "collapse" }` } data-parent="#accordion" id="collapseOne" role="tabpanel">
                                            <TableHeader>
                                                <CsvMonths csv={ csv } replaceLine={ replaceLine } theMonth={ theMonth } />
                                            </TableHeader>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne" role="tab" onClick={ ( e ) => { e.preventDefault(); onClickChangeMonth( 5, 5 ) } }>
                                            <a aria-controls="collapseOne" aria-expanded={ `${ theMonth === 5 && theMonthSection === 5 ? "true" : "false" }` } className={ `${ theMonth === 5 && theMonthSection === 5 ? "" : "collapsed" }` } data-toggle="collapse" href="#collapseOne">{ t( "website.june" ) }</a>
                                        </div>
                                        <div aria-labelledby="headingOne" class={ `${ theMonth === 5 && theMonthSection === 5 ? "collapse show" : "collapse" }` } data-parent="#accordion" id="collapseOne" role="tabpanel">
                                            <TableHeader>
                                                <CsvMonths csv={ csv } replaceLine={ replaceLine } theMonth={ theMonth } />
                                            </TableHeader>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne" role="tab" onClick={ ( e ) => { e.preventDefault(); onClickChangeMonth( 6, 6 ) } }>
                                            <a aria-controls="collapseOne" aria-expanded={ `${ theMonth === 6 && theMonthSection === 6 ? "true" : "false" }` } className={ `${ theMonth === 6 && theMonthSection === 6 ? "" : "collapsed" }` } data-toggle="collapse" href="#collapseOne">{ t( "website.july" ) }</a>
                                        </div>
                                        <div aria-labelledby="headingOne" class={ `${ theMonth === 6 && theMonthSection === 6 ? "collapse show" : "collapse" }` } data-parent="#accordion" id="collapseOne" role="tabpanel">
                                            <TableHeader>
                                                <CsvMonths csv={ csv } replaceLine={ replaceLine } theMonth={ theMonth } />
                                            </TableHeader>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne" role="tab" onClick={ ( e ) => { e.preventDefault(); onClickChangeMonth( 7, 7 ) } }>
                                            <a aria-controls="collapseOne" aria-expanded={ `${ theMonth === 7 && theMonthSection === 7 ? "true" : "false" }` } className={ `${ theMonth === 7 && theMonthSection === 7 ? "" : "collapsed" }` } data-toggle="collapse" href="#collapseOne">{ t( "website.august" ) }</a>
                                        </div>
                                        <div aria-labelledby="headingOne" class={ `${ theMonth === 7 && theMonthSection === 7 ? "collapse show" : "collapse" }` } data-parent="#accordion" id="collapseOne" role="tabpanel">
                                            <TableHeader>
                                                <CsvMonths csv={ csv } replaceLine={ replaceLine } theMonth={ theMonth } />
                                            </TableHeader>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne" role="tab" onClick={ ( e ) => { e.preventDefault(); onClickChangeMonth( 8, 8 ) } }>
                                            <a aria-controls="collapseOne" aria-expanded={ `${ theMonth === 8 && theMonthSection === 8 ? "true" : "false" }` } className={ `${ theMonth === 8 && theMonthSection === 8 ? "" : "collapsed" }` } data-toggle="collapse" href="#collapseOne">{ t( "website.septebre" ) }</a>
                                        </div>
                                        <div aria-labelledby="headingOne" class={ `${ theMonth === 8 && theMonthSection === 8 ? "collapse show" : "collapse" }` } data-parent="#accordion" id="collapseOne" role="tabpanel">
                                            <TableHeader>
                                                <CsvMonths csv={ csv } replaceLine={ replaceLine } theMonth={ theMonth } />
                                            </TableHeader>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne" role="tab" onClick={ ( e ) => { e.preventDefault(); onClickChangeMonth( 9, 9 ) } }>
                                            <a aria-controls="collapseOne" aria-expanded={ `${ theMonth === 9 && theMonthSection === 9 ? "true" : "false" }` } className={ `${ theMonth === 9 && theMonthSection === 9 ? "" : "collapsed" }` } data-toggle="collapse" href="#collapseOne">{ t( "website.octobre" ) }</a>
                                        </div>
                                        <div aria-labelledby="headingOne" class={ `${ theMonth === 9 && theMonthSection === 9 ? "collapse show" : "collapse" }` } data-parent="#accordion" id="collapseOne" role="tabpanel">
                                            <TableHeader>
                                                <CsvMonths csv={ csv } replaceLine={ replaceLine } theMonth={ theMonth } />
                                            </TableHeader>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne" role="tab" onClick={ ( e ) => { e.preventDefault(); onClickChangeMonth( 10, 10 ) } }>
                                            <a aria-controls="collapseOne" aria-expanded={ `${ theMonth === 10 && theMonthSection === 10 ? "true" : "false" }` } className={ `${ theMonth === 10 && theMonthSection === 10 ? "" : "collapsed" }` } data-toggle="collapse" href="#collapseOne">{ t( "website.november" ) }</a>
                                        </div>
                                        <div aria-labelledby="headingOne" class={ `${ theMonth === 10 && theMonthSection === 10 ? "collapse show" : "collapse" }` } data-parent="#accordion" id="collapseOne" role="tabpanel">
                                            <TableHeader>
                                                <CsvMonths csv={ csv } replaceLine={ replaceLine } theMonth={ theMonth } />
                                            </TableHeader>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingTwo" role="tab">
                                            <div className="card-header" id="headingOne" role="tab" onClick={ ( e ) => { e.preventDefault(); onClickChangeMonth( 11, 11 ) } }>
                                                <a aria-controls="collapseOne" aria-expanded={ `${ theMonth === 11 && theMonthSection === 11 ? "true" : "false" }` } className={ `${ theMonth === 11 && theMonthSection === 11 ? "" : "collapsed" }` } data-toggle="collapse" href="#collapseOne">{ t( "website.december" ) }</a>
                                            </div>
                                            <div aria-labelledby="headingOne" class={ `${ theMonth === 11 && theMonthSection === 11 ? "collapse show" : "collapse" }` } data-parent="#accordion" id="collapseOne" role="tabpanel">
                                                <TableHeader>
                                                    <CsvMonths csv={ csv } replaceLine={ replaceLine } theMonth={ theMonth } />
                                                </TableHeader>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <input
                    type="file"
                    id="files"
                    name="files"
                    style={ { display: "none" } }
                    onChange={ fileChangedHandler }
                    ref={ inputEl }
                    multiple
                />
            </div>

        </div>



    )
}

const mapStateToProps = ( state ) => ( {
    entity: state.entity
} )

export default connect( mapStateToProps, {} )( CovidRole )
