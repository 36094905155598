import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';


import ar from './utils/translation/ar.json';
import ur from './utils/translation/ur.json';
import bn from './utils/translation/bn.json';
import en from './utils/translation/en.json';
import fr from './utils/translation/fr.json';

// import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init
const Languages = [ 'en', 'fr', 'ar', 'ur', 'bn' ];

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // .use(Backend)
    // // detect user language
    // // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use( initReactI18next )
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init( {
        fallbackLng: 'en',
        lng: localStorage.getItem( "lang" ) || "en",
        preload: [ "ar", "en" ],
        initImmediate: false,
        debug: true,
        resources: {
            ar: { translation: ar },
            ur: { translation: ur },
            bn: { translation: bn },
            en: { translation: en },
            fr: { translation: fr }
        },
        supportedLngs: Languages,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    } );


export default i18n;