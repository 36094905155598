import React from 'react'

function Index ( props ) {
    return (
        <div className="page main-signin-wrapper">
            <div className="row signpages ext-center">
                <div className="col-md-7 mx-auto">
                    <div className="card alert-message">
                        <div className="card-body">
                            <div className="text-center text-white">
                                <i className="fa fa-times" aria-hidden="true"></i>
                                <h3 className="mt-4 mb-3">للأسف </h3>
                                <p className="tx-18 text-white-50">الصفحة التي تبحث عنها لا يمكن العثور عليه</p>
                                <a href="/dashboard" className="btn btn-danger"><i className="fa fa-home" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



export default Index

