import React from 'react'
import { connect } from 'react-redux'
import TextField from "@material-ui/core/TextField";
import '../style.css'
import Fajr from "../../../../../assets/img/fajr.png";
import Dhuhr from "../../../../../assets/img/dhuhr.png";
import Asr from "../../../../../assets/img/asr.png";
import Sunset from "../../../../../assets/img/sunset.png";
import Isha from "../../../../../assets/img/isha.png";
import { useTranslation } from 'react-i18next';

export const A = ( {
    onChange,
    iqamaTimes,
    adhan,
    onChangeAutoAdd,
    onChangeAutoMinus,
    onChangeAutoAddAdhan,
    onChangeAutoMinusAdhan,
} ) => {

    const { t } = useTranslation();


    return (

        <div className="container-fluid elementToFadeInAndOut">
            <div className="inner-body">
                <div className="row row-sm" >
                    <div className="col-sm-13" style={ { marginLeft: "-91px" } }>
                        <div className="card custom-card" style={ { margin: "50px" } }>

                            <div >
                                <div className="table-responsive border userlist-table">
                                    <table className="table card-table table-striped table-vcenter text-nowrap mb-0">
                                        <thead>
                                            <tr>
                                                <th className="wd-lg-8p"><span></span></th>
                                                <th className="wd-lg-20p"><span>{ t( "common.prayer" ) }</span></th>
                                                <th className="wd-lg-20p"><span>{ t( "common.adhan" ) }</span></th>
                                                <th className="wd-lg-20p"><span>{ t( "website.adjustAdhan" ) } </span></th>
                                                <th className="wd-lg-20p"><span>{ t( "website.fixedIqama" ) }</span></th>
                                                <th className="wd-lg-20p"><span>{ t( "website.minAfterAdhan" ) }</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <img alt="prayerLogo" className="rounded-circle avatar-md mr-2" style={ { height: "20px", width: "20px" } } src={ Fajr } />
                                                </td>
                                                <td>{ t( "entity.fajr" ) }</td>
                                                <td> { adhan.Fajr } </td>
                                                <td className="text-center">
                                                    <div className="qty mt-1">
                                                        <span className="minus bg-dark" onClick={ () => onChangeAutoMinusAdhan( "f" ) } >-</span>
                                                        <input type="number" className="count" name="qty" value={ iqamaTimes.FautoA === "" ? 0 : Number( iqamaTimes.FautoA ) } style={ { width: "50px" } } />
                                                        <span className="plus bg-dark" onClick={ () => onChangeAutoAddAdhan( "f" ) }>+</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <TextField
                                                        onChange={ e => onChange( e ) }
                                                        id="Fajr"
                                                        name="Fajr"
                                                        label=""
                                                        value={ iqamaTimes.Fajr || '00:00' }
                                                        type="time"
                                                        InputLabelProps={ {
                                                            shrink: true
                                                        } }
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    <div className="qty mt-1">
                                                        <span className="minus bg-dark" onClick={ () => onChangeAutoMinus( "f" ) } >-</span>
                                                        <input type="number" className="count" name="qty" value={ iqamaTimes.Fauto === "" ? 0 : Number( iqamaTimes.Fauto ) } style={ { width: "50px" } } />
                                                        <span className="plus bg-dark" onClick={ () => onChangeAutoAdd( "f" ) }>+</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <img alt="prayerLogo" className="rounded-circle avatar-md mr-2" style={ { height: "20px", width: "20px" } } src={ Dhuhr } />
                                                </td>
                                                <td>{ t( "entity.dhuhr" ) }</td>
                                                <td> { adhan.Dhuhr }</td>
                                                <td className="text-center">
                                                    <div className="qty mt-1">
                                                        <span className="minus bg-dark" onClick={ () => onChangeAutoMinusAdhan( "d" ) }>-</span>
                                                        <input type="number" className="count" name="qty" value={ iqamaTimes.DautoA === "" ? 0 : Number( iqamaTimes.DautoA ) } style={ { width: "50px" } } />
                                                        <span className="plus bg-dark" onClick={ () => onChangeAutoAddAdhan( "d" ) }>+</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <TextField
                                                        onChange={ e => onChange( e ) }
                                                        id="Dhuhr"
                                                        name="Dhuhr"
                                                        label=""
                                                        value={ iqamaTimes.Dhuhr || '00:00' }
                                                        type="time"
                                                        InputLabelProps={ {
                                                            shrink: true
                                                        } }
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    <div className="qty mt-1">
                                                        <span className="minus bg-dark" onClick={ () => onChangeAutoMinus( "d" ) }>-</span>
                                                        <input type="number" className="count" name="qty" value={ iqamaTimes.Dauto === "" ? 0 : Number( iqamaTimes.Dauto ) } style={ { width: "50px" } } />
                                                        <span className="plus bg-dark" onClick={ () => onChangeAutoAdd( "d" ) }>+</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <img alt="prayerLogo" className="rounded-circle avatar-md mr-2" style={ { height: "20px", width: "20px" } } src={ Asr } />
                                                </td>
                                                <td>{ t( "entity.asr" ) }</td>
                                                <td> { adhan.Asr } </td>
                                                <td className="text-center">
                                                    <div className="qty mt-1">
                                                        <span className="minus bg-dark" onClick={ () => onChangeAutoMinusAdhan( "a" ) }>-</span>
                                                        <input type="number" className="count" name="qty" value={ iqamaTimes.AautoA === "" ? 0 : Number( iqamaTimes.AautoA ) } style={ { width: "50px" } } />
                                                        <span className="plus bg-dark" onClick={ () => onChangeAutoAddAdhan( "a" ) }>+</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <TextField
                                                        onChange={ e => onChange( e ) }
                                                        id="Asr"
                                                        name="Asr"
                                                        label=""
                                                        value={ iqamaTimes.Asr || '00:00' }
                                                        type="time"
                                                        InputLabelProps={ {
                                                            shrink: true
                                                        } }
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    <div className="qty mt-1">
                                                        <span className="minus bg-dark" onClick={ () => onChangeAutoMinus( "a" ) }>-</span>
                                                        <input type="number" className="count" name="qty" value={ iqamaTimes.Aauto === "" ? 0 : Number( iqamaTimes.Aauto ) } style={ { width: "50px" } } />
                                                        <span className="plus bg-dark" onClick={ () => onChangeAutoAdd( "a" ) }>+</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <img alt="prayerLogo" className="rounded-circle avatar-md mr-2" style={ { height: "20px", width: "20px" } } src={ Sunset } />
                                                </td>
                                                <td>{ t( "entity.maghrib" ) }</td>
                                                <td> { adhan.Maghrib } </td>
                                                <td className="text-center">
                                                    <div className="qty mt-1">
                                                        <span className="minus bg-dark" onClick={ () => onChangeAutoMinusAdhan( "m" ) }>-</span>
                                                        <input type="number" className="count" name="qty" value={ iqamaTimes.MautoA === "" ? 0 : Number( iqamaTimes.MautoA ) } style={ { width: "50px" } } />
                                                        <span className="plus bg-dark" onClick={ () => onChangeAutoAddAdhan( "m" ) }>+</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <TextField
                                                        onChange={ e => onChange( e ) }
                                                        id="Maghrib"
                                                        name="Maghrib"
                                                        label=""
                                                        value={ iqamaTimes.Maghrib || '00:00' }
                                                        type="time"
                                                        InputLabelProps={ {
                                                            shrink: true
                                                        } }
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    <div className="qty mt-1">
                                                        <span className="minus bg-dark" onClick={ () => onChangeAutoMinus( "m" ) }>-</span>
                                                        <input type="number" className="count" name="qty" value={ iqamaTimes.Mauto === "" ? 0 : Number( iqamaTimes.Mauto ) } style={ { width: "50px" } } />
                                                        <span className="plus bg-dark" onClick={ () => onChangeAutoAdd( "m" ) }>+</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <img alt="prayerLogo" className="rounded-circle avatar-md mr-2" style={ { height: "20px", width: "20px" } } src={ Isha } />
                                                </td>
                                                <td>{ t( "entity.isha" ) }</td>
                                                <td> { adhan.Isha } </td>
                                                <td className="text-center">
                                                    <div className="qty mt-1">
                                                        <span className="minus bg-dark" onClick={ () => onChangeAutoMinusAdhan( "i" ) }>-</span>
                                                        <input type="number" className="count" name="qty" value={ iqamaTimes.IautoA === "" ? 0 : Number( iqamaTimes.IautoA ) } style={ { width: "50px" } } />
                                                        <span className="plus bg-dark" onClick={ () => onChangeAutoAddAdhan( "i" ) }>+</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <TextField
                                                        onChange={ e => onChange( e ) }
                                                        id="Isha"
                                                        name="Isha"
                                                        label=""
                                                        value={ iqamaTimes.Isha || '00:00' }
                                                        type="time"
                                                        InputLabelProps={ {
                                                            shrink: true
                                                        } }
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    <div className="qty mt-1">
                                                        <span className="minus bg-dark" onClick={ () => onChangeAutoMinus( "i" ) }>-</span>
                                                        <input type="number" className="count" name="qty" value={ iqamaTimes.Iauto === "" ? 0 : Number( iqamaTimes.Iauto ) } style={ { width: "50px" } } />
                                                        <span className="plus bg-dark" onClick={ () => onChangeAutoAdd( "i" ) }>+</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    )
}

const mapStateToProps = ( state ) => ( {

} )

const mapDispatchToProps = {

}

export default connect( mapStateToProps, mapDispatchToProps )( A )
