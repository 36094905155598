import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const MyMapComponent = ( props ) => {
    const mapRef = useRef( null );

    const { lat, lng, name, phone } = props.entity.entity;
    const position = [ lat, lng ];

    useEffect( () => {
        if ( mapRef.current ) {
            const map = L.map( mapRef.current ).setView( position, 16 );

            L.tileLayer( 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            } ).addTo( map );

            L.marker( position ).addTo( map )
                .bindPopup( `Mosquée ${ name } ${ phone }` )
                .openPopup();
        }
    }, [ lat, lng, name, phone ] );

    return <div ref={ mapRef } style={ { height: "100vh", width: "100%" } } />;
};

export default MyMapComponent;
