

export const putToAsyncStorage = async function ( data ) {

    // await AsyncStorage.removeItem( 'favorite' );
    try {
        let history = await localStorage.getItem( 'favorite' );
        if ( !history ) {
            let x = [ data ];
            await localStorage.setItem( "favorite", JSON.stringify( x ) );
        } else {
            let d = [ ...JSON.parse( history ) ];
            if ( d.filter( datas => datas.url === data.url ).length == 0 ) {
                if ( d.length >= 5 ) d.pop();
                d.unshift( data );
                await localStorage.setItem( "favorite", JSON.stringify( d ) );
            }
        }
    } catch ( error ) {
        console.log( error );
    }

};
