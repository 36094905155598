import React, { Fragment, useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { UserProfileContext } from "../../../Context.js";
import Donation from '../../../components/admin/Donation/Index';
import { getEntity, updateEntity } from "../../../action/entity";

export const Index = ( { match, updateEntity, getEntity, entity: { entityProfil, loading, csv, entity } } ) => {
    const { userProfile } = useContext( UserProfileContext );

    useEffect( () => {
        if ( userProfile ) {
            const { codeEntity, profile } = userProfile;
            const { token } = match.params;
            if ( ( encodeURI( codeEntity ).localeCompare( token ) === 0 ) && ( profile.isRole || profile.isAdmin ) ) {
                getEntity( codeEntity );
            } else {
                alert( "🟥" )
                window.location.replace( "/dashboard" );
            }
        }

    }, [ userProfile ] )
    const [ saving, setSaving ] = useState( false )
    const [ formData, setformData ] = useState( {

        paypal: loading ? "" : entity.entity.paypal,
        paypal2: loading ? "" : entity.entity.paypal2,

    } );


    useEffect( () => {

        setformData( {
            ...formData,
            paypal: loading ? "" : entity.entity.paypal,
            paypal2: loading ? "" : entity.entity.paypal2,
        } );

    }, [ loading ] );


    const onChange = ( e ) => {
        setformData( { ...formData, [ e.target.name ]: e.target.value } )
    }


    const onSave = async () => {
        const { codeEntity } = userProfile;

        setSaving( true );

        let aresponse = await updateEntity( formData, codeEntity );
        setSaving( false );
    };


    return (
        <Donation onChange={ onChange } formData={ formData } saving={ saving } onSave={ onSave } />
    )
}

Index.propTypes = {
    props: PropTypes
}

const mapStateToProps = ( state ) => ( {
    entity: state.entity
} )



export default connect( mapStateToProps, { getEntity, updateEntity } )( Index )
