import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Tutorials from './tutorials/Index';
import { useTranslation } from 'react-i18next';
import { setRoute, disableRoute } from '../../../action/routes'
import { isMobile, isTablet } from '../../../utils/device';

export const Settings = ( { setRoute, disableRoute, onChange, formData, onSave, success, onReset, saving } ) => {

    useEffect( () => {

        setRoute( 'admin/settings' );

        return () => {
            disableRoute()
        }
    }, [] )


    const { t, i18n } = useTranslation();
    const [ active, setActive ] = useState( {
        iqama: true,
        adhan: false,
        fCsv: false,
        widget: false
    } );


    const changeSubOptionsPrayer = ( active ) => {
        switch ( active ) {
            case 'iqama':
                setActive( { ...active, iqama: true } );
                break;
            case 'adhan':
                setActive( { ...active, adhan: true } );
                break;
            case 'fCsv':
                setActive( { ...active, fCsv: true } );
                break;
            case 'widget':
                setActive( { ...active, widget: true } );
                break;

            default:
                break;
        }

    }

    return (
        <Fragment>
            <div className="page">
                <div className="main-content side-content pt-0">
                    <div className="row row-sm container-fluid" style={ { maxWidth: active.fCsv ? '80%' : '90%', marginLeft: isMobile() ? " " : "70px" } }>
                        <div className={ `col-xl-8 col-lg-12` }>
                            <div className="card custom-card2" id="hori">
                                <div className="card-body">
                                    <div className="text-wrap">
                                        <div className="example">
                                            <div>
                                                <h6 className="main-content-label mb-1">{ t( 'website.generalTitle' ) }</h6>
                                                <p className="text-muted card-sub-title">{ t( 'website.generalInfo' ) }</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row row-sm" >
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <p className="mg-b-10">{ t( "common.name" ) } : </p>
                                                    <input type="text" onChange={ ( e ) => onChange( e ) } name="name" className="form-control" value={ formData.name } />
                                                    { success.name && <small style={ { color: "red" } }>{ t( 'autoMessage.notValid' ) }</small> }
                                                </div>
                                                <div className="form-group">
                                                    <p className="mg-b-10">{ t( "common.website" ) } </p>
                                                    <input type="text" onChange={ ( e ) => onChange( e ) } name="website" className="form-control" value={ formData.website } />
                                                    { success.website && <small style={ { color: "red" } }>{ t( 'autoMessage.onlyLink' ) }</small> }
                                                </div>
                                                <div className="form-group">
                                                    <p className="mg-b-10">{ t( "common.email" ) } 2 </p>
                                                    <input type="text" onChange={ ( e ) => onChange( e ) } name="email" className="form-control" value={ formData.email } />
                                                    { success.email && <small style={ { color: "red" } }> { t( 'autoMessage.onlyEmail' ) } </small> }

                                                </div>
                                                <div className="form-group">
                                                    <p className="mg-b-10">{ t( "landing.title2" ) } # : </p>
                                                    <div class="alert alert-warning" role="alert">
                                                        <input type="text" onChange={ ( e ) => onChange( e ) } name="phone" className="form-control" value={ formData.phone } />
                                                        <strong>{ t( 'autoMessage.warning' ) }!</strong> { t( 'autoMessage.onlyUniquePhone' ) }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">

                                                <div className="form-group">
                                                    <p className="mg-b-10">Facebook </p>
                                                    <input type="text" onChange={ ( e ) => onChange( e ) } name="facebook" className="form-control" value={ formData.facebook } />
                                                    { success.facebook && <small style={ { color: "red" } }>{ t( 'autoMessage.onlyLink' ) }</small> }

                                                </div>
                                                <div className="form-group">
                                                    <p className="mg-b-10">Youtube</p>
                                                    <input type="text" onChange={ ( e ) => onChange( e ) } name="youtube" className="form-control" value={ formData.youtube } />
                                                    { success.youtube && <small style={ { color: "red" } }>{ t( 'autoMessage.onlyLink' ) }</small> }

                                                </div>
                                                <div className="form-group">
                                                    <p className="mg-b-10">WhatsApp</p>
                                                    <input type="text" name="what" onChange={ ( e ) => onChange( e ) } className="form-control" value={ formData.what } />
                                                    { success.what && <small style={ { color: "red" } }>{ t( 'autoMessage.onlyLink' ) }</small> }

                                                </div>
                                                <div className="form-group">
                                                    <p className="mg-b-10">{ t( "common.imam" ) } # : </p>
                                                    <input type="text" onChange={ ( e ) => onChange( e ) } name="phoneImam" className="form-control" value={ formData.phoneImam } />
                                                    { success.imam && <small style={ { color: "red" } }>{ t( 'autoMessage.onlyPhone' ) }</small> }

                                                </div>
                                                <div className="form-group">
                                                    <p className="mg-b-10">{ t( "shops.14" ) } #:</p>
                                                    <input type="text" onChange={ ( e ) => onChange( e ) } name="phoneOther" className="form-control" value={ formData.phoneOther } />
                                                    { success.other && <small style={ { color: "red" } }>{ t( 'autoMessage.onlyPhone' ) }</small> }

                                                </div>
                                            </div>
                                            <div className="col-md-12 ">
                                                <div className="form-group mb-0">
                                                    <p className="mg-b-10">{ t( "common.description" ) } { t( "common.mosque" ) }</p>
                                                    <textarea className="form-control" onChange={ ( e ) => onChange( e ) } name="description" rows="4" value={ formData.description } ></textarea>
                                                    { success.description && <small style={ { color: "red" } }>{ t( 'autoMessage.tooShort' ) }</small> }
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div style={ { float: "left", marginLeft: "80%" } }>
                                    { saving ? <div className="spinner-grow" style={ { width: "5rem", height: "5rem", marginLeft: "40%" } } role="status">
                                        <span className="sr-only">{ t( 'autoMessage.loading' ) }</span>
                                    </div> : <div>
                                        <div style={ { float: "right" } }>
                                            <div>
                                                <button type="button" className="btn ripple btn-yellow btn-yellow" onClick={ () => onReset() }
                                                >🔃  { t( 'autoMessage.reset' ) }</button>
                                            </div>
                                        </div>
                                        <button type="button" className="btn ripple btn-dark btn-block" onClick={ () => onSave() }
                                            disabled={ success.description || success.other
                                                || success.name || success.website || success.email || success.facebook || success.youtube || success.what || success.imam
                                            }
                                        >{ t( 'common.save' ) } </button>
                                    </div> }
                                </div>
                            </div>

                        </div>

                        <Tutorials />

                    </div>

                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = ( state ) => ( {
    routes: state.routes
} )


export default connect( mapStateToProps, { setRoute, disableRoute } )( Settings )
