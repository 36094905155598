import React, { useState } from 'react'
import Prayer from '../../../containers/dashboard/prayerWdiget/Index';
import Header from '../header/Index';
import Qa from '../qa/Index';
import MobilePrayer from '../../../components/Prayer/Widget/Ui';
import EntityPosts from '../../../containers/entity/posts/Index';
import SpecialPostEntity from '../../../containers/entity/specialPosts/Index';
import { iqamaCalcul, calculeNextIqama } from '../../../utils/processingDate&Time';
import About from '../about/Index';
import Followers from '../followers/Index';
import PublishButton from '../../../containers/buttons/publishButton/Index';
import { isMobile, isTablet } from '../../../utils/device';

function Index ( { entity,
    entityProfil,
    loading,
    followEntity,
    isItRole,
    isItAdmin,
    user,
    codeEntity,
    inputEl,
    onButtonClick,
    adminEntityName,
    fileChangedHandler,
} ) {

    const [ section, setSections ] = useState( "0" );
    const [ postsType, setPostsType ] = useState( "" );

    const setMySection = ( args ) => {
        setSections( args );
    }
    const onChangePostsType = ( type ) => {
        setPostsType( type );
    };
    return (
        <div className="page">
            <div class="main-content side-content pt-0">
                <div class="container-fluid" style={ { marginLeft: isMobile() ? "" : "70px" } }>
                    <div class="inner-body">
                        <div class="page-header">
                            <div>
                                <h2 class="main-content-title tx-24 mg-b-5">Masjid<i class="fa fa-flag" aria-hidden="true"></i>
                                </h2>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">masjid</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">{ entity.entity.name }</li>
                                </ol>
                            </div>
                        </div>
                        <div class="row row-sm" >
                            {/* col-xl-12 col-lg-12 col-md-12 */ }
                            <div class="col-lg-4 " style={ { position: "sticky" } }>
                                <div class="card custom-card" id="sticky-top" style={ { backgroundColor: "transparent" } }>
                                    <MobilePrayer
                                        show={ true }
                                        iqamaTimes={ entityProfil.iqamaTime }
                                        apiPrayerTime={ entityProfil.apiPrayerTime }
                                        calculeNextIqama={ calculeNextIqama }
                                        entityProfil={ entityProfil.entityProfil }
                                        prayerTime={ entityProfil.prayerTime }
                                        color={ entityProfil.prayerTime.widgetColor }
                                        entity={ entityProfil.entity }
                                        tomorrowIqamaTime={ entityProfil.tomorrowIqamaTime }
                                        tomorrowApiPrayerTime={ entityProfil.tomorrowApiPrayerTime }
                                        highlighted={ entityProfil.highlighted }
                                        iqamaCalcul={ iqamaCalcul }
                                        isText={ false }
                                        text={ "..." } >
                                    </MobilePrayer>
                                </div>
                            </div>
                            <Header
                                user={ user }
                                entity={ entity }
                                entityProfil={ entityProfil }
                                loading={ loading }
                                setSections={ setMySection }
                                section={ section }
                                onChangePostsType={ onChangePostsType }
                                followEntity={ followEntity }
                                onButtonClick={ onButtonClick }
                                fileChangedHandler={ fileChangedHandler }
                                inputEl={ inputEl }
                            >
                                <EntityPosts
                                    isAdmin={ ( ( isItRole || isItAdmin ) && ( encodeURI( adminEntityName ).localeCompare( codeEntity ) === 0 ) ) || ( user.user.id === "5ea4b50e2c7bba00178e5ca9" ) }
                                    postsType={ postsType } codeEntity={ entity.codeEntity } picture={ entity.entity.logo } name={ entity.entity.name } />
                                <SpecialPostEntity isAdmin={ ( ( isItRole || isItAdmin ) && ( encodeURI( adminEntityName ).localeCompare( codeEntity ) === 0 ) ) || ( user.user.id === "5ea4b50e2c7bba00178e5ca9" ) } postsType={ postsType } codeEntity={ entity.codeEntity } picture={ entity.entity.logo } name={ entity.entity.name } />
                                <About entity={ entity } entityProfil={ entityProfil } />
                                <Followers entityProfil={ entityProfil } />
                            </Header>

                            { ( ( ( isItRole || isItAdmin ) && ( encodeURI( adminEntityName ).localeCompare( codeEntity ) === 0 ) ) || ( user.user.id === "5ea4b50e2c7bba00178e5ca9" ) ) && <PublishButton codeEntity={ codeEntity } /> }

                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default Index
