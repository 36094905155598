import React from 'react'
import Links from '../media/Link';
import { useTranslation } from 'react-i18next';
import ShortComment from '../media/ShortComment';

function FatawasEntity ( { question, show } ) {
    const { t } = useTranslation();
    const { imam, user } = question;

    return (
        <div className="main-content-body  tab-pane border-top-0" id="timeline" style={ { marginBottom: "-20px" } }>
            <div className="">
                <div className="main-content-body main-content-body-profile">
                    <div className="main-profile-body p-0">
                        <div class="">
                            <div class="card custom-card">
                                <div class="card-body">
                                    <div class="card-block">
                                        <div class="row align-items-center justify-content-center">
                                            <div class="col-auto user-lock">
                                                <img class="img-fluid rounded-circle" src={ imam.picture } alt="avatar" />
                                            </div>
                                            <div class="col">
                                                <h5>{ imam.name } { " " } { imam.handle }</h5>
                                                <span>{ imam.madhab }</span>
                                            </div>
                                        </div>
                                        <ul class="task-list">
                                            <li>
                                                <i class="task-icon bg-primary"></i>
                                                <h6> <span class="float-end text-muted fs-11"></span></h6>
                                                <p class="text-muted fs-12"><a onClick={ ( e ) => e.preventDefault() } class="font-weight-semibold">
                                                    <ShortComment comment={ question.answer } length={ question.answer.length } />
                                                </a></p>
                                            </li>
                                            <div class="card-block">
                                                <div class="row align-items-center justify-content-center">
                                                    <div class="col-auto user-lock">
                                                        <img class="img-fluid rounded-circle" src={ question.isAnonym ? "https://moonode-profile.s3.ca-central-1.amazonaws.com/anonym.png" : user.picture } alt="avatar" />
                                                    </div>
                                                    <div class="col">
                                                        { question.isAnonym ? "Anonyme" : <h5>{ user.name } { " " } { user.handle }</h5> }
                                                    </div>
                                                </div>
                                                <ul class="task-list">
                                                    <li>
                                                        <i class="task-icon bg-primary"></i>
                                                        <h6>{ t( "questions.app-question" ) } : <span class="float-end text-muted fs-11">{ question.date }</span></h6>
                                                        <p class="text-muted fs-12">{ question.question } </p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </ul>
                                    </div>
                                    { show && question.isUrl ? <Links link={ question.url } /> : null }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    )
}

export default FatawasEntity
