import axios from "axios";
import {
    GET_USER_NOTIFICATION,
    LOADING_USER_NOTIFICATION
} from "./types";

import { setAlert } from "./alert";
const config = { headers: { "Content-Type": "application/json" } };


export const getUserNotifications = () => async dispatch => {
    try {



        dispatch( {
            type: LOADING_USER_NOTIFICATION,
            payload: true
        } );


        const res = await axios.get( "/api/userNotifications/" );



        dispatch( {
            type: LOADING_USER_NOTIFICATION,
            payload: false
        } );

        dispatch( {
            type: GET_USER_NOTIFICATION,
            payload: res.data
        } );

    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};


export const getUserNotifications2 = () => async dispatch => {
    try {
        const res = await axios.get( "/api/userNotifications/" );
        dispatch( {
            type: GET_USER_NOTIFICATION,
            payload: res.data
        } );

    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};



export const makeThemSeen = () => async dispatch => {
    try {

        const res = await axios.post( "/api/userNotifications/makeThemSeen" );


    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};
