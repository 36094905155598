import React, { useState } from 'react'
import { connect } from 'react-redux'
import tvExemple from "../../../../assets/tv.png";
import tvExemple2 from "../../../../assets/classic.png";
import tvExemple3 from "../../../../assets/comming.jpeg";
import { useTranslation } from 'react-i18next';
import { Accordion, Card } from "react-bootstrap";
import copy from 'copy-to-clipboard';

export const TvSettings = ( { entity: { entity } } ) => {

    const { t } = useTranslation();
    const [ theme, seTtheme ] = useState( 0 );

    const [ modalIsOpen, setIsOpen ] = useState( false );

    function openModal () {
        setIsOpen( true );
    }
    function closeModal () {
        setIsOpen( false );
    }

    return (
        <div className="container-fluid elementToFadeInAndOut">
            <div className="inner-body">
                <div className="row row-sm" >
                    <div className="col-lg-12 col-md-12">
                        <div className="card custom-card">
                            <div className="row row-sm" >
                                <div className="col-sm-6 col-md-6 col-xl-3">
                                    <div className="card custom-card">
                                        <div className="card-body user-card text-center">
                                            <div className="">
                                                <img alt="avatar" src={ tvExemple } />
                                            </div>

                                            <h5 className="mb-1">{ t( "website.tvTemplatesTitle1" ) } </h5>
                                            <Accordion>
                                                <Card>
                                                    <Accordion.Toggle as={ Card.Header } eventKey="0">

                                                        <p className="mb-1 tx-inverse" style={ { cursor: "pointer", fontSize: "900", backgroundColor: "#FABF0F" } }>{ t( "website.tvTemplatesTitle3" ) } 1</p>

                                                    </Accordion.Toggle>

                                                    <Accordion.Collapse eventKey="0">
                                                        <Card.Body>
                                                            <figure className="highlight clip-widget" id="badge1"><pre>
                                                                <code style={ { color: "red" } }>
                                                                    https://www.moonode.tv/{ entity.phone }
                                                                </code>
                                                            </pre>

                                                            </figure>
                                                            <div className="clipboard-icon" data-clipboard-target="#badge1"
                                                                onClick={ () => { copy( `https://www.moonode.tv/${ entity.phone }` ); alert( t( "website.copied" ) ) } }
                                                            ><i className="fa fa-clipboard"></i></div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                    <Accordion.Toggle as={ Card.Header } eventKey="1">

                                                        <p className="mb-1 tx-inverse" style={ { cursor: "pointer", fontSize: "900", backgroundColor: "#FABF0F" } }>{ t( "website.tvTemplatesTitle3" ) } 2</p>

                                                    </Accordion.Toggle>

                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body>
                                                            <figure className="highlight clip-widget" id="badge1"><pre>
                                                                <code style={ { color: "red" } }>
                                                                    https://www.moonode.tv/{ entity.phone }/view/1
                                                                </code>
                                                            </pre>

                                                            </figure>
                                                            <div className="clipboard-icon" data-clipboard-target="#badge1"
                                                                onClick={ () => { copy( `https://www.moonode.tv/${ entity.phone }/view/1` ); alert( t( "website.copied" ) ) } }
                                                            ><i className="fa fa-clipboard"></i></div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                    <Accordion.Toggle as={ Card.Header } eventKey="2">

                                                        <p className="mb-1 tx-inverse" style={ { cursor: "pointer", fontSize: "900", backgroundColor: "#FABF0F" } }>{ t( "website.tvTemplatesTitle3" ) } 3</p>

                                                    </Accordion.Toggle>

                                                    <Accordion.Collapse eventKey="2">
                                                        <Card.Body>
                                                            <figure className="highlight clip-widget" id="badge1"><pre>
                                                                <code style={ { color: "red" } }>
                                                                    https://www.moonode.tv/{ entity.phone }/view/1/60
                                                                </code>
                                                            </pre>

                                                            </figure>
                                                            <div className="clipboard-icon" data-clipboard-target="#badge1"
                                                                onClick={ () => { copy( `https://www.moonode.tv/${ entity.phone }/view/1/60` ); alert( t( "website.copied" ) ) } }
                                                            ><i className="fa fa-clipboard"></i></div>
                                                            <div className="example">
                                                                <h6>{ t( "notif.3linkdesc" ) }</h6>
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                    <Accordion.Toggle as={ Card.Header } eventKey="3">

                                                        <p className="mb-1 tx-inverse" style={ { cursor: "pointer", fontSize: "900", backgroundColor: "#FABF0F" } }>{ t( "website.tvTemplatesTitle3" ) } 4</p>

                                                    </Accordion.Toggle>

                                                    <Accordion.Collapse eventKey="3">
                                                        <Card.Body>
                                                            <figure className="highlight clip-widget" id="badge1"><pre>
                                                                <code style={ { color: "red" } }>
                                                                    https://www.moonode.tv/{ entity.phone }/view/1/60/true
                                                                </code>
                                                            </pre>

                                                            </figure>
                                                            <div className="clipboard-icon" data-clipboard-target="#badge1"
                                                                onClick={ () => { copy( `https://www.moonode.tv/${ entity.phone }/view/1/60/true` ); alert( t( "website.copied" ) ) } }
                                                            ><i className="fa fa-clipboard"></i></div>
                                                            <div className="example">
                                                                <h6>{ t( "notif.4linkdesc" ) }</h6>
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                    <Accordion.Toggle as={ Card.Header } eventKey="4">

                                                        <p className="mb-1 tx-inverse" style={ { cursor: "pointer", fontSize: "900", backgroundColor: "#FABF0F" } }>{ t( "website.tvTemplatesTitle3" ) } 5</p>

                                                    </Accordion.Toggle>

                                                    <Accordion.Collapse eventKey="4">
                                                        <Card.Body>
                                                            <figure className="highlight clip-widget" id="badge1"><pre>
                                                                <code style={ { color: "red" } }>
                                                                    https://www.moonode.tv/noads/{ entity.phone }
                                                                </code>
                                                            </pre>

                                                            </figure>
                                                            <div className="clipboard-icon" data-clipboard-target="#badge1"
                                                                onClick={ () => { copy( `https://www.moonode.tv/noads/${ entity.phone }` ); alert( t( "website.copied" ) ) } }
                                                            ><i className="fa fa-clipboard"></i></div>
                                                            <div className="example">
                                                                <h6>{ t( "notif.5linkdesc" ) }</h6>
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>

                                            {/* <span className="text-muted"><i className="far fa-check-circle text-success mr-1" ></i>Verified</span> */ }

                                            <a href="#" className="btn ripple btn-primary mt-3" onClick={ ( e ) => { e.preventDefault(); seTtheme( 0 ) } }>{ t( "website.tvTemplatesTitle4" ) }</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6 col-xl-3">
                                    <div className="card custom-card">
                                        <div className="card-body user-card text-center">
                                            <div className="">

                                                <img alt="avatar" src={ tvExemple2 } />
                                            </div>
                                            <h5 className="mb-1" >{ t( "website.tvTemplatesTitle2" ) }</h5>
                                            <Accordion>
                                                <Card>
                                                    <Accordion.Toggle as={ Card.Header } eventKey="0">

                                                        <p className="mb-1 tx-inverse" style={ { cursor: "pointer", fontSize: "900", backgroundColor: "#FABF0F" } }>{ t( "website.tvTemplatesTitle3" ) } 1</p>

                                                    </Accordion.Toggle>

                                                    <Accordion.Collapse eventKey="0">
                                                        <Card.Body>
                                                            <figure className="highlight clip-widget" id="badge1"><pre>
                                                                <code style={ { color: "red" } }>
                                                                    https://www.moonode.tv/{ entity.phone }/view/2
                                                                </code>
                                                            </pre>

                                                            </figure>
                                                            <div className="clipboard-icon" data-clipboard-target="#badge1"
                                                                onClick={ () => { copy( `https://www.moonode.tv/${ entity.phone }/view/2` ); alert( t( "website.copied" ) ) } }
                                                            ><i className="fa fa-clipboard"></i></div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                            {/* <span className="text-muted"><i className="far fa-check-circle text-success mr-1" ></i>Verified</span> */ }
                                        </div>
                                        <a href="#" className="btn ripple btn-primary mt-3" onClick={ ( e ) => { e.preventDefault(); seTtheme( 1 ) } }>{ t( "website.tvTemplatesTitle4" ) }</a>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6 col-xl-3">
                                    <div className="card custom-card">
                                        <div className="card-body user-card text-center">
                                            <div className="">
                                                <img alt="avatar" src={ tvExemple3 } />
                                            </div>

                                            <h5 className="mb-1">{ t( "website.tvTemplatesComing" ) }</h5>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6 col-xl-3">
                                    <div className="card custom-card">
                                        <div className="card-body user-card text-center">
                                            <div className="">
                                                <img alt="avatar" src={ tvExemple3 } />
                                            </div>
                                            <div className="mt-2">
                                                <h5 className="mb-1">{ t( "website.tvTemplatesComing" ) }</h5>
                                                <p className="mb-1 tx-inverse"></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { theme === 0 && <iframe width="1680" height="650" src={ `https://www.moonode.tv/${ entity.phone }` } /> }
                                { theme === 1 && <iframe width="1680" height="650" src={ `https://www.moonode.tv/${ entity.phone }/view/2` } /> }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>



    )
}

const mapStateToProps = ( state ) => ( {

} )

const mapDispatchToProps = {

}


export default connect( mapStateToProps, mapDispatchToProps )( TvSettings )