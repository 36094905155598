import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import MyMapComponent from "../../components/map/Maps";

export class MapContainer extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            isOpen: false,
            lat: "",
            lng: "",
            isId: ""
        };
    }

    onToggleOpen = ( lat, lng, id ) => {

        this.setState( {
            isOpen: !this.state.isOpen
        } );

        if ( this.state.isOpen ) {
            this.setState( {
                lat: lat,
                lng: lng,
                isId: id
            } );
        } else {
            this.setState( {
                lat: "",
                lng: "",
                isId: ""
            } );
        }
    };

    render () {
        const { isOpen } = this.state;
        return (
            <div>
                <MyMapComponent
                    isMarkerShown
                    googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                    // loadingElement={<div style={{ height: `120%` }} />}
                    // containerElement={<div style={{ height: `400px` }} />}
                    // mapElement={<div style={{ height: `120%` }} />}
                    entities={ this.props.entities }
                    myEntities={ this.props.myEntities }
                    isOpen={ isOpen }
                    onToggleOpen={ this.onToggleOpen }
                    isId={ this.state.isId }
                >


                </MyMapComponent>
            </div>
        );
    }
}

export default MapContainer;
