import {

    GET_ENTITY,
    GET_ENTITY_CSV,
    GET_PRAYER_MTL,
    GET_ADMIN_PRAYER_TV,
    CREATE_ENTITY,
    GET_ENTITY_VISUAL
} from '../action/types';


const initialState = {
    entity: null,
    shops: null,
    subEntity: null,
    events: null,
    activities: null,
    opening: null,
    entityProfil: null,
    entityVisual: null,
    follower: null,
    member: null,
    prayerTime: null,
    created: false,
    loading: true,
    loadingCsv: true,
    postEntity: [],
    postEntityId: null,
    postLoading: false,
    postEnd: false,
    questions: [],
    Imams: [],
    csv: [],
    mtlPrayer: null,
    tvPrayer: null,
    tvSettings: null,
    id: null,
    error: {}
};

export default function ( state = initialState, action ) {
    const { type, payload } = action;

    switch ( type ) {

        case GET_ENTITY:
            return {
                ...state,
                entity: payload.Entity,
                entityProfil: payload.EntityProfil,
                loading: false
            };
        case GET_ENTITY_VISUAL:
            return {
                ...state,
                entityVisual: payload.entity.entityImage,
                loading: false
            };
        case GET_ENTITY_CSV:
            return {
                ...state,
                csv: payload,
                loadingCsv: false
            };
        case GET_PRAYER_MTL:
            return {
                ...state,
                mtlPrayer: payload,
                loadingCsv: false
            };
        case GET_ADMIN_PRAYER_TV:
            return {
                ...state,
                tvPrayer: payload,
                tvSettings: payload.tvSettings,
                loadingCsv: false
            };
        case CREATE_ENTITY:
            return {
                ...state,
                created: true,
                id: payload.codeEntity,
                loading: false
            };
        default:
            return state;
    }
}
