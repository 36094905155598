import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const ImageList = ( { handleImageClick, selectedImage, imageUrls, onPlusButtonClick, defaultImages, placeholderImage, placeholderImageStyle } ) => {
    const { t, i18n } = useTranslation();

    const imageStyle = {
        width: '130px',
        height: '78px',
        margin: '10px',
        objectFit: 'cover',
    };

    const selectedImageStyle = {
        ...imageStyle,
        border: '2px solid #18A1FD',
        borderRadius: '10px',
        position: 'relative',
        padding: '10px',
    };

    const selectedIndicatorStyle = {
        position: 'absolute',
        left: '-10px',
        top: '50%',
        transform: 'translateY(-50%)',
        width: '20px',
        height: '60px',
        backgroundColor: '#18A1FD',
        borderRadius: '10px',
    };

    const rows = imageUrls.map( ( image, index ) => (
        <div key={ index } style={ { display: 'flex', alignItems: 'center', justifyContent: 'center' } }>
            <div
                style={ selectedImage === image ? selectedImageStyle : imageStyle }
                onClick={ () => handleImageClick( image, index ) }
            >
                <img
                    src={ image }
                    alt={ `Image ${ index + 1 }` }
                    style={ { width: '100%', height: '100%', objectFit: 'cover' } }
                />
                { selectedImage === image && <div style={ selectedIndicatorStyle }></div> }
            </div>
        </div>
    ) );

    const defaultImageRows = defaultImages.map( ( image, index ) => (
        <div key={ index } style={ { display: 'flex', alignItems: 'center', justifyContent: 'center' } }>
            <div
                style={ selectedImage === image ? selectedImageStyle : image === placeholderImage ? placeholderImageStyle : imageStyle }
                onClick={ () => handleImageClick( image, index ) }
            >
                <img
                    src={ image }
                    alt={ `Image ${ index + 1 }` }
                    style={ { width: '100%', height: '100%', objectFit: 'cover' } }
                />
                { selectedImage === image && <div style={ selectedIndicatorStyle }></div> }
            </div>
        </div>
    ) );

    return (
        <>
            { rows }
            <div key="plus" style={ { display: 'flex', alignItems: 'center', justifyContent: 'center' } }>
                <button
                    style={ {
                        ...imageStyle,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#ddd',
                        color: '#333',
                        fontSize: '4.2em', // adjust the fontSize as needed
                        borderRadius: '20px',
                        padding: '10px',
                        lineHeight: '1',
                        transform: 'scale(0.7)', // add this to scale down the element
                    } }
                    onClick={ onPlusButtonClick }
                >
                    +
                </button>

            </div>
            <hr style={ { border: 'none', borderTop: '1px solid black', marginBottom: '10px' } } />
            <h5>{ t( "application.standard" ) }</h5>
            <hr style={ { border: 'none', borderTop: '1px solid black', marginTop: '10px' } } />
            { defaultImageRows }

        </>
    );
};

export const Index = ( { handleImageClick, selectedImage, component, additionalImages, handleImageUploadFromParent, defaultImages, placeholderImage, placeholderImageStyle } ) => {
    const { t } = useTranslation();
    // setup file input ref and state
    const fileInputRef = React.useRef();
    const [ uploadedImage, setUploadedImage ] = useState( null );
    const [ isImageJustUploaded, setIsImageJustUploaded ] = useState( false );

    // open file dialog
    const onButtonClick = () => {
        fileInputRef.current.click();
    };

    // handle file selection
    const handleFileChange = ( e ) => {
        const file = e.target.files[ 0 ];
        if ( file ) {
            // Get the file extension
            const fileExtension = file.name.split( "." ).pop().toLowerCase();

            // Check if the file is an image and the extension is one of the accepted formats
            if ( file.type.includes( "image" ) && [ "jpeg", "jpg", "png", "gif" ].includes( fileExtension ) ) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setUploadedImage( reader.result );
                    setIsImageJustUploaded( true );  // indicate that an image has just been uploaded
                    handleImageClick( reader.result, additionalImages.length ); // add this image to the additionalImages
                    handleImageUploadFromParent( e.target.files, component );
                };
                reader.readAsDataURL( file );
            } else {
                // handle non-image file upload or image file with unacceptable format
                alert( 'Please upload an image file in jpeg, jpg, png, or gif format' );
            }
        }
    };

    // when uploadedImage is changed, prompt user to change default image
    useEffect( () => {
        if ( uploadedImage && isImageJustUploaded ) {
            handleImageClick( uploadedImage, 0 ); // change default image
            setIsImageJustUploaded( false );  // reset the flag after handling the uploaded image
        }
    }, [ uploadedImage, handleImageClick, isImageJustUploaded ] );

    return (
        <div className="col-xl-4 col-lg-12 d-none d-xl-block custom-leftnav" >
            <div className="main-content-left-components">
                <div className="card custom-card" style={ { width: "80%" } }>
                    <div className="card-header custom-card-header border-bottom-0">
                    </div>
                    <div className="card-body">
                        <h6 className="main-content-label mb-1">{ t( 'website.tvAdhan' ) }</h6>
                        <br />
                        <div className="border">
                            <ImageList
                                imageUrls={ additionalImages }
                                handleImageClick={ handleImageClick }
                                selectedImage={ selectedImage }
                                onPlusButtonClick={ onButtonClick }
                                defaultImages={ defaultImages }
                                placeholderImage={ placeholderImage }
                                placeholderImageStyle={ placeholderImageStyle }
                            />
                        </div>
                    </div>
                    <input
                        type="file"
                        style={ { display: 'none' } }
                        ref={ fileInputRef }
                        onChange={ handleFileChange }
                        accept="image/*"
                    />

                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ( state ) => ( {} )

const mapDispatchToProps = {}

export default connect( mapStateToProps, mapDispatchToProps )( Index )
