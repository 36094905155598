import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import './style.css'
export const SalatRole = ( { officials, onChange, formData, onSave, onRemoveRole } ) => {
    const { t } = useTranslation()
    return (
        <div className="container-fluid elementToFadeInAndOut">
            <div className="inner-body">
                <div className="row row-sm" >
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div className="table-responsive border userlist-table">
                                    <div className="row row-sm" >
                                        <div className="col-lg-12 col-md-12">
                                            <div className="card custom-card">
                                                <div className="card-body">
                                                    <div>
                                                        <h6 className="main-content-label mb-1">{ t( "website.roleEmailInput" ) } </h6>
                                                        <p className="text-muted card-sub-title">{ t( "application.roleSalat" ) } \ { t( "application.tv" ) }</p>
                                                    </div>
                                                    <div className="form-group">
                                                        <input
                                                            onChange={ e => onChange( e ) }
                                                            name="email" label="0"
                                                            type="email" className="form-control input-lg" placeholder={ t( "common.email" ) } />
                                                        <br />
                                                        <h6 className="main-content-label mb-1">{ t( "application.roles" ) }</h6>
                                                        <select className="form-control select-lg select2"
                                                            onChange={ e => onChange( e ) }
                                                            id="role"
                                                            name="role"
                                                            value={ formData.role }>
                                                            <option value="prayer">{ t( "common.prayer" ) }</option>
                                                            <option value="tv">{ t( "application.tv" ) }</option>
                                                        </select>
                                                        <br />
                                                        <div className="input-group telephone-input">
                                                            <span className="input-group-btn">
                                                                <button className="btn ripple btn-primary" type="button" onClick={ () => onSave() }>{ t( "common.add" ) }</button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        <div className="card custom-card">
                            <div className="card-header border-bottom-0">
                                <label className="main-content-label my-auto">{ t( "website.roleUsersPrayer" ) }</label>
                            </div>
                            <div className="card-body">
                                <div className="">
                                    <div className="table-responsive-sm">
                                        <table className="table text-nowrap">
                                            <thead className="border-top">
                                                <tr>
                                                    <th className="bg-transparent">#</th>
                                                    <th className="bg-transparent">{ t( "common.email" ) }</th>
                                                    <th className="bg-transparent">{ t( "application.roles" ) }</th>
                                                    <th className="bg-transparent">{ t( "common.delete" ) }</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { officials.map( ( user, index ) =>
                                                    user.role.indexOf( "prayer" ) !== -1 && <tr className="border-bottom">
                                                        <td>{ index + 1 }</td>
                                                        <td><img src="https://users.eu-central-1.linodeobjects.com/Profile/user.png" className="wd-30 ht-30 mr-3" alt="" />{ user.email }</td>
                                                        <td>{ t( "common.prayer" ) }</td>
                                                        <td><a href="#" className="btn btn-outline-primary btn-sm" onClick={ ( e ) => { e.preventDefault(); onRemoveRole( user.email, "prayer" ) } }>Remove</a></td>
                                                    </tr> ) }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="card-header border-bottom-0">
                                <label className="main-content-label my-auto">{ t( "website.roleUsersTv" ) }</label>
                            </div>
                            <div className="card-body">
                                <div className="">
                                    <div className="table-responsive-sm">
                                        <table className="table text-nowrap">
                                            <thead className="border-top">
                                                <tr>
                                                    <th className="bg-transparent">#</th>
                                                    <th className="bg-transparent">{ t( "common.email" ) }</th>
                                                    <th className="bg-transparent">{ t( "application.roles" ) }</th>
                                                    <th className="bg-transparent">{ t( "common.delete" ) }</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { officials.map( ( user, index ) =>
                                                    user.role.indexOf( "tv" ) !== -1 && <tr className="border-bottom">
                                                        <td>{ index + 1 }</td>
                                                        <td><img src="https://users.eu-central-1.linodeobjects.com/Profile/user.png" className="wd-30 ht-30 mr-3" alt="" />{ user.email }</td>
                                                        <td>{ t( "application.tv" ) }</td>
                                                        <td><a href="#" className="btn btn-outline-primary btn-sm" onClick={ ( e ) => { e.preventDefault(); onRemoveRole( user.email, "tv" ) } }>Remove</a></td>
                                                    </tr> ) }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div >



    )
}

const mapStateToProps = ( state ) => ( {

} )

const mapDispatchToProps = {

}

export default connect( mapStateToProps, mapDispatchToProps )( SalatRole )
