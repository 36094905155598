import React, { useState } from 'react'
import { Card, Collapse } from "react-bootstrap"
import { useTranslation } from 'react-i18next'



function FeedComments ( { idComments, postComments, open, user: { user }, onSendComment, onChange, deleteComment } ) {
    const { t } = useTranslation();


    const [ state, setstate ] = useState( false )
    const onClickShow = () => {
        setstate( !state );
    }

    return (
        <Card.Footer className="text-muted">

            <Collapse in={ open }>


                <div id="example-collapse-text">

                    { postComments && postComments.length > 0 ?
                        postComments.map( ( i, index ) => (
                            <div className="row" key={ index } style={ { margin: "0.1rem" } }>
                                <div className="col-sm-1">

                                    <img src={ i.userInfo.picture } sizes={ 50 } />
                                    <small>
                                        { i.userInfo.handle }
                                    </small>
                                </div>
                                <div className="col-sm-11">
                                    { user.id === i.userInfo._id ? <div className="dropdown show"> <a onClick={ ( e ) => { e.preventDefault(); onClickShow() } } className="new option-dots2" data-bs-toggle="dropdown" href=""><i className="fas fa-ellipsis-v"></i></a>
                                        <div className={ `dropdown-menu dropdown-menu-end shadow ${ state ? "show" : "" } ` }> <a className="dropdown-item" onClick={ ( e ) => { e.preventDefault(); deleteComment( idComments, i._id ) } }>{ t( 'common.delete' ) }</a>  </div>
                                    </div> : null }
                                    <Card>
                                        <Card.Body>
                                            { i.text }

                                        </Card.Body>
                                    </Card>
                                    <br />
                                </div>

                            </div>
                        ) ) : null }
                    <div className="row" style={ { margin: "0.1rem" } }>
                        <div className="col-sm-1">
                            <img src={ user.picture } sizes={ 50 } />
                        </div>
                        <div className="col-sm-11">
                            <textarea onChange={ ( e ) => onChange( e ) } type="comment" className="form-control" id="comment" placeholder={ t( 'feed.write' ) } />
                            <button type="button" className="btn ripple btn-dark btn-block" onClick={ () => onSendComment() }>{ t( 'entity.postnow' ) }</button>

                        </div>
                    </div>
                </div>
            </Collapse>

        </Card.Footer>
    )
}



export default FeedComments

