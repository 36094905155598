import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import tvExemple3 from "../../../../assets/comming.jpeg";

export const Index = ( props ) => {

    const youTubeVideosrole =
        [ 'https://youtu.be/U5OnL0_tDes', // en //TODO-AGAIN
            'https://youtu.be/8nYRKGx1EFA', // fr
            'https://youtu.be/0ig_w8Oq3EM', // ar
            'https://youtu.be/1VPoTAWY0Wk', // Ur
            'https://youtu.be/ZdmgG9ZLiE4'  // bn
        ]

    const { t } = useTranslation();
    const [ roleVideo, setroleVideo ] = useState( youTubeVideosrole[ 0 ] );
    const [ active, setActive ] = useState( {
        en: true,
        fr: false,
        ar: false,
        ur: false,
        bn: false
    } );
    const [ video, setVideo ] = useState( {
        role: true,
    } );
    const changeSubOptionsrole = ( active ) => {
        switch ( active ) {
            case 'english':
                setActive( { ...active, en: true } );
                setroleVideo( youTubeVideosrole[ 0 ] )

                break;
            case 'french':
                setActive( { ...active, fr: true } );
                setroleVideo( youTubeVideosrole[ 1 ] )

                break;
            case 'arabic':
                setActive( { ...active, ar: true } );
                setroleVideo( youTubeVideosrole[ 2 ] )

                break;
            case 'urdu':
                setActive( { ...active, ur: true } );
                setroleVideo( youTubeVideosrole[ 3 ] )

                break;
            case 'bengali':
                setActive( { ...active, bn: true } );
                setroleVideo( youTubeVideosrole[ 4 ] )

                break;

            default:
                break;
        }

    }

    //change the tutorial video
    const changeWhichVideo = ( params ) => {
        switch ( params ) {
            case 'role':
                setVideo( {
                    role: true,
                    jumua: false,
                    csv: false,
                    adhan: false,
                    widget: false
                } );
                break;

            default:
                setVideo( {
                    role: true,

                } );
                break;
        }
    }
    const linkifyYouTubeURLs = ( text ) => {
        var re = /https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*?[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/ig;
        return text.toString().replace( re, 'http://www.youtube.com/embed/$1' );
    }
    // get the video with the appropriate language
    const theVideo = () => {
        let url = "https://www.youtube.com/@moonode4724";
        if ( video.role ) {
            url = roleVideo;
        }
        return linkifyYouTubeURLs( url );
    }

    return (
        <div className="col-xl-4 col-lg-12 d-none d-xl-block custom-leftnav">
            <div className="main-content-left-components">
                <div className="card custom-card">
                    <div className="card-body component-item">
                        <nav className="nav flex-column">
                            <a className={ `nav-link ${ video.role ? "active" : "" }` } onClick={ ( e ) => { e.preventDefault(); changeWhichVideo( 'role' ) } } href="!#">{ t( "website.howToAddRole" ) }</a>

                        </nav>
                    </div>
                </div>
                <div className="card custom-card" style={ { width: "130%" } }>
                    <div className="card-header custom-card-header border-bottom-0">
                        <h6 className="main-content-label mb-0">{ t( "website.howToAddRole" ) }</h6>
                    </div>
                    <div className="example">
                        <nav className="nav main-nav flex-column flex-md-row justify-content-center">
                            <a onClick={ ( e ) => { e.preventDefault(); setActive( { ...active, ur: false } ); changeSubOptionsrole( 'english' ) } } className={ `nav-link ${ active.en ? "active" : "" }` } style={ active.en ? {
                                backgroundColor: "#FABF0F",
                                padding: "5px",
                                borderRadius: "5px",
                                cursor: "pointer"
                            } : { cursor: "pointer" } } >{ t( "website.english" ) }</a>
                            <a onClick={ ( e ) => { e.preventDefault(); setActive( { ...active, en: false } ); changeSubOptionsrole( 'french' ) } } className={ `nav-link ${ active.fr ? "active" : "" }` } style={ active.fr ? {
                                backgroundColor: "#FABF0F",
                                padding: "5px",
                                borderRadius: "5px",
                                cursor: "pointer"
                            } : { cursor: "pointer" } }  >{ t( "website.french" ) }</a>
                            <a onClick={ ( e ) => { e.preventDefault(); setActive( { ...active, fr: false } ); changeSubOptionsrole( 'arabic' ) } } className={ `nav-link ${ active.ar ? "active" : "" }` } style={ active.ar ? {
                                backgroundColor: "#FABF0F",
                                padding: "5px",
                                borderRadius: "5px",
                                cursor: "pointer"
                            } : { cursor: "pointer" } }  >{ t( "website.arabic" ) }</a>
                            <a onClick={ ( e ) => { e.preventDefault(); setActive( { ...active, ar: false } ); changeSubOptionsrole( 'urdu' ) } } className={ `nav-link ${ active.ur ? "active" : "" }` } style={ active.ur ? {
                                backgroundColor: "#FABF0F",
                                padding: "5px",
                                borderRadius: "5px",
                                cursor: "pointer"
                            } : { cursor: "pointer" } }  >{ t( "website.urdu" ) }</a>
                            <a onClick={ ( e ) => { e.preventDefault(); setActive( { ...active, ur: false } ); changeSubOptionsrole( 'bengali' ) } } className={ `nav-link ${ active.bn ? "active" : "" }` } style={ active.bn ? {
                                backgroundColor: "#FABF0F",
                                padding: "5px",
                                borderRadius: "5px",
                                cursor: "pointer"
                            } : { cursor: "pointer" } }  >{ t( "website.bengali" ) }</a>
                        </nav>
                    </div>
                    <div className="card-body">
                        <div className="border">
                            <iframe


                                style={ {
                                    border: "0",
                                    height: "25vh",
                                    left: "0",
                                    position: "inherit",
                                    top: "30%",
                                    width: "100%"
                                } }
                                sandbox="allow-same-origin allow-scripts allow-forms allow-presentation"
                                allowFullScreen="allowfullscreen"
                                mozallowfullscreen="mozallowfullscreen"
                                msallowfullscreen="msallowfullscreen"
                                oallowfullscreen="oallowfullscreen"
                                webkitallowfullscreen="webkitallowfullscreen"
                                src={ `${ theVideo() }` }
                            >
                            </iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = ( state ) => ( {

} )

const mapDispatchToProps = {

}

export default connect( mapStateToProps, mapDispatchToProps )( Index )
