import axios from "axios";
//axios for backend


//error handling
import { setAlert } from "./alert";

//reducer type
import {
    GET_ENTITY,
    GET_ENTITY_CSV,
    GET_FEED,
    GET_PRAYER_MTL,
    GET_POST_ENTITY,
    GET_SPECIAL_POST_ENTITY,
    CREATE_ENTITY,
    GET_ENTITY_VISUAL,
    REMOVE_ALERT
} from "./types";
const axiosInstance = axios.create( {
    timeout: 600000, // 10 minutes
} );
// header configuration
const config = { headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "http://localhost:3000" } };


export const getCalendar = ( entityProfil, chosenMonth, choosenYear ) => async dispatch => {
    const body = JSON.stringify( entityProfil );
    try {
        const res = await axios.post( `/api/admin/calendar/${ chosenMonth }/${ choosenYear }`, body, config );
        return res.data;

    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.request", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }

        return false;
    }
};
export const getCalendarAdhan = ( entityProfil, chosenMonth, choosenYear ) => async dispatch => {
    const body = JSON.stringify( entityProfil );
    try {
        const res = await axios.post( `/api/admin/calendar/for/adhan/${ chosenMonth }/${ choosenYear }`, body, config );
        return res.data;

    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }

        return false;
    }
};


export const getFeed = ( nbrPosts ) => async dispatch => {
    try {
        const res = await axios.get( `/tapy/feed/${ nbrPosts }` );

        dispatch( {
            type: GET_FEED,
            payload: res.data
        } );
        return true;
    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};


export const getEntity = ( entityId, history ) => async dispatch => {
    try {

        const res = await axios.get( `/tapy/masjid/get/profile/${ entityId }` );
        dispatch( {
            type: GET_ENTITY,
            payload: res.data.profile
        } );

    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }

        // setTimeout( () => {
        //     window.location.replace( "/dashboard" );
        // }, 5000 );

        return false;
    }
};

export const getEntityVisuals = ( entityId, history ) => async dispatch => {
    try {

        const res = await axios.get( `/tapy/masjid/get/visual/${ entityId }` );
        dispatch( {
            type: GET_ENTITY_VISUAL,
            payload: res.data.visuals
        } );

    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {
            console.log( ' // Some other errors', error.message );
        }

        // setTimeout( () => {
        //     window.location.replace( "/dashboard" );
        // }, 5000 );

        return false;
    }
};

export const addDefaultImage = ( fileData, codeEntity, component ) => async dispatch => {
    try {


        let res = await axiosInstance.post(
            `/tapy/masjid/post/visual/${ codeEntity }/${ component }`,
            fileData,
            {
                headers: {
                    accept: "application/json",
                    "Accept-Language": "en-US,en;q=0.8",
                    "Content-Type": `multipart/form-data; boundary=${ fileData._boundary }`
                }
            }
        );

        window.location.reload();

    } catch ( error ) {
        console.log( error );
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {
            console.log( ' // Some other errors', error.message );
        }

        // setTimeout( () => {
        //     window.location.replace( "/dashboard" );
        // }, 5000 );

        return false;
    }
};
export const addDefaultImageUrl = ( awsUrl, codeEntity, component ) => async dispatch => {
    try {

        let res = await axiosInstance.post(
            `/tapy/masjid/post/visual/url/${ codeEntity }/${ component }`, { awsUrl, lang: "en" }
        );

        window.location.reload();

    } catch ( error ) {
        console.log( error );
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {
            console.log( ' // Some other errors', error.message );
        }

        // setTimeout( () => {
        //     window.location.replace( "/dashboard" );
        // }, 5000 );

        return false;
    }
};

export const updateEntity = ( content, entity ) => async dispatch => {
    const body = JSON.stringify( content );
    try {
        const res = await axios.post( `/api/admin/update/${ entity }`, body, config );
        dispatch( setAlert( res.data, "success" ) );
        setTimeout( () => {
            window.location.reload();
        }, 4000 );

    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }

        return false;
    }
};



export const editIqama = ( codeEntity, formData, t ) => async dispatch => {
    const body = JSON.stringify( formData );
    try {
        const res = await axios.post(
            `/api/entity/prayerTimes/${ codeEntity }`,
            body,
            config
        );

        dispatch( setAlert( { error: t( "notif.prayerTime" ) }, "success" ) );

        setTimeout( () => {
            window.location.reload();
        }, 10000 );
    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};

export const getSalatTimeForMontreal = ( codeEntity ) => async dispatch => {

    try {

        const res = await axios.get( `/tapy/masjid/get/prayer/Suggestionpourvous_MzMwIUAjJCXLhiY=` );
        dispatch( {
            type: GET_PRAYER_MTL,
            payload: res.data.profile
        } );

    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};

export const getPosts = postTypeId => async dispatch => {
    try {

        const res = await axios.get(
            `/api/posts/${ postTypeId }/Entity/${ postTypeId }`
        );
        dispatch( {
            type: GET_POST_ENTITY,
            payload: res.data
        } );
        return true;
    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};

export const getSpecialPosts = postTypeId => async dispatch => {
    try {

        const res = await axios.get(
            `/api/posts/${ postTypeId }/Entity/${ postTypeId }/special/posts`
        );
        dispatch( {
            type: GET_SPECIAL_POST_ENTITY,
            payload: res.data
        } );
        return true;
    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};

export const followEntity = entityId => async dispatch => {
    const body = JSON.stringify( entityId );
    try {
        const res = await axios.post( `/api/following/follow/entity`, body, config );
        window.location.reload();
    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};

//post entity picture
export const postEntityProfilePicture = (
    fileData,
    whichPic,
    codeEntity
) => async dispatch => {
    try {
        const res = await axios.post(
            `/api/entity/change/profilePicture/${ codeEntity }/${ whichPic }`,
            fileData,
            {
                headers: {
                    accept: "application/json",
                    "Accept-Language": "en-US,en;q=0.8",
                    "Content-Type": `multipart/form-data; boundary=${ fileData._boundary }`
                }
            }
        );

        dispatch( {
            type: GET_ENTITY,
            payload: res.data
        } );

        return true;
    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};

export const afterChoosing = ( userEntityId ) => async dispatch => {
    try {
        const res = await axios.get( `https://www.moonode.com/native/m/application/users/add/entity/to/profile/${ userEntityId }` );
    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};

export const createEntity = ( content, history ) => async dispatch => {
    const body = JSON.stringify( content );

    try {
        const res = await axios.post( `/api/entity`, body, config );
        window.location.reload();
    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};

export const addPrayerFile = ( fileData, codeEntity, t ) => async dispatch => {
    try {
        const res = await axios.post(
            `/api/admin/update/file/to/prayer/${ codeEntity }`,
            fileData,
            {
                headers: {
                    accept: "application/json",
                    "Accept-Language": "en-US,en;q=0.8",
                    "Content-Type": `multipart/form-data; boundary=${ fileData._boundary }`
                }
            }
        );

        dispatch( setAlert( { error: t( "notif.csvFileUpdated" ) }, "success", 4000 ) );

        return true;

    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( { error: t( "notif.csvFileNotUpdate" ) }, "register" ) );
                } else {
                    dispatch( setAlert( { error: t( "notif.csvFileNotUpdate" ) }, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        setTimeout( () => {
            window.location.reload();
        }, 5000 );
        return false;

    }
};

export const checkPrayerFile = ( codeEntity, t ) => async dispatch => {
    try {
        const res = await axios.get( `/api/admin/check/file/to/prayer/${ codeEntity }` );
        setTimeout( () => {
            dispatch( setAlert( { error: t( "notif.csvFileUpdatedAndVerified" ) }, "success" ) );
        }, 5000 );
        return true;
    } catch ( error ) {
        if ( error.response ) {
            dispatch( { type: REMOVE_ALERT } )
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( { error: t( "notif.csvFileUpdatedNotGood" ) }, "register", 10000 ) );
                } else {
                    dispatch( setAlert( { error: t( "notif.csvFileUpdatedNotGood" ) }, "register" ), 10000 );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        setTimeout( () => {
            window.location.reload();
        }, 10000 );

        return false;

    }
};

export const addRoles = ( content, entity, type ) => async dispatch => {
    const body = JSON.stringify( content );

    try {
        const res = await axios.post( `/api/admin/officials/${ entity }/${ type }`, body, config );
        dispatch( setAlert( { error: `le courriel ${ content.email } est ajouté` }, "success" ) );
        setTimeout( () => {
            window.location.reload();
        }, 2000 );
    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};

export const deleteOfficials = ( entity, index, type ) => async dispatch => {

    const body = JSON.stringify( index );

    try {
        const res = await axios.delete( `/api/admin/officials/${ type }/${ entity }/${ index }` );
        dispatch( setAlert( { error: `Effacé avec succès` }, "success" ) );

        setTimeout( () => {
            window.location.reload();
        }, 2000 );

    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};

export const editTv = ( codeEntity, tvSettings, t ) => async dispatch => {
    const body = JSON.stringify( tvSettings );

    try {
        const res = await axios.post( `/api/admin/tv/settings/moonode/${ codeEntity }`, body, config );
        dispatch( setAlert( { error: t( "notif.tvSettingsUpdated" ) }, "success" ) );

        setTimeout( () => {
            window.location.reload();
        }, 5000 );
        return true;

    } catch ( error ) {
        if ( error ) {
            console.log( " ٱلْحَمْدُ لِلَّٰهِ" );
            const errors = error.response.data;
            if ( errors.message ) {
                dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
            } else {
                dispatch( setAlert( errors, "register" ) );
            }
        }
        return false;
    }
};
