import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getSalatTimeForMontreal } from '../../../action/entity';
import { iqamaCalcul, calculeNextIqama } from '../../../utils/processingDate&Time';
import MobilePrayer from '../../../components/Prayer/Widget/Ui';
export const PrayerTimeWidget = ( { getSalatTimeForMontreal, entity: { mtlPrayer }, show = true, isText = false, text } ) => {

    useEffect( () => {
        getSalatTimeForMontreal();
    }, [] )
    return (
        mtlPrayer && <MobilePrayer
            show={ show }
            iqamaTimes={ mtlPrayer.iqamaTime }
            apiPrayerTime={ mtlPrayer.apiPrayerTime }
            calculeNextIqama={ calculeNextIqama }
            entityProfil={ mtlPrayer.entityProfil }
            prayerTime={ mtlPrayer.prayerTime }
            entity={ mtlPrayer.entity }
            color={ mtlPrayer.prayerTime.widgetColor }
            tomorrowIqamaTime={ mtlPrayer.tomorrowIqamaTime }
            tomorrowApiPrayerTime={ mtlPrayer.tomorrowApiPrayerTime }
            highlighted={ mtlPrayer.highlighted }
            iqamaCalcul={ iqamaCalcul }
            isText={ isText }
            text={ text }
        />
    )
}

const mapStateToProps = ( state ) => ( {
    entity: state.entity
} )

const mapDispatchToProps = {

}

export default connect( mapStateToProps, { getSalatTimeForMontreal } )( PrayerTimeWidget )
