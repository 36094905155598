import React from 'react'
import moment from 'moment-hijri'

import { Card, Container, Row, Col } from "react-bootstrap"
import { useTranslation } from 'react-i18next'
import './welcome.css'
export default function FastDays ( { whitedays } ) {
    const { t } = useTranslation();
    const year = moment().format( 'iYYYY' )
    const month = moment().format( 'iMM' )
    return (
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
            <Card style={ { height: "100%" } }>
                <Card.Header style={ { height: "20%" } }>
                    <h4 className="main-content-label tx-13 font-weight-bold mb-1" style={ { textAlign: "center" } }>{ t( 'website.whiteDays' ) }{ " " }
                    </h4>
                </Card.Header>
                <div className="">
                    <div className="card-header border-bottom-0 pb-1">
                        <p className="tx-12 mb-0 text-muted">
                            {/* Whoever fasts one day for the sake of Allah, Allah will move his face away from the Fire a distance of seventy autumns (years). [Ibn Majah]  */ }
                            { t( 'website.whiteDaysInfo' ) }
                        </p>
                    </div>
                    <div className="product-timeline card-body pt-3 mt-1">
                        <ul className="timeline-1 mb-0">
                            <li className="mt-0"> <i className="ti-timer product-icon" style={ { marginTop: "-8%" } }></i>  <br /><span className="font-weight-semibold mb-4 tx-14 ">{ moment( 13 + "/" + month + "/" + year, 'iDD/iMM/iYYYY' ).format( 'DD' ) }  </span>
                                <p className="mb-0 text-muted tx-12">{ new Date().toLocaleString( 'en-en', { month: 'long' } ) }</p>
                            </li>
                            <li className="mt-0"> <i className="ti-timer product-icon" style={ { marginTop: "-8%" } }></i>  <br /><span className="font-weight-semibold mb-4 tx-14 ">{ moment( 14 + "/" + month + "/" + year, 'iDD/iMM/iYYYY' ).format( 'DD' ) } </span>
                                <p className="mb-0 text-muted tx-12">{ new Date().toLocaleString( 'en-en', { month: 'long' } ) }</p>
                            </li>
                            <li className="mt-0"> <i className="ti-timer product-icon" style={ { marginTop: "-8%" } }></i>  <br /><span className="font-weight-semibold mb-4 tx-14 ">{ moment( 15 + "/" + month + "/" + year, 'iDD/iMM/iYYYY' ).format( 'DD' ) } </span>
                                <p className="mb-0 text-muted tx-12">{ new Date().toLocaleString( 'en-en', { month: 'long' } ) }</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <Card.Text style={ { textAlign: "center", height: "30%" } }>
                </Card.Text>
            </Card>
        </div>
    )
}
