
export const processingResultToArray = function ( result, option, terms ) {
    let finalData = [];
    if ( result.length > 0 ) {
        // if (option.shopservices) {
        //     result[ 0 ].filter(profess => {
        //         if (!(terms === "") &&
        //             ((profess.isProfession && profess.profession) &&
        //                 profess.profession.toLowerCase().trim().includes(terms.toLowerCase().trim()))) {
        //             finalData.unshift({
        //                 title: profess.handle + " est un : " + profess.profession,
        //                 more: true,
        //                 info: "de la mosquée : " + profess.entityName,
        //                 url: "/" + profess.codeUserId,
        //                 pic: profess.picture,
        //             });
        //         }
        //     })
        // }
        // if (option.imams) {
        //     result[ 1 ].filter(imam => {
        //         if (!(terms === "") && imam.name.toLowerCase().trim().includes(terms.toLowerCase().trim())) {
        //             finalData.unshift({
        //                 title: "Imam " + imam.name + " " + imam.handle,
        //                 more: false,
        //                 info: "",
        //                 url: imam.url,
        //                 pic: imam.picture,
        //             });
        //         }
        //     })
        // }

        // if (option.shopservices) {
        //     result[ 2 ].filter(shop => {
        //         if (!(terms === "") && (shop && shop.type) &&
        //             (shop.type.toLowerCase().trim().includes(terms.toLowerCase().trim())
        //                 || shop.name.toLowerCase().trim().includes(terms.toLowerCase().trim())
        //             )) {
        //             finalData.unshift({
        //                 title: shop.type + "  " + shop.name,
        //                 more: true,
        //                 info: "à coté de la mosquée : " + shop.entityName,
        //                 url: shop.url,
        //                 pic: shop.logo,
        //             });
        //         }
        //     })
        // }

        // if (option.questions) {
        //     result[ 3 ].filter(question => {
        //         if (!(terms === "") &&
        //             (question.title.toLowerCase().trim().includes(terms.toLowerCase().trim()))) {
        //             finalData.unshift({
        //                 title: question.title.slice(0, 30) + "...",
        //                 more: false,
        //                 info: "",
        //                 url: "/question/" + question.codeQuestion,
        //                 pic: "https://moonode.s3.ca-central-1.amazonaws.com/utils/qa.png",
        //             });
        //         }
        //     })
        // }

        if ( option.mosques ) {
            result[ 0 ].filter( mosque => {
                if ( !( terms === "" ) &&
                    ( mosque.name.toLowerCase().trim().includes( terms.toLowerCase().trim() ) ) ) {
                    finalData.unshift( {
                        title: "Mosquée " + mosque.name,
                        more: false,
                        info: "",
                        url: mosque.url,
                        pic: mosque.logo,
                    } );
                }
            } )
        }

        return finalData;
    } else {
        return [];
    }


};
