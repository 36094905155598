import {
    LOADING_USER_NOTIFICATION,
    GET_USER_NOTIFICATION
} from "../action/types";

const initialState = {
    nbrNotSeen: 0,
    registrationMobileId: null,
    seen: false,
    userNotifications: [],
    userNotificationLoading: false,
    error: {}
};

export default function ( state = initialState, action ) {
    const { type, payload } = action;

    switch ( type ) {
        case GET_USER_NOTIFICATION:
            return {
                ...state,
                userNotifications: payload.userNotifications,
                nbrNotSeen: payload.nbrNotSeen,
                seen: payload.seen,
                registrationMobileId: payload.registrationMobileId,
            };
        case LOADING_USER_NOTIFICATION:
            return {
                ...state,
                userNotificationLoading: payload
            };
        default:
            return state;
    }
}
