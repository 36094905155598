import React, { Fragment, useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { UserProfileContext } from "../../../Context.js";
import { getEntity, addRoles, deleteOfficials } from "../../../action/entity";
import Roles from '../../../components/admin/Roles/Index';

export const Index = ( { match, getEntity, entity: { entity, loading }, addRoles, deleteOfficials } ) => {
    const { userProfile } = useContext( UserProfileContext );

    useEffect( () => {
        if ( userProfile ) {
            const { codeEntity, profile } = userProfile;
            const { token } = match.params;
            if ( ( encodeURI( codeEntity ).localeCompare( token ) === 0 ) && ( profile.isRole || profile.isAdmin ) ) {
                getEntity( codeEntity );
            } else {
                alert( "🟥" )
                window.location.replace( "/dashboard" );
            }
        }
    }, [ loading, userProfile ] );


    const [ formData, setformData ] = useState( {
        email: "",
        role: "prayer"
    } )

    const onChange = ( e ) => {
        setformData( { ...formData, [ e.target.name ]: e.target.value } )
    }

    const onRemoveRole = ( email, type ) => {
        const { codeEntity } = userProfile;
        deleteOfficials( codeEntity, email, type )
    }

    const onSave = ( type ) => {
        const { codeEntity } = userProfile;
        addRoles( formData, codeEntity, formData.role );
    };
    return (
        entity ? <Roles
            officials={ entity.entity.officials }
            onChange={ onChange }
            formData={ formData }
            onRemoveRole={ onRemoveRole }
            onSave={ onSave }
        /> : <h1>SALAM</h1>
    )
}

Index.propTypes = {
    props: PropTypes
}

const mapStateToProps = ( state ) => ( {
    entity: state.entity
} )


export default connect( mapStateToProps, { getEntity, addRoles, deleteOfficials } )( Index )
