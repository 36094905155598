import {
    GET_FEED,
} from "../action/types";

const initialState = {
    feed: [],
    loadingFeed: false,
    ahadith: [],
    whitedays: [],
    error: {}
};

export default function ( state = initialState, action ) {
    const { type, payload } = action;
    switch ( type ) {
        case GET_FEED:
            return {
                ...state,
                feed: payload.feed,
                ahadith: payload.hadith,
                whitedays: payload.whitedays
            };
        default:
            return state;
    }
}
