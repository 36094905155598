import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import SalatRole from './subSections/SalatRole';
import TvRole from './subSections/TvRole';
import Tutorials from './tutorials/Index';
import { useTranslation } from 'react-i18next';
import { setRoute, disableRoute } from '../../../action/routes'
import { isMobile, isTablet } from '../../../utils/device';

export const Prayer = ( { match, routes, setRoute, disableRoute, onRemoveRole, officials, formData, onChange, onSave } ) => {

    useEffect( () => {
        setRoute( 'admin/roles' );
        return () => {
            disableRoute()
        }
    }, [] )


    const { t, i18n } = useTranslation();

    const [ active, setActive ] = useState( {
        prayer: true,
        tv: false,
    } );

    const changeSubOptionsPrayer = ( active ) => {
        switch ( active ) {
            case 'prayer':
                setActive( { ...active, prayer: true } );
                break;
            case 'tv':
                setActive( { ...active, tv: true } );
                break;
            default:
                break;
        }
    }


    return (
        <Fragment>
            <div className="page">
                <div className="main-content side-content pt-0">
                    <div className="row row-sm container-fluid" style={ { maxWidth: active.fCsv ? '80%' : '90%', marginLeft: isMobile() ? " " : "70px" } }>
                        <div className={ `col-xl-8 col-lg-12` }>
                            <div className="card custom-card2" id="hori">
                                <div className="card-body">
                                    <div className="text-wrap">
                                        <div className="example">
                                            <div>
                                                <h6 className="main-content-label mb-1">{ t( 'application.roles' ) }</h6>
                                            </div>
                                            <div className="p-3 bg-light">
                                                <nav className="nav nav-pills flex-column flex-md-row">
                                                    <a onClick={ ( e ) => { e.preventDefault(); setActive( { ...active, tv: false } ); changeSubOptionsPrayer( 'prayer' ) } } className={ `nav-link ${ active.prayer ? "active" : "" }` } href="#">{ t( "application.roles" ) }</a>
                                                </nav>
                                            </div>
                                        </div>
                                        { active.prayer && <SalatRole onRemoveRole={ onRemoveRole } onSave={ onSave } officials={ officials } formData={ formData } onChange={ onChange } /> }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Tutorials />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = ( state ) => ( {
    routes: state.routes
} )


export default connect( mapStateToProps, { setRoute, disableRoute } )( Prayer )
