import React from 'react'
import PropTypes from 'prop-types'
function EmptyPosts ( { image } ) {
    return (
        <div className="main-content-body  tab-pane border-top-0" id="timeline" style={ { margin: "18px", paddingBottom: "18px" } }>
            <div className="">
                <div className="main-content-body main-content-body-profile">
                    <div className="main-profile-body p-0">
                        <div className="row row-sm" >
                            <div className="col-12">
                                <div className="card mg-b-20 border" >
                                    <div className="card-header p-4">
                                        <div className="media" style={ { alignContent: "center", marginLeft: "40%", marginTop: "20%", marginBottom: "20%" } } >
                                            <img src={ image } style={ { width: "30%", height: "30%" } } />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

EmptyPosts.propTypes = {

}

export default EmptyPosts

