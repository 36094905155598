import axios from "axios";
import { setAlert } from "./alert";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import {
    USER_LOGIN,
    GET_USER_PROFILE,

} from "./types";
const config = { headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "http://localhost:3000" } };



/**
 * ACTION LOAD THE USER
 * @param {*} user
 */

export const loginUser = ( user ) => async dispatch => {
    const body = JSON.stringify( user );

    try {
        // const res = await axios.post( "/native/m/application/users/coconut/login/2/local", {
        const res = await axios.post( "/native/m/application/users/coconut/login", {
            codeType: "LAN-3",
            email: user.email,
            password: user.password,
            remember: true,
        }, config );

        const { token } = res.data;
        // Set token to ls
        localStorage.setItem( "jwtToken", token );

        // Set token to Auth header
        setAuthToken( token );

        // Decode token to get user data
        const decoded = await jwt_decode( token );

        // Set current user
        dispatch( setCurrentUser( decoded ) );
        try {

        } catch ( error ) {
            console.log( error );
        }
        window.location.replace( "/dashboard" );

        return true;

    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};

// Log user out
export const logoutUser = user => async dispatch => {
    try {
        const res = await axios.get( `/api/users/logout/${ user.email }` );
        if ( res.status === 200 ) {
            dispatch(
                setAlert( res.data.message.msg[ res.data.msg ], "mailConfirmation" )
            );
            localStorage.removeItem( "jwtToken" );
            localStorage.removeItem( 'authToken' );
            setAuthToken( false );
            window.location.replace( "/" );
        }
    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};
// Log user out
export const getUserProfile = userId => async dispatch => {
    try {

        try {
            const res = await axios.get( `/tapy/userProfile/get/profile/${ userId }` );

            dispatch( {
                type: GET_USER_PROFILE,
                payload: res.data.response
            } );
        } catch ( error ) {
            if ( error.response ) {
                const errors = error.response.data;
                console.log( errors );
                if ( errors ) {
                    if ( errors.message ) {
                        dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                    } else {
                        dispatch( setAlert( errors, "register" ) );
                    }
                }
            } else if ( error.request ) {
                console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
            } else {

                console.log( ' // Some other errors', error.message );
            }
            return false;
        }


    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};


// Set logged in user
export const setCurrentUser = decoded => {
    return {
        type: USER_LOGIN,
        payload: decoded
    };
};


export const changeUserPassword = content => async dispatch => {
    const body = JSON.stringify( content );
    try {

        const res = await axios.post(
            `/api/users/user/change/password`,
            body,
            config
        );

        dispatch( setAlert( { error: "Votre mot de passe est changé" }, "success" ) );


    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};

export const emailUs = content => async dispatch => {
    const body = JSON.stringify( content );
    try {
        const res = await axios.post( "/api/users/email/us", body, config );
        dispatch( setAlert( res.data.response, "success" ) );
        setTimeout( () => {
            window.location.reload()
        }, 4000 );
    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};

export const registration = ( user, t ) => async dispatch => {
    const body = JSON.stringify( user );

    try {
        // alert( user );
        const res = await axios.post( "/api/users/register", body, config );
        dispatch( setAlert( { error: t( "landing.signin" ) }, "success" ) );

        setTimeout( () => {
            window.location.replace( "/confirm/email" );
        }, 3000 );
    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;

    }
};

export const loginUserGoogle = ( user ) => async dispatch => {
    const body = JSON.stringify( user );

    try {
        const res = await axios.post( "/api/users/login/with/google", body, config );
        const { token } = res.data;

        // Set token to ls
        localStorage.setItem( "jwtToken", token );
        // Set token to Auth header
        setAuthToken( token );
        // Decode token to get user data
        const decoded = await jwt_decode( token );
        // Set current user
        dispatch( setCurrentUser( decoded ) );

        window.location.replace( "/dashboard" );

    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};

export const sendTheMail = ( email, t ) => async dispatch => {
    try {
        if ( !email.email ) {
            dispatch( setAlert( { error: t( "common.add" ) + " " + t( "common.email" ) }, "register" ) );
            window.location.reload();
        } else {
            const res = await axios.get( `/tapy/userProfile/reset/password/${ email.email }` );
            dispatch( setAlert( { error: t( "landing.resetpasswordinfo" ) }, "success" ) );
            setTimeout( () => {
                window.location.replace( "/" );
            }, 5000 );
        }

    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false

    }
};

export const changeUserPasswordToken = ( token, content ) => async dispatch => {
    const body = JSON.stringify( content );
    try {

        const res = await axios.post(
            `/api/users/password/user/to/reset/${ token }`,
            body,
            config
        );
        dispatch( setAlert( { error: "Votre mot de passe est changé" }, "success" ) );

        setTimeout( () => {
            window.location.replace( "/" );
        }, 4000 );

    } catch ( error ) {
        if ( error ) {
            const errors = error.response.data;
            if ( errors.message ) {
                dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
            } else {
                dispatch( setAlert( errors, "register" ) );
            }
        }
        return false;
    }
};
export const confirmUserMail = token => async dispatch => {
    try {
        const res = await axios.get( `/api/users/confirmail/${ token }` );

        if ( res.data.msg === 10 ) {
            dispatch( setAlert( { error: "Votre courriel est activé, bienvenue sur moonode" }, "success" ) );

        } else {
            dispatch( setAlert( { error: "Le lien est expiré." }, "register" ) );
        }
        setTimeout( () => {
            window.location.replace( "/signin" );
        }, 3000 );
        // dispatch({ type: USER_LOGIN, payload: res.data });
    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false
    }
};

export const sendEmailToMoonode = ( name, email, subject, m ) => async dispatch => {
    try {
        let content = {
            name: name,
            email: email,
            message: "[from website] subject :" + subject + "\n\n\n" + "\n\n" + "\n\n" + m,
        }

        const body = JSON.stringify( content );
        const res = await axios.post( `/api/users/contact/us/from/front/end`, body, config );
        dispatch( setAlert( { error: 'Sent successfully |  تم الارسال بنجاح | Bien envoyé' }, "success" ) );
        return true;
    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false

    }
}