import React, { useState } from 'react'
import Image from './Image';
import Audio from './Audio';
import Videos from './Video';
import Pdfs from './Pdf';
import Link from './Link';

function FeedMedia ( { data } ) {

    const [ page, setPage ] = useState( 0 );

    const [ isOpen, setIsOpen ] = useState( false );
    const [ photoIndex, setPhotoIndex ] = useState( null );

    const onCloseLightBox = () => {
        setIsOpen( false );
    }
    return (
        <div>
            { ( data.fileType === "image" ) && data.image.length > 0 && <Image post={ data } /> }
            { ( data.fileType === "audio" ) && data.audio.length > 0 && <Audio post={ data } /> }
            { ( data.fileType === "pdf" ) && data.pdf.length > 0 && <Pdfs data={ data } /> }
            { ( data.fileType === "video" ) && ( data.video.length > 0 ) ? (
                <Videos post={ data } isVideo={ false } video={ data.video[ 0 ] } />
            ) : null }
            { ( data.isLink ) && ( data.link.length > 0 ) ? (
                <Link link={ data.link } />
            ) : null }
        </div>
    )
}



export default FeedMedia

