import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import TvSettings from './Settings/Index';
import Templates from './Settings/Templates';
import Notice from './Settings/Notice';

import Tutorials from './tutorials/Index';
import { useTranslation } from 'react-i18next';
import { setRoute, disableRoute } from '../../../action/routes'
import { isMobile, isTablet } from '../../../utils/device';

export const Prayer = ( { setRoute,
    disableRoute,
    onChange,
    tvSettings,
    onChange2,
    saving,
    onSave,
    entity,
    onChangeAnnouncement } ) => {

    useEffect( () => {
        setRoute( 'admin/television' );
        return () => {
            disableRoute()
        }
    }, [] )


    const { t, i18n } = useTranslation();

    const [ active, setActive ] = useState( {
        tv: true,
        template: false,
        notice: false
    } );

    const changeSubOptionsPrayer = ( active ) => {
        switch ( active ) {
            case 'tv':
                setActive( { ...active, tv: true } );
                break;
            case 'template':
                setActive( { ...active, template: true } );
                break;
            case 'notice':
                setActive( { ...active, notice: true } );
                break;

            default:
                break;
        }
    }

    return (
        <Fragment>
            <div className="page">
                <div className="main-content side-content pt-0">
                    <div className="row row-sm container-fluid" style={ { maxWidth: active.fCsv ? '80%' : '90%', marginLeft: isMobile() ? " " : "70px" } }>
                        <div className={ `col-xl-8 col-lg-12` }>
                            <div className="card custom-card2" id="hori">
                                <div className="card-body">
                                    <div className="text-wrap">
                                        <div className="example">
                                            <div>
                                                <h6 className="main-content-label mb-1">{ t( 'website.tvGeneral' ) }</h6>
                                                <p className="text-muted card-sub-title">{ t( 'website.tvGeneralInfo' ) } </p>
                                            </div>
                                            <div className="p-3 bg-light">

                                                <nav className="nav nav-pills flex-column flex-md-row">
                                                    <a onClick={ ( e ) => { e.preventDefault(); setActive( { ...active, template: false } ); changeSubOptionsPrayer( 'tv' ) } } className={ `nav-link ${ active.tv ? "active" : "" }` } href="#">{ t( "website.tvSettings" ) }</a>
                                                    <a onClick={ ( e ) => { e.preventDefault(); setActive( { ...active, tv: false, template: false } ); changeSubOptionsPrayer( 'template' ) } } className={ `nav-link ${ active.template ? "active" : "" }` } href="#">{ t( "website.tvTemplates" ) }</a>
                                                    <a onClick={ ( e ) => { e.preventDefault(); setActive( { ...active, tv: false, template: false } ); changeSubOptionsPrayer( 'notice' ) } } className={ `nav-link ${ active.notice ? "active" : "" }` } href="#">{ t( "website.tvNotice" ) } </a>

                                                </nav>
                                            </div>
                                        </div>
                                        { active.tv && <TvSettings onChange={ onChange } tvSettings={ tvSettings } onChange2={ onChange2 } /> }
                                        { active.template && <Templates entity={ entity } /> }
                                        { active.notice && <Notice onChange={ onChangeAnnouncement }
                                            tvSettings={ tvSettings } onChange2={ onChange2 } /> }
                                    </div>
                                </div>
                                <div style={ { float: "left", marginLeft: "80%" } }>
                                    { saving ? <div className="spinner-grow" style={ { width: "5rem", height: "5rem", marginLeft: "40%" } } role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div> : <div>
                                        <button type="button" className="btn ripple btn-dark btn-block" onClick={ () => onSave() }>{ t( "common.save" ) } </button>
                                    </div> }
                                </div>
                            </div>
                        </div>
                        <Tutorials />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = ( state ) => ( {
    routes: state.routes
} )


export default connect( mapStateToProps, { setRoute, disableRoute } )( Prayer )
