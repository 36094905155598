import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const initialState = {};

const middleware = [ thunk ];

const store = createStore(
    rootReducer,
    initialState,
    compose(
        composeWithDevTools( applyMiddleware( ...middleware ) ),
        window.REDUX_DEVTOOLS_EXTENSION ? window.REDUX_DEVTOOLS_EXTENSION() : f => f )

);

export default store;
