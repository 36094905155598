import React, { useState, useRef } from 'react'
import { connect } from 'react-redux'
import './publishButton.css';
import Modal from './Modal';

//actions
import { addPosts } from "../../../action/post";
import { useTranslation } from 'react-i18next';

export const Index = ( { addPosts, auth: { user }, isAdmin = false, codeEntity } ) => {

    const { t } = useTranslation();
    const inputEl = useRef( null );
    const closeEl = useRef( null );
    let data = new FormData();

    const [ type, setType ] = useState( "news" );
    const [ loading, setLoading ] = useState( false );

    /**
     * STATE 1
     */
    const [ postInput, setPostInput ] = useState( {
        codeType: "LAN-3",
        userPost: "",
        text: "",
        isComment: true,
        isTv: false,
        postTypeId: codeEntity,
        postType: "public",
        type: "Entity",
        kindPost: type
    } );

    /**
     * STATE 2
     */
    const [ files, setfiles ] = useState( "" );
    const [ valid, setvalid ] = useState( false );
    /**
     * this function change type of mosque posts (input)
     * */
    const changeTypeInput = e => {
        e.preventDefault();
        switch ( type ) {
            case "news":
                setType( "janaza" );
                break;
            case "janaza":
                setType( "visite" );
                break;
            case "visite":
                setType( "news" );
                break;
            default:
                setType( "news" );
                break;
        }
    };

    /**
     * on change form
     * @param {e} e
     */
    const onChange = e => {

        setPostInput( { ...postInput, [ e.target.name ]: e.target.value } );
    };

    const onChangeCheck = e => {
        setPostInput( { ...postInput, isComment: !postInput.isComment } );
    };

    const onChangeCheckTv = e => {
        setPostInput( { ...postInput, isTv: !postInput.isTv } );
    };

    /**
     * This function is for simulating a click
     * for input button
     *
     */
    const onButtonClick = () => {
        inputEl.current.click();
    };

    /**
     * This function is for changing pravecy of the post
     */
    const onButtonPrivacy = async () => {
        switch ( postInput.postType ) {
            case "private":
                await setPostInput( { ...postInput, postType: "public" } );

                break;
            case "public":
                await setPostInput( { ...postInput, postType: "private" } );

                break;
            default:
                break;
        }
    };

    /**
     * On submit for forms
     * @param {e} e
     */
    const onSubmit = async e => {
        setvalid( true );
        setLoading( true );
        e.preventDefault();

        let isFile = false;

        if ( files ) {
            if ( files.length > 0 ) {
                isFile = true;
                for ( let index = 0; index < files.length; index++ ) {
                    var option = await new Blob( [ files[ index ] ] );
                    await data.append( "file", option, files[ index ].name );
                }
            }
        }

        let valids = await addPosts( postInput, isFile, data );

        if ( valids ) {
            setLoading( false );
            setPostInput( { ...postInput, text: " " } );
            setfiles( "" );
            setvalid( false );
            data = null;
            closeModal();

        }
    };



    /**
     * On change input
     * @param {e} e the files on input
     */
    const fileChangedHandler = async e => {
        if ( e ) {
            await setfiles( e.target.files );
        }
    };



    const [ modalIsOpen, setIsOpen ] = useState( false );

    function openModal () {
        setIsOpen( true );
    }
    function closeModal () {
        setIsOpen( false );
    }

    return (
        <div>
            <div className="fab-container" onClick={ () => openModal() }>
                <div className="fab fab-icon-holder">
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>
                </div>
            </div>
            <Modal
                openModal={ openModal }
                closeModal={ closeModal }
                modalIsOpen={ modalIsOpen }
                isTv={ postInput.isTv }
            >
                <div className="main-content pt-0">
                    <div className="container">
                        <div className="inner-body">
                            <div className="row row-sm" >
                                <div className="col-lg-12 col-md-12 col-md-12">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="form-group">
                                                <textarea
                                                    style={ { width: "100%", borderRadius: "10px" } }
                                                    type="text" className="uk-input"
                                                    placeholder={ postInput.text ? " " : t( 'feed.write' ) }
                                                    name="text"
                                                    onChange={ e => onChange( e ) }
                                                    value={ postInput.text }
                                                    cols="20" rows="5" />
                                            </div>


                                            <div className="form-group">
                                                <label className="">{ t( 'entity.janazainfo' ) }</label>
                                                <select className="form-control select2 form-select"
                                                    onChange={ onChange }
                                                    value={ postInput.kindPost }
                                                    name="kindPost"
                                                >
                                                    <option value="news">{ t( 'entity.postnews' ) }</option>
                                                    <option value="janaza">{ t( 'entity.postjanaza' ) }</option>
                                                    <option value="sick">{ t( 'entity.postsick' ) }</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <div className="example">
                                                    <div className="btn-icon-list">
                                                        <button className="btn ripple btn-primary btn-icon" onClick={ ( e ) => { e.preventDefault(); onButtonClick() } }>   <i className="fa fa fa-video-camera"></i></button>
                                                        <button className="btn ripple btn-secondary btn-icon" onClick={ ( e ) => { e.preventDefault(); onButtonClick() } }>   <i className="fa fa fa-picture-o"></i></button>
                                                        <button className="btn ripple btn-success btn-icon" onClick={ ( e ) => { e.preventDefault(); onButtonClick() } }> <i className="fa fa-volume-up" aria-hidden="true"></i></button>
                                                        <button className="btn ripple btn-info btn-icon" onClick={ ( e ) => { e.preventDefault(); onButtonClick() } }>  <i className="fa fa-file-pdf-o" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                            { files.length > 0 ? (
                                                <div className="p-4 border rounded-6 mb-4 form-group">
                                                    <div>
                                                        <button className="btn btn-success btn-lg full-width" onClick={ ( e ) => e.preventDefault() }>
                                                            { " " }
                                                            { files.length > 1
                                                                ? files.length + " fichiers"
                                                                : files[ 0 ].name }{ " " }
                                                        </button>
                                                    </div>
                                                </div>
                                            ) : null }


                                            <div className="form-group">
                                                <label className="">{ t( 'common.choose' ) }</label>
                                                <div className="d-md-flex ad-post-details">
                                                    <div className="main-toggle-group-demo mg-t-10">
                                                        <div>
                                                            <p className="text-muted card-sub-title">{ postInput.isTv ? t( 'common.tvpost' ) : t( 'common.notvpost' ) }</p>
                                                        </div>
                                                        <div style={ { marginLeft: "30px" } } className={ `main-toggle main-toggle-success ${ postInput.isTv ? "on" : "" }` } onClick={ () => onChangeCheckTv() }>
                                                            <span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex ad-post-details">
                                                    <div className="main-toggle-group-demo mg-t-10">
                                                        <div>
                                                            <p className="text-muted card-sub-title">{ postInput.isComment ? t( 'common.commentwa' ) : t( 'common.nocomments' ) }</p>
                                                        </div>
                                                        <div style={ { marginLeft: "30px" } } className={ `main-toggle main-toggle-success ${ postInput.isComment ? "on" : "" }` } onClick={ () => onChangeCheck() }>
                                                            <span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex ad-post-details">
                                                    <div className="main-toggle-group-demo mg-t-10">
                                                        <div>
                                                            <p className="text-muted card-sub-title">{ postInput.postType === "public" ? t( 'entity.public' ) : t( 'common.makepublic' ) }</p>
                                                        </div>
                                                        <div style={ { marginLeft: "30px" } } className={ `main-toggle main-toggle-success ${ ( postInput.postType === "public" ) ? "on" : "" }` } onClick={ () => onButtonPrivacy() }>
                                                            <span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <input
                                                type="file"
                                                id="files"
                                                name="files"
                                                style={ { display: "none" } }
                                                onChange={ fileChangedHandler }
                                                ref={ inputEl }
                                                multiple
                                            />
                                        </div>
                                        { loading ? <div className="spinner-grow" style={ { width: "5rem", height: "5rem", marginLeft: "40%" } } role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div> : <button className="btn btn-primary" onClick={ ( e ) => onSubmit( e ) } >
                                            <h5 style={ { textAlign: "center" } }>
                                                { t( 'entity.postnow' ) }
                                            </h5>
                                        </button> }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

const mapStateToProps = ( state ) => ( {
    post: state.post,
    auth: state.auth
} )

export default connect( mapStateToProps, { addPosts } )( Index )
