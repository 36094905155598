import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import CalendarUi from './subSections/Index';
import CalendarUiAdhan from './subSections/indexAdhan';
import Tutorials from './tutorials/Index';
import { useTranslation } from 'react-i18next';
import { setRoute, disableRoute } from '../../../action/routes'
import { isMobile } from '../../../utils/device';
import { MoonodeFont, MoonodeCalendarStyle, MoonodeCalendarStyle2, MoonodeCalendarStyle3, MoonodeCalendarStyle4, MoonodeCalendarStyle5, MoonodeCalendarStyle6 } from '../../../utils/Base64Logos'


export const Prayer = ( {
    setentityLogoBase64,
    setRoute,
    entityProfil,
    disableRoute,
    choosenMonth,
    choosenMonthShort,
    setchoosenMonth,
    onChangeMonth,
    islamicMonthsLong,
    islamicMonthsShort,
    body,
    name,
    localLoading,
    choosenMonthLong,
    choosenYear,
    onChangeYear,
    entityLogoBase64,
    choosenMonthAdhan,
    choosenMonthShortAdhan,
    onChangeMonthAdhan,
    choosenMonthLongAdhan,
    bodyAdhan
} ) => {

    const [ masjidName, setmasjidName ] = useState( name.slice( 0, 30 ) )
    const [ chosenImage, setchosenImage ] = useState( MoonodeCalendarStyle() )
    const [ chosenImageIndex, setchosenImageIndex ] = useState( 1 )
    const [ refresh, setRefresh ] = useState( false )
    useEffect( () => {
        setRoute( 'admin/calendar' );
        return () => {
            disableRoute()
        }
    }, [] );


    const { t, i18n } = useTranslation();

    const [ active, setActive ] = useState( {
        calendar: true,
        calendarAdhan: false,
    } );


    const changeSubOptionsPrayer = ( active ) => {
        switch ( active ) {
            case 'calendar':
                setActive( { ...active, calendar: true } );
                break;
            case 'calendarAdhan':
                setActive( { ...active, calendarAdhan: true } );
                break;
            default:
                break;
        }
    }



    //change templates
    const [ selectedImage, setSelectedImage ] = useState( 0 );

    function handleImageClick ( image, index ) {
        setRefresh( !refresh )
        onChangeChoosenImage( ( index + 1 ) );
        setSelectedImage( image );
        setchosenImageIndex( ( index + 1 ) )
    }
    const onChangeMasjidName = e => {
        setRefresh( !refresh )
        setmasjidName( e.target.value );
    };


    const onChangeChoosenImage = ( template ) => {
        console.log( template )
        switch ( template ) {
            case 1:
                setchosenImage( MoonodeCalendarStyle() )
                break;
            case 2:
                setchosenImage( MoonodeCalendarStyle2() )
                break;
            case 3:
                setchosenImage( MoonodeCalendarStyle3() )
                break;
            case 4:
                setchosenImage( MoonodeCalendarStyle4() )
                break;
            case 5:
                setchosenImage( MoonodeCalendarStyle5() )
                break;
            case 6:
                setchosenImage( MoonodeCalendarStyle6() )
                break;
            default:
                setchosenImage( MoonodeCalendarStyle() )
                break;
        }
    }

    useEffect( () => {
        onChangeChoosenImage( chosenImageIndex );
    }, [ chosenImageIndex ] )


    return (
        <Fragment>
            <div className="page">
                <div className="main-content side-content pt-0">
                    <div className="row row-sm container-fluid" style={ { maxWidth: active.fCsv ? '80%' : '90%', marginLeft: isMobile() ? " " : "70px" } }>
                        <div className={ `col-xl-8 col-lg-12` }>
                            <div className="card custom-card2" id="hori">
                                <div className="card-body" >
                                    <div className="text-wrap">
                                        <div className="example">
                                            <div>
                                                <h6 className="main-content-label mb-1">{ t( 'landingSite.hijriCalTitle' ) }</h6>
                                                <p className="text-muted card-sub-title"> { t( 'landingSite.hijriCal' ) }</p>
                                            </div>
                                            <div className="p-3 bg-light">
                                                <nav className="nav nav-pills flex-column flex-md-row">
                                                    <a onClick={ ( e ) => { e.preventDefault(); setActive( { ...active, calendarAdhan: false } ); changeSubOptionsPrayer( 'calendar' ) } } className={ `nav-link ${ active.calendar ? "active" : "" }` } href="#">{ t( 'landingSite.hijriCalTitle' ) + " " + t( 'common.iqama' ) + " + " + t( 'common.adhan' ) } { " " } </a> { " " }
                                                    <a onClick={ ( e ) => { e.preventDefault(); setActive( { ...active, calendar: false } ); changeSubOptionsPrayer( 'calendarAdhan' ) } } className={ `nav-link ${ active.calendarAdhan ? "active" : "" }` } href="#">{ " " } { t( 'landingSite.hijriCalTitle' ) + " " + t( 'common.adhan' ) } </a>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                    <>
                                        { active.calendar && <CalendarUi
                                            setentityLogoBase64={ setentityLogoBase64 }
                                            entityLogoBase64={ entityLogoBase64 }
                                            entityProfil={ entityProfil }
                                            choosenMonth={ choosenMonth }
                                            name={ masjidName }
                                            choosenMonthShort={ choosenMonthShort }
                                            setchoosenMonth={ setchoosenMonth }
                                            onChangeMonth={ onChangeMonth }
                                            onChangeMasjidName={ onChangeMasjidName }
                                            chosenImage={ chosenImage }
                                            body={ body }
                                            choosenMonthLong={ choosenMonthLong }
                                            choosenYear={ choosenYear }
                                            onChangeYear={ onChangeYear }
                                            islamicMonthsLong={ islamicMonthsLong }
                                            islamicMonthsShort={ islamicMonthsShort }
                                            refresh={ refresh }
                                            localLoading={ localLoading }
                                        /> }

                                        { active.calendarAdhan && <CalendarUiAdhan
                                            setentityLogoBase64={ setentityLogoBase64 }
                                            entityLogoBase64={ entityLogoBase64 }
                                            entityProfil={ entityProfil }
                                            choosenMonth={ choosenMonthAdhan }
                                            name={ masjidName }
                                            choosenMonthShort={ choosenMonthShortAdhan }
                                            setchoosenMonth={ setchoosenMonth }
                                            onChangeMonth={ onChangeMonthAdhan }
                                            onChangeMasjidName={ onChangeMasjidName }
                                            chosenImage={ chosenImage }
                                            body={ bodyAdhan }
                                            choosenMonthLong={ choosenMonthLongAdhan }
                                            choosenYear={ choosenYear }
                                            onChangeYear={ onChangeYear }
                                            islamicMonthsLong={ islamicMonthsLong }
                                            islamicMonthsShort={ islamicMonthsShort }
                                            refresh={ refresh }
                                            localLoading={ localLoading }
                                        /> }
                                    </>
                                </div>

                            </div>
                        </div>
                        <Tutorials handleImageClick={ handleImageClick } selectedImage={ selectedImage } />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = ( state ) => ( {
    routes: state.routes,
    entity: state.entity
} )


export default connect( mapStateToProps, { setRoute, disableRoute } )( Prayer )
