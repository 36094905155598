
import {
    SET_ROUTE,
    REMOVE_ROUTE
} from "./types";

export const setRoute = ( route ) => async dispatch => {
    try {
        dispatch( {
            type: SET_ROUTE,
            payload: route
        } );
    } catch ( error ) {
        console.log( error );
        return false;
    }
};

export const disableRoute = () => async dispatch => {
    try {
        dispatch( {
            type: REMOVE_ROUTE,
        } );
    } catch ( error ) {
        console.log( error );
        return false;
    }
};

