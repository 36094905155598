import { combineReducers } from "redux";
import alert from "./alert";
import routes from './routes';
import entity from './entity';
import auth from './auth';
import feed from './feed';
import post from './post';
import qa from './qa';
import entities from './entities';
import search from './search';
import userNotification from './userNotification';
import profile from './profile'

export default combineReducers( {
    userNotification,
    entities,
    profile,
    routes,
    entity,
    search,
    alert,
    auth,
    feed,
    post,
    qa,
} );
