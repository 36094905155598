import axios from "axios";
import {
    GET_SEARCH,
    SET_LOADING_SEARCH
} from "./types";
import { setAlert } from "./alert";
const config = { headers: { "Content-Type": "application/json" } };

/**
 * Post question
 * @param {*} user
 */
export const getSearch = ( content, options ) => async dispatch => {

    let theBody = {};
    theBody.content = content;
    theBody.options = options;

    const body = JSON.stringify( theBody );


    try {

        dispatch( {
            type: SET_LOADING_SEARCH,
            payload: true
        } );

        const res = await axios.post( "/api/search", body, config );

        dispatch( {
            type: SET_LOADING_SEARCH,
            payload: false
        } );

        dispatch( {
            type: GET_SEARCH,
            payload: res.data
        } );

    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};