import moment from "moment";
import { format, differenceInSeconds, isAfter, isBefore } from 'date-fns'

export const calculeNextIqama = function ( iqamaTime, type ) {
    try {
        let now = format( new Date(), "HH:mm" )
        let time, befor;
        switch ( type ) {
            case "Fajr":
                time = now < iqamaTime.Fajr
                befor = now > iqamaTime.Isha;
                return time || befor;
            case "Dhuhr":
                time = now < iqamaTime.Dhuhr;
                befor = now > iqamaTime.Fajr;
                return time && befor;
            case "Asr":
                time = now < iqamaTime.Asr;
                befor = now > iqamaTime.Dhuhr;
                return time && befor;
            case "Maghrib":
                time = now < iqamaTime.Maghrib;
                befor = now > iqamaTime.Asr;
                return time && befor;
            case "Isha":
                time = now < iqamaTime.Isha;
                befor = now > iqamaTime.Maghrib;
                return time && befor;
        }
    } catch ( error ) {
        return false;
    }

};
const nextPrayerTimer = function ( now, iqamaTime, adhanTime, salat ) {
    let _now = new Date( "1988-08-22" + "T" + now );
    let _iqamaTime = new Date( "1988-08-22" + "T" + iqamaTime )
    let _adhanTime = new Date( "1988-08-22" + "T" + adhanTime.replace( /\s+/g, '' ) )
    return { salat: salat, timeToIqama: differenceInSeconds( _iqamaTime, _now ), timeToAdhan: differenceInSeconds( _adhanTime, _now ), iqamaTimer: ( isAfter( _now, _adhanTime ) && isBefore( _now, _iqamaTime ) ) }
}

export const whatIsNext = function ( tvPrayer ) {
    try {
        let now = format( new Date(), "HH:mm:ss" )
        let time, befor;
        let valid = true;
        time = now < tvPrayer.iqamaTime.Fajr
        befor = now > tvPrayer.iqamaTime.Isha;
        if ( time || befor && valid ) {
            valid = false;
            return nextPrayerTimer( now, tvPrayer.iqamaTime.Fajr, tvPrayer.apiPrayerTime.Fajr, "Fajr" )
        }
        time = now < tvPrayer.iqamaTime.Dhuhr;
        befor = now > tvPrayer.iqamaTime.Fajr;
        if ( time && befor && valid ) {
            valid = false;
            return nextPrayerTimer( now, tvPrayer.iqamaTime.Dhuhr, tvPrayer.apiPrayerTime.Dhuhr, "Dhuhr" );
        }
        time = now < tvPrayer.iqamaTime.Asr;
        befor = now > tvPrayer.iqamaTime.Dhuhr;
        if ( time && befor && valid ) {
            valid = false;
            return nextPrayerTimer( now, tvPrayer.iqamaTime.Asr, tvPrayer.apiPrayerTime.Asr, "Asr" );
        }
        time = now < tvPrayer.iqamaTime.Maghrib;
        befor = now > tvPrayer.iqamaTime.Asr;
        if ( time && befor && valid ) {
            valid = false;
            return nextPrayerTimer( now, tvPrayer.iqamaTime.Maghrib, tvPrayer.apiPrayerTime.Maghrib, "Maghrib" );
        }
        time = now < tvPrayer.iqamaTime.Isha;
        befor = now > tvPrayer.iqamaTime.Maghrib;
        if ( time && befor && valid ) {
            valid = false;
            return nextPrayerTimer( now, tvPrayer.iqamaTime.Isha, tvPrayer.apiPrayerTime.Isha, "Isha" );
        }

    } catch ( error ) {
        return { salat: "Salat", time: 0 }
    }
};

export const iqamaCalcul = function ( iqama, addedTime, adhan ) {

    if ( Number( addedTime ) > 0 ) {
        let theIqama = moment( adhan, "hh:mm" );
        return theIqama.add( addedTime, "minutes" ).format( "HH:mm" )
    } else {
        return iqama === "N/A" ? adhan : iqama;
    }

};