import React from 'react'
import PropTypes from 'prop-types'
import './style.css'
const CalendarUi = props => {
    return (
        <div className="parent">
            <div className="child">
                <table>
                    <tbody>
                        <tr>
                            <td>Row 1, Column 1</td>
                            <td>Row 1, Column 2</td>
                        </tr>
                        <tr>
                            <td>Row 2, Column 1</td>
                            <td>Row 2, Column 2</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

CalendarUi.propTypes = {}

export default CalendarUi