import React from 'react'

const KindPost = ( { kindPost } ) => {
    let color;
    let text;
    switch ( kindPost ) {
        case "janaza":
            color = "#e53935"
            text = "Janaza"
            break;
        case "sick":
            color = "#3a7bd5"
            text = "Visite Malade"
            break;
    }
    return (
        kindPost !== "news" &&
        <div className="boxi" style={ { display: "flex", justifyContent: "flex-end", alignItems: "center" } }>
            <div style={ { backgroundColor: color, height: "-2px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", marginRight: "-15px" } }>
                <span style={ { fontSize: "15px", color: "#fff", padding: "20px" } } > { text } </span>
            </div>
        </div>
    )
}

export default KindPost
