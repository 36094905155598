import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
// import { Card, CardBody } from "reactstrap";
import './mobile.min.css'
import './powered.css'
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next'
import Widget from './Ui'
const MobilePrayer = ( {
    apiPrayerTime,
    iqamaTimes,
    calculeNextIqama,
    entityProfil,
    entity,
    prayerTime,
    tomorrowIqamaTime,
    tomorrowApiPrayerTime,
    highlighted,
    iqamaCalcul,
    color,
    onChange
} ) => {
    const { t } = useTranslation();

    return (
        <div className="row row-sm" >
            <div className="col-xl-12 col-lg-12">
                <div className="card custom-card" id="basic">
                    <div className="card-body">
                        <br />
                        <div>
                            <h6 className="main-content-label mb-1">{ t( "website.widgetTitle" ) }</h6>
                            <p className="text-muted card-sub-title"> { t( "website.widgetInfo" ) }</p>
                        </div>
                        <div className="text-wrap">
                            <div className="card-body">
                                <div>
                                    <h6 className="main-content-label mb-1">{ t( "common.color" ) }</h6>

                                </div>

                                <div className="form-group">

                                    <select
                                        name="widgetColor"
                                        className="form-control select-lg select2"
                                        onChange={ ( e ) => onChange( e ) }
                                        value={ color }
                                    >

                                        <option value={ "#16acea" }>{ t( "common.blue" ) }</option>
                                        <option value={ "#79C300" }>{ t( "common.green" ) }</option>
                                        <option value={ "#ee204d" }>{ t( "common.rouge" ) }</option>
                                        <option value={ "#FFA500" }>{ t( "common.orange" ) }</option>
                                        <option value={ "#d902ee" }>{ t( "common.purple" ) }</option>
                                        <option value={ "#FABF0F" }>{ t( "common.yellow" ) }</option>
                                        <option value={ "black" }>{ t( "common.black" ) }</option>
                                    </select>
                                </div>
                            </div>
                            <div className="text-wrap">

                                <figure className="highlight clip-widget" id="badge1"><pre>
                                    <code style={ { color: "red" } }>
                                        &lt;iframe{ '\n' }
                                        allow="fullscreen" style="border: 0; height: 720px; left: 0; position: inherit; top: 10px; width: 100%; paddingTop: 10px" { '\n' }
                                        sandbox="allow-same-origin allow-scripts allow-forms allow-presentation"{ '\n' }
                                        sandbox="allow-same-origin allow-scripts allow-forms allow-presentation"{ '\n' }
                                        src="https://www.moonode.com/{ entity.entity.codeEntity }/HhQj7itZ6R6wTvOJqoI9" { '\n' }
                                        frameBorder="0"
                                        /&gt;
                                    </code>

                                </pre>
                                    <div className="clipboard-icon" data-clipboard-target="#badge1"
                                        onClick={ () => {
                                            copy( `<iframe allow="fullscreen" style="border: 0; height: 720px; left: 0; 
                                            position: inherit; top: 10px; width: 100%; paddingTop: 10px" sandbox="allow-same-origin allow-scripts allow-forms allow-presentation" 
                                            src="https://www.moonode.com/${ entity.entity.codeEntity }/HhQj7itZ6R6wTvOJqoI9" frameBorder="0" />` ); alert( t( "website.copied" ) )
                                        } }
                                    ><i className="fa fa-clipboard"></i></div>
                                    <div className="example">
                                        <h6>{ t( "website.widgetAdapt" ) }</h6>
                                    </div>
                                </figure>
                                <div className="card-body">

                                    <button type="button" className="btn ripple btn-dark btn-block"
                                        onClick={ () => {
                                            copy( `<iframe allow="fullscreen" style="border: 0; height: 720px; left: 0; 
                                        position: inherit; top: 10px; width: 100%; paddingTop: 10px" sandbox="allow-same-origin allow-scripts allow-forms allow-presentation" 
                                        src="https://www.moonode.com/${ entity.entity.codeEntity }/HhQj7itZ6R6wTvOJqoI9" frameBorder="0" />` ); alert( t( "website.copied" ) )
                                        } }

                                    >{ t( "website.copy" ) } </button>
                                </div>
                                <br />
                                <br />
                                <div className="example">
                                    <h3>{ t( "autoMessage.sharing" ) }</h3>
                                    <h6>{ t( "autoMessage.warningShare" ) }</h6>
                                </div>
                                <figure className="highlight clip-widget" id="badge1"><pre>
                                    <code style={ { color: "red" } }>
                                        https://www.moonode.com/{ entity.entity.codeEntity }/HhQj7itZ6R6wTvOJqoI9
                                    </code>
                                </pre>
                                    <div className="clipboard-icon" data-clipboard-target="#badge1"
                                        onClick={ () => {
                                            copy( `https://www.moonode.com/${ entity.entity.codeEntity }/HhQj7itZ6R6wTvOJqoI9` ); alert( t( "website.copied" ) )
                                        } }
                                    ><i className="fa fa-clipboard"></i></div>

                                </figure>
                                <div className="card-body">
                                    <button type="button" className="btn ripple btn-dark btn-block"
                                        onClick={ () => {
                                            copy( `https://www.moonode.com/${ entity.entity.codeEntity }/HhQj7itZ6R6wTvOJqoI9` ); alert( t( "website.copied" ) )
                                        } }

                                    >{ t( "website.copy" ) } </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row row-sm" >
                    <div className="col-xl-12 col-lg-12">
                        <div className="card custom-card" id="basic">

                            <div className="inner-body">
                                <div className="" style={ { width: "40%", marginLeft: "30%" } }>
                                    <Widget
                                        iqamaTimes={ iqamaTimes }
                                        apiPrayerTime={ apiPrayerTime }
                                        calculeNextIqama={ calculeNextIqama }
                                        entityProfil={ entityProfil }
                                        prayerTime={ prayerTime }
                                        isText={ false }
                                        color={ color }
                                        entity={ entity.entity.entity }
                                        tomorrowIqamaTime={ tomorrowIqamaTime }
                                        tomorrowApiPrayerTime={ tomorrowApiPrayerTime }
                                        highlighted={ highlighted }
                                        iqamaCalcul={ iqamaCalcul }
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

        </div>

    )
}

MobilePrayer.propTypes = {

}

export default MobilePrayer
