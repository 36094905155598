import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import General from './general/Index';
import Password from './password/Index';
import Mosque from './mosque/Index';
import Contact from './contactUs/Index';
import { updateUserInfo, getUserProfile, getUserInfo, addPictureToProfile } from '../../action/profile';
import { logoutUser } from '../../action/auth';

export const Index = ( {
    updateUserInfo,
    profile: { profile, userInfo, loadingUserInfo },
    addPictureToProfile,
    getUserProfile,
    auth: { user },
    getUserInfo,
    logoutUser

} ) => {

    useEffect( () => {
        getUserProfile( user.user.id );
        getUserInfo( user.user.id );
        return () => {
        }
    }, [] )

    const { t } = useTranslation();
    const [ active, setActive ] = useState( {
        general: true,
        password: false,
        mosque: false,
        contact: false
    } );

    const changeSubOptionsProfile = ( active ) => {
        switch ( active ) {
            case 'general':
                setActive( { ...active, general: true } );
                break;
            case 'password':
                setActive( { ...active, password: true } );
                break;
            case 'mosque':
                setActive( { ...active, mosque: true } );
                break;
            case 'contact':
                setActive( { ...active, contact: true } );
                break;
            default:
                break;
        }

    }

    const [ bio, setBio ] = useState( {
        codeType: "LAN-3",
        userId: userInfo ? userInfo.user._id : " ",
        madhab: userInfo ? userInfo.user.madhab : " ",
        language: userInfo ? userInfo.user.language : " ",
        handle: userInfo ? userInfo.user.handle : " ",
        name: userInfo ? userInfo.user.name : " ",

    } );

    useEffect( () => {
        setBio( {
            ...bio,
            codeType: "LAN-3",
            userId: userInfo ? userInfo.user._id : " ",
            madhab: userInfo ? userInfo.user.madhab : " ",
            language: userInfo ? userInfo.user.language : " ",
            handle: userInfo ? userInfo.user.handle : " ",
            name: userInfo ? userInfo.user.name : " ",

        } );
        return () => {
        }
    }, [ userInfo ] );

    const [ saving, setSaving ] = useState( false );

    const onChange = e => {
        setBio( { ...bio, [ e.target.name ]: e.target.value } );
    };

    const onSave = async () => {
        if ( bio.handle.length <= 3 || bio.name.length <= 3 ) {
            alert( "too short" );
        } else {
            setSaving( true );

            let valid = await updateUserInfo( bio );
            if ( valid ) {
                setSaving( false );
            }
        }

    }

    return (
        <div>
            <div class="main-content pt-0">
                <div class="container">
                    <div class="inner-body">
                        <div class="page-header" style={ { marginTop: "-30px" } }>
                            <div>
                                <h2 class="main-content-title tx-24 mg-b-5">Profile</h2>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Pages</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Profile</li>
                                </ol>
                            </div>
                        </div>


                        <div class="row square">
                            <div class="col-lg-12 col-md-12">
                                <div class="card custom-card">
                                    <div class="card-body">
                                        <div class="panel profile-cover">
                                            <div class="profile-cover__img">
                                                <img src={ user.user.picture } alt="img" />
                                                <h3 class="h3">{ user.user.name } { " " } { user.user.handle }</h3>
                                            </div>
                                            <div class="profile-cover__action" height='226px' width='1194px' style={ { marginTop: "-20px" } } onClick={ ( e ) => { e.preventDefault() } }>
                                                <img src={ "https://general.eu-central-1.linodeobjects.com/basics/platforms/masjed.png" } style={ { height: '300px', width: '1000px', objectFit: "cover" } } />
                                            </div>
                                            <div class="profile-cover__info" style={ { marginBottom: "40px" } }>

                                            </div>
                                        </div>
                                        <div class="profile-tab tab-menu-heading">
                                            <nav class="nav main-nav-line p-3 tabs-menu profile-nav-line bg-gray-100">
                                                <a class={ `nav-link ${ active.general ? "active" : "" }` } data-bs-toggle="tab" href="#general" onClick={ ( e ) => { e.preventDefault(); changeSubOptionsProfile( 'general' ) } }>{ t( "application.general" ) }</a>
                                                <a class={ `nav-link ${ active.password ? "active" : "" }` } data-bs-toggle="tab" href="#password" onClick={ ( e ) => { e.preventDefault(); changeSubOptionsProfile( 'password' ) } }>{ t( "common.password" ) }</a>
                                                <a class={ `nav-link ${ active.mosque ? "active" : "" }` } data-bs-toggle="tab" href="#mosque" onClick={ ( e ) => { e.preventDefault(); changeSubOptionsProfile( 'mosque' ) } }>{ t( "common.mosque" ) }</a>
                                                <a class={ `nav-link ${ active.contact ? "active" : "" }` } data-bs-toggle="tab" href="#contact" onClick={ ( e ) => { e.preventDefault(); changeSubOptionsProfile( 'contact' ) } }>{ t( "entity.contact" ) }</a>
                                                <button type="button" className="btn btn-secondary btn-sm" style={ { marginLeft: "20px", marginTop: '-4px' } }
                                                    onClick={ () =>
                                                        window.confirm( t( "common.signout" ) + " ?" ) ? logoutUser( user.user ) : alert( "👍" )
                                                    }
                                                >{ t( "common.signout" ) }</button>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="row row-sm" >
                            <div class="col-lg-12 col-md-12">
                                <div class="card custom-card main-content-body-profile">
                                    <div class="tab-content">
                                        { !loadingUserInfo && active.general &&
                                            <General
                                                userInfo={ userInfo }
                                                logoutUser={ logoutUser }
                                                user={ user.user }
                                                onSave={ onSave }
                                                onChange={ onChange }
                                                bio={ bio }
                                                saving={ saving }
                                            /> }
                                        { !loadingUserInfo && active.password &&
                                            <Password

                                            /> }
                                        { !loadingUserInfo && active.mosque && <Mosque profile={ profile } /> }
                                        { !loadingUserInfo && active.contact && <Contact user={ user.user } /> }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}

const mapStateToProps = ( state ) => ( {
    auth: state.auth,
    profile: state.profile
} )


export default connect( mapStateToProps, {
    addPictureToProfile,
    updateUserInfo,
    getUserProfile,
    getUserInfo,
    logoutUser,
} )( Index )
