import React from 'react'
import PropTypes from 'prop-types'
import { isMobile, isTablet } from '../../utils/device';

const PassTokenUi = ( { translate, onEdit, onSubmit } ) => {

    return (
        <div className="page main-signin-wrapper">
            <div className="row signpages text-center">
                <div className="col-md-12">
                    <div className="card">
                        <div className="row row-sm" >
                            <div className="col-lg-6 col-xl-5 d-none d-lg-block" style={ { backgroundColor: "#3a3a3a" } }>
                                <div className="mt-5 pt-4 p-2 pos-absolute">
                                    {/* <img src="../../assets/img/brand/logo-light.png" className="header-brand-img mb-4" alt="logo" /> */ }
                                    <div className="clearfix"></div>
                                    <img src="https://general.eu-central-1.linodeobjects.com/basics/platforms/moonode_logo.png" className="ht-100 mb-0" alt="user" />
                                    <h5 className="mt-4 text-white"></h5>
                                    <hr />
                                    <span className="tx-white-6 tx-13 mb-5 mt-xl-0">{ translate( 'landing.resetpasswordinfo' ) }</span>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-7 col-xs-12 col-sm-12 login_form ">
                                <div className="container-fluid">
                                    <div className="row row-sm" >
                                        <div className="card-body mt-2 mb-2">
                                            <img src="../../assets/img/brand/logo.png" className=" d-lg-none header-brand-img text-start float-start mb-4" alt="logo" />
                                            <div className="clearfix"></div>
                                            <h5 className="text-start mb-2">{ translate( 'landing.reset' ) } { " " }  </h5>
                                            <div className="form-group text-start">
                                                <br />
                                                <label>{ translate( "common.password" ) }</label>
                                                <input
                                                    className="form-control"
                                                    placeholder={ translate( "common.password" ) }
                                                    id="password"
                                                    type="password"
                                                    name="password"
                                                    onChange={ ( e ) => onEdit( e ) }
                                                />
                                            </div>
                                            <div className="form-group text-start">
                                                <br />
                                                <label>{ translate( "common.confirmpassword" ) }</label>
                                                <input
                                                    className="form-control"
                                                    placeholder={ translate( "common.confirmpassword" ) }
                                                    id="password"
                                                    type="password"
                                                    name="password2"
                                                    onChange={ ( e ) => onEdit( e ) }
                                                />
                                            </div>
                                            <button
                                                className="btn ripple btn-main-primary btn-block" onClick={ () => onSubmit() }>{ translate( 'common.change' ) }</button>
                                            <br />
                                            <br />
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

PassTokenUi.propTypes = {

}

export default PassTokenUi
