import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import SignUpUi from '../../components/login/SignUpUi';
import { registration } from "../../action/auth";

export const SignUp = ( { registration } ) => {
    const { t } = useTranslation();
    const [ formData, setFormData ] = useState( {
        codeType: "LAN-3",
        name: "",
        handle: "",
        day: "01",
        year: "01",
        month: "2000",
        email: "",
        email2: "",
        phoneNum: "",
        gender: "",
        entityType: "Mosquée",
        password: "",
        password2: "",
        isAccepted: false,
        remember: false,
        kindOfUser: "aBeliever"
    } );
    const {
        codeType,
        name,
        handle,
        day,
        year,
        month,
        email,
        gender,
        entityType,
        password,
        password2,
        isAccepted,
        kindOfUser,
        remember
    } = formData;

    const [ captcha, setcaptcha ] = useState( 'xxx' )
    const [ loading, setloading ] = useState( false )
    const [ capp, isCaptcha ] = useState( 'xxx' );
    const onChange = e => {
        setFormData( { ...formData, [ e.target.name ]: e.target.value } );
    };
    const onChangeCheck = e => {
        setFormData( { ...formData, isAccepted: !formData.isAccepted } );

    };

    const onSubmit = async e => {
        setloading( true );
        e.preventDefault();
        let newUser = {
            codeType,
            name,
            handle,
            day,
            year,
            month,
            email,
            gender,
            entityType,
            isAccepted,
            password,
            password2,
            kindOfUser,
            captcha
        };
        newUser.password2 = password;
        let response = await registration( newUser, t );
        if ( !response ) {
            setloading( false );
            capp.reset();
        }
    };

    return (
        <SignUpUi
            t={ t }
            loading={ loading }
            onChange={ onChange }
            onSubmit={ onSubmit }
            onChangeCheck={ onChangeCheck }
            setcaptcha={ setcaptcha }
            isCaptcha={ isCaptcha }

        />
    )
}

const mapStateToProps = ( state ) => ( {

} )

export default connect( mapStateToProps, { registration } )( SignUp )
