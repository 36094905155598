import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { changeUserPassword } from '../../../action/auth';

export const Index = ( { changeUserPassword } ) => {
    const { t } = useTranslation();
    const [ formData, setformData ] = useState( {
        oldPassword: "",
        password: "",
        password2: ""
    } )

    const onChangePassword = ( e ) => {
        setformData( { ...formData, [ e.target.name ]: e.target.value } );
    }


    return (
        <div>
            <div class="main-content-body tab-pane p-4 border-top-0 active" id="edit">
                <div class="card-body border">
                    <div class="mb-4 main-content-label">{ t( "profile.settings" ) }</div>
                    <form class="form-horizontal">
                        <div class="mb-4 main-content-label">{ t( "common.password" ) }</div>
                        <div class="form-group ">
                            <div class="row row-sm" >
                                <div class="col-md-3">
                                    <label class="form-label">{ t( "common.password" ) }</label>
                                </div>
                                <div class="col-md-9">
                                    <input
                                        className="form-control"
                                        type="password"
                                        name="oldPassword"
                                        placeholder={ t( "common.password" ) }
                                        onChange={ ( e ) => onChangePassword( e ) }
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="form-group ">
                            <div class="row row-sm" >
                                <div class="col-md-3">
                                    <label class="form-label">{ t( "common.newpassword" ) }</label>
                                </div>
                                <div class="col-md-9">
                                    <input
                                        className="form-control"
                                        type="password"
                                        name="password"
                                        placeholder={ t( "common.newpassword" ) }
                                        onChange={ e => onChangePassword( e ) }
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="form-group ">
                            <div class="row row-sm" >
                                <div class="col-md-3">
                                    <label class="form-label">{ t( "common.confirmpassword" ) }</label>
                                </div>
                                <div class="col-md-9">
                                    <input
                                        className="form-control"
                                        type="password" name="password2"
                                        placeholder={ t( "common.confirmpassword" ) }
                                        onChange={ e => onChangePassword( e ) }
                                    />
                                </div>
                                <div className="col-md-9" style={ { float: "right" } }>
                                    <button type="button" className="btn btn-primary btn-sm" onClick={ () => changeUserPassword( formData ) }>{ t( "common.save" ) }</button>
                                </div>
                            </div>

                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ( state ) => ( {

} )



export default connect( mapStateToProps, { changeUserPassword } )( Index )
