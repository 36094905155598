import React, { Fragment, useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { Translation, useTranslation } from 'react-i18next';
import saoudi from '../../assets/flags/saoudi.png';
import england from '../../assets/flags/england.png';
import french from '../../assets/flags/french.png';
import pakistan from '../../assets/flags/pakistan.png';
import bangladesh from '../../assets/flags/bangladesh.jpeg';
import { putToAsyncStorage } from '../../utils/localStorage';
import TopHeaderNotification from '../../containers/topHeaderNotification/Index';
import { isMobile, isTablet } from '../../utils/device';
export const Index = ( {
    children,
    onHideSideBar,
    hidden,
    routes: { isRoute, actualRoute },
    isAuthenticated,
    userProfile,
    isEntity,
    isItAdmin,
    isItRole,
    onChange,
    formData,
    items,
    searchLoading,
    user,
    setHiddenMobile,
    hiddenMobile

} ) => {

    const [ isHovered, setIsHovered ] = useState( null );

    const handleMouseEnter = ( linkType ) => {
        setIsHovered( linkType );
    };

    const handleMouseLeave = () => {
        setIsHovered( null );
    };

    useEffect( () => {
    }, [ isRoute, actualRoute ] )
    const myRef = useRef( null )

    const [ notification, setShowNotification ] = useState( false )
    const [ searchShow, onSearchShowChange ] = useState( false )
    const [ subMenu, onOpenSubMenu ] = useState( false )
    // const [ fullScreen, setFullScreen ] = useState( false )
    const [ activeLanguage, setActiveLanguage ] = useState( false )
    const { t, i18n } = useTranslation();

    const onChangeLanguage = ( language ) => {
        i18n.changeLanguage( language )
        localStorage.setItem( "lang", language )
    }

    const show = () => {
        setActiveLanguage( !activeLanguage );
    }
    // const fullScreenToggler = () => {
    //     setFullScreen( !fullScreen )
    // }
    // const fullScreenTogglera = ( a ) => {
    //     setFullScreen( a )
    // }

    const showNotification = () => {
        setShowNotification( !notification )
    }

    const [ theState, setTheState ] = useState( [] )

    useEffect( () => {
        async function getCachedResult () {
            setTheState( JSON.parse( await localStorage.getItem( 'favorite' ) ) ? JSON.parse( await localStorage.getItem( 'favorite' ) ) : [] );
        }
        getCachedResult();
        return () => {
        }
    }, [ items ] );

    const scrollTo = ( ref ) => {
        const section = document.querySelector( '#mosque-admin' );
        section && section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    }
    useEffect( () => {

        setTimeout( () => {
            scrollTo()
        }, 2000 );
    }, [ isItAdmin ] )
    return (
        isAuthenticated && <Fragment>

            <div className="main-sidebar main-sidebar-sticky side-menu" style={ { overflow: isItAdmin || isItRole ? "scroll" : "" } }>
                <div className="sidemenu-logo">
                    <a className="main-logo" href="/">
                        <img src="https://general.eu-central-1.linodeobjects.com/basics/platforms/moonode_logo.png" style={ { width: "50px" } } className="header-brand-img desktop-logo" alt="logo" />
                        <img src="https://general.eu-central-1.linodeobjects.com/basics/platforms/moonode_logo.png" style={ { width: "50px" } } className="header-brand-img icon-logo" alt="logo" />
                        <img src="https://general.eu-central-1.linodeobjects.com/basics/platforms/moonode_logo.png" style={ { width: "50px" } } className="header-brand-img desktop-logo theme-logo" alt="logo" />
                        <img src="https://general.eu-central-1.linodeobjects.com/basics/platforms/moonode_logo.png" style={ { width: "50px" } } className="header-brand-img icon-logo theme-logo" alt="logo" />
                    </a>
                    { !hidden && <h3 style={ { paddingLeft: "22%", marginTop: "19px", color: "#FABF0F", } }>
                        Moonode
                    </h3> }
                </div>
                <br />
                <div className="main-sidebar-body">
                    <br />
                    <br />
                    <ul className="nav">
                        <li className="nav-header"><span className="nav-label" style={ { color: "#FABF0F" } }>  { t( "application.home" ) }</span></li>
                        <li className={ `nav-item ${ isRoute && actualRoute === "user/dashboard" ? "active" : "" }` }>
                            <a className="nav-link" href="/dashboard"><span className="shape1"></span><span className="shape2"></span><i className="ti-home sidemenu-icon"></i><span className="sidemenu-label" style={ { color: isRoute && actualRoute === "user/dashboard" ? '#3a3a3a' : '' } }>{ t( "website.dashboard" ) }</span></a>
                        </li>
                        <li className={ `nav-item ${ isRoute && actualRoute === "user/mosques" ? "active" : "" }` }>
                            <a className="nav-link" href="/mosques"><span className="shape1"></span><span className="shape2"></span><i className="ti-location-arrow sidemenu-icon"></i><span className="sidemenu-label" style={ { color: isRoute && actualRoute === "user/mosques" ? '#3a3a3a' : '' } } >{ t( "website.mosques" ) }</span></a>
                        </li>
                        <li className={ `nav-item ${ isRoute && actualRoute === "user/mymosque" ? "active" : "" }` }>
                            { isEntity ? <a className="nav-link" href={ `${ userProfile.urlEntity }` }><span className="shape1"></span><span className="shape2"></span><i className="ti-heart sidemenu-icon"></i><span className="sidemenu-label" style={ { color: isRoute && actualRoute === "user/mymosque" ? '#3a3a3a' : '' } } >{ userProfile.entityName }</span></a> :
                                <a className="nav-link" href="/dashboard"><span className="shape1"></span><span className="shape2"></span><i className="ti-heart sidemenu-icon"></i><span className="sidemenu-label" style={ { color: isRoute && actualRoute === "user/mymosque" ? '#3a3a3a' : '' } } >{ t( "website.favorite" ) }</span></a> }
                        </li>
                        <li className="nav-item show">
                            <a className="nav-link with-sub" href="/dashboard" onClick={ ( e ) => e.preventDefault() }><span className="shape1"></span><span className="shape2"></span><i className="ti-stats-up sidemenu-icon"></i><span className="sidemenu-label"  >{ t( "website.mylist" ) }</span><i className="angle fe fe-chevron-right"></i></a>
                            <ul className="nav-sub">
                                { theState.slice( 0, 10 ).map( ( item, index ) =>
                                    <li className="nav-sub-item">
                                        <a className="nav-sub-link" href={ item.url } >
                                            <i className="ti-flag-alt sidemenu-icon" style={ { paddingRight: '10px' } }></i> { " " }
                                            { " " + item.title }</a>
                                    </li>
                                ) }
                            </ul>
                        </li>
                        <li className="nav-header"><span className="nav-label" style={ { color: "#FABF0F" } }>{ t( "website.application" ) }</span></li>
                        <li className={ `nav-item ${ isRoute && actualRoute === "user/adkharm" ? "active" : "" }` }>
                            <a className="nav-link" href="/adhkarm"><span className="shape1"></span><span className="shape2"></span><i className="ti-light-bulb sidemenu-icon"></i><span className="sidemenu-label" style={ { color: isRoute && actualRoute === "user/adkharm" ? '#3a3a3a' : '' } } >{ t( "application.adkarsabah" ) }</span></a>
                        </li>
                        <li className={ `nav-item ${ isRoute && actualRoute === "user/adkhars" ? "active" : "" }` }>
                            <a className="nav-link" href="/adhkars"><span className="shape1"></span><span className="shape2"></span><i className="ti-star sidemenu-icon"></i><span className="sidemenu-label" style={ { color: isRoute && actualRoute === "user/adkhars" ? '#3a3a3a' : '' } } >{ t( "application.adkarmassah" ) }</span></a>
                        </li>
                        { isItAdmin ? <li className="nav-header"><span className="nav-label" style={ { color: "#FABF0F" } }>{ t( "website.adminstrator" ) }</span></li> : null }
                        { ( isItRole && userProfile && userProfile.profile.role.prayer ) || isItAdmin ?
                            <li className={ `nav-item ${ isRoute && actualRoute === "admin/prayer" ? "active" : "" }` } onClick={ ( e ) => { e.preventDefault(); window.location.replace( `/admin/prayer/${ userProfile.codeEntity }` ) } }>

                                <a className="nav-link" href={ `/admin/prayer/${ userProfile.codeEntity }` }><span className="shape1"></span><span className="shape2"></span><i className="ti-time sidemenu-icon"></i><span className="sidemenu-label" style={ { color: isRoute && actualRoute === "admin/prayer" ? '#3a3a3a' : '' } } >{ t( "common.prayer" ) + " " }

                                </span></a>
                            </li> : null
                        }
                        { ( isItRole && userProfile && userProfile.profile.role.prayer ) || isItAdmin ?
                            <li className={ `nav-item ${ isRoute && actualRoute === "admin/calendar" ? "active" : "" }` } onClick={ ( e ) => { e.preventDefault(); window.location.replace( `/admin/calendar/${ userProfile.codeEntity }` ) } }>

                                <a className="nav-link" href={ `/admin/calendar/${ userProfile.codeEntity }` }><span className="shape1"></span><span className="shape2"></span><i className="ti-calendar sidemenu-icon"></i><span className="sidemenu-label" style={ { color: isRoute && actualRoute === "admin/calendar" ? '#3a3a3a' : '' } } >{ t( "landingSite.hijriCalTitle" ) }

                                    { " " } <small style={ { backgroundColor: 'red', color: 'white', padding: 5, borderRadius: 5, marginLeft: 3 } }>new</small>
                                </span></a>
                            </li> : null
                        }
                        { ( isItRole && userProfile && userProfile.profile.role.tv ) || isItAdmin ?
                            <li className={ `nav-item ${ isRoute && actualRoute === "admin/television" ? "active" : "" } show` }>
                                <a className="nav-link" href={ `/admin/television/${ userProfile.codeEntity }` }><span className="shape1"></span><span className="shape2"></span><i className="ti-layout-media-overlay-alt sidemenu-icon"></i><span className="sidemenu-label" style={ { color: isRoute && actualRoute === "admin/television" ? '#3a3a3a' : '' } } >{ t( "application.tv" ) } { " " }
                                </span></a>
                                <ul className="nav-sub">
                                    <li className="nav-sub-item">
                                        <a
                                            className={ `nav-sub-link ${ isRoute && actualRoute === "admin/television/visuals" ? "active" : "" }` }
                                            href={ `/admin/television/visuals/${ userProfile.codeEntity }` }
                                            style={ { fontSize: isHovered === 'adhan' ? "20px" : "15px" } }
                                            onMouseEnter={ () => handleMouseEnter( 'adhan' ) }
                                            onMouseLeave={ handleMouseLeave }
                                        >
                                            <i className="ti-arrow-circle-right"> { " " }</i> <small style={ { marginLeft: "5px" } }>  Visuals Control

                                            </small>
                                        </a>
                                    </li>

                                </ul>
                            </li> : null
                        }

                        { isItAdmin ?
                            <li className={ `nav-item ${ isRoute && actualRoute === "admin/settings" ? "active" : "" }` }>
                                <a className="nav-link" href={ `/admin/settings/${ userProfile.codeEntity }` }><span className="shape1"></span><span className="shape2"></span><i className="ti-settings sidemenu-icon"></i><span className="sidemenu-label" style={ { color: isRoute && actualRoute === "admin/settings" ? '#3a3a3a' : '' } } >{ t( "application.general" ) }
                                    { " " }</span></a>
                            </li>
                            : null
                        }

                        { isItAdmin ?
                            <li className={ `nav-item ${ isRoute && actualRoute === "admin/newsletter" ? "active" : "" }` }>
                                <a className="nav-link" href={ `/admin/newsletter/${ userProfile.codeEntity }` }><span className="shape1"></span><span className="shape2"></span><i className="ti-email sidemenu-icon"></i><span className="sidemenu-label" style={ { color: isRoute && actualRoute === "admin/newsletter" ? '#3a3a3a' : '' } } >{ t( "application.newsletter" ) }</span></a>
                            </li> : null
                        }
                        { isItAdmin ?
                            <li className={ `nav-item ${ isRoute && actualRoute === "admin/roles" ? "active" : "" }` }>
                                <a className="nav-link" href={ `/admin/roles/${ userProfile.codeEntity }` }><span className="shape1"></span><span className="shape2"></span><i className="ti-user sidemenu-icon"></i><span className="sidemenu-label" style={ { color: isRoute && actualRoute === "admin/roles" ? '#3a3a3a' : '' } } >{ t( "application.roles" ) }</span></a>
                            </li>
                            : null
                        }

                        { ( isItAdmin ) ?
                            <li className={ `nav-item ${ isRoute && actualRoute === "admin/donation" ? "active" : "" }` } id="mosque-admin">
                                <a className="nav-link" href={ `/admin/donation/${ userProfile.codeEntity }` }><span className="shape1"></span><span className="shape2"></span><i className="ti-money sidemenu-icon"></i><span className="sidemenu-label" style={ { color: isRoute && actualRoute === "admin/donation" ? '#3a3a3a' : '' } } >{ t( "common.donation" ) }</span></a>
                            </li> : null
                        }
                    </ul>
                </div>
            </div>
            <div className="main-header side-header sticky" style={ { top: 0 } }>
                <div className="container-fluid" >
                    <div className="main-header-left">
                        <a onClick={ ( e ) => { e.preventDefault(); onHideSideBar(); ( isMobile() || isTablet() ) ? setHiddenMobile( !hiddenMobile ) : setHiddenMobile( false ) } } className="main-header-menu-icon" id="mainSidebarToggle"><span></span></a>
                    </div>
                    <div className="main-header-center">
                        <div className="responsive-logo">
                            <a href="index.html"><img src="https://general.eu-central-1.linodeobjects.com/basics/platforms/moonode_logo.png" style={ { width: "50px" } } className="mobile-logo" alt="logo" /></a>
                            <a href="index.html"><img src="https://general.eu-central-1.linodeobjects.com/basics/platforms/moonode_logo.png" style={ { width: "50px" } } className="mobile-logo-dark" alt="logo" /></a>
                        </div>
                        <div className="input-group">
                            <div className="input-group-btn search-panel">
                                <select className="form-control select2-no-search">

                                    <option value="IT Projects">
                                        Mosques
                                    </option>

                                </select>
                            </div>
                            <input
                                className="form-control rounded-0"
                                type="text"
                                onChange={ ( e ) => onChange( e ) }
                                name="searching"
                                style={ { color: "black" } }
                                placeholder={ t( "feed.search" ) }
                                value={ formData.searching }
                                autoComplete="off"
                            />
                            <button className="btn search-btn"><i className="fe fe-search"></i></button>

                        </div>

                    </div>
                    <div className="main-header-right">
                        {/* <div className="dropdown header-search show">
                                <a className="nav-link icon header-search">
                                    <i className="fe fe-search header-icons"></i>
                                </a>
                                <div className="dropdown-menu">
                                    <div className="main-form-search p-2">
                                        <div className="input-group">
                                            <div className="input-group-btn search-panel">
                                                <select className="form-control select2-no-search">
                                                    <option label="All categories">
                                                    </option>
                                                    <option value="IT Projects">
                                                        IT Projects
                                                    </option>
                                                    <option value="Business Case">
                                                        Business Case
                                                    </option>
                                                    <option value="Microsoft Project">
                                                        Microsoft Project
                                                    </option>
                                                    <option value="Risk Management">
                                                        Risk Management
                                                    </option>
                                                    <option value="Team Building">
                                                        Team Building
                                                    </option>
                                                </select>
                                            </div>
                                            <input type="search" className="form-control" placeholder="Search for anything..." />
                                            <div className="input-group-btn search-panel">
                                                <select className="form-control select2-no-search">
                                                    <option label="All categories">
                                                    </option>
                                                    <option value="IT Projects">
                                                        IT Projects
                                                    </option>
                                                    <option value="Business Case">
                                                        Business Case
                                                    </option>
                                                    <option value="Microsoft Project">
                                                        Microsoft Project
                                                    </option>
                                                    <option value="Risk Management">
                                                        Risk Management
                                                    </option>
                                                    <option value="Team Building">
                                                        Team Building
                                                    </option>
                                                </select>
                                            </div>
                                            <button className="btn search-btn"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg></button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        <div onClick={ () => show() } className={ `dropdown main-header-notification flag-dropdown ${ activeLanguage ? "show" : "" }` }>
                            <a className="nav-link icon country-Flag">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><circle cx="256" cy="256" r="256" fill="#f0f0f0" /><g fill="#0052b4"><path d="M52.92 100.142c-20.109 26.163-35.272 56.318-44.101 89.077h133.178L52.92 100.142zM503.181 189.219c-8.829-32.758-23.993-62.913-44.101-89.076l-89.075 89.076h133.176zM8.819 322.784c8.83 32.758 23.993 62.913 44.101 89.075l89.074-89.075H8.819zM411.858 52.921c-26.163-20.109-56.317-35.272-89.076-44.102v133.177l89.076-89.075zM100.142 459.079c26.163 20.109 56.318 35.272 89.076 44.102V370.005l-89.076 89.074zM189.217 8.819c-32.758 8.83-62.913 23.993-89.075 44.101l89.075 89.075V8.819zM322.783 503.181c32.758-8.83 62.913-23.993 89.075-44.101l-89.075-89.075v133.176zM370.005 322.784l89.075 89.076c20.108-26.162 35.272-56.318 44.101-89.076H370.005z" /></g><g fill="#d80027"><path d="M509.833 222.609H289.392V2.167A258.556 258.556 0 00256 0c-11.319 0-22.461.744-33.391 2.167v220.441H2.167A258.556 258.556 0 000 256c0 11.319.744 22.461 2.167 33.391h220.441v220.442a258.35 258.35 0 0066.783 0V289.392h220.442A258.533 258.533 0 00512 256c0-11.317-.744-22.461-2.167-33.391z" /><path d="M322.783 322.784L437.019 437.02a256.636 256.636 0 0015.048-16.435l-97.802-97.802h-31.482v.001zM189.217 322.784h-.002L74.98 437.019a256.636 256.636 0 0016.435 15.048l97.802-97.804v-31.479zM189.217 189.219v-.002L74.981 74.98a256.636 256.636 0 00-15.048 16.435l97.803 97.803h31.481zM322.783 189.219L437.02 74.981a256.328 256.328 0 00-16.435-15.047l-97.802 97.803v31.482z" /></g></svg>
                            </a>
                            <div className="dropdown-menu">
                                <a onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'ar' ) } } className="dropdown-item d-flex ">
                                    <span className="avatar  mr-3 align-self-center bg-transparent"><img src={ saoudi } alt="img" /></span>
                                    <div className="d-flex">
                                        <span className="mt-2">{ t( "website.arabic" ) }</span>
                                    </div>
                                </a>
                                <a onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'en' ) } } className="dropdown-item d-flex">
                                    <span className="avatar  mr-3 align-self-center bg-transparent"><img src={ england } alt="img" /></span>
                                    <div className="d-flex">
                                        <span className="mt-2">{ t( "website.english" ) }</span>
                                    </div>
                                </a>
                                <a onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'fr' ) } } className="dropdown-item d-flex">
                                    <span className="avatar mr-3 align-self-center bg-transparent"><img src={ french } alt="img" /></span>
                                    <div className="d-flex">
                                        <span className="mt-2">{ t( "website.french" ) }</span>
                                    </div>
                                </a>
                                <a onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'ur' ) } } className="dropdown-item d-flex">
                                    <span className="avatar mr-3 align-self-center bg-transparent"><img src={ pakistan } alt="img" /></span>
                                    <div className="d-flex">
                                        <span className="mt-2">{ t( "website.urdu" ) }</span>
                                    </div>
                                </a>
                                <a onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'bn' ) } } className="dropdown-item d-flex">
                                    <span className="avatar mr-3 align-self-center bg-transparent"><img src={ bangladesh } alt="img" /></span>
                                    <div className="d-flex">
                                        <span className="mt-2">{ t( "website.bengali" ) }</span>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div onClick={ ( e ) => { e.preventDefault(); showNotification() } } className={ `dropdown main-header-notification ${ notification ? "show" : "" }` }>
                            { <TopHeaderNotification notification={ notification } /> }
                        </div>

                        <div className="dropdown main-profile-menu">
                            <a className="d-flex" href="/profile">
                                <span className="main-img-user" ><img alt="avatar" src={ user.picture } /></span>
                            </a>
                        </div>

                        <button
                            onClick={ () => onOpenSubMenu( !subMenu ) }
                            className="navbar-toggler navresponsive-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent-4"
                            aria-controls="navbarSupportedContent-4" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="fe fe-more-vertical header-icons navbar-toggler-icon"></i>
                        </button>
                    </div>
                </div>
                <div uk-dropdown="pos: bottom;mode:click;animation: uk-animation-slide-bottom-small"
                    className="dropdown-search">
                    {/* <!-- User menu --> */ }
                    { isMobile() || isTablet() ? null : <ul className="dropdown-search-list">
                        { searchLoading ?
                            <li>
                                <a href="#">
                                    <img src="assets/images/avatars/avatar-2.jpg" alt="" /> loading
                                </a>
                            </li>
                            :
                            <div style={ { backgroundColor: "#fff", width: "50%" } }>
                                { items && items.length > 0 ? items.map( ( item, i ) =>
                                    <div key={ i } style={ { margin: "5px", cursor: "pointer" } }>
                                        <a onClick={ ( e ) => { e.preventDefault(); putToAsyncStorage( item ); window.location.replace( item.url ) } }>
                                            <h6>
                                                <img src={ item.pic } style={ { width: "45px", height: "45px" } } alt="" />
                                                { item.title }</h6>
                                        </a>
                                    </div>
                                ) : null }
                            </div>
                        }
                    </ul> }
                </div>
            </div>
            {/* <!-- Mobile-header --> */ }
            <div className="mobile-main-header">
                <div className="mb-1 navbar navbar-expand-lg  nav nav-item  navbar-nav-right responsive-navbar navbar-dark  ">
                    <div className={ `navbar-collapse collapse ${ subMenu ? "show" : "" }` } id="navbarSupportedContent-4">
                        <div className="d-flex order-lg-2" style={ { marginLeft: "20%" } }>
                            <div className={ `dropdown header-search ${ searchShow ? "show" : "" }` }>
                                <a className="nav-link icon header-search" onClick={ () => onSearchShowChange( !searchShow ) }>
                                    <i className="fe fe-search header-icons"></i>
                                </a>
                                <div className="dropdown-menu">
                                    <div className="main-form-search p-2">
                                        <div className="input-group">
                                            <div className="input-group-btn search-panel">
                                                <select className="form-control select2-no-search">
                                                    <option value="IT Projects">
                                                        Mosques
                                                    </option>
                                                </select>
                                            </div>
                                            <input
                                                className="form-control rounded-0"
                                                type="text"
                                                onChange={ ( e ) => onChange( e ) }
                                                name="searching"
                                                style={ { color: "black" } }
                                                placeholder={ t( "feed.search" ) }
                                                value={ formData.searching }
                                                autoComplete="off"

                                            />
                                            <button className="btn search-btn"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg></button>

                                        </div>
                                        <ul className="dropdown-search-list">
                                            { searchLoading ?
                                                <li>
                                                    <a href="#">
                                                        <img src="assets/images/avatars/avatar-2.jpg" alt="" /> loading
                                                    </a>
                                                </li>
                                                :
                                                <div style={ { backgroundColor: "#fff", width: "80%" } }>
                                                    { items && items.length > 0 ? items.map( ( item, i ) =>
                                                        <div key={ i } style={ { margin: "5px", cursor: "pointer" } }>
                                                            <a onClick={ ( e ) => { e.preventDefault(); putToAsyncStorage( item ); window.location.replace( item.url ) } }>
                                                                <h6>
                                                                    <img src={ item.pic } style={ { width: "45px", height: "45px" } } alt="" />
                                                                    { item.title }</h6>
                                                            </a>
                                                        </div>
                                                    ) : null }
                                                </div>
                                            }
                                        </ul>
                                    </div>

                                </div>
                            </div>
                            <div onClick={ () => show() } className={ `dropdown main-header-notification flag-dropdown ${ activeLanguage ? "show" : "" }` }>
                                <a className="nav-link icon country-Flag">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><circle cx="256" cy="256" r="256" fill="#f0f0f0" /><g fill="#0052b4"><path d="M52.92 100.142c-20.109 26.163-35.272 56.318-44.101 89.077h133.178L52.92 100.142zM503.181 189.219c-8.829-32.758-23.993-62.913-44.101-89.076l-89.075 89.076h133.176zM8.819 322.784c8.83 32.758 23.993 62.913 44.101 89.075l89.074-89.075H8.819zM411.858 52.921c-26.163-20.109-56.317-35.272-89.076-44.102v133.177l89.076-89.075zM100.142 459.079c26.163 20.109 56.318 35.272 89.076 44.102V370.005l-89.076 89.074zM189.217 8.819c-32.758 8.83-62.913 23.993-89.075 44.101l89.075 89.075V8.819zM322.783 503.181c32.758-8.83 62.913-23.993 89.075-44.101l-89.075-89.075v133.176zM370.005 322.784l89.075 89.076c20.108-26.162 35.272-56.318 44.101-89.076H370.005z" /></g><g fill="#d80027"><path d="M509.833 222.609H289.392V2.167A258.556 258.556 0 00256 0c-11.319 0-22.461.744-33.391 2.167v220.441H2.167A258.556 258.556 0 000 256c0 11.319.744 22.461 2.167 33.391h220.441v220.442a258.35 258.35 0 0066.783 0V289.392h220.442A258.533 258.533 0 00512 256c0-11.317-.744-22.461-2.167-33.391z" /><path d="M322.783 322.784L437.019 437.02a256.636 256.636 0 0015.048-16.435l-97.802-97.802h-31.482v.001zM189.217 322.784h-.002L74.98 437.019a256.636 256.636 0 0016.435 15.048l97.802-97.804v-31.479zM189.217 189.219v-.002L74.981 74.98a256.636 256.636 0 00-15.048 16.435l97.803 97.803h31.481zM322.783 189.219L437.02 74.981a256.328 256.328 0 00-16.435-15.047l-97.802 97.803v31.482z" /></g></svg>
                                </a>
                                <div className="dropdown-menu">
                                    <a onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'ar' ) } } className="dropdown-item d-flex ">
                                        <span className="avatar  mr-3 align-self-center bg-transparent"><img src={ saoudi } alt="img" /></span>
                                        <div className="d-flex">
                                            <span className="mt-2">{ t( "website.arabic" ) }</span>
                                        </div>
                                    </a>
                                    <a onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'en' ) } } className="dropdown-item d-flex">
                                        <span className="avatar  mr-3 align-self-center bg-transparent"><img src={ england } alt="img" /></span>
                                        <div className="d-flex">
                                            <span className="mt-2">{ t( "website.english" ) }</span>
                                        </div>
                                    </a>
                                    <a onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'fr' ) } } className="dropdown-item d-flex">
                                        <span className="avatar mr-3 align-self-center bg-transparent"><img src={ french } alt="img" /></span>
                                        <div className="d-flex">
                                            <span className="mt-2">{ t( "website.french" ) }</span>
                                        </div>
                                    </a>
                                    <a onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'ur' ) } } className="dropdown-item d-flex">
                                        <span className="avatar mr-3 align-self-center bg-transparent"><img src={ pakistan } alt="img" /></span>
                                        <div className="d-flex">
                                            <span className="mt-2">{ t( "website.urdu" ) }</span>
                                        </div>
                                    </a>
                                    <a onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'bn' ) } } className="dropdown-item d-flex">
                                        <span className="avatar mr-3 align-self-center bg-transparent"><img src={ bangladesh } alt="img" /></span>
                                        <div className="d-flex">
                                            <span className="mt-2">{ t( "website.bengali" ) }</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div onClick={ ( e ) => { e.preventDefault(); showNotification() } } className={ `dropdown main-header-notification ${ notification ? "show" : "" }` }>
                                { <TopHeaderNotification notification={ notification } /> }
                            </div>

                            <div className="dropdown main-header-notification ">
                                <a className="d-flex" href="/profile">
                                    <span className="main-img-user" ><img alt="avatar" src={ user.picture } /></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="jumps-prevent" style={ { paddingTop: "64px" } }></div>
            { children }
        </Fragment >

    )
}

const mapStateToProps = ( state ) => ( {
    routes: state.routes
} )



export default connect( mapStateToProps, {} )( Index )
