import {
    GET_PROFILE,
    GET_USER_INFO
} from "../action/types";

const initialState = {
    profile: null,
    profiles: [],
    userInfo: null,
    loading: true,
    loadingUserInfo: true,
    error: {}
};

export default function ( state = initialState, action ) {
    const { type, payload } = action;

    switch ( type ) {
        case GET_PROFILE:
            return {
                ...state,
                profile: payload,
                loading: false
            };
        case GET_USER_INFO:
            return {
                ...state,
                userInfo: payload,
                loadingUserInfo: false
            };
        default:
            return state;
    }
}
