import React, { useState, useRef, useEffect } from 'react';
import JoditEditor from "jodit-react";

import PropTypes from 'prop-types'
import { connect } from 'react-redux'


export const SummerNote = ( { onChangeContent } ) => {


    const editor = useRef( null )
    const [ content, setContent ] = useState( '' )

    const config = {
        readonly: false // all options from https://xdsoft.net/jodit/doc/
    }

    const onChange = ( e ) => {
        console.log( e )
    }

    useEffect( () => {
        onChangeContent( content );

        return () => {
        };
    }, [ content ] );


    return (
        <JoditEditor
            ref={ editor }
            value={ content }
            config={ config }
            tabIndex={ 1 } // tabIndex of textarea
            onBlur={ newContent => setContent( newContent ) } // preferred to use only this option to update the content for performance reasons
            onChange={ newContent => { onChange( newContent ) } }
        />
    );
}

SummerNote.propTypes = {
    props: PropTypes
}

const mapStateToProps = ( state ) => ( {

} )



export default connect( mapStateToProps, {} )( SummerNote )
