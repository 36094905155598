import React, { Fragment, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app

const Images = ( { post } ) => {

    const [ isOpen, setIsOpen ] = useState( false );
    const [ photoIndex, setPhotoIndex ] = useState( 0 );

    let images = [];

    for ( let index = 0; index < post.image.length; index++ ) {
        images.unshift( post.image[ index ] );
    }
    const [ selectedIndex, setSelectedIndex ] = useState( 0 );
    return (
        <Fragment>
            <div>
                <div class="carousel slide" data-bs-ride="carousel" id="carouselExample3">
                    <ol class="carousel-indicators">
                        { images.map( ( i, index ) =>
                            <li key={ index } class={ `${ index === selectedIndex ? "active" : "" }` } data-bs-slide-to="0" data-bs-target="#carouselExample3" onClick={ ( e ) => { e.preventDefault(); setSelectedIndex( index ) } }></li>
                        ) }
                    </ol>
                    <div class="carousel-inner">
                        { images.map( ( i, index ) => <div class={ `carousel-item ${ index === selectedIndex ? "active" : "" }` } onClick={ () => setIsOpen( true ) }>
                            <img key={ index } alt="img" class="d-block w-100" src={ i } />
                        </div> ) }
                    </div>
                </div>
            </div>




            { isOpen && (
                <div style={ { position: "absolute" } }>
                    ]
                    <Lightbox
                        mainSrc={ images[ photoIndex ] }
                        nextSrc={
                            images.length > 1 ? images[ ( photoIndex + 1 ) % images.length ] : null
                        }
                        prevSrc={
                            images.length > 1
                                ? images[ ( photoIndex + images.length - 1 ) % images.length ]
                                : null
                        }
                        onCloseRequest={ () => setIsOpen( false ) }
                        onMovePrevRequest={ () =>
                            setPhotoIndex( ( photoIndex + images.length - 1 ) % images.length )
                        }
                        onMoveNextRequest={ () =>
                            setPhotoIndex( ( photoIndex + images.length + 1 ) % images.length )
                        }
                    />
                </div>

            ) }
        </Fragment>
    );
};

export default Images;
