//axios for backend
import axios from "axios";

//error handling
import { setAlert } from "./alert";

//reducer type
import {
    GET_ALL,
    ENTITY_ERROR,
    GET_GEO_LOCALISATION,
    SET_LOADING_ENTITIES
} from "./types";

// header configuration
const config = { headers: { "Content-Type": "application/json" } };

/**
 *
 * @param {*} history
 * @param {*} content
 */

export const getAllEntities = () => async dispatch => {
    try {
        dispatch( {
            type: SET_LOADING_ENTITIES,
            payload: true
        } );
        const res = await axios.get( `/api/entity/get/allEntities` );

        dispatch( {
            type: GET_ALL,
            payload: res.data
        } );

        dispatch( {
            type: SET_LOADING_ENTITIES,
            payload: false
        } );
    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};

export const getLocation = () => async dispatch => {
    try {
        if ( "geolocation" in navigator ) {
            // check if geolocation is supported/enabled on current browser
            navigator.geolocation.getCurrentPosition(
                function success ( position ) {
                    // for when getting location is a success
                    let result = {};
                    result.lat = position.coords.latitude;
                    result.lng = position.coords.longitude;
                    dispatch( {
                        type: GET_GEO_LOCALISATION,
                        payload: result
                    } );
                },
                function error ( error_message ) {
                    // for when getting location results in an error
                    console.error(
                        "An error has occured while retrievinglocation",
                        error_message
                    );
                    ipLookUp();
                }
            );
        } else {
            // geolocation is not supported
            // get your location some other way
            console.log( "geolocation is not enabled on this browser" );
            ipLookUp();
        }
    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};

export const ipLookUp = () => async dispatch => {
    try {
        const res = await axios.get( `http://ip-api.com/json` );
        console.log( "IP_API WORKING" );
        console.log( "IP_API WORKING" );
    } catch ( error ) {
        console.log( "ip-API not working" );
    }
};
