import React, { Fragment, useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { UserProfileContext } from "../../../Context.js";
import Settings from '../../../components/admin/Settings/Index';
import { getEntity, updateEntity } from "../../../action/entity";

export const Index = ( { match, getEntity, updateEntity, entity: { entityProfil, loading, csv, entity } } ) => {
    const [ saving, setSaving ] = useState( false )

    const { userProfile } = useContext( UserProfileContext );
    const [ resetDefault, setResetDefault ] = useState( false )
    useEffect( () => {
        if ( userProfile ) {
            const { codeEntity, profile } = userProfile;
            const { token } = match.params;
            if ( ( encodeURI( codeEntity ).localeCompare( token ) === 0 ) && ( profile.isRole || profile.isAdmin ) ) {
                getEntity( codeEntity );
            } else {
                alert( "🟥" )
                window.location.replace( "/dashboard" );
            }
        }

    }, [ userProfile ] );

    const [ formData, setformData ] = useState( {

        name: loading ? "" : entity.entity.name,
        website: loading ? "" : entity.entity.website,
        email: loading ? "" : entity.entity.email,
        facebook: loading ? "" : entity.entity.facebook,
        youtube: loading ? "" : entity.entity.youtube,
        phone: loading ? "" : entity.entity.phone,
        phoneImam: loading ? "" : entity.entity.phoneImam,
        phoneOther: loading ? "" : entity.entity.phoneOther,
        description: loading ? "" : entity.entity.description,
        what: loading ? "" : entity.entity.what,
        openHour: {},
        entityId: userProfile && userProfile.profile && userProfile.profile.entity ? userProfile.profile.entity : "N/A"

    } )
    const [ success, setSuccess ] = useState( {
        website: false,
        email: false,
        youtube: false,
        facebook: false,
        imam: false,
        what: false,
        other: false,
        name: false,
        description: false,
    } )

    useEffect( () => {

        setformData( {
            ...formData,
            name: loading ? "" : entity.entity.name,
            website: loading ? "" : entity.entity.website,
            email: loading ? "" : entity.entity.email,
            facebook: loading ? "" : entity.entity.facebook,
            youtube: loading ? "" : entity.entity.youtube,
            phone: loading ? "" : entity.entity.phone,
            phoneImam: loading ? "" : entity.entity.phoneImam,
            phoneOther: loading ? "" : entity.entity.phoneOther,
            description: loading ? "" : entity.entity.description,
            what: loading ? "" : entity.entity.what,
            openHour: loading ? {} : entity.entity.openHour,
            entityId: userProfile && userProfile.profile && userProfile.profile.entity ? userProfile.profile.entity : "N/A"

        } );

    }, [ loading, resetDefault ] );




    function isUrl ( url ) {
        let returnedV = false;
        try {
            if ( !( url === undefined ) && ( url !== null ) && ( url.search( /\.{2}/ ) === -1 ) ) {
                var urlRegex, urlRegex2, urlWWW;
                urlRegex = /((https|http)?:\/\/[^\s]+)/g;
                urlWWW = url.match( urlRegex );
                if ( !url.match( urlRegex ) ) {
                    urlRegex2 = /(?:(?:https?|ftp):\/\/)?[\w/\-?=%.]+\.[\w/\-?=%.]+/g;
                    urlWWW = url.match( urlRegex2 );
                    if ( urlWWW !== null ) {
                        urlWWW[ 0 ] = "http://" + urlWWW[ 0 ]
                    }
                }
                if ( urlWWW && urlWWW.length >= 1 ) {
                    returnedV = urlWWW && urlWWW.length >= 1;
                    return urlWWW && urlWWW.length >= 1
                } else {
                    return returnedV;
                }
            } else {
                return returnedV;
            }
            return returnedV
        } catch ( error ) {
            console.log( error.message );
            console.log( 'Error Url Not Found ' );
            return false;
        }

    }
    function getUrl ( url ) {

        try {
            if ( !( url === undefined ) && ( url !== null ) && ( url.search( /\.{2}/ ) === -1 ) ) {
                var urlRegex, urlRegex2, urlWWW;
                urlRegex = /((https|http)?:\/\/[^\s]+)/g;
                urlWWW = url.match( urlRegex );
                if ( !url.match( urlRegex ) ) {
                    urlRegex2 = /(?:(?:https?|ftp):\/\/)?[\w/\-?=%.]+\.[\w/\-?=%.]+/g;
                    urlWWW = url.match( urlRegex2 );
                    if ( urlWWW !== null ) {
                        urlWWW[ 0 ] = "http://" + urlWWW[ 0 ]
                    }
                }
                return urlWWW[ 0 ];
            }

        } catch ( error ) {
            console.log( error.message );
            console.log( 'Error Url Not Found ' );
            return false;
        }

    }


    function isValidEmail ( email ) {
        return /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test( email );
    }
    function isValidPhoneNumber ( phoneNumber ) {
        return /^\+?[0-9]{10,13}$/.test( phoneNumber );
    }

    function validateString ( str ) {
        return !/^.{26,}$/.test( str ) && !/\d/.test( str ) && str.length > 3;
    }

    function validateStringForDesc ( str ) {
        return /^.{51,}$/.test( str );
    }
    const onChange = ( e ) => {
        switch ( e.target.name ) {
            case 'website':
                setSuccess( { ...success, website: !isUrl( e.target.value ) } )
                break;
            case 'youtube':
                setSuccess( { ...success, youtube: !isUrl( e.target.value ) } )
                break;
            case 'facebook':
                setSuccess( { ...success, facebook: !isUrl( e.target.value ) } )
                break;
            case 'what':
                setSuccess( { ...success, what: !isUrl( e.target.value ) } )
                break;
            case 'phoneImam':
                setSuccess( { ...success, imam: !isValidPhoneNumber( e.target.value ) } )
                break;
            case 'phoneOther':
                setSuccess( { ...success, other: !isValidPhoneNumber( e.target.value ) } )
                break;
            case 'email':
                setSuccess( { ...success, email: !isValidEmail( e.target.value ) } )
                break;
            case 'name':
                setSuccess( { ...success, name: !validateString( e.target.value ) } )
                break;
            case 'description':
                setSuccess( { ...success, description: !validateStringForDesc( e.target.value ) } )
                break;
            default:
                break;
        }
        setformData( { ...formData, [ e.target.name ]: e.target.value } )
    }
    const onReset = () => {
        setResetDefault( !resetDefault )
        setSuccess( {
            website: false,
            email: false,
            youtube: false,
            facebook: false,
            imam: false,
            what: false,
            other: false,
            name: false,
            description: false,
        } )
    }

    const onSave = async () => {
        const { codeEntity } = userProfile;
        setformData( { ...formData, entityId: userProfile.profile.entity } )
        setSaving( true );
        let valid = await updateEntity( formData, codeEntity )
        setSaving( false )
    };



    return (
        <Settings onChange={ onChange } success={ success } formData={ formData } onSave={ onSave } onReset={ onReset } saving={ saving } />
    )
}

Index.propTypes = {
    props: PropTypes
}

const mapStateToProps = ( state ) => ( {
    entity: state.entity

} )



export default connect( mapStateToProps, { getEntity, updateEntity } )( Index )
