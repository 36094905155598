import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import mashaAllahClicked from '../../assets/img/mashaAllah-actif.png'
import mashaAllahShowed from '../../assets/img/mashaAllah-off.png'
import mashaAllahClickedCircle from '../../assets/img/mashaAllah-count.png'
import FeedComment from '../../containers/comments/entityComments';
import FeedMedia from '../media/FeedMedia'
import ShortComment from '../media/ShortComment'
import { formatDistanceToNow } from 'date-fns'
import { enCA } from 'date-fns/locale'
import KindPost from '../media/KindPost';
import Share from '../share/Index';
import { useTranslation } from 'react-i18next'

const ifThereIsLike = async ( post, user ) => {
    return 0 === post.likes.map( item => item.user && item.user.toString() ).indexOf( user.id );
};


export const Post = ( { feed, show, isAdmin, onChangeLike, index, user, postEntityId, picture, name, onChangeTv, tvChange, privacyChange, deletePost } ) => {

    const { t } = useTranslation();
    const [ menuDots, setmenuDots ] = useState( false )
    const [ modal, setmodal ] = useState( false )
    const [ loading, setLoading ] = useState( false )
    const [ fakelike, setFakeLike ] = useState( 0 )
    const [ sharedLink, setSharedLink ] = useState( "" )
    const [ open, setOpen ] = useState( false );
    const [ liked, setliked ] = useState( ifThereIsLike( feed, user ) )

    useEffect( () => {
        setliked( ifThereIsLike( feed, user ) )

    }, [ feed ] );


    const onLike = () => {
        setliked( !liked )
        fakelike > 0 ? setFakeLike( 0 ) : setFakeLike( 1 );
    }

    const openModal = () => {
        setmodal( !modal );
        setSharedLink( `https://www.moonode.com/shared/${ feed.postId }/${ feed._id }` )
    }

    const setIsLoading = () => {
        setLoading( true );
    }
    return (
        <div className="main-content-body  tab-pane border-top-0" id="timeline" style={ { margin: "18px", paddingBottom: "18px" } }>
            <div className="">
                <div className="main-content-body main-content-body-profile">
                    <div className="main-profile-body p-0">
                        <div className="row row-sm" >
                            <div className="col-12">
                                <div className="card mg-b-20 border" >
                                    <div className="card-header p-4">
                                        <div className="media" >
                                            <div className="media-user me-2" style={ { cursor: "pointer" } }>
                                                <div className="main-img-user avatar-md"><img alt="" className="rounded-circle" src={ picture } /></div>
                                            </div>
                                            <div className="media-body" style={ { cursor: "pointer" } }>
                                                <h6 className="mb-0 mg-t-2 ms-2"> { " " } { name }</h6><span className="text-primary ms-2">{ formatDistanceToNow( new Date( feed.date ), { locale: enCA } ) }</span> </div>
                                            { show && <div className="ms-auto">
                                                <div className="dropdown show" onClick={ () => { onLike(); onChangeLike( index, feed.postId, feed._id ) } }>
                                                    { liked ? <img src={ mashaAllahClicked } height="50px" width="50px" style={ { cursor: "pointer" } } /> : <img src={ mashaAllahShowed } height="50px" width="50px" style={ { cursor: "pointer" } } /> }
                                                </div>
                                            </div> }
                                            { isAdmin &&
                                                <div className="dropdown main-profile-menu" style={ { cursor: "pointer" } }>
                                                    <a className="d-flex" style={ { cursor: "pointer" } } onClick={ ( e ) => { e.preventDefault(); setmenuDots( !menuDots ) } }>
                                                        <span className="main-img-user" > <i class="fa fa-ellipsis-h"></i></span>
                                                    </a>
                                                    { loading ?
                                                        <div className={ `dropdown-menu show` }>
                                                            <span className="dropdown-item border-top">
                                                                <div className="spinner-grow " style={ { width: "5rem", height: "5rem", marginLeft: "40%" } } role="status">
                                                                    <span className="sr-only">Loading...</span>
                                                                </div>
                                                            </span>
                                                        </div>
                                                        :
                                                        <div className={ `dropdown-menu ${ menuDots ? "show" : "" }` }>
                                                            <a className="dropdown-item border-top" onClick={ ( e ) => { e.preventDefault(); privacyChange( postEntityId, feed._id ); setIsLoading() } }>
                                                                <i class="fa fa-user-secret" aria-hidden="true"></i> { feed.type === "private" ? t( 'common.makepublic' ) : t( 'common.makeprivate' ) }
                                                            </a>
                                                            <a className="dropdown-item" onClick={ ( e ) => { e.preventDefault(); deletePost( postEntityId, feed._id ); setIsLoading() } }>
                                                                <i class="fa fa-trash" aria-hidden="true"></i> { t( 'common.delete' ) }
                                                            </a>
                                                            { feed.isTv && <a className="dropdown-item" onClick={ ( e ) => { e.preventDefault(); tvChange( postEntityId, feed._id ); onChangeTv( index ); setIsLoading() } }>
                                                                <i class="fa fa-television" aria-hidden="true"></i> { t( 'common.removefromtv' ) }
                                                            </a> }
                                                        </div> }
                                                </div> }
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        <KindPost kindPost={ feed.kindPost } />
                                        <ShortComment comment={ feed.text } length={ feed.text.length } key={ index } />
                                        <FeedMedia data={ feed } />
                                        <br />
                                        { show && <div className="media mg-t-15 profile-footer">
                                            <div className="media-body">
                                                <p style={ { fontSize: "15px" } }>{ feed.likes.length > 0 ? ( feed.likes.length ) : null }   { ( feed.likes.length ) > 0 ? <img style={ { height: "1.5rem", paddingRight: "0.5rem" } } src={ mashaAllahClickedCircle } alt="" title="ما شاء الله" /> : null }</p>
                                            </div>

                                            <div className="ms-auto">
                                                <div className="dropdown show">
                                                    { feed.isComment && <a onClick={ ( e ) => { e.preventDefault(); setOpen( !open ) } } className="new" style={ { cursor: "pointer" } }>
                                                        { feed.comment.length > 0 ? ( feed.comment.length + " " ) : null }
                                                        <i className="far fa-comment me-3" style={ { cursor: "pointer" } }></i></a> }
                                                    <a className="new" onClick={ ( e ) => { e.preventDefault(); openModal() } } style={ { cursor: "pointer" } } ><i className="far fa-share-square" ></i></a> </div>
                                            </div>
                                        </div> }
                                    </div>
                                    { open && <FeedComment feed={ feed } open={ open } setOpen={ setOpen } postEntityId={ postEntityId } /> }
                                    { modal && <Share open={ modal } sharedLink={ sharedLink } /> }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ( state ) => ( {

} )



export default connect( mapStateToProps, {} )( Post )


