import React, { Fragment, useState, useEffect, useContext, useRef } from 'react'
import { connect } from 'react-redux'
import { UserProfileContext } from "../../../Context.js";
import Prayer from '../../../components/admin/Prayer/Index';
import { getEntity, editIqama, addPrayerFile, checkPrayerFile } from "../../../action/entity";
// import { readRemoteFile } from 'react-papaparse'
import { iqamaCalcul, calculeNextIqama } from '../../../utils/processingDate&Time';
import { useTranslation } from 'react-i18next';


export const Index = ( { match, checkPrayerFile, addPrayerFile, editIqama, getEntity, entity: { entityProfil, loading, csv, entity } } ) => {
    let data = new FormData();
    const inputEl = useRef( null );
    const { userProfile } = useContext( UserProfileContext );
    const [ files, setfiles ] = useState( "" );
    const [ isFileLoading, setIsFileLoading ] = useState( false );
    const { t } = useTranslation();
    const [ saving, setSaving ] = useState( false );
    const [ activeChange, setActiveChange ] = useState( false )

    useEffect( () => {
        if ( userProfile ) {
            const { codeEntity, profile } = userProfile;
            const { token } = match.params;
            if ( ( encodeURI( codeEntity ).localeCompare( token ) === 0 ) && ( profile.isRole || profile.isAdmin ) ) {
                getEntity( codeEntity );
            } else {
                alert( "🟥" )
                window.location.replace( "/dashboard" );
            }
        }

        setPrayerTimes( {
            codeType: "LAN-3",
            Fauto: entityProfil ? entityProfil.prayerTime.Fauto : "0",
            Dauto: entityProfil ? entityProfil.prayerTime.Dauto : "0",
            Aauto: entityProfil ? entityProfil.prayerTime.Aauto : "0",
            Mauto: entityProfil ? entityProfil.prayerTime.Mauto : "0",
            Iauto: entityProfil ? entityProfil.prayerTime.Iauto : "0",
            FautoA: entityProfil ? entityProfil.prayerTime.FautoA : "0",
            DautoA: entityProfil ? entityProfil.prayerTime.DautoA : "0",
            AautoA: entityProfil ? entityProfil.prayerTime.AautoA : "0",
            MautoA: entityProfil ? entityProfil.prayerTime.MautoA : "0",
            IautoA: entityProfil ? entityProfil.prayerTime.IautoA : "0",
            Fajr: entityProfil ? entityProfil.prayerTime.Fajr : "",
            Dhuhr: entityProfil ? entityProfil.prayerTime.Dhuhr : "",
            Asr: entityProfil ? entityProfil.prayerTime.Asr : "",
            Maghrib: entityProfil ? entityProfil.prayerTime.Maghrib : "",
            Isha: entityProfil ? entityProfil.prayerTime.Isha : "",
            iftar: entityProfil ? entityProfil.prayerTime.iftar : "",
            itikaf: entityProfil ? entityProfil.prayerTime.itikaf : "",
            Taraweeh: entityProfil ? entityProfil.prayerTime.Taraweeh : "",
            Taraweeh2: entityProfil ? entityProfil.prayerTime.Taraweeh2 : "8",
            Eid: entityProfil ? entityProfil.prayerTime.Eid : "",
            Eid2: entityProfil ? entityProfil.prayerTime.Eid2 : "",
            Eid3: entityProfil ? entityProfil.prayerTime.Eid3 : "",
            Eid4: entityProfil ? entityProfil.prayerTime.Eid4 : "",
            toggleTaraweeh: entityProfil ? entityProfil.prayerTime.toggleTaraweeh || false : false,
            toggleitikaf: entityProfil ? entityProfil.prayerTime.toggleitikaf || false : false,
            toggleIftar: entityProfil ? entityProfil.prayerTime.toggleIftar || false : false,
            toggleEid: entityProfil ? entityProfil.prayerTime.toggleEid || false : false,
            toggleEid2: entityProfil ? entityProfil.prayerTime.toggleEid2 || false : false,
            toggleEid3: entityProfil ? entityProfil.prayerTime.toggleEid3 || false : false,
            toggleEid4: entityProfil ? entityProfil.prayerTime.toggleEid4 || false : false,
            JumuaLang: entityProfil ? entityProfil.prayerTime.JumuaLang : "",
            Jumua2Lang: entityProfil ? entityProfil.prayerTime.JumuaLang : "",
            Jumua3Lang: entityProfil ? entityProfil.prayerTime.JumuaLang : "",
            Jumua4Lang: entityProfil ? entityProfil.prayerTime.JumuaLang : "",
            Jumua: entityProfil ? entityProfil.prayerTime.Jumua : "",
            Jumua2: entityProfil ? entityProfil.prayerTime.Jumua2 : "",
            toggleJumua: entityProfil ? entityProfil.prayerTime.toggleJumua || false : "",
            Jumua3: entityProfil ? entityProfil.prayerTime.Jumua3 : "",
            toggleJumua2: entityProfil ? entityProfil.prayerTime.toggleJumua2 || false : "",
            Jumua4: entityProfil ? entityProfil.prayerTime.Jumua4 : "",
            toggleJumua3: entityProfil ? entityProfil.prayerTime.toggleJumua3 || false : "",
            school: entityProfil ? entityProfil.school || "0" : "",
            methode: entityProfil ? entityProfil.methode || "2" : "",
            fDegree: entityProfil ? entityProfil.fDegree || "0" : "",
            mDegree: entityProfil ? entityProfil.mDegree || "0" : "",
            iDegree: entityProfil ? entityProfil.iDegree || "0" : "",
            widgetColor: entityProfil ? entityProfil.prayerTime.widgetColor : "",
            csv: entityProfil ? entityProfil.csv || "N/A" : "",
            isCsv: entityProfil ? entityProfil.isCsv || false : "",
            prayerTimeYear: entityProfil ? entityProfil.prayerTimeYear : [],
            specialPrayerName: "",
            specialPrayerDate: "",
            specialPrayerEndDate: "",
        }
        );
        return () => {

        }
    }, [ loading, userProfile ] );

    const [ iqamaTimes, setPrayerTimes ] = useState( {
        codeType: "LAN-3",
        Fauto: entityProfil ? entityProfil.prayerTime.Fauto : "0",
        Dauto: entityProfil ? entityProfil.prayerTime.Dauto : "0",
        Aauto: entityProfil ? entityProfil.prayerTime.Aauto : "0",
        Mauto: entityProfil ? entityProfil.prayerTime.Mauto : "0",
        Iauto: entityProfil ? entityProfil.prayerTime.Iauto : "0",
        FautoA: entityProfil ? entityProfil.prayerTime.FautoA : "0",
        DautoA: entityProfil ? entityProfil.prayerTime.DautoA : "0",
        AautoA: entityProfil ? entityProfil.prayerTime.AautoA : "0",
        MautoA: entityProfil ? entityProfil.prayerTime.MautoA : "0",
        IautoA: entityProfil ? entityProfil.prayerTime.IautoA : "0",
        Fajr: entityProfil ? entityProfil.prayerTime.Fajr : "",
        Dhuhr: entityProfil ? entityProfil.prayerTime.Dhuhr : "",
        Asr: entityProfil ? entityProfil.prayerTime.Asr : "",
        Maghrib: entityProfil ? entityProfil.prayerTime.Maghrib : "",
        Isha: entityProfil ? entityProfil.prayerTime.Isha : "",
        iftar: entityProfil ? entityProfil.prayerTime.iftar : "",
        itikaf: entityProfil ? entityProfil.prayerTime.itikaf : "",
        Taraweeh: entityProfil ? entityProfil.prayerTime.Taraweeh : "",
        Taraweeh2: entityProfil ? entityProfil.prayerTime.Taraweeh2 : "8",
        Eid: entityProfil ? entityProfil.prayerTime.Eid : "",
        Eid2: entityProfil ? entityProfil.prayerTime.Eid2 : "",
        Eid3: entityProfil ? entityProfil.prayerTime.Eid3 : "",
        Eid4: entityProfil ? entityProfil.prayerTime.Eid4 : "",
        toggleTaraweeh: entityProfil ? entityProfil.prayerTime.toggleTaraweeh || false : false,
        toggleitikaf: entityProfil ? entityProfil.prayerTime.toggleitikaf || false : false,
        toggleIftar: entityProfil ? entityProfil.prayerTime.toggleIftar || false : false,
        toggleEid: entityProfil ? entityProfil.prayerTime.toggleEid || false : false,
        toggleEid2: entityProfil ? entityProfil.prayerTime.toggleEid2 || false : false,
        toggleEid3: entityProfil ? entityProfil.prayerTime.toggleEid3 || false : false,
        toggleEid4: entityProfil ? entityProfil.prayerTime.toggleEid4 || false : false,
        JumuaLang: entityProfil ? entityProfil.prayerTime.JumuaLang : "",
        Jumua2Lang: entityProfil ? entityProfil.prayerTime.JumuaLang : "",
        Jumua3Lang: entityProfil ? entityProfil.prayerTime.JumuaLang : "",
        Jumua4Lang: entityProfil ? entityProfil.prayerTime.JumuaLang : "",
        Jumua: entityProfil ? entityProfil.prayerTime.Jumua : "",
        Jumua2: entityProfil ? entityProfil.prayerTime.Jumua2 : "",
        toggleJumua: entityProfil ? entityProfil.prayerTime.toggleJumua || false : "",
        Jumua3: entityProfil ? entityProfil.prayerTime.Jumua3 : "",
        toggleJumua2: entityProfil ? entityProfil.prayerTime.toggleJumua2 || false : "",
        Jumua4: entityProfil ? entityProfil.prayerTime.Jumua4 : "",
        toggleJumua3: entityProfil ? entityProfil.prayerTime.toggleJumua3 || false : "",
        school: entityProfil ? entityProfil.school || "0" : "",
        methode: entityProfil ? entityProfil.methode || "2" : "",
        fDegree: entityProfil ? entityProfil.fDegree || "0" : "",
        mDegree: entityProfil ? entityProfil.mDegree || "0" : "",
        iDegree: entityProfil ? entityProfil.iDegree || "0" : "",
        widgetColor: entityProfil ? entityProfil.prayerTime.widgetColor : "",
        csv: entityProfil ? entityProfil.csv || "N/A" : "",
        isCsv: entityProfil ? entityProfil.isCsv || false : "",
        prayerTimeYear: entityProfil ? entityProfil.prayerTimeYear : [],
        specialPrayerName: "",
        specialPrayerDate: "",
        specialPrayerEndDate: "",
    } );



    const onChange = e => {

        switch ( e.target.name ) {
            case "Fajr":
                setPrayerTimes( { ...iqamaTimes, Fauto: String( 0 ), [ e.target.name ]: e.target.value } );
                break;
            case "Dhuhr":
                setPrayerTimes( { ...iqamaTimes, Dauto: String( 0 ), [ e.target.name ]: e.target.value } );
                break;
            case "Asr":
                setPrayerTimes( { ...iqamaTimes, Aauto: String( 0 ), [ e.target.name ]: e.target.value } );
                break;
            case "Maghrib":
                setPrayerTimes( { ...iqamaTimes, Mauto: String( 0 ), [ e.target.name ]: e.target.value } );
                break;
            case "Isha":
                setPrayerTimes( { ...iqamaTimes, Iauto: String( 0 ), [ e.target.name ]: e.target.value } );
                break;
            case "widgetColor":
                setPrayerTimes( { ...iqamaTimes, widgetColor: e.target.value } );
                break;
            default:
                setPrayerTimes( { ...iqamaTimes, [ e.target.name ]: e.target.value } );
                break;
        }


    };

    const onChange2 = e => {
        setPrayerTimes( { ...iqamaTimes, [ e.target.name ]: e.target.value } );
    };

    const onChangeActiveCsv = e => {
        setPrayerTimes( { ...iqamaTimes, isCsv: !iqamaTimes.isCsv } );
    };

    const onJumua2 = () => {
        setPrayerTimes( { ...iqamaTimes, toggleJumua: !iqamaTimes.toggleJumua } );
        setActiveChange( true );

    };
    const onJumua3 = () => {
        setPrayerTimes( { ...iqamaTimes, toggleJumua2: !iqamaTimes.toggleJumua2 } );
        setActiveChange( true );

    };
    const onJumua4 = () => {
        setPrayerTimes( { ...iqamaTimes, toggleJumua3: !iqamaTimes.toggleJumua3 } );
        setActiveChange( true );

    };

    const handleToggleEid = ( event ) => {
        const { name, checked } = event.target;
        setPrayerTimes( ( prevState ) => ( {
            ...prevState,
            [ name ]: checked,
        } ) );
    };

    const [ auto, setAuto ] = useState( 0 )
    const [ autoA, setAutoA ] = useState( 0 )

    const onChangeAutoAdd = ( auto ) => {
        switch ( auto ) {
            case "f":
                let myFauto = iqamaTimes.Fauto === "" ? 0 : Number( iqamaTimes.Fauto );
                myFauto++;
                setPrayerTimes( { ...iqamaTimes, Fauto: String( myFauto ), Fajr: "" } );
                break;
            case "d":
                let myDauto = iqamaTimes.Dauto === "" ? 0 : Number( iqamaTimes.Dauto );
                myDauto++;
                setPrayerTimes( { ...iqamaTimes, Dauto: String( myDauto ), Dhuhr: "" } );
                break;
            case "a":
                let myAauto = iqamaTimes.Aauto === "" ? 0 : Number( iqamaTimes.Aauto );
                myAauto++;
                setPrayerTimes( { ...iqamaTimes, Aauto: String( myAauto ), Asr: "" } );
                break;
            case "m":
                let myMauto = iqamaTimes.Mauto === "" ? 0 : Number( iqamaTimes.Mauto );
                myMauto++;
                setPrayerTimes( { ...iqamaTimes, Mauto: String( myMauto ), Maghrib: "" } );
                break;
            case "i":
                let myIauto = iqamaTimes.Iauto === "" ? 0 : Number( iqamaTimes.Iauto );
                myIauto++;
                setPrayerTimes( { ...iqamaTimes, Iauto: String( myIauto ), Isha: "" } );
                break;
        }
        setAuto( 0 );

    }

    const onChangeAutoMinus = ( auto ) => {
        switch ( auto ) {
            case "f":
                let myFauto = iqamaTimes.Fauto === "" ? 0 : Number( iqamaTimes.Fauto );
                if ( myFauto > 0 ) {
                    myFauto--;
                    setPrayerTimes( { ...iqamaTimes, Fauto: String( myFauto ), Fajr: "" } );
                }
                break;
            case "d":
                let myDauto = iqamaTimes.Dauto === "" ? 0 : Number( iqamaTimes.Dauto );
                if ( myDauto > 0 ) {
                    myDauto--;
                    setPrayerTimes( { ...iqamaTimes, Dauto: String( myDauto ), Dhuhr: "" } );
                }
                break;
            case "a":
                let myAauto = iqamaTimes.Aauto === "" ? 0 : Number( iqamaTimes.Aauto );
                if ( myAauto > 0 ) {
                    myAauto--;
                    setPrayerTimes( { ...iqamaTimes, Aauto: String( myAauto ), Asr: "" } );
                }
                break;
            case "m":
                let myMauto = iqamaTimes.Mauto === "" ? 0 : Number( iqamaTimes.Mauto );
                if ( myMauto > 0 ) {
                    myMauto--;
                    setPrayerTimes( { ...iqamaTimes, Mauto: String( myMauto ), Maghrib: "" } );
                }
                break;
            case "i":
                let myIauto = iqamaTimes.Iauto === "" ? 0 : Number( iqamaTimes.Iauto );
                if ( myIauto > 0 ) {
                    myIauto--;
                    setPrayerTimes( { ...iqamaTimes, Iauto: String( myIauto ), Isha: "" } );
                }
                break;
        }
        setAuto( 0 );

    }
    const onChangeAutoAddAdhan = ( auto ) => {
        switch ( auto ) {
            case "f":
                let myFautoA = iqamaTimes.FautoA === "" ? 0 : Number( iqamaTimes.FautoA );
                myFautoA++;
                setPrayerTimes( { ...iqamaTimes, FautoA: String( myFautoA ) } );
                break;
            case "d":
                let myDautoA = iqamaTimes.DautoA === "" ? 0 : Number( iqamaTimes.DautoA );
                myDautoA++;
                setPrayerTimes( { ...iqamaTimes, DautoA: String( myDautoA ) } );
                break;
            case "a":
                let myAautoA = iqamaTimes.AautoA === "" ? 0 : Number( iqamaTimes.AautoA );
                myAautoA++;
                setPrayerTimes( { ...iqamaTimes, AautoA: String( myAautoA ) } );
                break;
            case "m":
                let myMautoA = iqamaTimes.MautoA === "" ? 0 : Number( iqamaTimes.MautoA );
                myMautoA++;
                setPrayerTimes( { ...iqamaTimes, MautoA: String( myMautoA ) } );
                break;
            case "i":
                let myIautoA = iqamaTimes.IautoA === "" ? 0 : Number( iqamaTimes.IautoA );
                myIautoA++;
                setPrayerTimes( { ...iqamaTimes, IautoA: String( myIautoA ) } );
                break;
        }
        setAutoA( 0 );

    }

    const onChangeAutoMinusAdhan = ( auto ) => {
        switch ( auto ) {
            case "f":
                let myFautoA = iqamaTimes.FautoA === "" ? 0 : Number( iqamaTimes.FautoA );
                myFautoA--;
                setPrayerTimes( { ...iqamaTimes, FautoA: String( myFautoA ) } );
                break;
            case "d":
                let myDautoA = iqamaTimes.DautoA === "" ? 0 : Number( iqamaTimes.DautoA );
                myDautoA--;
                setPrayerTimes( { ...iqamaTimes, DautoA: String( myDautoA ) } );
                break;
            case "a":
                let myAautoA = iqamaTimes.AautoA === "" ? 0 : Number( iqamaTimes.AautoA );
                myAautoA--;
                setPrayerTimes( { ...iqamaTimes, AautoA: String( myAautoA ) } );
                break;
            case "m":
                let myMautoA = iqamaTimes.MautoA === "" ? 0 : Number( iqamaTimes.MautoA );
                myMautoA--;
                setPrayerTimes( { ...iqamaTimes, MautoA: String( myMautoA ) } );
                break;
            case "i":
                let myIautoA = iqamaTimes.IautoA === "" ? 0 : Number( iqamaTimes.IautoA );
                myIautoA--;
                setPrayerTimes( { ...iqamaTimes, IautoA: String( myIautoA ) } );
                break;
        }
        setAutoA( 0 );

    }

    const replaceLine = ( selectedLine, selectedDate ) => {
        let aCsv = iqamaTimes.prayerTimeYear;
        aCsv[ selectedLine ] = selectedDate;
        // setPrayerTimes( { ...iqamaTimes, prayerTimeYear: aCsv } );
        setPrayerTimes( iqamaTimes => ( {
            ...iqamaTimes,
            prayerTimeYear: aCsv
        } ) )
    }

    const onSave = async () => {
        const { codeEntity } = userProfile;
        setSaving( true );
        let valid = await editIqama( codeEntity, iqamaTimes, t );
        if ( valid ) {
            setSaving( true );
        } else {
            setSaving( false );
        }

    };

    const onButtonClick = () => {
        inputEl.current.click();
    };
    const onSubmitFile = async e => {
        const { codeEntity } = userProfile;

        e.preventDefault();
        setIsFileLoading( true );
        var option = new Blob( [ files[ 0 ] ] );

        data.append( "file", option, files[ 0 ].name );

        let valids = await addPrayerFile( data, codeEntity, t );
        if ( valids ) {
            setfiles( "" );
            data = null;
            let x = await checkPrayerFile( codeEntity, t );

            if ( x ) {
                setTimeout( () => {
                    window.location.reload();
                }, 20000 );
            }
        }
    };


    return (

        loading ? null : <Prayer
            entity={ entity }
            onChange2={ onChange2 }
            files={ files }
            setfiles={ setfiles }
            onButtonClick={ onButtonClick }
            inputEl={ inputEl }
            handleToggleEid={ handleToggleEid }
            isFileLoading={ isFileLoading }
            onSubmitFile={ onSubmitFile }
            apiPrayerTime={ entityProfil.apiPrayerTime }
            calculeNextIqama={ calculeNextIqama }
            entityProfil={ entityProfil }
            prayerTime={ entityProfil.prayerTime }
            tomorrowIqamaTime={ entityProfil.tomorrowIqamaTime }
            tomorrowApiPrayerTime={ entityProfil.tomorrowApiPrayerTime }
            highlighted={ entityProfil.highlighted }
            iqamaTimes={ iqamaTimes }
            adhan={ entityProfil.apiPrayerTime }
            onChange={ onChange }
            onChangeActiveCsv={ onChangeActiveCsv }
            csv={ iqamaTimes.prayerTimeYear }
            replaceLine={ replaceLine }
            activeChange={ activeChange }
            iqamaCalcul={ iqamaCalcul }
            onChangeAutoAdd={ onChangeAutoAdd }
            onChangeAutoMinus={ onChangeAutoMinus }
            onChangeAutoMinusAdhan={ onChangeAutoMinusAdhan }
            onChangeAutoAddAdhan={ onChangeAutoAddAdhan }
            onJumua2={ onJumua2 }
            onJumua3={ onJumua3 }
            onJumua4={ onJumua4 }
            calculatedIqama={ entityProfil.iqamaTime }
            onSave={ onSave }
            saving={ saving }
        />

    )
}



const mapStateToProps = ( state ) => ( {
    entity: state.entity
} )


export default connect( mapStateToProps, { getEntity, editIqama, addPrayerFile, checkPrayerFile } )( Index )
