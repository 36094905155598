import React from 'react'
import moonImage from '../../../assets/img/moon.png'
import moment from 'moment-hijri'
import { useTranslation } from 'react-i18next'

export default function HijriDate () {
    const { t } = useTranslation();
    return (
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
            <div className="card custom-card" style={ { height: "100%" } }>
                <div className="card-body">
                    <div className="card-item" >
                        <div className="card-item-title mb-0 justify-content-center">
                            <img src={ moonImage } alt="moonImage" />
                        </div>
                        <div className="card-item-body justify-content-center">
                            <div className="card-item-stat">
                                <h6 className="font-weight-bold mb-0"> { t( 'website.hijriDate' ) } { " " } { moment().format( 'iD-iM-iYYYY' ) }</h6>
                                <h6 className="font-weight-bold mb-0">{ t( "website.noHijriDate" ) } { " " } { moment().format( 'D-M-YYYY' ) }</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
