import React from 'react'
import { useTranslation } from 'react-i18next';
import { formatDistanceToNow } from 'date-fns'
import { enCA } from 'date-fns/locale'
function Index ( { entityProfil } ) {
    const { t } = useTranslation();
    return (
        <div >
            <div class="card custom-card">
                <div class="card-header pb-0 border-bottom-0">
                    <label class="main-content-label mb-2 pt-1">{ t( 'entity.following' ) }</label>
                    <p class="tx-12 mb-0 text-muted">{ t( 'entity.numberoffollower' ) } { entityProfil.followers.length } </p>
                </div>
                <div class="card-body">
                    <ul class="visitor mb-0 d-block users-images list-unstyled list-unstyled-border">
                        { entityProfil.followers.map( user =>
                            <li class="media d-flex mb-3 mt-0 pt-0">
                                <img class="me-3 rounded-circle avatar avatar-md" src={ user.user.picture } alt="avatar" />
                                <div class="media-body mb-1">
                                    <div class="float-end"><small></small></div>
                                    <h5 class="media-title tx-15 mb-0">{ user.user.name } { " " } { user.user.handle }</h5>
                                    <span class="text-muted">{ formatDistanceToNow( new Date( user.date ), { locale: enCA } ) }</span>
                                </div>
                            </li>
                        ) }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Index
