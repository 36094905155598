import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Dashboard from '../../components/dashboard/index';
import { getFeed } from '../../action/entity';
import { addLike } from '../../action/post'
import { useTranslation } from 'react-i18next';

import { setRoute, disableRoute } from '../../action/routes'
import { isMobile, isTablet } from '../../utils/device';

export const Index = ( { getFeed, qa, feed: { loadingFeed, feed, ahadith, whitedays }, setRoute, disableRoute, auth: { user }, addLike } ) => {

    const { t } = useTranslation();
    const [ nbrPosts, setNumberPosts ] = useState( 1 );
    const [ theFeed, settheFeed ] = useState( feed )

    useEffect( () => {
        setRoute( 'user/dashboard' );

        getFeed( nbrPosts, feed );
        return () => {
            disableRoute()
        }

    }, [] )

    useEffect( () => {
        settheFeed( feed );
        return () => {
            settheFeed( [] );
        }
    }, [ feed ] )

    const onFeedChange = ( aFeed ) => {
        settheFeed( aFeed );
    }

    const onGetFeed = ( nbr ) => {
        setNumberPosts( nbrPosts + 10 );
        if ( nbrPosts <= 100 ) {

            getFeed( nbrPosts );
        }

    }

    return (
        <div className="main-content side-content pt-0">
            <div className="container-fluid" style={ { marginLeft: isMobile() ? " " : "70px" } } >
                <div className="inner-body justify-content-center">
                    <div className="page-header">
                        <div>
                            <h2 className="main-content-title tx-24 mg-b-5">{ t( 'map.route' ) } / { t( 'website.dashboard' ) } </h2>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">{ t( 'map.route' ) }</a></li>
                                <li className="breadcrumb-item active" aria-current="page">🏠</li>
                            </ol>
                        </div>
                    </div>
                    <Dashboard feed={ theFeed } user={ user } addLike={ addLike } onGetFeed={ onGetFeed } settheFeed={ onFeedChange }
                        whitedays={ whitedays } ahadith={ ahadith } qa={ qa } />
                </div>
            </div>
        </div>
    )
}



const mapStateToProps = state => ( {
    feed: state.feed,
    auth: state.auth,
    qa: state.qa
} );

export default connect( mapStateToProps, { getFeed, setRoute, disableRoute, addLike } )( Index );