import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'

import './mobile.min.css'
import './powered.css'
import { useTranslation } from 'react-i18next'
import saoudi from '../../../assets/flags/saoudi.png';
import england from '../../../assets/flags/england.png';
import french from '../../../assets/flags/french.png';
import pakistan from '../../../assets/flags/pakistan.png';
import bangladesh from '../../../assets/flags/bangladesh.jpeg';

const MobilePrayer = ( {
    tomorrowApiPrayerTime,
    tomorrowIqamaTime,
    flyerScreenState,
    calculeNextIqama,
    apiPrayerTime,
    iqamaTimes,
    entity,
    prayerTime,
    highlighted,
    iqamaCalcul,
    show = true,
    isText,
    text,
    color,
    children
} ) => {

    const { t, i18n } = useTranslation();
    const { f, d, a, m, i } = highlighted;
    let data = {};
    data.prayerTime = iqamaTimes;
    data.apiPrayerTime = apiPrayerTime;
    const [ activeLanguage, setActiveLanguage ] = useState( false )

    const { toggleJumua, toggleJumua2, toggleJumua3, toggleJumua4, toggleJumua5, toggleJumua6, toggleJumua7, Jumua, Jumua2, Jumua3, Jumua4, Jumua5, Jumua6, Jumua7, Jumua8 } = prayerTime;
    const [ state, setstate ] = useState( {
        Isha: false,
        Maghrib: false,
        Asr: false,
        Dhuhr: false,
        Fajr: false,
        iDiff: false,
        mDiff: false,
        aDiff: false,
        dDiff: false,
        fDiff: false,
    } )
    const onChangeLanguage = ( language ) => {
        localStorage.setItem( "savedLanguageWidget", "true" );
        i18n.changeLanguage( language )
        localStorage.setItem( "lang", language )
    }
    const showLanguageChange = () => {
        setActiveLanguage( !activeLanguage );
    }
    useEffect( () => {
        let now = format( new Date(), "HH:mm" )
        if ( calculeNextIqama( iqamaTimes, "Isha" ) ) {
            setstate( { Isha: true, mDiff: tomorrowIqamaTime.mIsDiff, aDiff: tomorrowIqamaTime.aIsDiff, dDiff: tomorrowIqamaTime.dIsDiff, fDiff: tomorrowIqamaTime.fIsDiff } );
        } else if ( calculeNextIqama( iqamaTimes, "Maghrib" ) ) {
            setstate( { Maghrib: true, aDiff: tomorrowIqamaTime.aIsDiff, dDiff: tomorrowIqamaTime.dIsDiff, fDiff: tomorrowIqamaTime.fIsDiff } );
        } else if ( calculeNextIqama( iqamaTimes, "Asr" ) ) {
            setstate( { Asr: true, dDiff: tomorrowIqamaTime.dIsDiff, fDiff: tomorrowIqamaTime.fIsDiff } );
        } else if ( calculeNextIqama( iqamaTimes, "Dhuhr" ) ) {
            setstate( { Dhuhr: true, fDiff: tomorrowIqamaTime.fIsDiff } );
        } else if ( calculeNextIqama( iqamaTimes, "Fajr" ) && now > iqamaTimes.Fajr ) {
            setstate( { Fajr: true, iDiff: tomorrowIqamaTime.iIsDiff, mDiff: tomorrowIqamaTime.mIsDiff, aDiff: tomorrowIqamaTime.aIsDiff, dDiff: tomorrowIqamaTime.dIsDiff, fDiff: tomorrowIqamaTime.fIsDiff } );
        } else if ( calculeNextIqama( iqamaTimes, "Fajr" ) ) {
            setstate( { Fajr: true, iDiff: false, mDiff: false, aDiff: false, dDiff: false, fDiff: false } );
        }
    }, [] )

    return (
        <div className="inner-body" style={ { maxWidth: "450px", backgroundColor: "transparent" } }>
            <div className=""
            >

                { flyerScreenState && show
                    &&
                    <div onClick={ () => showLanguageChange() } className={ `dropdown main-header-notification flag-dropdown ${ activeLanguage ? "show" : "" }` }>
                        <a className="nav-link icon country-Flag">
                            <span className="avatar  mr-3 align-self-center bg-transparent" ><img src={ saoudi } alt="img" style={ { borderRadius: "30px" } } /></span>

                        </a>
                        <div className="dropdown-menu">
                            <a onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'ar' ) } } className="dropdown-item d-flex ">
                                <span className="avatar  mr-3 align-self-center bg-transparent"><img src={ saoudi } alt="img" /></span>
                                <div className="d-flex">
                                    <span className="mt-2">{ t( "website.arabic" ) }</span>
                                </div>
                            </a>
                            <a onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'en' ) } } className="dropdown-item d-flex">
                                <span className="avatar  mr-3 align-self-center bg-transparent"><img src={ england } alt="img" /></span>
                                <div className="d-flex">
                                    <span className="mt-2">{ t( "website.english" ) }</span>
                                </div>
                            </a>
                            <a onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'fr' ) } } className="dropdown-item d-flex">
                                <span className="avatar mr-3 align-self-center bg-transparent"><img src={ french } alt="img" /></span>
                                <div className="d-flex">
                                    <span className="mt-2">{ t( "website.french" ) }</span>
                                </div>
                            </a>
                            <a onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'ur' ) } } className="dropdown-item d-flex">
                                <span className="avatar mr-3 align-self-center bg-transparent"><img src={ pakistan } alt="img" /></span>
                                <div className="d-flex">
                                    <span className="mt-2">{ t( "website.urdu" ) }</span>
                                </div>
                            </a>
                            <a onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'bn' ) } } className="dropdown-item d-flex">
                                <span className="avatar mr-3 align-self-center bg-transparent"><img src={ bangladesh } alt="img" /></span>
                                <div className="d-flex">
                                    <span className="mt-2">{ t( "website.bengali" ) }</span>
                                </div>
                            </a>
                        </div>
                    </div>
                }
                <div className="card custom-card">
                    <div className="container-1" >
                        <div className="box-1" >
                            <h3 style={ { color: "#000", paddingTop: "1vh" } }>
                                <marquee direction="left" width="200" bgcolor="white" style={ { width: "100%" } }>
                                    <small style={ { fontSize: "3vh" } }>{ isText ? text : entity.name }
                                        <img src={ entity.logo } style={ { width: "3vh", marginTop: "0px", marginLeft: "14px", borderRadius: "3vh" } } />
                                    </small>
                                </marquee>
                            </h3>
                        </div>
                    </div>
                    <div className="container-1">
                        <div className="box-1" id={ `prayer_card_background${ color.replace( "#", "" ) }` }>
                            <h3 style={ { color: "#000", paddingTop: "2vh", fontSize: "3vh" } }>{ t( 'common.prayer' ) }</h3>
                        </div>
                        <div className="box-2" id={ `prayer_card_background${ color.replace( "#", "" ) }` }>
                            <h3 style={ { color: "#000", paddingTop: "2vh", fontSize: "3vh" } }>{ t( 'common.adhan' ) }</h3>
                        </div>
                        { show && <div className="box-3" id={ `prayer_card_background${ color.replace( "#", "" ) }` }>
                            <h3 style={ { color: "#000", paddingTop: "2vh", fontSize: "3vh" } }>{ t( 'common.iqama' ) }</h3>
                        </div> }
                    </div>
                    <div className="container-1">
                        <div className="box-1" id={ `prayer_card_background${ color.replace( "#", "" ) }` }>
                            <h3 style={ { color: "#000", paddingTop: "2vh", fontSize: "3vh" } }>{ t( "entity.fajr" ) }</h3>
                        </div>

                        <div className="box-2" id={ `${ state.Fajr ? `prayer_card_background${ color.replace( "#", "" ) }` : state.fDiff && f ? "box-highlighted2" : "" }` } >
                            <h3 style={ { paddingTop: "0.3333vw", fontSize: "4vh", color: ( state.fDiff && f ) ? "#fff" : "#000" } }>{ state.fDiff ? tomorrowApiPrayerTime.Fajr : apiPrayerTime.Fajr }</h3>
                        </div>
                        { show && <div className="box-3" id={ `${ state.Fajr ? `prayer_card_background${ color.replace( "#", "" ) }` : state.fDiff && f ? "box-highlighted2" : "" }` } >
                            <h3 style={ { paddingTop: "0.3333vw", fontSize: "4vh", color: ( state.fDiff && f ) ? "#fff" : "#000" } }>{ state.fDiff ? iqamaCalcul( tomorrowIqamaTime.Fajr, iqamaTimes.Fauto, apiPrayerTime.Fajr ) : iqamaCalcul( iqamaTimes.Fajr, iqamaTimes.Fauto, apiPrayerTime.Fajr ) }</h3>
                        </div> }
                    </div>

                    <div className="container-1" >
                        <div className="box-1" id={ `prayer_card_background${ color.replace( "#", "" ) }` }>
                            <h3 style={ { color: "#000", paddingTop: "2vh", fontSize: "3vh" } }>{ t( "entity.sunrise" ) }</h3>
                        </div>
                        <div className="box-2" id={ `${ state.Dhuhr ? `prayer_card_background${ color.replace( "#", "" ) }` : state.dDiff && d ? "box-highlighted2" : "" }` } >
                            <h3 style={ { paddingTop: "0.3333vw", fontSize: "4vh", color: ( state.dDiff && d ) ? "#fff" : "#000" } }>{ apiPrayerTime.Sunrise }</h3>
                        </div>
                        { false && <div className="box-3" id={ `${ state.Dhuhr ? `prayer_card_background${ color.replace( "#", "" ) }` : state.dDiff && d ? "box-highlighted2" : "" }` } >
                            <h3 style={ { paddingTop: "0.3333vw", fontSize: "4vh", color: ( state.dDiff && d ) ? "#fff" : "#000" } }>{ state.dDiff ? iqamaCalcul( tomorrowIqamaTime.Dhuhr, iqamaTimes.Dauto, apiPrayerTime.Dhuhr ) : iqamaCalcul( iqamaTimes.Dhuhr, iqamaTimes.Dauto, apiPrayerTime.Dhuhr ) }</h3>
                        </div> }
                    </div>
                    <div className="container-1" >
                        <div className="box-1" id={ `prayer_card_background${ color.replace( "#", "" ) }` }>
                            <h3 style={ { color: "#000", paddingTop: "2vh", fontSize: "3vh" } }>{ t( "entity.dhuhr" ) }</h3>
                        </div>
                        <div className="box-2" id={ `${ state.Dhuhr ? `prayer_card_background${ color.replace( "#", "" ) }` : state.dDiff && d ? "box-highlighted2" : "" }` } >
                            <h3 style={ { paddingTop: "0.3333vw", fontSize: "4vh", color: ( state.dDiff && d ) ? "#fff" : "#000" } }>{ state.dDiff ? tomorrowApiPrayerTime.Dhuhr : apiPrayerTime.Dhuhr }</h3>
                        </div>
                        { show && <div className="box-3" id={ `${ state.Dhuhr ? `prayer_card_background${ color.replace( "#", "" ) }` : state.dDiff && d ? "box-highlighted2" : "" }` } >
                            <h3 style={ { paddingTop: "0.3333vw", fontSize: "4vh", color: ( state.dDiff && d ) ? "#fff" : "#000" } }>{ state.dDiff ? iqamaCalcul( tomorrowIqamaTime.Dhuhr, iqamaTimes.Dauto, apiPrayerTime.Dhuhr ) : iqamaCalcul( iqamaTimes.Dhuhr, iqamaTimes.Dauto, apiPrayerTime.Dhuhr ) }</h3>
                        </div> }
                    </div>
                    <div className="container-1" >
                        <div className="box-1" id={ `prayer_card_background${ color.replace( "#", "" ) }` }>
                            <h3 style={ { color: "#000", paddingTop: "2vh", fontSize: "3vh" } }>{ t( "entity.asr" ) }</h3>
                        </div>
                        <div className="box-2" id={ `${ state.Asr ? `prayer_card_background${ color.replace( "#", "" ) }` : state.aDiff && a ? "box-highlighted2" : "" }` } >
                            <h3 style={ { paddingTop: "0.3333vw", fontSize: "4vh", color: ( state.aDiff && a ) ? "#fff" : "#000" } }>{ state.aDiff ? tomorrowApiPrayerTime.Asr : apiPrayerTime.Asr }</h3>
                        </div>
                        { show && <div className="box-3" id={ `${ state.Asr ? `prayer_card_background${ color.replace( "#", "" ) }` : state.aDiff && a ? "box-highlighted2" : "" }` } >
                            <h3 style={ { paddingTop: "0.3333vw", fontSize: "4vh", color: ( state.aDiff && a ) ? "#fff" : "#000" } }>{ state.aDiff ? iqamaCalcul( tomorrowIqamaTime.Asr, iqamaTimes.Aauto, apiPrayerTime.Asr ) : iqamaCalcul( iqamaTimes.Asr, iqamaTimes.Aauto, apiPrayerTime.Asr ) }</h3>
                        </div> }
                    </div>
                    <div className="container-1" >
                        <div className="box-1" id={ `prayer_card_background${ color.replace( "#", "" ) }` }>
                            <h3 style={ { color: "#000", paddingTop: "2vh", fontSize: "3vh" } }>{ t( "entity.maghrib" ) }</h3>
                        </div>
                        <div className="box-2" id={ `${ state.Maghrib ? `prayer_card_background${ color.replace( "#", "" ) }` : state.mDiff && m ? "box-highlighted2" : "" }` } >
                            <h3 style={ { paddingTop: "0.3333vw", fontSize: "4vh", color: ( state.mDiff && m ) ? "#fff" : "#000" } }>{ state.mDiff ? tomorrowApiPrayerTime.Maghrib : apiPrayerTime.Maghrib }</h3>
                        </div>
                        { show && <div className="box-3" id={ `${ state.Maghrib ? `prayer_card_background${ color.replace( "#", "" ) }` : state.mDiff && m ? "box-highlighted2" : "" }` } >
                            <h3 style={ { paddingTop: "0.3333vw", fontSize: "4vh", color: ( state.mDiff && m ) ? "#fff" : "#000" } }>{ state.mDiff ? iqamaCalcul( tomorrowIqamaTime.Maghrib, iqamaTimes.Mauto, apiPrayerTime.Maghrib ) : iqamaCalcul( iqamaTimes.Maghrib, iqamaTimes.Mauto, apiPrayerTime.Maghrib ) }</h3>
                        </div> }
                    </div>
                    <div className="container-1">
                        <div className="box-1" id={ `prayer_card_background${ color.replace( "#", "" ) }` }>
                            <h3 style={ { color: "#000", paddingTop: "2vh", fontSize: "3vh" } }>{ t( "entity.isha" ) }</h3>
                        </div>
                        <div className="box-2" id={ `${ state.Isha ? `prayer_card_background${ color.replace( "#", "" ) }` : state.iDiff && i ? "box-highlighted2" : "" }` } >
                            <h3 style={ { paddingTop: "0.3333vw", fontSize: "4vh", color: ( state.iDiff && i ) ? "#fff" : "#000" } }>{ state.iDiff ? tomorrowApiPrayerTime.Isha : apiPrayerTime.Isha }</h3>
                        </div>
                        { show && <div className="box-3" id={ `${ state.Isha ? `prayer_card_background${ color.replace( "#", "" ) }` : state.iDiff && i ? "box-highlighted2" : "" }` } >
                            <h3 style={ { paddingTop: "0.3333vw", fontSize: "4vh", color: ( state.iDiff && i ) ? "#fff" : "#000" } }>{ state.iDiff ? iqamaCalcul( tomorrowIqamaTime.Isha, iqamaTimes.Iauto, apiPrayerTime.Isha ) : iqamaCalcul( iqamaTimes.Isha, iqamaTimes.Iauto, apiPrayerTime.Isha ) }</h3>
                        </div> }
                    </div>
                    { show && <div className="container-1" >
                        <div className="box-1" id={ `prayer_card_background${ color.replace( "#", "" ) }` }>
                            <h3 style={ { color: "#000", paddingTop: "2vh", fontSize: "3vh" } }>{ t( "entity.jumua" ) } </h3>
                        </div>
                        <div className="box-11">
                            <h3 style={ { color: "#000", textAlign: "center", paddingTop: "2vh", fontSize: "3vh" } }>{ Jumua }</h3>
                        </div>
                    </div> }
                    { toggleJumua ?
                        <div className="container-1" >
                            <div className="box-1" id={ `prayer_card_background${ color.replace( "#", "" ) }` }>
                                <h3 style={ { color: "#000", paddingTop: "2vh", fontSize: "3vh" } }>{ t( "entity.jumua" ) }  II</h3>
                            </div>
                            <div className="box-11">
                                <h3 style={ { color: "#000", textAlign: "center", paddingTop: "2vh", fontSize: "3vh" } }>{ Jumua2 }</h3>
                            </div>
                        </div> : null }
                    { toggleJumua2 ?
                        <div className="container-1" >
                            <div className="box-1" id={ `prayer_card_background${ color.replace( "#", "" ) }` }>
                                <h3 style={ { color: "#000", paddingTop: "2vh", fontSize: "3vh" } }>{ t( "entity.jumua" ) }  III</h3>
                            </div>
                            <div className="box-11">
                                <h3 style={ { color: "#000", textAlign: "center", paddingTop: "2vh", fontSize: "3vh" } }>{ Jumua3 }</h3>
                            </div>
                        </div> : null }
                    { toggleJumua3 ?
                        <div className="container-1" >
                            <div className="box-1" id={ `prayer_card_background${ color.replace( "#", "" ) }` }>
                                <h3 style={ { color: "#000", paddingTop: "2vh", fontSize: "3vh" } }>{ t( "entity.jumua" ) }  IV</h3>
                            </div>
                            <div className="box-11">
                                <h3 style={ { color: "#000", textAlign: "center", paddingTop: "2vh", fontSize: "3vh" } }>{ Jumua4 }</h3>
                            </div>
                        </div> : null }


                    { flyerScreenState && show
                        &&
                        <div className="container-1" style={ { marginBottom: "0px", marginTop: '-25px' } }>
                            <div className="" style={ { display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '50px', borderRadius: '10px' } }>
                                <div style={ { textAlign: "right", borderRadius: '10px', border: 'none', } }>
                                    <marquee>
                                        <small>Powered by Moonode <img src={ 'https://general.eu-central-1.linodeobjects.com/basics/platforms/moonode_logo.png' } alt="img" style={ { borderRadius: "30px", height: '20px', width: '20px' } } /></small>
                                    </marquee>
                                </div>
                                <div style={ { display: 'inline-flex', justifyContent: 'center', alignItems: 'center', border: 'none' } }>
                                    <a href="https://apps.apple.com/ca/app/moonode/id1508859104" target="_blank">
                                        <img src={ require( './store.png' ) } alt="Download Moonode on the App Store" style={ { width: '150px', height: "40px", borderRadius: '10px', objectFit: 'contain', marginRight: "10px" } } />
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=com.moonode.moonode&hl=en_CA&gl=US" target="_blank">
                                        <img src={ require( './play.png' ) } alt="Download Moonode on Google Play" style={ { width: '150px', height: "40px", borderRadius: '10px', objectFit: 'contain', marginLeft: "10px" } } />
                                    </a>
                                </div>
                                <div style={ { textAlign: "left" } }>
                                    {/* Empty div for layout alignment */ }
                                </div>
                            </div>
                        </div>
                    }
                </div>

            </div>
            <br />
            { flyerScreenState && <nav>
                <ul class="pagination">
                    <li class="page-item"><a class="page-link" href="" style={ { backgroundColor: "red", height: "36px", width: "36px" } } onClick={ ( e ) => e.preventDefault() }> </a></li>
                    <li class="page-item"><a class="page-link" href="" onClick={ ( e ) => e.preventDefault() } style={ { minWidth: "150px", height: "36px", } }>{ t( 'autoMessage.tomorrowPrayer' ) }</a></li>
                </ul>
            </nav> }
            { flyerScreenState && <nav style={ { marginTop: '-10px' } }>
                <ul class="pagination">
                    <li class="page-item"><a class="page-link" href="" style={ { backgroundColor: "#FABF0F", height: "36px", width: "36px" } }
                        id={ `prayer_card_background${ color.replace( "#", "" ) }` }
                        onClick={ ( e ) => e.preventDefault() }>  </a></li>
                    <li class="page-item"><a class="page-link" href="" onClick={ ( e ) => e.preventDefault() } style={ { minWidth: "150px", height: "36px", } }>{ t( 'autoMessage.nextPrayer' ) }</a></li>
                </ul>
            </nav> }

        </div>
    )
}


export default MobilePrayer
