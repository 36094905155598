import React from 'react'
import { connect } from 'react-redux'
import Prayer from '../../../../Prayer/Widget/Index';

export const PrayerWidget = ( {

    apiPrayerTime,
    iqamaTimes,
    calculeNextIqama,
    entity,
    entityProfil,
    prayerTime,
    tomorrowIqamaTime,
    tomorrowApiPrayerTime,
    highlighted,
    iqamaCalcul,
    calculatedIqama,
    onChange
} ) => {
    return (

        <div className="container-fluid elementToFadeInAndOut">
            <div className="inner-body">
                <div className="row row-sm" >
                    <div className="col-lg-12 col-md-12">
                        <Prayer
                            iqamaTimes={ calculatedIqama }
                            color={ iqamaTimes.widgetColor }
                            apiPrayerTime={ apiPrayerTime }
                            onChange={ onChange }
                            calculeNextIqama={ calculeNextIqama }
                            entityProfil={ entityProfil }
                            prayerTime={ prayerTime }
                            entity={ entity }
                            tomorrowIqamaTime={ tomorrowIqamaTime }
                            tomorrowApiPrayerTime={ tomorrowApiPrayerTime }
                            highlighted={ highlighted }
                            iqamaCalcul={ iqamaCalcul }
                        />
                    </div>
                </div>
            </div>

        </div>



    )
}

const mapStateToProps = ( state ) => ( {

} )

const mapDispatchToProps = {

}

export default connect( mapStateToProps, mapDispatchToProps )( PrayerWidget )
