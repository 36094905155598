import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Stack from '@mui/material/Stack';
// import Button from '@mui/material/Button';
// import Snackbar from '@mui/material/Snackbar';
// import MuiAlert from '@mui/material/Alert';

// const Alert = React.forwardRef( function Alert ( props, ref ) {
//     return <MuiAlert elevation={ 6 } ref={ ref } variant="filled" { ...props } />;
// } );
let toastId = "pxywmld";

function notify ( params ) {

    if ( !toast.isActive( toastId ) && params.length > 5 ) {

        toastId = toast.warn( params.length > 5 ? params : "🔺", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        } );

    }
}
function notifySuccess ( params ) {
    if ( !toast.isActive( toastId ) && params.length > 5 ) {
        toastId =
            toast.success( params.length > 5 ? params : "✅", {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            } );

    }
}

const AlertUi = ( { alerts } ) => {
    const [ open, setOpen ] = React.useState( false );
    let success = false;
    let myMess = [];
    if ( alerts !== null && alerts.length > 0 ) {
        switch ( alerts[ 0 ].alertType ) {

            case "register":
                myMess.unshift( alerts[ 0 ].msg );
                for ( const key in alerts[ 0 ].msg ) {
                    if ( alerts[ 0 ].msg.hasOwnProperty( key ) ) {
                        myMess.unshift( alerts[ 0 ].msg[ key ] );
                    }
                }
                break;
            case "mailConfirmation":
                myMess.unshift( alerts[ 0 ].msg );
                break;
            case "success":
                for ( const key in alerts[ 0 ].msg ) {
                    if ( alerts[ 0 ].msg.hasOwnProperty( key ) ) {
                        myMess.unshift( alerts[ 0 ].msg[ key ] );
                    }
                }
                success = true;
                break;
            default:
                break;
        }
    }

    // const handleClick = () => {
    //     setOpen( true );
    // };

    const handleClose = ( event, reason ) => {
        if ( reason === 'clickaway' ) {
            return;
        }
        setOpen( false );
    };

    return (
        <Fragment>
            { alerts !== null && alerts.length >= 1 ? (
                <Fragment key={ 1 }>
                    <div key={ 1 }>
                        { success ? (
                            <dialog>
                                { notifySuccess( myMess[ 0 ] ) }
                            </dialog>
                        ) : (
                            <dialog>
                                { notify( myMess[ 0 ] ) }
                            </dialog>
                        ) }
                    </div>
                    <ToastContainer
                        position="bottom-left"
                        autoClose={ 5000 }
                        hideProgressBar={ false }
                        newestOnTop={ false }
                        closeOnClick
                        rtl={ false }
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Fragment>
            ) : null }


        </Fragment >

    );
};

// Alert.propTypes = {
//     alerts: PropTypes.array.isRequired
// };

const mapStateToProps = state => ( {
    alerts: state.alert
} );

export default connect( mapStateToProps )( AlertUi );

//    <Alert severity="error">This is an error message!</Alert>
//    <Alert severity="warning">This is a warning message!</Alert>
//    <Alert severity="info">This is an information message!</Alert>
//    <Alert severity="success">This is a success message!</Alert>