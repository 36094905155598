import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { UserProfileContext } from "../../Context.js";

import FrontNavigation from '../../components/navigation/index';
import { getUserProfile } from '../../action/auth';
import { processingResultToArray } from '../../utils/searchItem';
import { getSearch } from '../../action/search';

export const Index = ( {
    onHideSideBar, hidden, getSearch,
    search: { result, searchLoading },
    setHiddenMobile,
    hiddenMobile,
    isAuthenticated, children, getUserProfile, user: { user }, auth: { userProfile, isEntity, isItAdmin, isItRole }
} ) => {
    useEffect( () => {
        getUserProfile( user.id );
    }, [] )

    const [ option, setOption ] = useState( {
        imams: true,
        mosques: true,
        questions: true,
        shopservices: true
    } )
    //-------------------SEARCH LOGIC--------------------// !!!: 
    const [ formData, setFormData ] = useState( {
        searching: ""
    } );
    useEffect( () => {

        getSearch( formData.searching, option );
        return () => {
            getSearch( formData, option );
        }
    }, [] )
    const [ items, setItems ] = useState( processingResultToArray( result, option, formData.searching ) );


    useEffect( () => {
        setItems( processingResultToArray( result, option, formData.searching ) )

    }, [ formData ] )

    const onChange = ( e ) => {

        setFormData( { ...formData, [ e.target.name ]: e.target.value } );
    }


    return (
        <UserProfileContext.Provider value={ { userProfile } }>

            <FrontNavigation
                onHideSideBar={ onHideSideBar }
                hidden={ hidden }
                isAuthenticated={ isAuthenticated }
                children={ children }
                userProfile={ userProfile }
                user={ user }
                setHiddenMobile={ setHiddenMobile }
                hiddenMobile={ hiddenMobile }
                isEntity={ isEntity }
                isItAdmin={ isItAdmin }
                isItRole={ isItRole }
                onChange={ onChange }
                formData={ formData }
                setFormData={ setFormData }
                searchLoading={ searchLoading }
                result={ result }
                setOption={ setOption }
                option={ option }
                items={ items }

            />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </UserProfileContext.Provider>

    )
}

const mapStateToProps = ( state ) => ( {
    auth: state.auth,
    search: state.search,

} )



export default connect( mapStateToProps, { getUserProfile, getSearch } )( Index )
