import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { getComment, addLike } from '../../action/post';
import Shared from '../../components/shared/Index';

export const Index = ( { match, getComment, addLike, post: { postComments, idComments, loading } } ) => {

    useEffect( () => {
        getComment( match.params.id, match.params.nbr );
        return () => {

        }
    }, [] )

    return (
        <Fragment>
            { idComments && <Shared postComments={ postComments } /> }
        </Fragment>
    )
}



const mapStateToProps = ( state ) => ( {
    post: state.post
} )


export default connect( mapStateToProps, { getComment, addLike } )( Index )
