import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compareAsc, format, getMonth } from 'date-fns'
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

const customStyles = {
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "transparent",
        border: "none",

    },
};

export const Index = ( { csv, theMonth, replaceLine } ) => {
    const { t } = useTranslation();
    const [ modalIsOpen, setIsOpen ] = useState( false );
    const [ selectedDate, setselectedDate ] = useState( null );
    const [ selectedLine, setselectedLine ] = useState( 0 );
    const turnOnOffModal = () => {
        setIsOpen( !modalIsOpen )
    }

    const onChange = e => {
        setselectedDate( { ...selectedDate, [ e.target.name ]: e.target.value } );
    };
    return (
        <Fragment>
            <tbody>
                { csv.map( ( month, i ) => getMonth( new Date( month.date ), 'yyyy-MM-dd' ) === theMonth &&
                    <tr >
                        <a onClick={ ( e ) => { e.preventDefault(); turnOnOffModal(); setselectedDate( month ); setselectedLine( i ) } } className="modal-effect btn btn-outline-primary btn-block" data-effect="effect-super-scaled show" data-toggle="modal" href="#modaldemo8"> <i className="fa fa-pen"></i> </a>
                        <th scope="row">{ month.date } { " " }</th>
                        <td>{ month.adhanFajr }</td>
                        <td>{ month.iqamaFajr }</td>
                        <td>{ month.adhanDhuhr }</td>
                        <td>{ month.iqamaDhuhr }</td>
                        <td>{ month.adhanAsr }</td>
                        <td>{ month.iqamaAsr }</td>
                        <td>{ month.adhanMaghrib }</td>
                        <td>{ month.iqamaMaghrib }</td>
                        <td>{ month.adhanIsha }</td>
                        <td>{ month.iqamaIsha }</td>
                        <td>{ month.Jumua1 }</td>
                        <td>{ month.Jumua2 }</td>
                        <td>{ month.shourouk }</td>
                        <td>{ month.dateHijri }</td>
                    </tr>
                ) }
            </tbody>
            <Modal
                isOpen={ modalIsOpen }
                onRequestClose={ turnOnOffModal }
                style={ customStyles }
                ariaHideApp={ false }
                contentLabel=":) ">
                <div>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content modal-content-demo" style={ { width: "500px", overflow: "scroll" } }>
                            <div  >
                                <div >
                                    <table className="table text-nowrap text-md-nowrap table-bordered mg-b-0" style={ { zoom: "75%" } }>

                                        <tbody >
                                            { selectedDate ? <tr><th> <small>{ t( "website.date" ) } </small> { selectedDate.date } <input onChange={ ( e ) => onChange( e ) } placeholder={ selectedDate.date } value={ selectedDate.date } name="date" disabled />
                                            </th></tr> : null }
                                            { selectedDate ? <tr><th style={ { backgroundColor: "#FABF0F" } }><small >A. { t( "entity.fajr" ) } </small> { selectedDate.adhanFajr } <input onChange={ ( e ) => onChange( e ) } placeholder={ selectedDate.adhanFajr } value={ selectedDate.adhanFajr } name="adhanFajr" /> </th> </tr> : null }
                                            { selectedDate ? <tr><th style={ { backgroundColor: "#fcd45c" } }><small>I. { t( "entity.fajr" ) }  </small> { selectedDate.iqamaFajr } <input onChange={ ( e ) => onChange( e ) } placeholder={ selectedDate.iqamaFajr } value={ selectedDate.iqamaFajr } name="iqamaFajr" /> </th> </tr> : null }
                                            { selectedDate ? <tr><th style={ { backgroundColor: "#FABF0F" } }><small >A. { t( "entity.dhuhr" ) }  </small>{ selectedDate.adhanDhuhr } <input onChange={ ( e ) => onChange( e ) } placeholder={ selectedDate.adhanDhuhr } value={ selectedDate.adhanDhuhr } name="adhanDhuhr" /> </th> </tr> : null }
                                            { selectedDate ? <tr><th style={ { backgroundColor: "#fcd45c" } }><small>I.  { t( "entity.dhuhr" ) } </small> { selectedDate.iqamaDhuhr } <input onChange={ ( e ) => onChange( e ) } placeholder={ selectedDate.iqamaDhuhr } value={ selectedDate.iqamaDhuhr } name="iqamaDhuhr" /> </th> </tr> : null }
                                            { selectedDate ? <tr><th style={ { backgroundColor: "#FABF0F" } }><small >A.  { t( "entity.asr" ) } </small> { selectedDate.adhanAsr } <input onChange={ ( e ) => onChange( e ) } placeholder={ selectedDate.adhanAsr } value={ selectedDate.adhanAsr } name="adhanAsr" /> </th> </tr> : null }
                                            { selectedDate ? <tr><th style={ { backgroundColor: "#fcd45c" } }><small>I.  { t( "entity.asr" ) } </small> { selectedDate.iqamaAsr } <input onChange={ ( e ) => onChange( e ) } placeholder={ selectedDate.iqamaAsr } value={ selectedDate.iqamaAsr } name="iqamaAsr" /> </th> </tr> : null }
                                            { selectedDate ? <tr><th style={ { backgroundColor: "#FABF0F" } }><small >A.  { t( "entity.maghrib" ) } </small> { selectedDate.adhanMaghrib } <input onChange={ ( e ) => onChange( e ) } placeholder={ selectedDate.adhanMaghrib } value={ selectedDate.adhanMaghrib } name="adhanMaghrib" /> </th> </tr> : null }
                                            { selectedDate ? <tr><th style={ { backgroundColor: "#fcd45c" } }><small>I.  { t( "entity.maghrib" ) } </small>{ selectedDate.iqamaMaghrib } <input onChange={ ( e ) => onChange( e ) } placeholder={ selectedDate.iqamaMaghrib } value={ selectedDate.iqamaMaghrib } name="iqamaMaghrib" /> </th> </tr> : null }
                                            { selectedDate ? <tr><th style={ { backgroundColor: "#FABF0F" } }><small >A.  { t( "entity.isha" ) } </small> { selectedDate.adhanIsha } <input onChange={ ( e ) => onChange( e ) } placeholder={ selectedDate.adhanIsha } value={ selectedDate.adhanIsha } name="adhanIsha" /> </th> </tr> : null }
                                            { selectedDate ? <tr><th style={ { backgroundColor: "#fcd45c" } }><small>I.  { t( "entity.isha" ) } </small>{ selectedDate.iqamaIsha } <input onChange={ ( e ) => onChange( e ) } placeholder={ selectedDate.iqamaIsha } value={ selectedDate.iqamaIsha } name="iqamaIsha" /> </th> </tr> : null }
                                            { selectedDate ? <tr><th><small> { t( "entity.jumua" ) } 1 </small> { selectedDate.Jumua1 } <input onChange={ ( e ) => onChange( e ) } placeholder={ selectedDate.Jumua1 } value={ selectedDate.Jumua1 } name="Jumua1" /> </th> </tr> : null }
                                            { selectedDate ? <tr><th><small> { t( "entity.jumua" ) } 2 </small>{ selectedDate.Jumua2 } <input onChange={ ( e ) => onChange( e ) } placeholder={ selectedDate.Jumua2 } value={ selectedDate.Jumua2 } name="Jumua2" /> </th> </tr> : null }
                                            { selectedDate ? <tr><th><small> { t( "entity.sunrise" ) } </small> { selectedDate.shourouk } <input onChange={ ( e ) => onChange( e ) } placeholder={ selectedDate.shourouk } value={ selectedDate.shourouk } name="shourouk" /> </th> </tr> : null }
                                            { selectedDate ? <tr><th><small>H.  { t( "website.date" ) } </small>{ selectedDate.dateHijri } <input onChange={ ( e ) => onChange( e ) } placeholder={ selectedDate.dateHijri } value={ selectedDate.dateHijri } name="dateHijri" /> </th> </tr> : null }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="modal-footer">
                                    <p style={ { color: "red" } }>{ t( "website.addPMAM" ) } </p>
                                    <button className="btn ripple btn-primary" type="button" onClick={ () => { turnOnOffModal(); replaceLine( selectedLine, selectedDate ); } } >Conserver</button>
                                    <button className="btn ripple btn-secondary" data-dismiss="modal" type="button" onClick={ () => { turnOnOffModal(); setselectedDate( null ) } }>Annuler</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </Fragment >
    )
}

Index.propTypes = {
    props: PropTypes
}

const mapStateToProps = ( state ) => ( {

} )

const mapDispatchToProps = {

}

export default connect( mapStateToProps, mapDispatchToProps )( Index )
