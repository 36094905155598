import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { getAllEntities, getLocation } from "../../action/entities";
import MapOpen from './MapOpen'
import { useTranslation } from "react-i18next";

const Index = ( {
    getAllEntities,
    getLocation,
    entities: { entities, allEntitiesloading, lat, lng, geolocatisation },
} ) => {
    const { t } = useTranslation();
    const [ myEntities, setMyEntities ] = useState( {
        index: null,
        found: false
    } );
    const [ open, setOpen ] = useState( false );
    const [ state, setState ] = useState( {
        lat: "",
        lng: "",
        isId: ""
    } );

    const [ set, setset ] = useState( null );

    useEffect( () => {
        getAllEntities();
        getLocation();
    }, [ getAllEntities, myEntities ] );

    const onToggleOpen = ( lat, lng, id ) => {
        // setOpen(!open);

        if ( open ) {
            setState( { lat: lat, lng: lng, isId: id } );
        } else {
            setState( {
                lat: "",
                lng: "",
                isId: ""
            } );
        }
    };

    const getTheLocation = () => {
        getLocation();
        if ( geolocatisation ) {
            let lat1 = geolocatisation.lat;
            let lon1 = geolocatisation.lng;
            var pi = Math.PI;
            var R = 6371; //equatorial radius
            var distances = [];
            var closest = -1;

            for ( let i = 0; i < entities.length; i++ ) {
                var lat2 = entities[ i ].entity.lat;
                var lon2 = entities[ i ].entity.lng;

                var chLat = lat2 - geolocatisation.lat;
                var chLon = lon2 - geolocatisation.lng;

                var dLat = chLat * ( pi / 180 );
                var dLon = chLon * ( pi / 180 );

                var rLat1 = lat1 * ( pi / 180 );
                var rLat2 = lat2 * ( pi / 180 );

                var a =
                    Math.sin( dLat / 2 ) * Math.sin( dLat / 2 ) +
                    Math.sin( dLon / 2 ) *
                    Math.sin( dLon / 2 ) *
                    Math.cos( rLat1 ) *
                    Math.cos( rLat2 );
                var c = 2 * Math.atan2( Math.sqrt( a ), Math.sqrt( 1 - a ) );
                var d = R * c;

                distances[ i ] = d;
                if ( closest == -1 || d < distances[ closest ] ) {
                    closest = i;
                }
            }
            setMyEntities( { ...myEntities, found: true, index: closest } );
        }
    };

    const returnToAllMosque = () => {
        setMyEntities( { ...myEntities, found: false } );
    };
    return (
        <Fragment>
            <div class="main-content pt-0">

                <div class="container" style={ { marginTop: "-40px" } }>
                    <div class="inner-body">
                        <div class="page-header">
                            <div>
                                <h2 class="main-content-title tx-24 mg-b-5">Mosques</h2>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Maps</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Mosques</li>
                                </ol>
                            </div>
                        </div>

                        <div class="row row-sm" >
                            <div class="col-md-8 col-lg-9">
                                <div class="row row-sm" >
                                    { myEntities.found ? <div class="col-md-6 col-lg-6 col-xl-4 col-sm-6"
                                        style={ { cursor: "pointer" } }
                                        onClick={ ( e ) => { e.preventDefault(); window.open( `https://www.google.com/maps/dir/?api=1&origin=${ geolocatisation.lat },${ geolocatisation.lng }&destination=${ entities[ myEntities.index ].entity.lat },${ entities[ myEntities.index ].entity.lng }` ) } }

                                    >
                                        <div class="card custom-card">
                                            <div class="p-0 ht-100p">
                                                <div class="product-grid">
                                                    <div class="product-content">
                                                        <h3 class="title"><a href="#">{ entities[ myEntities.index ].entity.name }</a></h3>
                                                        <div class="price"><span class="text-danger">{ entities[ myEntities.index ].entity.phone } ({ entities[ myEntities.index ].entity.sublocality })</span></div>
                                                        <ul class="rating">
                                                            <li class="fas fa-star"></li>
                                                            <li class="fas fa-star"></li>
                                                            <li class="fas fa-star"></li>
                                                            <li class="fas fa-star"></li>
                                                            <li class="fas fa-star"></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null }


                                </div>
                                {/* <nav>
                                    <ul class="pagination justify-content-end">
                                        <li class="page-item disabled"><a class="page-link" href="#">Prev</a></li>
                                        <li class="page-item"><a class="page-link" href="#">Next</a></li>
                                    </ul>
                                </nav> */}
                                { entities.length > 0 && <MapOpen
                                    entities={ entities }
                                    myEntities={ myEntities }
                                /> }
                            </div>
                            <div class="col-md-4 col-lg-3 col-xl-3">
                                <div class="card custom-card" style={ { visibility: "hidden" } }>
                                    <div class="card-body">
                                        <div class="row row-sm" >
                                            <div class="col-sm-12">
                                                <div class="input-group">
                                                    <div class="form-group">
                                                        <label class="form-label">{ t( 'map.permission' ) }</label>
                                                    </div>
                                                    <span class="">
                                                        <button type="button" class="btn btn-primary btn-lg btn-block">{ t( "map.closest" ) }</button>

                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row row-sm" >
                                    <div class="col-md-12 col-lg-12">
                                        <div class="card custom-card">
                                            <div class="card-header custom-card-header">
                                                <h6 class="main-content-label mb-3" style={ { color: "#fff" } }></h6>
                                            </div>
                                            <div class="card-body">
                                                <label class="form-label">{ t( 'map.permission' ) }</label>

                                                <a class="btn ripple btn-primary btn-block" style={ { color: "#fff" } } onClick={ ( e ) => { e.preventDefault(); getTheLocation() } }>{ t( "map.closest" ) }</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    );
};

Index.propTypes = {};

const mapStateToProps = state => ( {
    entities: state.entities,
    entity: state.entity,
    auth: state.auth
} );

export default withRouter( connect( mapStateToProps, { getAllEntities, getLocation } )( Index ) );
