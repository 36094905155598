import React, { Fragment, useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { UserProfileContext } from "../../../Context.js";
import Television from '../../../components/admin/Television/Index';
import { getEntity, editTv } from "../../../action/entity";
import { useTranslation } from 'react-i18next';

export const Index = ( { match, getEntity, editTv, entity: { entityProfil, loading, csv, entity } } ) => {
    const { t } = useTranslation();
    const { userProfile } = useContext( UserProfileContext );
    useEffect( () => {
        if ( userProfile ) {
            const { codeEntity, profile } = userProfile;
            const { token } = match.params;
            if ( ( encodeURI( codeEntity ).localeCompare( token ) === 0 ) && ( profile.isRole || profile.isAdmin ) ) {
                getEntity( codeEntity );
            } else {
                alert( "🟥" )
                window.location.replace( "/dashboard" );
            }
        }

    }, [ userProfile ] );


    useEffect( () => {
        setTvSettings( {
            activated: entityProfil ? entityProfil.tvSettings.activated : false,
            interval: entityProfil ? entityProfil.tvSettings.interval : "30",
            color: entityProfil ? entityProfil.tvSettings.color : "",
            langs: entityProfil ? entityProfil.tvSettings.langs : "5",
            fontWeight: entityProfil ? entityProfil.tvSettings.fontWeight : "700",
            isTimeConvention: entityProfil ? entityProfil.tvSettings.isTimeConvention : false,
            hijriPreference: entityProfil ? entityProfil.hijriPreference : "0",
            a: entityProfil ? entityProfil.highlighted.a : true,
            d: entityProfil ? entityProfil.highlighted.d : true,
            f: entityProfil ? entityProfil.highlighted.f : true,
            i: entityProfil ? entityProfil.highlighted.i : true,
            m: entityProfil ? entityProfil.highlighted.m : true,
            isActivated: entityProfil ? entityProfil.announcement.isActivated : false,
            message: entityProfil ? entityProfil.announcement.message : "بسم الله الرحمن الرحيم الحمد لله رب العالمين، والصلاة والسلام على سيدنا محمد وعلى آله وصحبه أجمعين",
            direction: entityProfil ? entityProfil.announcement.direction : "rtl",

        }
        );





    }, [ loading ] );

    const [ saving, setSaving ] = useState( false )

    const [ tvSettings, setTvSettings ] = useState( {
        activated: entityProfil ? entityProfil.tvSettings.activated : false,
        interval: entityProfil ? entityProfil.tvSettings.interval : "30",
        color: entityProfil ? entityProfil.tvSettings.color : "",
        langs: entityProfil ? entityProfil.tvSettings.langs : "5",
        fontWeight: entityProfil ? entityProfil.tvSettings.fontWeight : "700",
        isTimeConvention: entityProfil ? entityProfil.tvSettings.isTimeConvention : false,
        hijriPreference: entityProfil ? entityProfil.hijriPreference : "0",
        a: entityProfil ? entityProfil.highlighted.a : true,
        d: entityProfil ? entityProfil.highlighted.d : true,
        f: entityProfil ? entityProfil.highlighted.f : true,
        i: entityProfil ? entityProfil.highlighted.i : true,
        m: entityProfil ? entityProfil.highlighted.m : true,
        isActivated: entityProfil ? entityProfil.announcement.isActivated : false,
        message: entityProfil ? entityProfil.announcement.message : "بسم الله الرحمن الرحيم الحمد لله رب العالمين، والصلاة والسلام على سيدنا محمد وعلى آله وصحبه أجمعين",
        direction: entityProfil ? entityProfil.announcement.direction : "rtl",
    } );



    const onSave = () => {
        const { codeEntity } = userProfile;
        setSaving( true );
        let saving = editTv( codeEntity, tvSettings, t );
        if ( saving ) {
            setSaving( false );
        }
    };
    const onChange = e => {
        setTvSettings( { ...tvSettings, [ e.target.name ]: e.target.value } );
    };


    const onChange2 = ( e ) => {
        switch ( e ) {
            case "f":
                setTvSettings( { ...tvSettings, f: !tvSettings.f } );

                break;
            case "d":
                setTvSettings( { ...tvSettings, d: !tvSettings.d } );

                break;
            case "a":
                setTvSettings( { ...tvSettings, a: !tvSettings.a } );

                break;
            case "m":
                setTvSettings( { ...tvSettings, m: !tvSettings.m } );

                break;
            case "i":
                setTvSettings( { ...tvSettings, i: !tvSettings.i } );
                break;
        }
    };
    return (
        <Television
            onChange={ onChange }
            tvSettings={ tvSettings }
            onChange2={ onChange2 }
            saving={ saving }
            entity={ entity }
            onSave={ onSave }
            onChangeAnnouncement={ onChange }

        />
    )
}

Index.propTypes = {
    props: PropTypes
}

const mapStateToProps = ( state ) => ( {
    entity: state.entity
} )


export default connect( mapStateToProps, { getEntity, editTv } )( Index )
