import React from 'react'
import MyMapComponent from "../../map/Index";
import { useTranslation } from 'react-i18next';
function Index ( { entity, entityProfil } ) {
    const { t } = useTranslation();
    return (
        <div>
            <div class="col-lg-12 col-md-12">
                <div class="card custom-card main-content-body-profile">
                    <div class="tab-content">
                        <div class="main-content-body tab-pane p-4 border-top-0 active" id="about">
                            <div class="card-body p-0 border p-0 rounded-10">
                                <div class="p-4">
                                    <h4 class="tx-15 text-uppercase mb-3"> { t( 'common.about' ) } { entity.entity.name } </h4>
                                    <p class="m-b-5">{ entity.entity.description } </p>
                                    <div class="m-t-30">
                                        <div class=" p-t-10">
                                            <h5 class="text-primary m-b-5 tx-14">{ t( 'common.address' ) }</h5>
                                            <p><b>{ entity.entity.address } </b></p>
                                            <p class=""> { entity.entity.address } { entity.entity.city } | { entity.entity.postalCode } ({ entity.entity.sublocality }) </p>
                                        </div>

                                        <div class="">
                                            <h5 class="text-primary m-b-5 tx-14">{ t( 'entity.methodAdhan' ) }</h5>
                                            <p class="">{ entityProfil.methode === "2" ? "shafii" : "" }</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="border-top"></div>
                                <div class="p-4">
                                    <label class="main-content-label tx-13 mg-b-20">{ t( 'entity.contact' ) }</label>
                                    <div class="d-sm-flex">
                                        <div class="mg-sm-r-20 mg-b-10">
                                            <div class="main-profile-contact-list">
                                                <div class="media">
                                                    <div class="media-icon bg-primary-transparent text-primary"> <i class="icon ion-md-phone-portrait"></i> </div>
                                                    <div class="media-body"> <span>Mobile</span>
                                                        <div> { entity.entity.phone }  </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mg-sm-r-20 mg-b-10">
                                            <div class="main-profile-contact-list">
                                                <div class="media">
                                                    <div class="media-icon bg-success-transparent text-success"> <i class="icon ion-logo-whatsapp"></i> </div>
                                                    <div class="media-body"> <span>whatsapp</span>
                                                        <a href={ entity.entity.what }> { entity.entity.what } </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="border-top"></div>
                                <div class="p-3 p-sm-4">
                                    <label class="main-content-label tx-13 mg-b-20">{ t( 'entity.social' ) }</label>
                                    <div class="d-xl-flex">

                                        <div class="mg-md-r-20 mg-b-10">
                                            <div class="main-profile-social-list">
                                                <div class="media">
                                                    <div class="media-icon bg-primary-transparent text-primary">
                                                        <i class="fa fa-youtube-square" aria-hidden="true"></i>


                                                    </div>
                                                    <div class="media-body"> <span>Youtube</span>  { entity.entity.youtube !== "N/A" ?
                                                        <a href={ entity.entity.youtube } target="_blank" className="social-item bg-dribbble">
                                                            { entity.entity.name }
                                                        </a>
                                                        : <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                                    }</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mg-md-r-20 mg-b-10">
                                            <div class="main-profile-social-list">

                                                <div class="media">
                                                    <div class="media-icon bg-primary-transparent text-primary"> <i class="fa fa-facebook"></i> </div>

                                                    <div class="media-body"> <span>Facebook</span>
                                                        { entity.entity.facebook !== "N/A" ?
                                                            <a href={ entity.entity.facebook } target="_blank" className="social-item bg-facebook">

                                                                { entity.entity.name }
                                                            </a>
                                                            : <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="p-3 p-sm-4">
                                    <label class="main-content-label tx-13 mg-b-20">{ t( 'entity.map' ) }</label>
                                    <div class="d-xl-flex">
                                        <div style={ { width: "100%", padding: "5vh", backgroundColor: "#fff" } }>
                                            <MyMapComponent
                                                isMarkerShown
                                                googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                                                // loadingElement={<div style={{ height: `120%` }} />}
                                                // containerElement={<div style={{ height: `400px` }} />}
                                                // mapElement={<div style={{ height: `120%` }} />}

                                                isOpen={ false }
                                                //   onToggleOpen={this.onToggleOpen}
                                                //   isId={this.state.isId}
                                                //   shops={entity.shops}
                                                entity={ entity }
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>



        </div>
    )
}

export default Index
