import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { emailUs } from '../../../action/auth';

export const Index = ( { emailUs, user } ) => {
    const { t } = useTranslation();
    const [ formData, setformData ] = useState( {
        name: user.name,
        subject: "",
        email: user.email,
        message: "",
    } )

    const onChange = ( e ) => {
        setformData( { ...formData, [ e.target.name ]: e.target.value } )
    }

    return (
        <div>
            <div className="main-content-body tab-pane p-4 border-top-0 active" id="edit">
                <div className="card-body border">
                    <div className="mb-4 main-content-label">{ t( "website.contactUs" ) }</div>
                    <form className="form-horizontal">
                        <div className="form-group ">
                            <div className="row row-sm" >
                                <div className="col-md-3">
                                    <label className="form-label">{ t( "application.subjectnewsletter" ) }</label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder={ t( "application.subjectnewsletter" ) }
                                        name="subject"
                                        value={ formData.subject }
                                        onChange={ ( e ) => onChange( e ) }
                                        autoComplete="name"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group ">
                            <div className="row row-sm" >
                                <div className="col-md-3">
                                    <label className="form-label">{ t( "application.contentnewsletter" ) }</label>
                                </div>
                                <div className="col-md-9">
                                    <textarea
                                        className="form-control"
                                        placeholder={ t( "application.contentnewsletter" ) }
                                        value={ formData.message }
                                        onChange={ ( e ) => onChange( e ) }
                                        type="text"
                                        name="message"
                                        fullWidth
                                        multiline
                                        rows={ 10 }
                                        rowsMax={ 4 }
                                    />
                                </div>
                                <div className="col-md-9" style={ { float: "right" } }>
                                    <button type="button" className="btn btn-primary btn-sm" onClick={ () => emailUs( formData ) }>{ t( "common.save" ) }</button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ( state ) => ( {

} )


export default connect( mapStateToProps, { emailUs } )( Index )
