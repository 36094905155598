import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { MoonodeFont } from '../../../../utils/Base64Logos'
import { getPictureFromInternet } from '../../../../action/profile'
// import FileBase from 'react-file-base64';
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const myFond = MoonodeFont()
window.pdfMake.vfs[ "SpaceGrotesk.ttf" ] = myFond

export const MyDocument = ( {
    entityLogoBase64,
    chosenImage,
    body,
    name,
    choosenMonthLong,
    choosenYear,
    onChangeYear,
    onChangeMonth,
    onChangeMasjidName,
    islamicMonthsLong,
    islamicMonthsShort,
    refresh,
    localLoading
} ) => {

    useEffect( () => {
        createPDF( name, chosenImage )
    }, [ onChangeMasjidName, islamicMonthsLong, chosenImage, refresh ] )


    let completeDate = choosenMonthLong + " " + choosenYear + " ";
    const { t } = useTranslation();
    const [ pdfData, setPdfData ] = useState( null );

    const createPDF = ( masjidName, chosenImageProp ) => {
        pdfMake.fonts = {
            Roboto: {
                normal: 'SpaceGrotesk.ttf',
                bold: 'SpaceGrotesk.ttf',
                italics: 'SpaceGrotesk.ttf',
                bolditalics: 'SpaceGrotesk.ttf'
            },
            SpaceGrotesk: {
                normal: 'SpaceGrotesk.ttf',
                bold: 'SpaceGrotesk.ttf',
                italics: 'SpaceGrotesk.ttf',
                bolditalics: 'SpaceGrotesk.ttf'
            }
        }
        var docDefinition = {
            background: {
                image: chosenImageProp
            },

            content: [
                {
                    columns: [
                        {
                            // Reduced left margin to move the image a bit to the right
                            width: '45%', // Adjust this value as needed
                            text: ''
                        },
                        {
                            width: 'auto',
                            image: entityLogoBase64,
                            fit: [ 120, 120 ],
                            alignment: 'center',
                            margin: [ -10, 10, 0, 0 ] // Slight downward adjustment
                        },
                        {
                            width: '*',
                            text: ''
                        }
                    ],
                    columnGap: 10
                },

                {
                    table: {
                        body: [
                            [ { text: masjidName, style: 'header', border: [ false, false, false, false ] } ]
                        ]
                    },
                    layout: 'noBorders', // This will remove the borders from the table
                    margin: [ 0, -20, 0, 0 ], // Margin to move the table (and thus the text) right and a bit down
                },
                {

                    text: completeDate,
                    style: 'monthTitle',
                },
                {
                    layout: 'lightHorizontalLines',
                    style: 'body',
                    table: {
                        headerRows: 1,
                        widths: [ 15, 20, '*', '*', '*', '*', '*' ],
                        body,
                    },
                    margin: [ 30, 130, 30, 130 ] // set top margin to 10
                }
            ],
            styles: {
                header: {
                    fontSize: 15,
                    bold: false,
                    color: 'black',
                    alignment: 'center',
                    fillColor: 'white',
                    margin: [ 0, 5, 0, 0 ],
                    borderRadius: [ 10, 10, 10, 10 ],

                },
                body: {
                    fontSize: 7,
                    bold: true,
                    margin: [ 40, 150, 50, 150 ]
                },
                monthTitle: {
                    fontSize: 20,
                    bold: false,
                    margin: [ 210, 50, 40, -100 ]
                },
            }
        };

        // Create a PDF document
        var pdfDoc = pdfMake.createPdf( docDefinition );

        // Get the PDF data and set it in the state
        pdfDoc.getBlob( ( pdfData ) => {
            setPdfData( pdfData );
        } );
    };

    const downloadPDF = () => {
        if ( pdfData ) {
            // Download the PDF file
            const downloadLink = document.createElement( 'a' );
            downloadLink.href = window.URL.createObjectURL( pdfData );
            downloadLink.download = `Moonode-Hijri-${ name.slice( 0, 70 ) }.pdf`;
            downloadLink.click();
        }
    };

    return (
        <div className="example">
            <div className="p-3 bg-light mb-3">
                <nav className="nav main-nav flex-column flex-md-row justify-content-center">
                    {/* <a onClick={ ( e ) => { e.preventDefault(); } } className={ `nav-link ${ "" }` } href="#">     { t( 'landingSite.hijriLogo' ) } { 'optional' } </a> */ }
                </nav>
                <br />
                <br />
                {/* <FileBase
                    type="file"
                    multiple={ false }
                    onDone={ ( { base64 } ) => onImageLoaded( base64 ) }
                /> */}
                {/* <div className="form-group">
                    <div className="alert alert-warning" role="alert">
                        <strong>{ t( 'autoMessage.warning' ) }!</strong>{ " " } { t( 'landingSite.hijriWarning' ) }
                    </div>
                </div> */}
                <div style={ { display: 'flex', justifyContent: 'center', alignItems: 'center' } }>
                    { entityLogoBase64 && <img src={ entityLogoBase64 } alt="My Image" /> }
                </div>
                <br />
                <br />
                <nav className="nav main-nav flex-column flex-md-row justify-content-center">

                </nav>
                <div className="card-body">
                    <div className="form-group">
                        <div>
                            <h6 className="main-content-label mb-1">{ t( 'landingSite.hijriCalTitle' ) } { " " }{ t( 'common.name' ) }</h6>
                        </div>
                        <input type="text" onChange={ ( e ) => onChangeMasjidName( e ) } name="name" className="form-control select-lg select2" value={ name } />
                        { name.length > 20 && <small style={ { color: "red" } }>{ t( 'autoMessage.notValid' ) }</small> }
                    </div>

                    <div>
                        <h6 className="main-content-label mb-1">{ t( 'landingSite.hijriYear' ) }</h6>
                    </div>
                    <div className="form-group">
                        <select
                            className="form-control select-lg select2"
                            name="year"
                            onChange={ ( e ) => onChangeYear( e ) }
                        >
                            <option value={ '' }> { " " }  </option>
                            <option value={ '1444' }> { " " } 1444 </option>
                            <option value={ '1445' }> { " " } 1445 </option>
                            <option value={ '1446' }> { " " } 1446 </option>
                            <option value={ '1447' }> { " " } 1447 </option>
                            <option value={ '1448' }> { " " } 1448 </option>
                            <option value={ '1449' }> { " " } 1449 </option>
                            <option value={ '1450' }> { " " } 1450 </option>
                            <option value={ '1451' }> { " " } 1451 </option>
                            <option value={ '1452' }> { " " } 1452 </option>
                            <option value={ '1453' }> { " " } 1453 </option>
                        </select>
                    </div>
                    { choosenYear && <div>
                        <h6 className="main-content-label mb-1">{ t( 'landingSite.hijriMonth' ) }</h6>
                    </div> }


                    { choosenYear && <div className="form-group">
                        <select
                            className="form-control select-lg select2"
                            name="month"
                            onChange={ ( e ) => onChangeMonth( e ) }
                        >
                            <option value=""> </option>

                            { islamicMonthsLong.map( ( month, index ) =>
                                <option value={ index }> { month } { " " } { "( " }{ islamicMonthsShort[ index ] }  { " )" }</option>
                            ) }
                        </select>
                    </div> }
                    {/* <div style={ { float: "left", marginLeft: "80%" } }>
                        { choosenMonthLong && <button className="btn btn-primary btn-lg btn-block" style={ { marginLeft: "20px", marginTop: '-4px' } } onClick={ () => downloadPDF() }> { t( 'landingSite.download' ) } </button> }
                    </div> */}
                    { localLoading ? <div className="spinner-grow" style={ { width: "5rem", height: "5rem" } } role="status">
                        <span className="sr-only">Loading...</span>
                    </div> : <>

                        { choosenYear && <div style={ { float: "left", marginLeft: "80%" } }>
                            { choosenMonthLong && <button className="btn btn-primary btn-lg btn-block" style={ { marginLeft: "20px", marginTop: '-4px' } } onClick={ () => downloadPDF() }> { t( 'landingSite.download' ) } </button> }
                        </div> }
                    </> }
                </div>

            </div>

        </div>
    )
}

MyDocument.propTypes = {
    props: PropTypes
}

const mapStateToProps = ( state ) => ( {

} )

const mapDispatchToProps = {

}

export default connect( mapStateToProps, { getPictureFromInternet } )( MyDocument )
