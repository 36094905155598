import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'


export const CovidRole = ( { onChange, iqamaTimes } ) => {
    const { t } = useTranslation()
    return (

        <div className="container-fluid elementToFadeInAndOut">
            <div className="inner-body">
                <div className="row row-sm" >
                    <div className="col-lg-12 col-md-12">
                        <div className="card custom-card">
                            <div className="form-group">
                                <div class="alert alert-warning" role="alert">
                                    <strong>{ t( 'autoMessage.warning' ) }!</strong> { t( 'autoMessage.adhanWarning' ) }
                                </div>
                            </div>
                            <div className="card-body">
                                <div>
                                    <h6 className="main-content-label mb-1">{ t( "common.adhan" ) }</h6>

                                </div>
                                <div className="form-group">
                                    <p className="text-muted card-sub-title">{ t( "entity.fajr" ) }</p>
                                    <input
                                        onChange={ e => onChange( e ) }
                                        name="fDegree" label="0" value={ iqamaTimes.fDegree }
                                        type="text" className="form-control input-lg" placeholder="Fajr" />
                                </div>
                                <div className="form-group">
                                    <p className="text-muted card-sub-title">{ t( "entity.maghrib" ) }</p>
                                    <input
                                        onChange={ e => onChange( e ) }
                                        name="mDegree" label="0" value={ iqamaTimes.mDegree }
                                        type="text" className="form-control input-lg" placeholder="Maghrib" />
                                </div>
                                <div className="form-group">
                                    <p className="text-muted card-sub-title">{ t( "entity.isha" ) }</p>

                                    <input
                                        onChange={ e => onChange( e ) }
                                        name="iDegree" label="0" value={ iqamaTimes.iDegree }
                                        type="text" className="form-control input-lg" placeholder="Isha" />
                                </div>

                            </div>
                            <div className="card-body">
                                <div>
                                    <h6 className="main-content-label mb-1"> { t( "application.chooseschool" ) } </h6>
                                    <p className="text-muted card-sub-title"></p>
                                </div>
                                <div className="form-group">
                                    <select className="form-control select-lg select2"
                                        onChange={ e => onChange( e ) }
                                        id="school"
                                        name="school"
                                        value={ iqamaTimes.school }>
                                        <option value="0">Shafi'i</option>
                                        <option value="1">Hanafi</option>
                                    </select>
                                </div>
                            </div>
                            <div className="card-body">
                                <div>
                                    <h6 className="main-content-label mb-1">{ t( "common.choose" ) }</h6>
                                    <p className="text-muted card-sub-title"> { t( "application.adhaninfo" ) }</p>
                                </div>

                                <div className="form-group">
                                    <select

                                        className="form-control select-lg select2"
                                        onChange={ e => onChange( e ) }
                                        value={ iqamaTimes.methode }
                                        id="method"
                                        name="methode"
                                    >
                                        <option value=""></option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>



    )
}

const mapStateToProps = ( state ) => ( {

} )

const mapDispatchToProps = {

}

export default connect( mapStateToProps, mapDispatchToProps )( CovidRole )
