import React from 'react'
import { Card, Container, Row, Col } from "react-bootstrap"
import './welcome.css';
export default function userWelcome ( { user } ) {
    return (
        <div class="row row-sm  mt-lg-4" style={ { marginTop: "15px" } }>
            <div class="col-sm-12 col-lg-12 col-xl-12" >
                <div class="card bg-primary custom-card card-box" style={ { borderRadius: "30px" } }>
                    <div class="card-body p-4" style={ { borderRadius: "30px" } } id="gradianMN">
                        <div class="row align-items-center">
                            <div class="offset-lg-4 offset-sm-6 col-lg-8 col-sm-6 col-12 img-bg ">
                                <h4 class="d-flex  mb-3">
                                    <span class="font-weight-bold text-white "> ! { user.name } { " " } { user.handle } السلام عليكم </span>
                                </h4>
                                <p class="tx-white-10 mb-4">
                                    إِنَّمَا الْمُؤْمِنُونَ إِخْوَةٌ فَأَصْلِحُوا بَيْنَ أَخَوَيْكُمْ ۚ وَاتَّقُوا اللَّهَ لَعَلَّكُمْ تُرْحَمُونَ  </p>
                            </div>
                            <img src={ user.picture } alt="user-img" style={ { width: "20vh", height: "20vh", borderRadius: "100%" } } />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
