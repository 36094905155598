import React, { Fragment, useEffect } from "react";

import { Router, Route, Switch } from "react-router-dom";
import PropTypes from 'prop-types'
import jwt_decode from "jwt-decode";
import history from './history';
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./action/auth";

import Routes from './routes/AppRoutes'
//Redux
import { Provider } from "react-redux";
import store from "./store";

import { connect } from 'react-redux'
import './assets/css/style.min.css'
//Google Analytics
import ReactGa from 'react-ga';

// Check for token
if ( localStorage.jwtToken ) {
    // Set auth token header auth
    try {
        setAuthToken( localStorage.jwtToken );
        // Decode token and get user info and exp
        const decoded = jwt_decode( localStorage.jwtToken );
        // Set user and isAuthenticated
        store.dispatch( setCurrentUser( decoded ) );

        // Check for expired token
        const currentTime = Date.now() / 1000;
        if ( decoded.exp < currentTime ) {

            // Logout user
            store.dispatch( logoutUser( store.getState().auth.user.user ) );
            // Clear current Profile TODO
            // store.dispatch(clearCurrentProfile());
            // // Redirect to login
            // window.location.href = '/login';
        }
    } catch ( error ) {
        console.log( error );
    }

}


export const Application = ( props ) => {

    return (
        <Provider store={ store }>
            <Router history={ history }>
                <Routes />
            </Router>
        </Provider>
    )
}

const mapStateToProps = ( state ) => ( {

} )

const mapDispatchToProps = {

}

export default Application
