import React, { Fragment } from "react";

const Videos = ( { post, isVideo, video } ) => {

    return (
        <Fragment>
            <div className="fullsizevid" style={ { marginBottom: "-25px" } }>
                <div className="embed-video">
                    { isVideo ? (
                        <iframe
                            title="play"
                            src={ video }
                            style={ { width: "100%" } }
                            allowFullScreen
                            data-autoplay={ 0 }
                            autoPlay={ 0 }
                            controls
                        />
                    ) : (
                        <video
                            title="play"
                            src={ post.video[ 0 ] }
                            allowFullScreen
                            style={ { width: "100%" } }
                            data-autoplay={ 0 }
                            autoPlay={ 0 }
                            controls
                        />
                    ) }
                </div>
            </div>
        </Fragment>

    );
};

export default Videos;
