import React, { useState } from 'react'
import { connect } from 'react-redux'
import CreateUI from '../../../components/create/entity/Index';
import { createEntity } from "../../../action/entity";

export const Index = ( { createEntity, history, entity } ) => {

    const [ clicked, setClicked ] = useState( " " );

    const [ data, setData ] = useState( {
        codeType: "LAN-3",
        name: "",
        phone: "",
        country: "Canada",
        address: "",
        city: "",
        zip: " ",
        website: "www.mymoque.com",
        description: "Mosque, any house or open area of prayer in Islam, from the Arabic word masjid meaning 'a place of prostration.' The building is largely an open space",
        bankInfo: false,
        bankMail: "",
        bankPhone: "",
        bankNum: "",
        inputs: [],
        show: entity.created,
        openHour: {
            sundayOpen: true,
            sundayOpenAt: "5:00 AM",
            sundayCloseAt: "10:00 PM",

            mondayOpen: true,
            mondayOpenAt: "5:00 AM",
            mondayCloseAt: "10:00 PM",

            tuesdayOpen: true,
            tuesdayOpenAt: "5:00 AM",
            tuesdayCloseAt: "10:00 PM",

            thursdayOpen: true,
            thursdayOpenAt: "5:00 AM",
            thursdayCloseAt: "10:00 PM",

            wednesdayOpen: true,
            wednesdayOpenAt: "5:00 AM",
            wednesdayCloseAt: "10:00 PM",

            fridayOpen: true,
            fridayOpenAt: "5:00 AM",
            fridayCloseAt: "10:00 PM",

            saturdayOpen: true,
            saturdayOpenAt: "5:00 AM",
            saturdayCloseAt: "10:00 PM"
        }
    } );

    const onChangePhone = e => {
        setData( { ...data, phone: e.target.value } );
    };

    const onChangeData = ( e ) => {
        setData( { ...data, [ e.target.name ]: e.target.value } );
    };

    return (
        <div>
            <CreateUI
                onChangeData={ onChangeData }
                editData={ setData }
                data={ data }
                createEntity={ createEntity }
                onChangePhone={ onChangePhone }
            />
        </div>
    )
}



const mapStateToProps = state => ( {
    entity: state.entity
} );




export default connect( mapStateToProps, { createEntity } )( Index )
