import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Salawat from './subSections/Index';
import Adhan from './subSections/adhan/Index';
import FCsv from './subSections/fcsv/Index';
import Widget from './subSections/widget/Index';
import Tutorials from './tutorials/Index';
import { useTranslation } from 'react-i18next';
import { setRoute, disableRoute } from '../../../action/routes'
import Skeleton from 'react-loading-skeleton';
import { isMobile, isTablet } from '../../../utils/device';


export const Prayer = ( {

    apiPrayerTime,
    entity,
    iqamaCalcul,
    calculeNextIqama,
    entityProfil,
    prayerTime,
    tomorrowIqamaTime,
    tomorrowApiPrayerTime,
    highlighted,
    onChange,
    onChange2,
    iqamaTimes,
    csv,
    replaceLine,
    onChangeActiveCsv,
    onButtonClick,
    inputEl,
    adhan,
    setRoute,
    disableRoute,
    onChangeAutoAdd,
    onChangeAutoMinus,
    onChangeAutoAddAdhan,
    onChangeAutoMinusAdhan,
    onJumua2,
    onJumua3,
    onJumua4,
    onSave,
    saving,
    files,
    setfiles,
    isFileLoading,
    onSubmitFile,
    calculatedIqama,
    handleToggleEid

} ) => {
    useEffect( () => {
        setRoute( 'admin/prayer' );
        return () => {
            disableRoute()
        }
    }, [] );

    const { t, i18n } = useTranslation();

    const [ active, setActive ] = useState( {
        iqama: true,
        adhan: false,
        fCsv: false,
        widget: false
    } );




    const changeSubOptionsPrayer = ( active ) => {
        switch ( active ) {
            case 'iqama':
                setActive( { ...active, iqama: true } );
                break;
            case 'adhan':
                setActive( { ...active, adhan: true } );
                break;
            case 'fCsv':
                setActive( { ...active, fCsv: true } );
                break;
            case 'widget':
                setActive( { ...active, widget: true } );
                break;
            default:
                break;
        }

    }


    return (
        <Fragment>
            <div className="page">
                <div className="main-content side-content pt-0">
                    <div className="row row-sm container-fluid" style={ { maxWidth: active.fCsv ? '80%' : '90%', marginLeft: isMobile() ? " " : "70px" } }>
                        <div className={ `col-xl-8 col-lg-12` }>
                            <div className="card custom-card2" id="hori">
                                <div className="card-body" >
                                    <div className="text-wrap">
                                        <div className="example">
                                            <div>
                                                <h6 className="main-content-label mb-1">{ t( 'website.prayerInfo' ) }</h6>
                                                <p className="text-muted card-sub-title">{ t( 'website.subtitle' ) }</p>
                                            </div>
                                            <div className="p-3 bg-light">
                                                <nav className="nav nav-pills flex-column flex-md-row">
                                                    <a onClick={ ( e ) => { e.preventDefault(); setActive( { ...active, widget: false } ); changeSubOptionsPrayer( 'iqama' ) } } className={ `nav-link ${ active.iqama ? "active" : "" }` } href="#">{ t( "common.iqama" ) + " " } </a>
                                                    <a onClick={ ( e ) => { e.preventDefault(); setActive( { ...active, iqama: false } ); changeSubOptionsPrayer( 'adhan' ) } } className={ `nav-link ${ active.adhan ? "active" : "" }` } href="#">{ t( "common.adhan" ) }</a>
                                                    <a onClick={ ( e ) => { e.preventDefault(); setActive( { ...active, adhan: false } ); changeSubOptionsPrayer( 'fCsv' ) } } className={ `nav-link ${ active.fCsv ? "active" : "" }` } href="#">{ t( "website.csvFile" ) } </a>
                                                    <a onClick={ ( e ) => { e.preventDefault(); setActive( { ...active, fCsv: false } ); changeSubOptionsPrayer( 'widget' ) } } className={ `nav-link ${ active.widget ? "active" : "" }` } href="#">{ t( "website.widget" ) } </a>
                                                </nav>
                                            </div>
                                        </div>
                                        <>
                                            { active.iqama && <Salawat
                                                handleToggleEid={ handleToggleEid }
                                                iqamaTimes={ iqamaTimes }
                                                adhan={ adhan }
                                                onChangeAutoAdd={ onChangeAutoAdd }
                                                onChangeAutoMinus={ onChangeAutoMinus }
                                                onChangeAutoMinusAdhan={ onChangeAutoMinusAdhan }
                                                onChangeAutoAddAdhan={ onChangeAutoAddAdhan }
                                                onChange={ onChange }
                                                onJumua2={ onJumua2 }
                                                onJumua3={ onJumua3 }
                                                onJumua4={ onJumua4 }
                                                onChangeActiveCsv={ onChangeActiveCsv }
                                            /> }
                                            { active.adhan && <Adhan iqamaTimes={ iqamaTimes } onChange={ onChange2 } /> }
                                            { active.fCsv && <FCsv
                                                onButtonClick={ onButtonClick }
                                                onSubmitFile={ onSubmitFile }
                                                iqamaTimes={ iqamaTimes }
                                                csv={ csv }
                                                replaceLine={ replaceLine }
                                                download={ iqamaTimes.csv }
                                                isCsv={ iqamaTimes.isCsv }
                                                files={ files }
                                                setfiles={ setfiles }
                                                isFileLoading={ isFileLoading }
                                                inputEl={ inputEl }
                                            /> }
                                            { active.widget && <Widget
                                                iqamaCalcul={ iqamaCalcul }
                                                iqamaTimes={ iqamaTimes }
                                                onChange={ onChange }
                                                apiPrayerTime={ apiPrayerTime }
                                                calculeNextIqama={ calculeNextIqama }
                                                entityProfil={ entityProfil }
                                                entity={ entity }
                                                prayerTime={ prayerTime }
                                                tomorrowIqamaTime={ tomorrowIqamaTime }
                                                tomorrowApiPrayerTime={ tomorrowApiPrayerTime }
                                                highlighted={ highlighted }
                                                calculatedIqama={ calculatedIqama }

                                            /> }
                                        </>

                                    </div>
                                </div>
                                <div style={ { float: "left", marginLeft: "80%" } }>
                                    { saving ? <div className="spinner-grow" style={ { width: "5rem", height: "5rem", marginLeft: "40%" } } role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div> : <div >
                                        <button type="button" className="btn ripple btn-dark btn-block" onClick={ () => onSave() }> { t( "common.save" ) } </button>
                                    </div> }
                                </div>
                            </div>
                        </div>
                        <Tutorials />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = ( state ) => ( {
    routes: state.routes,
    entity: state.entity
} )


export default connect( mapStateToProps, { setRoute, disableRoute } )( Prayer )
