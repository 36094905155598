//axios for backend
import axios from "axios";

//error handling
import { setAlert } from "./alert";
//reducer type
import {
    GET_ENTITY,
    GET_ENTITY_CSV,
    GET_ADMIN_PRAYER_TV

} from "./types";

// header configuration
const config = { headers: { "Content-Type": "application/json" } };


export const sendNewsLetter = ( content, entity, fileData, t ) => async dispatch => {
    try {
        //  res = await axios.post( `/api/admin/send/the/newsletter/${ entity }`, body, config );
        const res = await axios( {
            method: 'post',
            url: `/api/admin/send/the/newsletter/${ entity }`,
            headers: {
                accept: "application/json",
                "Accept-Language": "en-US,en;q=0.8",
                "Content-Type": `multipart/form-data; boundary=${ fileData._boundary }`
            },
            data: fileData,
        } );
        dispatch( setAlert( { error: t( "notif.emailIsSentSuccess" ) }, "success" ) );
        return true;
    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};



export const getPrayerEntityTvWithId = ( codeEntity ) => async dispatch => {

    try {

        const res = await axios.get( `/tapy/masjid/get/prayer/${ codeEntity }` );
        dispatch( {
            type: GET_ADMIN_PRAYER_TV,
            payload: res.data.profile
        } );
    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};

export const sendContacts = ( content, entity ) => async dispatch => {
    const body = JSON.stringify( content );

    try {
        const res = await axios.post( `/api/admin/send/contacts/${ entity }`, body, config );

        dispatch( setAlert( { error: `Les contacts ont été ajoutés avec succès` }, "success" ) );
        setTimeout( () => {
            window.location.reload();
        }, 2000 );
    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};

export const deleteContact = ( entity, index ) => async dispatch => {
    // const body = JSON.stringify(content);

    try {
        const res = await axios.delete( `/api/admin/delete/contacts/${ entity }/${ index }` );
        dispatch( setAlert( { error: `Contact effacé avec succès` }, "success" ) );

        setTimeout( () => {
            window.location.reload();
        }, 2000 );

    } catch ( error ) {
        if ( error.response ) {
            const errors = error.response.data;
            console.log( errors );
            if ( errors ) {
                if ( errors.message ) {
                    dispatch( setAlert( errors.message.msg[ errors.msg ], "mailConfirmation" ) );
                } else {
                    dispatch( setAlert( errors, "register" ) );
                }
            }
        } else if ( error.request ) {
            console.log( "// The request was made but no response was received // Error details are stored in error.reqeust", error.request );
        } else {

            console.log( ' // Some other errors', error.message );
        }
        return false;
    }
};