import React, { useState } from "react";

import Video from "./Video";
// import Microlink from "@microlink/react";
import './media.css';
import { isMobile, isTablet } from "../../utils/device";

const Link = ( { link } ) => {

    let pwd = null


    const getParameterByName = ( name, url ) => {
        // if (!url) url = window.location.href;
        name = name.replace( /[\[\]]/g, '\\$&' );
        var regex = new RegExp( '[?&]' + name + '(=([^&#]*)|&|#|$)' ),
            results = regex.exec( url );
        if ( !results ) return null;
        if ( !results[ 2 ] ) return '';
        return decodeURIComponent( results[ 2 ].replace( /\+/g, ' ' ) );
    }

    let url = " ";
    let isVideo = false;
    let pathZoom = "";
    let isZoom = false;


    const linkifyYouTubeURLs = ( text ) => {
        var re = /https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*?[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/ig;
        return text.toString().replace( re, 'http://www.youtube.com/embed/$1' );
    }


    if ( link ) {
        let query = link[ 0 ];
        let video_id = query.split( "v=" )[ 1 ];
        if ( query.includes( "facebook.com" ) && query.includes( "video" ) ) {
            let regex = '/(videos|story_fbid)(\/|=)(\d+)(\/|&)?/'
            let url2 = link[ 0 ].match( regex );
            url = `https://www.facebook.com/plugins/video.php?href=${ query }/&width=600&height=400&autoPlay=0`;
            isVideo = true;
        } else if ( query.includes( "youtu" ) ) {
            // url = `https://www.youtube.com/embed/${ video_id }?autoPlay=0`;
            url = linkifyYouTubeURLs( link[ 0 ] );
            isVideo = true;

        } else if ( query.includes( "vimeo.com" ) ) {
            const vimeo_id = query.split( "com/" )[ 1 ];
            url = `https://player.vimeo.com/video/${ vimeo_id && vimeo_id }?autoPlay=0`;
            isVideo = true;
        } else if ( query.includes( "zoom.us" ) ) {
            pwd = getParameterByName( "pwd", link[ 0 ] )
            pathZoom = link[ 0 ].match( /\d+/ );
            if ( pathZoom ) {
                url = `https://zoom.us/wc/${ pathZoom[ 0 ] }/join?un=TWluZGF1Z2Fz`
                isZoom = true;
                // prefer=1&
            }

        }
    }

    return (
        link &&
        link[ 0 ] && (
            <span style={ {
                position: "relative"
            } }>
                { isZoom ?
                    <div className="fullsizevid" style={ { marginBottom: "-35px" } } >
                        <div className="box">
                            <div>
                                <small uk-tooltip="title: Mot de passe de l'URL du meeting ZOOM">{ pwd ? "Mot de passe: " : "" }</small>
                                <small style={ { color: "green" } }>{ pwd ? pwd : "" }</small>
                            </div>
                        </div>

                        <div className="embed-video" id="moonode_zoom">
                            <iframe allow="microphone; camera; fullscreen"
                                style={ {
                                    border: "0",
                                    height: "600px",
                                    left: "0",
                                    position: "inherit",
                                    top: "30%",
                                    width: "100%"
                                } }
                                sandbox="allow-same-origin allow-scripts allow-forms allow-presentation"
                                src={ url } frameBorder="0" />
                        </div>
                    </div>
                    :
                    <span >
                        { isVideo ? (
                            <Video post={ null } isVideo={ true } video={ url } />
                        ) : (
                            <>{ isMobile() || isTablet() ? <a href={ `${ link[ 0 ] }` } > LINK </a> : null }</>
                        ) }{ " " }
                    </span>
                }
            </span>

        )
    );
};

export default Link;
