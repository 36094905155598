import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Newsletter from './subSections/Newsletter';

import Tutorials from './tutorials/Index';
import { useTranslation } from 'react-i18next';
import { setRoute, disableRoute } from '../../../action/routes'
import Contacts from './subSections/Contacts';
import { isMobile, isTablet } from '../../../utils/device';

export const Prayer = ( {
    setRoute,
    disableRoute,
    onChangeContent,
    onChange,
    setFileNames,
    formData,
    fileNames,
    handleDrop,
    extractEmails,
    entityContacts,
    removeContact,
    onSubmit,
    loading,
    setformData,
    onButtonClick,
    inputEl,
} ) => {

    useEffect( () => {

        setRoute( 'admin/newsletter' );

        return () => {
            disableRoute()
        }
    }, [] )


    const { t, i18n } = useTranslation();

    const [ active, setActive ] = useState( {
        newsletter: true,
        allcontacts: false,

    } );

    const changeSubOptionsPrayer = ( active ) => {
        switch ( active ) {
            case 'newsletter':
                setActive( { ...active, newsletter: true } );
                break;
            case 'allcontacts':
                setActive( { ...active, allcontacts: true } );
                break;

            default:
                break;
        }

    }



    return (
        <Fragment>
            <div className="page">
                <div className="main-content side-content pt-0">
                    <div className="row row-sm container-fluid" style={ { maxWidth: active.newsletter ? '100%' : '90%', marginLeft: isMobile() ? " " : "70px" } }>
                        <div className={ `col-xl-8 col-lg-12` }>
                            <div className="card custom-card2" id="hori">
                                <div className="card-body">
                                    <div className="text-wrap">
                                        <div className="example">
                                            <div>
                                                <h6 className="main-content-label mb-1">{ t( 'application.newsletter' ) }</h6>
                                                <p className="text-muted card-sub-title">{ t( 'application.emailinfo' ) }</p>
                                            </div>
                                            <div className="p-3 bg-light">
                                                <nav className="nav nav-pills flex-column flex-md-row">
                                                    <a onClick={ ( e ) => { e.preventDefault(); setActive( { ...active, allcontacts: false } ); changeSubOptionsPrayer( 'newsletter' ) } } className={ `nav-link ${ active.newsletter ? "active" : "" }` } href="#">{ t( "common.email" ) }</a>
                                                    <a onClick={ ( e ) => { e.preventDefault(); setActive( { ...active, newsletter: false } ); changeSubOptionsPrayer( 'allcontacts' ) } } className={ `nav-link ${ active.allcontacts ? "active" : "" }` } href="#">{ t( "entity.contact" ) }</a>
                                                </nav>
                                            </div>
                                        </div>
                                        { active.newsletter && <Newsletter
                                            onChangeContent={ onChangeContent }
                                            onChange={ onChange }
                                            setFileNames={ setFileNames }
                                            fileNames={ fileNames }
                                            formData={ formData }
                                            setformData={ setformData }
                                            handleDrop={ handleDrop }
                                            loading={ loading }
                                            onSubmit={ onSubmit }
                                            onButtonClick={ onButtonClick }
                                            inputEl={ inputEl }
                                        /> }

                                        { active.allcontacts &&
                                            <Contacts
                                                extractEmails={ extractEmails }
                                                formData={ formData }
                                                onChange={ onChange }
                                                entityContacts={ entityContacts }
                                                removeContact={ removeContact }
                                            /> }

                                    </div>

                                </div>

                            </div>

                        </div>

                        <Tutorials />

                    </div>

                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = ( state ) => ( {
    routes: state.routes
} )


export default connect( mapStateToProps, { setRoute, disableRoute } )( Prayer )
