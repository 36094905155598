import isEmpty from '../utils/is-empty';

import {
    USER_LOGIN,
    GET_USER_PROFILE,
} from '../action/types';


const initialState = {
    isAuthenticated: null,
    loading: true,
    user: null,
    userProfile: null,
    isEntity: false,
    isItAdmin: false,
    isItRole: false,
};

export default function ( state = initialState, action ) {

    const { type, payload } = action;

    switch ( type ) {
        case USER_LOGIN:
            return {
                ...state,
                isAuthenticated: !isEmpty( payload ),
                loading: false,
                user: payload
            }
        case GET_USER_PROFILE:
            return {
                ...state,
                userProfile: payload.response,
                loading: false,
                isEntity: payload.isEntity,
                isItAdmin: payload.isItAdmin,
                isItRole: payload.isItRole,
            }

        default:
            return state;
    }

}