import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';

import PassResetUi from '../../components/login/PassResetUi';
import { sendTheMail } from '../../action/auth';

export const SignIn = ( { sendTheMail } ) => {
    const { t } = useTranslation();
    const [ formData, setformData ] = useState( {
        email: "",
    } )

    const [ emailSent, setSentEmail ] = useState( false )

    const onSendMailChange = ( e ) => {
        setformData( { ...formData, [ e.target.name ]: e.target.value } );
    }

    const onClickSendMail = () => {
        if ( !emailSent ) {
            sendTheMail( formData, t );
        }
        setSentEmail( true );
        setformData( { email: "" } )
    }

    return (
        <PassResetUi
            translate={ t }
            onClickSendMail={ onClickSendMail }
            onSendMailChange={ onSendMailChange }
        />
    )
}

const mapStateToProps = ( state ) => ( {

} )


export default connect( mapStateToProps, { sendTheMail } )( SignIn );