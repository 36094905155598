import React, { Fragment, useState, useEffect, useContext, useRef } from 'react'
import { connect } from 'react-redux'
import { UserProfileContext } from "../../../../Context.js";
import Adhan from '../../../../components/admin/Television/Adhan/Index';
import { getEntityVisuals, addDefaultImage, addDefaultImageUrl } from "../../../../action/entity";
import { useTranslation } from 'react-i18next';

export const Index = ( { match, getEntityVisuals, addDefaultImage, addDefaultImageUrl, entity: { entityVisual, loading } } ) => {

    const { t } = useTranslation();
    const [ progress, setProgress ] = useState( 0 );
    const { userProfile } = useContext( UserProfileContext );
    const [ isFileLoading, setIsFileLoading ] = useState( false )
    const [ files, setfiles ] = useState( null )
    const [ component, setComponent ] = useState( null )
    const [ isNewUpload, setIsNewUpload ] = useState( false );


    let data = new FormData();

    useEffect( () => {
        if ( userProfile ) {
            const { codeEntity, profile } = userProfile;
            const { token } = match.params;
            if ( ( encodeURI( codeEntity ).localeCompare( token ) === 0 ) && ( profile.isRole || profile.isAdmin ) ) {
                getEntityVisuals( codeEntity );
            } else {
                alert( "🟥" )
                window.location.replace( "/dashboard" );
            }
        }

    }, [ userProfile ] );

    const changeIsNewUpload = async ( bool ) => {
        setIsNewUpload( bool )
    }

    const handleImageUploadFromParent = async ( files, component ) => {
        if ( files.length > 0 ) {
            setIsFileLoading( true )
            changeIsNewUpload( true )
            for ( let index = 0; index < files.length; index++ ) {
                console.log( index + "I", files[ index ].name )
                var option = await new Blob( [ files[ index ] ] );
                await data.append( "file", option, files[ index ].name );
            }
        }

        setfiles( data );
        setComponent( component );
    }

    const postVisual = async ( awsUrl, component ) => {
        const { codeEntity } = userProfile;

        let interval = setInterval( () => {
            setProgress( prevProgress => {
                if ( prevProgress >= 95 ) { // stop at 95
                    clearInterval( interval );
                    return 95;
                }
                return prevProgress + 2; // increment progress by 5 every half second.
            } );
        }, 500 );
        let valids = false
        if ( isNewUpload ) {
            valids = await addDefaultImage( files, codeEntity, component );
        } else {
            setComponent( component );

            valids = await addDefaultImageUrl( awsUrl, codeEntity, component ); // Use the new function if it's not a new upload
        }

        if ( valids ) {
            setProgress( 100 ); // manually set to 100 if addDefaultImage is successful
            clearInterval( interval );
            setTimeout( () => {
                window.location.reload();
            }, 20000 );
        } else {
            clearInterval( interval );
            setProgress( 0 );
        }
    }


    return (
        !loading && <Adhan
            entityVisual={ entityVisual }
            addDefaultImage={ addDefaultImage }
            isFileLoading={ isFileLoading }
            handleImageUploadFromParent={ handleImageUploadFromParent }
            postVisual={ postVisual }
            changeIsNewUpload={ changeIsNewUpload }
            progress={ progress }

        />

    )
}



const mapStateToProps = ( state ) => ( {
    entity: state.entity
} )


export default connect( mapStateToProps, { getEntityVisuals, addDefaultImage, addDefaultImageUrl } )( Index )
