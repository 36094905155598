import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Salawat from './Iqama/Salawat';
import Jumuas from './Iqama/Jumuas';
import Eids from './Iqama/Eids';
import { CsvStatus } from './Iqama/CsvStatus';
import { useTranslation } from 'react-i18next';

export const Index = ( {
    onChange,
    iqamaTimes,
    onChangeActiveCsv,
    adhan,
    onChangeAutoAdd,
    onChangeAutoMinus,
    onChangeAutoAddAdhan,
    onChangeAutoMinusAdhan,
    onJumua2,
    onJumua3,
    onJumua4,
    handleToggleEid
} ) => {
    const { t } = useTranslation();

    const [ active, setActive ] = useState( {
        salawat: true,
        jumuas: false,
        csvStatus: false,
        tutorials: false,
        eids: false
    } );

    const changeSubOptionsPrayer = ( active ) => {
        switch ( active ) {
            case 'salawat':
                setActive( { ...active, salawat: true } );
                break;
            case 'jumuas':
                setActive( { ...active, jumuas: true } );
                break;
            case 'csvStatus':
                setActive( { ...active, csvStatus: true } );
                break;
            case 'tutorials':
                setActive( { ...active, tutorials: true } );
                break;
            case 'eids':
                setActive( { ...active, eids: true } );
                break;

            default:
                break;
        }
    }


    return (
        <div className="example">
            <div className="p-3 bg-light mb-3">
                <nav className="nav main-nav flex-column flex-md-row justify-content-center">
                    <a onClick={ ( e ) => { e.preventDefault(); setActive( { ...active, tutorials: false } ); changeSubOptionsPrayer( 'salawat' ) } } className={ `nav-link ${ active.salawat ? "active" : "" }` } href="#">{ t( "website.salawat" ) }</a>
                    <a onClick={ ( e ) => { e.preventDefault(); setActive( { ...active, salawat: false } ); changeSubOptionsPrayer( 'jumuas' ) } } className={ `nav-link ${ active.jumuas ? "active" : "" }` } href="#">{ t( "website.jumuas" ) }      </a>
                    <a onClick={ ( e ) => { e.preventDefault(); setActive( { ...active, jumuas: false } ); changeSubOptionsPrayer( 'csvStatus' ) } } className={ `nav-link ${ active.csvStatus ? "active" : "" }` } href="#">{ t( "website.csvStatus" ) }</a>
                    <a onClick={ ( e ) => { e.preventDefault(); setActive( { ...active, csvStatus: false } ); changeSubOptionsPrayer( 'eids' ) } } className={ `nav-link ${ active.eids ? "active" : "" }` } href="#">Eid & Ramadan { " " }
                    </a>

                </nav>
            </div>
            { active.salawat && <Salawat
                iqamaTimes={ iqamaTimes }
                adhan={ adhan }
                onChange={ onChange }
                onChangeAutoAdd={ onChangeAutoAdd }
                onChangeAutoMinus={ onChangeAutoMinus }
                onChangeAutoMinusAdhan={ onChangeAutoMinusAdhan }
                onChangeAutoAddAdhan={ onChangeAutoAddAdhan }
            /> }
            { active.jumuas && <Jumuas
                dhuhr={ adhan.Dhuhr }
                iqamaTimes={ iqamaTimes }
                onChange={ onChange }
                onJumua2={ onJumua2 }
                onJumua3={ onJumua3 }
                onJumua4={ onJumua4 }
            /> }
            { active.csvStatus && <CsvStatus
                iqamaTimes={ iqamaTimes }
                onChangeActiveCsv={ onChangeActiveCsv }
            /> }
            { active.eids && <Eids
                handleToggleEid={ handleToggleEid }
                iqamaTimes={ iqamaTimes }
                onChange={ onChange }

            /> }
        </div>
    )
}

Index.propTypes = {
    props: PropTypes
}

const mapStateToProps = ( state ) => ( {

} )

const mapDispatchToProps = {

}

export default connect( mapStateToProps, mapDispatchToProps )( Index )
