import {
    GET_ALL,
    GET_GEO_LOCALISATION,
    SET_LOADING_ENTITIES
} from "../action/types";

const initialState = {
    entities: [],
    geolocatisation: null,
    lat: 0,
    lng: 0,
    allEntitiesloading: false,
    error: {}
};

export default function ( state = initialState, action ) {
    const { type, payload } = action;

    switch ( type ) {
        case GET_ALL:
            return {
                ...state,
                entities: payload
            };
        case GET_GEO_LOCALISATION:
            return {
                ...state,
                geolocatisation: payload,
                lat: payload.lat,
                lng: payload.lng
            };
        case SET_LOADING_ENTITIES:
            return {
                ...state,
                allEntitiesloading: payload
            };

        default:
            return state;
    }
}
