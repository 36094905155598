import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

export const TableHeader = ( { children } ) => {
    const { t } = useTranslation();
    return (
        <div className="card-body">
            <div className="table-responsive">
                <table className="table text-nowrap text-md-nowrap table-bordered mg-b-0">
                    <thead>
                        <tr>
                            <th></th>
                            <th>{ t( "website.date" ) }</th>
                            <th style={ { backgroundColor: "#FABF0F" } }>A. { t( "entity.fajr" ) } </th>
                            <th style={ { backgroundColor: "#fcd45c", color: "#fff" } }>I. { t( "entity.fajr" ) } </th>
                            <th style={ { backgroundColor: "#FABF0F" } }>A. { t( "entity.dhuhr" ) } </th>
                            <th style={ { backgroundColor: "#fcd45c", color: "#fff" } }>I. { t( "entity.dhuhr" ) } </th>
                            <th style={ { backgroundColor: "#FABF0F" } }>A. { t( "entity.asr" ) } </th>
                            <th style={ { backgroundColor: "#fcd45c", color: "#fff" } }>I. { t( "entity.asr" ) } </th>
                            <th style={ { backgroundColor: "#FABF0F" } }>A. { t( "entity.maghrib" ) } </th>
                            <th style={ { backgroundColor: "#fcd45c", color: "#fff" } }>I. { t( "entity.maghrib" ) } </th>
                            <th style={ { backgroundColor: "#FABF0F" } }>A. { t( "entity.isha" ) } </th>
                            <th style={ { backgroundColor: "#fcd45c", color: "#fff" } }>I. { t( "entity.isha" ) } </th>
                            <th>{ t( "entity.jumua" ) } </th>
                            <th>{ t( "entity.jumua" ) }  2</th>
                            <th>{ t( "entity.sunrise" ) } </th>
                            <th>{ t( "entity.hijriDate" ) } </th>
                        </tr>
                    </thead>
                    { children }
                </table>
            </div>
        </div>
    )
}

TableHeader.propTypes = {
    props: PropTypes
}

const mapStateToProps = ( state ) => ( {

} )


export default connect( mapStateToProps, {} )( TableHeader )
