import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Tutorials from './tutorials/Index';
import { useTranslation } from 'react-i18next';
import { setRoute, disableRoute } from '../../../action/routes'
import { isMobile, isTablet } from '../../../utils/device';

export const Settings = ( { setRoute, disableRoute, onChange, formData, onSave, saving } ) => {


    useEffect( () => {

        setRoute( 'admin/donation' );

        return () => {
            disableRoute()
        }
    }, [] )


    const { t, i18n } = useTranslation();

    const [ active, setActive ] = useState( {
        iqama: true,
        adhan: false,
        fCsv: false,
        widget: false
    } );

    const changeSubOptionsPrayer = ( active ) => {
        switch ( active ) {
            case 'iqama':
                setActive( { ...active, iqama: true } );
                break;
            case 'adhan':
                setActive( { ...active, adhan: true } );
                break;
            case 'fCsv':
                setActive( { ...active, fCsv: true } );
                break;
            case 'widget':
                setActive( { ...active, widget: true } );
                break;

            default:
                break;
        }

    }

    return (
        <Fragment>
            <div className="page">
                <div className="main-content side-content pt-0">
                    <div className="row row-sm container-fluid" style={ { maxWidth: active.fCsv ? '80%' : '90%', marginLeft: isMobile() ? " " : "70px" } }>
                        <div className={ `col-xl-8 col-lg-12` }>
                            <div className="card custom-card2" id="hori">
                                <div className="card-body">
                                    <div className="text-wrap">
                                        <div className="example">
                                            <div>
                                                <h6 className="main-content-label mb-1">{ t( 'website.donationGenral' ) }</h6>
                                                <p className="text-muted card-sub-title">{ t( 'website.donationGenralInfo' ) }</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">

                                        <div className="row row-sm" >
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <p className="mg-b-10">{ t( "common.zakat" ) } : </p>
                                                    <input type="text" className="form-control" name="paypal" onChange={ ( e ) => onChange( e ) } value={ formData.paypal } />
                                                </div>
                                                <div className="form-group">
                                                    <p className="mg-b-10">{ t( "common.donation" ) } :</p>
                                                    <input type="text" className="form-control" name="paypal2" onChange={ ( e ) => onChange( e ) } value={ formData.paypal2 } />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={ { float: "left", marginLeft: "80%" } }>
                                    { saving ? <div className="spinner-grow" style={ { width: "5rem", height: "5rem", marginLeft: "40%" } } role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div> : <div >
                                        <button type="button" className="btn ripple btn-dark btn-block" onClick={ () => onSave() }>{ t( "common.save" ) } </button>
                                    </div> }
                                </div>
                            </div>

                        </div>

                        <Tutorials />

                    </div>

                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = ( state ) => ( {
    routes: state.routes
} )


export default connect( mapStateToProps, { setRoute, disableRoute } )( Settings )
