import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import LandingUi from '../../components/landing/Index';

export const Index = ( { isAuthenticated } ) => {
    const [ show, setshow ] = useState( isAuthenticated );
    useEffect( () => {
        setshow( isAuthenticated );
    }, [ isAuthenticated ] );
    const Landing = (
        <LandingUi />
    );
    const redirect = () => {
        window.location.replace( "/dashboard" );
    };
    return <>{ show ? redirect() : Landing }</>;

};

const mapStateToProps = ( state ) => ( {
    isAuthenticated: state.auth.isAuthenticated
} );



export default connect( mapStateToProps, {} )( Index );
