import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Templates from './templates/Index';
import { useTranslation } from 'react-i18next';
import { setRoute, disableRoute } from '../../../../action/routes'
import { isMobile } from '../../../../utils/device';

export const VisualsControl = ( { entityVisual, changeIsNewUpload, handleImageUploadFromParent, postVisual, progress } ) => {

    const [ defaultImgUrl, setDefaultImgUrl ] = useState( [ 'https://dinscreen.eu-central-1.linodeobjects.com/default/kaaba-1690566096744.webp' ] );

    useEffect( () => {
        setRoute( 'admin/televdision/visuals' );
        return () => {
            disableRoute()
        }
    }, [] )
    const { t, i18n } = useTranslation();

    const [ active, setActive ] = useState( {
        calendarAdhan: true,
    } );

    const [ component, setComponent ] = useState( 'TvAdhan' );
    const [ chosenImage, setChosenImage ] = useState( entityVisual.defaultAdhan );
    const [ defaultImage, setDefaultImage ] = useState( entityVisual.defaultAdhan );
    const [ isLoading, setIsLoading ] = useState( false );


    const handleImageClick = ( image, index ) => {
        changeIsNewUpload( false );
        setChosenImage( image );
    };

    useEffect( () => {
        let defaultImg;
        switch ( component ) {
            case 'TvAdhan':
                defaultImg = entityVisual.defaultAdhan;
                setDefaultImgUrl( [ 'https://dinscreen.eu-central-1.linodeobjects.com/default/kaaba-1690566096744.webp' ] );
                break;
            case 'TvIqama':
                defaultImg = entityVisual.defaultIqama;
                setDefaultImgUrl( [ 'https://dinscreen.eu-central-1.linodeobjects.com/default/aqsa-2323923.webp' ] );
                break;
            case 'TvShuruk':
                defaultImg = entityVisual.defaultShuruk;
                setDefaultImgUrl( [ 'https://dinscreen.eu-central-1.linodeobjects.com/default/sunriseen-original.gif' ] );
                break;
            case 'TvMidnight':
                defaultImg = entityVisual.defaultMidnight;
                setDefaultImgUrl( [ 'https://dinscreen.eu-central-1.linodeobjects.com/default/midnighten-original.gif' ] );
                break;
            case 'TvThird':
                defaultImg = entityVisual.defaultThird;
                setDefaultImgUrl( [ 'https://dinscreen.eu-central-1.linodeobjects.com/default/thirdnighten-original.gif' ] );
                break;
        }
        setDefaultImage( defaultImg );
        setChosenImage( defaultImg );
        setIsLoading( true ); // Start loading
        setTimeout( () => { // Simulate half second delay
            setDefaultImage( defaultImg );
            setChosenImage( defaultImg );
            setIsLoading( false ); // Stop loading
        }, 500 );
    }, [ component, entityVisual ] );

    return (
        <Fragment>
            <div className="page">
                <div className="main-content side-content pt-0">
                    <div className="row row-sm container-fluid" style={ { maxWidth: active.fCsv ? '80%' : '90%', marginLeft: isMobile() ? " " : "70px" } }>
                        <div className={ `col-xl-8 col-lg-12` }>
                            <div className="card custom-card2" id="hori">
                                <div className="card-body" >
                                    <div className="text-wrap">
                                        <div className="example" style={ { minWidth: "350px" } }>
                                            <div>
                                                <h6 className="main-content-label mb-1">{ t( 'website.tvadhan' ) }</h6><br />
                                                <p className="text-muted card-sub-title"> { t( 'website.tvAdhanDescription' ) }</p>
                                            </div>
                                            <div>

                                                <p className="text-muted card-sub-title" > <b>{ t( 'website.tvAdhanRecommendation' ) }</b></p>
                                            </div>
                                            <div className="p-3 bg-light">
                                                <nav className="nav nav-pills flex-column flex-md-row">
                                                    <a onClick={ ( e ) => { e.preventDefault(); setComponent( 'TvAdhan' ); } } className={ `nav-link ${ component === 'TvAdhan' ? "active" : "" }` } href="#">{ " " } { " " + t( 'common.adhan' ) } </a>
                                                    <a onClick={ ( e ) => { e.preventDefault(); setComponent( 'TvIqama' ); } } className={ `nav-link ${ component === 'TvIqama' ? "active" : "" }` } href="#">{ " " } { " " + t( 'common.iqama' ) } </a>
                                                    <a onClick={ ( e ) => { e.preventDefault(); setComponent( 'TvShuruk' ); } } className={ `nav-link ${ component === 'TvShuruk' ? "active" : "" }` } href="#">{ " " } { " " + t( 'common.shuruk' ) } </a>
                                                    <a onClick={ ( e ) => { e.preventDefault(); setComponent( 'TvMidnight' ); } } className={ `nav-link ${ component === 'TvMidnight' ? "active" : "" }` } href="#">{ " " } { " " + t( 'common.midnight' ) } </a>
                                                    <a onClick={ ( e ) => { e.preventDefault(); setComponent( 'TvThird' ); } } className={ `nav-link ${ component === 'TvThird' ? "active" : "" }` } href="#">{ " " } { " " + t( 'common.thirdOfNight' ) } </a>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                    <>
                                        { isLoading ? (
                                            <div className="spinner-grow" style={ { backgroundColor: "#FABF0F", width: "5rem", height: "5rem", marginLeft: "40%" } } role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        ) : (
                                            <img src={ chosenImage } />
                                        ) }
                                    </>


                                </div>
                                { progress > 0 ? <div className="progress mg-b-10">
                                    <div
                                        className="progress-bar progress-bar-striped progress-bar-animated bg-warning"
                                        role="progressbar"
                                        aria-valuenow={ progress }
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        style={ { width: `${ progress }%` } }
                                    >
                                    </div>
                                </div> : <>
                                    { chosenImage !== defaultImage &&

                                        <button className="btn ripple btn-primary" type="button" onClick={ () => postVisual( chosenImage, component ) }>
                                            { t( "common.save" ) }</button>
                                    }

                                </> }

                            </div>
                        </div>
                        <Templates
                            handleImageClick={ handleImageClick }
                            defaultImages={ defaultImgUrl }
                            selectedImage={ chosenImage }
                            handleImageUploadFromParent={ handleImageUploadFromParent }
                            component={ component }
                            additionalImages={ entityVisual[ `additional${ component.substring( 2, component.length ) }` ] || [] } // dynamically select the additional images based on the active component

                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = ( state ) => ( {
    routes: state.routes,
    entity: state.entity
} )


export default connect( mapStateToProps, { setRoute, disableRoute } )( VisualsControl )
