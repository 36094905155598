import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';

import PassTokenUi from '../../components/login/PassTokenUi';
import { changeUserPasswordToken } from '../../action/auth';


export const SignIn = ( { auth: { user }, changeUserPasswordToken, match } ) => {
    const { t } = useTranslation();
    const [ state, setState ] = useState( {
        password: "",
        password2: ""
    } );

    const onSubmit = async () => {
        changeUserPasswordToken( match.params.token, state );
    };

    const onEdit = e => {
        setState( { ...state, [ e.target.name ]: e.target.value } );
    };



    return (
        <PassTokenUi
            translate={ t }
            onSubmit={ onSubmit }
            onEdit={ onEdit }
        />
    )
}

const mapStateToProps = ( state ) => ( {
    auth: state.auth

} )


export default connect( mapStateToProps, { changeUserPasswordToken } )( SignIn );