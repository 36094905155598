import React, { useState, useMemo } from 'react';

import { connect } from 'react-redux'
import TextField from "@material-ui/core/TextField";
import { Switch, FormControlLabel } from '@material-ui/core';
import '../style.css'

import Dhuhr from "../../../../../assets/img/dhuhr.png";
import Asr from "../../../../../assets/img/asr.png";
import { useTranslation } from 'react-i18next';


export const Eids = ( {
    iqamaTimes,
    onChange,
    handleToggleEid
} ) => {
    const { t } = useTranslation();
    const options = useMemo( () => {
        const options = [];
        for ( let i = 1; i <= 40; i++ ) {
            options.push( i );
        }
        return options;
    }, [] );

    return (

        <div className="container-fluid elementToFadeInAndOut">
            <div className="inner-body">
                <div className="row row-sm" >
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div className="table-responsive border userlist-table">
                                    <table className="table card-table table-striped table-vcenter text-nowrap mb-0">
                                        <thead>
                                            <tr>
                                                <th className="wd-lg-8p"><span></span></th>
                                                <th className="wd-lg-20p"><span>{ t( "website.eidPrayer" ) }</span></th>
                                                <th className="wd-lg-20p"><span>{ t( "website.fixedIqama" ) }</span></th>
                                                <th className="wd-lg-20p"><span>{ t( "common.activte" ) }\{ t( "common.desactive" ) }</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <img alt="prayerLogo" className="rounded-circle avatar-md mr-2" src={ Dhuhr } />
                                                </td>
                                                <td className="text-black">{ t( "website.eid" ) }</td>

                                                <td>
                                                    { " " }
                                                    <TextField
                                                        onChange={ e => onChange( e ) }
                                                        id="eid"
                                                        value={ iqamaTimes.Eid || '00:00' }
                                                        name="Eid"
                                                        type="time"
                                                        InputLabelProps={ {
                                                            shrink: true
                                                        } }
                                                    />
                                                </td>
                                                <td>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                size="small"
                                                                color="secondary"
                                                                name='toggleEid'
                                                                checked={ iqamaTimes.toggleEid }
                                                                value={ iqamaTimes.toggleEid }
                                                                onChange={ ( e ) => handleToggleEid( e ) } />
                                                        }
                                                        label={ iqamaTimes.toggleEid ? "Activé" : "Désactivé" }
                                                    />
                                                </td>

                                            </tr>

                                            { iqamaTimes.toggleEid && <tr>
                                                <td>
                                                    <img alt="prayerLogo" className="rounded-circle avatar-md mr-2" src={ Dhuhr } />
                                                </td>
                                                <td className="text-black">{ t( "website.eid" ) } 2</td>
                                                <td>
                                                    { " " }
                                                    <TextField
                                                        onChange={ e => onChange( e ) }
                                                        id="eid2"
                                                        name="Eid2"
                                                        value={ iqamaTimes.Eid2 || '00:00' }
                                                        type="time"
                                                        min="09:00"
                                                        max="18:00"
                                                        InputLabelProps={ {
                                                            shrink: true
                                                        } }
                                                    />
                                                </td>
                                                <td>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                size="small"
                                                                color="secondary"
                                                                name='toggleEid2'
                                                                checked={ iqamaTimes.toggleEid2 }
                                                                value={ iqamaTimes.toggleEid2 }
                                                                onChange={ ( e ) => handleToggleEid( e ) } />
                                                        }
                                                        label={ iqamaTimes.toggleEid2 ? "Activé" : "Désactivé" }
                                                    />
                                                </td>
                                            </tr> }
                                            { iqamaTimes.toggleEid2 && <tr>
                                                <td>
                                                    <img alt="prayerLogo" className="rounded-circle avatar-md mr-2" src={ Dhuhr } />
                                                </td>
                                                <td className="text-black">{ t( "website.eid" ) } 3</td>
                                                <td>
                                                    { " " }
                                                    <TextField
                                                        onChange={ e => onChange( e ) }
                                                        id="eid3"
                                                        name="Eid3"
                                                        value={ iqamaTimes.Eid3 || '00:00' }
                                                        type="time"
                                                        min="09:00"
                                                        max="18:00"
                                                        InputLabelProps={ {
                                                            shrink: true
                                                        } }
                                                    />
                                                </td>
                                                <td>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                size="small"
                                                                color="secondary"
                                                                name='toggleEid3'
                                                                checked={ iqamaTimes.toggleEid3 }
                                                                value={ iqamaTimes.toggleEid3 }
                                                                onChange={ ( e ) => handleToggleEid( e ) } />
                                                        }
                                                        label={ iqamaTimes.toggleEid3 ? "Activé" : "Désactivé" }
                                                    />
                                                </td>
                                            </tr> }
                                            { iqamaTimes.toggleEid3 && <tr>
                                                <td>
                                                    <img alt="prayerLogo" className="rounded-circle avatar-md mr-2" src={ Dhuhr } />
                                                </td>
                                                <td className="text-black">{ t( "website.eid" ) } 4</td>
                                                <td>
                                                    { " " }
                                                    <TextField
                                                        onChange={ e => onChange( e ) }
                                                        id="eid4"
                                                        name="Eid4"
                                                        value={ iqamaTimes.Eid4 || '00:00' }
                                                        type="time"
                                                        min="09:00"
                                                        max="18:00"
                                                        InputLabelProps={ {
                                                            shrink: true
                                                        } }
                                                    />
                                                </td>
                                                <td>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                size="small"
                                                                color="secondary"
                                                                name='toggleEid4'
                                                                checked={ iqamaTimes.toggleEid4 }
                                                                value={ iqamaTimes.toggleEid4 }
                                                                onChange={ ( e ) => handleToggleEid( e ) } />
                                                        }
                                                        label={ iqamaTimes.toggleEid4 ? "Activé" : "Désactivé" }
                                                    />
                                                </td>
                                            </tr> }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="card-body">
                                {/* toggleTaraweeh2 */ }
                                <div className="table-responsive border userlist-table">
                                    <table className="table card-table table-striped table-vcenter text-nowrap mb-0">
                                        <thead>
                                            <tr>
                                                <th className="wd-lg-8p"><span></span></th>
                                                <th className="wd-lg-20p"><span>{ t( "website.taraweeh" ) }</span></th>
                                                <th className="wd-lg-20p"><span>{ t( "website.startingTime" ) }</span></th>
                                                <th className="wd-lg-20p"><span>{ t( "website.numberRakaa" ) }</span></th>
                                                <th className="wd-lg-20p"><span>{ t( "common.activte" ) }\{ t( "common.desactive" ) }</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <img alt="prayerLogo" className="rounded-circle avatar-md mr-2" src={ Dhuhr } />
                                                </td>
                                                <td className="text-black">{ t( "website.taraweeh" ) }</td>


                                                <td>
                                                    { " " }
                                                    <TextField
                                                        onChange={ e => onChange( e ) }
                                                        id="eid"
                                                        value={ iqamaTimes.Isha || '00:00' }
                                                        name="Taraweeh"
                                                        type="time"
                                                        InputLabelProps={ {
                                                            shrink: true
                                                        } }
                                                        disabled={ true }
                                                    />
                                                </td>
                                                <td>
                                                    { " " }
                                                    <select value={ Number( iqamaTimes.Taraweeh2 ) } onChange={ e => onChange( e ) } name="Taraweeh2">
                                                        <option value=""></option>
                                                        { options.map( ( option ) => (
                                                            <option key={ option } value={ option }>
                                                                { option }
                                                            </option>
                                                        ) ) }
                                                    </select>
                                                </td>
                                                <td>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                size="small"
                                                                color="secondary"
                                                                name='toggleTaraweeh'
                                                                checked={ iqamaTimes.toggleTaraweeh }
                                                                value={ iqamaTimes.toggleTaraweeh }
                                                                onChange={ ( e ) => handleToggleEid( e ) } />
                                                        }
                                                        label={ iqamaTimes.toggleTaraweeh ? "Activé" : "Désactivé" }
                                                    />
                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            {/* Ftoor */ }
                            <div className="card-body">
                                <div className="table-responsive border userlist-table">
                                    <table className="table card-table table-striped table-vcenter text-nowrap mb-0">
                                        <thead>
                                            <tr>
                                                <th className="wd-lg-8p"><span></span></th>
                                                <th className="wd-lg-20p"><span>{ t( "website.iftar" ) }</span></th>
                                                <th className="wd-lg-20p"><span>{ t( "website.startingTime" ) }</span></th>
                                                <th className="wd-lg-20p"><span>{ t( "common.activte" ) }\{ t( "common.desactive" ) }</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <img alt="prayerLogo" className="rounded-circle avatar-md mr-2" src={ Dhuhr } />
                                                </td>
                                                <td className="text-black">{ t( "website.iftar" ) }</td>

                                                <td>
                                                    { " " }
                                                    <TextField
                                                        onChange={ e => onChange( e ) }
                                                        id="iftar"
                                                        value={ iqamaTimes.iftar || '00:00' }
                                                        name="iftar"
                                                        type="time"
                                                        InputLabelProps={ {
                                                            shrink: true
                                                        } }
                                                    />
                                                </td>
                                                <td>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                size="small"
                                                                color="secondary"
                                                                name='toggleIftar'
                                                                checked={ iqamaTimes.toggleIftar }
                                                                value={ iqamaTimes.toggleIftar }
                                                                onChange={ ( e ) => handleToggleEid( e ) } />
                                                        }
                                                        label={ iqamaTimes.toggleIftar ? "Activé" : "Désactivé" }
                                                    />
                                                </td>

                                            </tr>


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/* Itikaf */ }
                            <div className="card-body">
                                <div className="table-responsive border userlist-table">
                                    <table className="table card-table table-striped table-vcenter text-nowrap mb-0">
                                        <thead>
                                            <tr>
                                                <th className="wd-lg-8p"><span></span></th>
                                                <th className="wd-lg-20p"><span>{ t( "website.itkafInfo" ) }</span></th>
                                                <th className="wd-lg-20p"><span></span></th>
                                                <th className="wd-lg-20p"><span>{ t( "common.activte" ) }\{ t( "common.desactive" ) }</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <img alt="prayerLogo" className="rounded-circle avatar-md mr-2" src={ Dhuhr } />
                                                </td>
                                                <td className="text-black">{ t( "website.itikaf" ) }</td>

                                                <td>

                                                </td>
                                                <td>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                size="small"
                                                                color="secondary"
                                                                name='toggleitikaf'
                                                                checked={ iqamaTimes.toggleitikaf }
                                                                value={ iqamaTimes.toggleitikaf }
                                                                onChange={ ( e ) => handleToggleEid( e ) } />
                                                        }
                                                        label={ iqamaTimes.toggleitikaf ? "Activé" : "Désactivé" }
                                                    />
                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>



    )
}

const mapStateToProps = ( state ) => ( {

} )

const mapDispatchToProps = {

}

export default connect( mapStateToProps, mapDispatchToProps )( Eids )
