import React, { useState } from 'react'
import { Card } from "react-bootstrap"
import { useTranslation } from 'react-i18next';

export default function DikrOfTheDay ( { ahadith: { hadith } } ) {
    const { t } = useTranslation();
    const [ isActivated, setisActivated ] = useState( false )

    setTimeout( () => {
        setisActivated( !isActivated );
    }, 10000 );

    return (
        hadith && hadith.length > 0 ? <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
            <Card style={ { height: "100%" } }>
                <Card.Header style={ { height: "20%" } }>
                    <h4 className="main-content-label tx-13 font-weight-bold mb-1" style={ { textAlign: "center" } }>{ t( 'website.dikrDay' ) }</h4>
                    <br /><br />
                    <div class="carousel slide" data-bs-ride="carousel" id="carouselExample4">

                        <div class="carousel-inner ">
                            <div class={ `carousel-item ${ isActivated ? "active" : "" }` }>
                                <div dangerouslySetInnerHTML={ { __html: hadith[ 0 ].body } }></div>
                            </div>
                            <div class={ `carousel-item ${ !isActivated ? "active" : "" }` }>
                                <div dangerouslySetInnerHTML={ { __html: hadith[ 1 ].body } }></div>

                            </div>

                        </div>
                    </div>
                </Card.Header>
            </Card>
        </div > : <div className="spinner-grow" style={ { width: "5rem", height: "5rem" } } role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )
}
