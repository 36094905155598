import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'


export const TvSettings = ( { onChange, tvSettings, onChange2 } ) => {
    const { t } = useTranslation();
    return (

        <div className="container-fluid elementToFadeInAndOut">
            <div className="inner-body">
                <div className="row row-sm" >
                    <div className="col-lg-12 col-md-12">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div>
                                    <h6 className="main-content-label mb-1">{ t( "entity.tvAds" ) }</h6>
                                    <p className="text-muted card-sub-title">{ t( "application.menutv" ) } </p>
                                </div>
                                <div className="form-group">
                                    <select
                                        className="form-control select-lg select2"
                                        name="activated"
                                        value={ tvSettings.activated }
                                        onChange={ ( e ) => onChange( e ) }>

                                        <option value={ true }>{ t( "common.activte" ) }</option>
                                        <option value={ false }>{ t( "common.desactive" ) }</option>
                                    </select>
                                </div>
                            </div>
                            <div className="card-body">
                                <div>
                                    <h6 className="main-content-label mb-1">{ t( "website.conventionTitle" ) } </h6>
                                    <p className="text-muted card-sub-title">{ t( "website.conventionDescription" ) }</p>
                                </div>
                                <div className="form-group">
                                    <select
                                        className="form-control select-lg select2"
                                        name="isTimeConvention"
                                        value={ tvSettings.isTimeConvention }
                                        onChange={ ( e ) => onChange( e ) }>
                                        <option value={ false }>{ t( "website.isTimeConvention1" ) }</option>
                                        <option value={ true }>{ t( "website.isTimeConvention2" ) }</option>
                                    </select>
                                </div>
                            </div>
                            <div className="card-body">
                                <div>
                                    <h6 className="main-content-label mb-1">{ t( "website.fontWeightTitle" ) } </h6>
                                    <p className="text-muted card-sub-title" style={ { fontWeight: tvSettings.fontWeight } }>{ t( "website.fontWeightDescription" ) }</p>
                                </div>
                                <div className="form-group">
                                    <select
                                        className="form-control select-lg select2"
                                        name="fontWeight"
                                        value={ tvSettings.fontWeight }
                                        onChange={ ( e ) => onChange( e ) }>
                                        <option value={ "700" } style={ { fontWeight: "900" } }></option>
                                        <option value={ "900" } style={ { fontWeight: "900" } }>{ t( "website.fontWeight1" ) }</option>
                                        <option value={ "500" } style={ { fontWeight: "500" } }>{ t( "website.fontWeight2" ) }</option>
                                        <option value={ "200" } style={ { fontWeight: "300" } }>{ t( "website.fontWeight3" ) }</option>
                                    </select>
                                </div>
                            </div>
                            <div className="card-body">
                                <div>
                                    <h6 className="main-content-label mb-1">{ t( "common.color" ) }</h6>
                                    <p className="text-muted card-sub-title">{ t( "website.tvChangeColor" ) }</p>
                                </div>
                                <div className="form-group">
                                    <select

                                        className="form-control select-lg select2"
                                        onChange={ ( e ) => onChange( e ) }
                                        name="color"
                                        value={ tvSettings.color }>

                                        <option value={ "#16acea" }>{ t( "common.blue" ) }</option>
                                        <option value={ "#79C300" }>{ t( "common.green" ) }</option>
                                        <option value={ "#ee204d" }>{ t( "common.rouge" ) }</option>
                                        <option value={ "#FFA500" }>{ t( "common.orange" ) }</option>
                                        <option value={ "#d902ee" }>{ t( "common.purple" ) }</option>
                                        <option value={ "#FABF0F" }>{ t( "common.yellow" ) }</option>
                                        <option value={ "#000000" }>{ t( "common.black" ) }</option>
                                    </select>
                                </div>
                            </div>
                            <div className="card-body">
                                <div>
                                    <h6 className="main-content-label mb-1">{ t( "profile.language" ) }</h6>
                                    <p className="text-muted card-sub-title">{ t( "website.tvChangeLanguage" ) }</p>
                                </div>
                                <div className="form-group">
                                    <select

                                        className="form-control select-lg select2"
                                        name="langs"
                                        onChange={ ( e ) => onChange( e ) }
                                        value={ tvSettings.langs }

                                    >

                                        <option value={ "0" }> { t( "website.french" ) } </option>
                                        <option value={ "1" }> { t( "website.english" ) } </option>
                                        <option value={ "2" }> { t( "website.arabic" ) } </option>
                                        <option value={ "3" }> { t( "website.french" ) } /{ t( "website.english" ) } </option>
                                        <option value={ "4" }> { t( "website.french" ) } /{ t( "website.arabic" ) }</option>
                                        <option value={ "5" }> { t( "website.english" ) }/{ t( "website.arabic" ) }</option>
                                        <option value={ "6" }> { t( "website.urdu" ) }/{ t( "website.english" ) }</option>
                                    </select>
                                </div>
                            </div>
                            <div className="card-body">
                                <div>
                                    <h6 className="main-content-label mb-1">{ t( "website.date" ) }</h6>
                                    <p className="text-muted card-sub-title">{ t( "website.tvChangeDate" ) }</p>
                                </div>
                                <div className="form-group">
                                    <select

                                        className="form-control select-lg select2"
                                        name="hijriPreference"
                                        onChange={ ( e ) => onChange( e ) }
                                        value={ tvSettings.hijriPreference }
                                    >
                                        <option value={ "2" }>{ t( "application.plustwodays" ) }</option>
                                        <option value={ "1" }>{ t( "application.plusoneday" ) }</option>
                                        <option value={ "0" }>{ t( "application.standard" ) }</option>
                                        <option value={ "-1" }>{ t( "application.minustwodays" ) }</option>
                                        <option value={ "-2" }>{ t( "application.minusoneday" ) }</option>
                                    </select>
                                </div>
                            </div>
                            <div className="card-body">
                                <div>
                                    <h6 className="main-content-label mb-1">{ t( "website.tvTomorrow" ) }</h6>
                                    <p className="text-muted card-sub-title">{ t( "application.displayinfo" ) }</p>
                                </div>
                                <div className="form-group">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="card custom-card">
                                            <div className="card-body">
                                                <div className="main-toggle-group-demo mg-t-10">
                                                    <div>
                                                        <p className="text-muted card-sub-title">{ t( "entity.fajr" ) } { " " }</p>
                                                    </div>
                                                    <div style={ { marginLeft: "30px" } } className={ `main-toggle main-toggle-success ${ tvSettings.f ? "on" : "" }` } onClick={ () => onChange2( "f" ) }>
                                                        <span></span>
                                                    </div>
                                                </div>
                                                <div className="main-toggle-group-demo mg-t-10">
                                                    <div>
                                                        <p className="text-muted card-sub-title">{ t( "entity.dhuhr" ) } { " " }</p>
                                                    </div>
                                                    <div style={ { marginLeft: "30px" } } className={ `main-toggle main-toggle-success ${ tvSettings.d ? "on" : "" }` } onClick={ () => onChange2( "d" ) }>
                                                        <span></span>
                                                    </div>
                                                </div>
                                                <div className="main-toggle-group-demo mg-t-10">
                                                    <div>
                                                        <p className="text-muted card-sub-title">{ t( "entity.asr" ) } { " " }</p>
                                                    </div>
                                                    <div style={ { marginLeft: "30px" } } className={ `main-toggle main-toggle-success ${ tvSettings.a ? "on" : "" }` } onClick={ () => onChange2( "a" ) }>
                                                        <span></span>
                                                    </div>
                                                </div>
                                                <div className="main-toggle-group-demo mg-t-10">
                                                    <div>
                                                        <p className="text-muted card-sub-title">{ t( "entity.maghrib" ) } { " " } </p>
                                                    </div>
                                                    <div style={ { marginLeft: "30px" } } className={ `main-toggle main-toggle-success ${ tvSettings.m ? "on" : "" }` } onClick={ () => onChange2( "m" ) }>
                                                        <span></span>
                                                    </div>
                                                </div>
                                                <div className="main-toggle-group-demo mg-t-10">
                                                    <div>
                                                        <p className="text-muted card-sub-title">{ t( "entity.isha" ) } { " " }</p>
                                                    </div>
                                                    <div style={ { marginLeft: "30px" } } className={ `main-toggle main-toggle-success ${ tvSettings.i ? "on" : "" }` } onClick={ () => onChange2( "i" ) }>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>



    )
}

const mapStateToProps = ( state ) => ( {

} )

const mapDispatchToProps = {

}


export default connect( mapStateToProps, mapDispatchToProps )( TvSettings )