import React from "react";

const Index = ( { entities, loading } ) => {
    return (
        <div>
            <div className="landing-page content-bg-wrap">
                <div className="body-bg-white">
                    <section className="medium-padding120">
                        <div className="container">
                            <ul className="table-careers">
                                <li className="head">
                                    <span></span>
                                    <span>CONDITIONS D’UTILISATION <br />ET POLITIQUE DE CONFIDENTIALITÉ</span>
                                    <span></span>
                                </li>
                            </ul>
                            <p style={ { textAlign: "justify" } }>
                                Bienvenue sur la plateforme moonode! <br /><br />
                                Le contenu de ce site web et de l’application («moonode» et/ou
                                «plateforme» désignant mutuellement et conjointement les termes «site» et
                                «application») offre des services afinqu’entrent en contact des
                                communautés, des mosquées et des commerces. La visionde la plateforme et
                                de vous rapprocher de votre entourage et de votre communauté etles
                                services offerts aux utilisateurs réguliers de moonode sont gratuits, et
                                le resteront.

                                CONDITIONS D’UTILISATION <br /><br />
                                Veuillez lire attentivement les conditions d’utilisation de la plateforme
                                (« Conditionsd’utilisation ») avant d’utiliser ou de naviguer sur moonode.
                                En accédant à celle-ci et enl’utilisant, vous consentez à être lié par
                                toutes ces conditions d’utilisation. Si vous n’êtespas d’accord avec ces
                                conditions d’utilisation, votre seul recours est de quitter laplateforme
                                immédiatement. Ainsi, les conditions d’utilisation forment un
                                accordjuridiquement contraignant entre vous-mêmes et moonode inc., une
                                société légalementconstituée selon la Loi dans la province du Québec, au
                                Canada. Moonodeinc peut, entout temps et sans préavis, modifier, compléter
                                ou remplacer les Conditions d’utilisation,à son entière discrétion. En
                                continuant d’utiliser moonode après toute modification desConditions
                                d’utilisation, cela signifie que vous les acceptez telles que modifiées.{ " " }
                                1.Liens vers d’autres sites (hyperliens) <br /><br />
                                Ce site peut contenir des liens vers d’autres sites web (que l’on appelle
                                communémentles hyperliens) qui ne sont pas la propriété de moonode. Tous
                                ces autres sites web, leurcontenu et leurs pages ne sont pas contrôlés par
                                moonode. Ainsi, moonode n’est pasresponsable du contenu (incluant toute
                                erreur ou omission) de ces autres sites web, nide toute perte ou tout
                                dommage que vous pourriez subir en raison de l’utilisation de cesautres
                                sites web ou des liens vers ces autres sites web. Ces liens vers d’autres
                                sitesweb sont uniquement fournis pour améliorer votre utilisation du site,
                                vous permettre devous connecter avec ou vous abonner à moonode sur
                                différentes plateformes, etcompléter des transactions. <br /><br />
                                2.Droits d’auteur, marques et autres droits de propriété intellectuelle

                                Tout le contenu de ce Site est protégé par les lois canadiennes sur le
                                droit d’auteur etles marques de commerce en tant qu’œuvres originales.
                                L’absence d’un symbole dedroit d’auteur ou de marque de commerce déposés
                                ou enregistrés ne signifie pas queledit contenu n’est pas protégé et
                                n’appartient pas à moonode. <br /><br />
                                3.Autorisation d’utilisation <br /><br />
                                Si vous téléchargez du contenu ou des éléments à travers moonode, vous
                                êtes autoriséà le faire uniquement à titre d’utilisation personnelle,
                                temporaire et non-commerciale. Ils’agit donc d’une autorisation
                                d’utilisation, et non d’un transfert de propriété. En vertu de ces
                                conditions d’utilisation, vous ne pouvez donc pas: modifier, copier ou
                                reproduire lecontenu, l’information et tout autre élément du site;
                                utiliser le contenu ou tout élément àdes fins commerciales ou pour tout
                                affichage public (commercial ou non-commercial); ettransférer ou
                                distribuer le contenu, l’information ou tout autre élément à une
                                autrepersonne ou le diffuser ou publier sur d’autres sites. Le non-respect
                                des conditionsd’utilisation entraînera automatiquement la révocation de
                                cette autorisation d’utilisationaccordée. De plus, cette autorisation peut
                                être révoquée à tout moment par moonode,peu importe la raison. Après
                                utilisation ou suite à la fin des conditions d’utilisation ou dela
                                présente autorisation, tout contenu téléchargé et qui est en votre
                                possession (peuimporte le format) doit être détruit. <br /><br />
                                4.Remboursement <br /><br />À moins d’indication contraire, toutes les ventes
                                sont finales et sujettes à aucunremboursement. <br /><br />
                                5.Limitation de responsabilité

                                Le propriétaire de moonode inc, ainsi que ses administrateurs, dirigeants,
                                agents,mandataires, employés, filiales et sociétés affiliées n’assument
                                aucune responsabilitépour les conséquences résultant directement ou
                                indirectement de toute action, inactionou omission prises par tout
                                utilisateur en se basant sur les informations ou le contenutrouvés sur
                                moonode. Toute information fournie par cette plateforme, ou qui se
                                trouvesur celle-ci, est fournie à des fins promotionnelles ou informatives
                                uniquement et ne doiten aucun cas être considérée comme un avis
                                professionnel. En utilisant moonode,l’utilisateur accepte et convient à ce
                                que d’utiliser ou de suivre toute information ourecommandation qui y
                                figurent est à ses propres risques et périls. Vous acceptez doncde
                                naviguer et d’utiliser ce site à vos propres risques et périls. En aucun
                                cas moonode,ses administrateurs, dirigeants, agents, mandataires,
                                employés, filiales et sociétésaffiliées ne sauraient être tenues
                                responsables envers quiconque, des dommages,incluant, mais sans s’y
                                limiter, la perte de données ou de profits ou l’interruption de
                                vosactivités, découlant de l’utilisation ou de l’impossibilité
                                d’utilisation du site, de soncontenu, de ses informations ou de tout autre
                                élément, de l’interruption ou de lafermeture du site ou d’erreurs ou
                                omissions dans l’information ou le contenu du site, etce, même si moonode
                                est ou a été informée verbalement ou par écrit de la possibilité detels
                                dommages. <br /><br />
                                6.Indemnisation <br /><br />
                                Comme condition essentielle à l’utilisation de ce site, vous acceptez et
                                convenez, parles présentes conditions d’utilisation, d’indemniser moonode,
                                ses administrateurs,dirigeants, agents, mandataires, employés, filiales et
                                sociétés affiliées, et de les tenirindemnes de toutes responsabilité,
                                dépenses (incluant les frais judiciaires etextrajudiciaires) et dommages
                                découlant de réclamations résultant ou découlant devotre utilisation de ce
                                site, de ses informations, de son contenu et de tout autre élément.

                                7.Intégralité

                                Ces conditions d’utilisation, ainsi que tout autre avis légal, politique,
                                règlement oudirective de moonode liés à ces conditions d’utilisation ou
                                contenus sur ce site, constituent la totalité et l’intégralité de
                                l’entente intervenue entre vous et moonoderelativement à votre utilisation
                                de ce site, et remplace toute autre entente (orale ouécrite) préalable,
                                présente ou ultérieure, revendication ou représentation. Ces
                                conditionsd’utilisation ne peuvent être modifiées, complétées ou
                                remplacées que par moonode,conformément à l’article 8 des conditions
                                d’utilisation. <br /><br />
                                8.Modification des conditions d’utilisation <br /><br />
                                moonode peut, en tout temps et sans préavis, modifier, compléter ou
                                remplacer lesconditions d’utilisation du site, à son entière discrétion.
                                En accédant à moonode aprèstoute modification des conditions
                                d’utilisation, cela signifie que vous les acceptez tellesque modifiées.{ " " }

                                9.Lois applicables <br /><br />
                                Toute réclamation relativement à moonode sera régie par les lois
                                applicables dans laprovince du Québec, nonobstant tout principe relatif au
                                conflit des lois. <br /><br />
                                10.Élection de domicile

                                Toute réclamation ou poursuite judiciaire relative à moonode devra être
                                introduite dansle district judiciaire de Montréal, province du Québec,
                                Canada, à l’exclusion de toutautre district judiciaire. <br /><br />
                                POLITIQUE DE CONFIDENTIALITÉ

                                1.Protection des informations personnelles et confidentialité

                                L’objet de la présente politique de confidentialité (la « politique de
                                confidentialité ») estde vous informer sur le type d’informations que
                                moonode peut collecter de vous, sur cequ’elle prévoit faire avec ces
                                informations et sur les mesures qu’elle prendra afin demaintenir vos
                                informations personnelles confidentielles. En utilisant moonode,
                                sesinformations, son contenu et les services qu’elle fournit, vous
                                acceptez les termes decette politique de confidentialité. <br /><br />
                                2.Consentement à la collecte d’informations <br /><br />
                                En soumettant vos informations personnelles à moonode ou à l’un de ses
                                fournisseurstiers, vous acceptez et consentez à la collecte, à
                                l’utilisation, à la divulgation et autransfert de vos informations
                                personnelles, conformément aux dispositions de lapolitique de
                                confidentialité. Vous pouvez refuser ou retirer votre consentement, et ce
                                entout temps en contactant moonode à l’adresse suivante:
                                moonodeinc@gmail.com.Cependant, vous comprenez qu’en refusant ou en
                                retirant votre consentement,moonode pourrait ne plus être en mesure de
                                vous fournir ses services, l’utilisation de saplateforme, ses
                                informations, son contenu ou tout autre élément. Si vous avez moins de18
                                ans, vous ne devez fournir aucune information personnelle à moonode sans
                                leconsentement de votre parent ou tuteur légal. <br /><br />
                                3. Quelles sont les informations personnelles collectées par moonode?{ " " }
                                a) Types d’informations. moonode pourrait collecter votre nom, le
                                nom de votre entreprise, votre adresse courriel, votre situation familiale,
                                votre profession, vos influences, votre nom d’utilisateur, votre mot de passe et
                                <br /><br />
                                b) Fournisseurs tiers. moonode peut travailler avec des fournisseurs,
                                applications,modules, plugiciels ou sites web externes (les «fournisseurs
                                tiers») afin de vous fournirun meilleur service sur sa plateforme. Ces
                                fournisseurs tiers peuvent collecter vosinformations personnelles afin de
                                vous fournir lesdits services. <br /><br />
                                3.Comment moonode utilisera-t-elle vos informations personnelles? <br /><br />
                                Vos informations personnelles seront utilisées afin de vous fournir du
                                contenu informatif et promotionnel, ainsi que pour vous offrir les services
                                que vous pourriez acheter ou auxquels vous pourriez vous abonner à partir
                                de la plateforme. De plus, vos informations de paiement ne sont pas
                                collectées par moonode et stockées sur saplateforme, ainsi que par un des
                                fournisseurs visés à l’article 3.b) ci-dessus; moonodepourrait également
                                utiliser vos informations personnelles pour vous répondre lorsquevous lui
                                transmettez un message, ou si vous demandez de l’aide pour un service
                                quevous avez acheté ou auquel vous vous êtes abonné tels que votre nom, le
                                nom de votreentreprise, votre adresse courriel, votre âge, votre numéro de
                                téléphone, votre nomd’utilisateur et votre mot de passé. Ces informations
                                pourraient aussi être collectées parmoonode via sa plateforme, et
                                pourraient être stockées sur celle-ci ou traitées par lebiais des
                                fournisseurs tiers visés à l’article 3.b) ci-dessus. Si vous vous abonnez
                                àl’infolettre de moonode, vos informations personnelles pourraient être
                                utilisées pourvous la transmettre. Mentionnons également que moonode
                                pourrait utiliser vosinformations personnelles à des fins marketing afin
                                de vous transmettre ses promotions,vous informer de ses événements
                                spéciaux, ou vous communiquer toute autre publicité.

                                4.Stockage de vos informations personnelles

                                Comme préalablement mentionné, la plateforme peut stocker vos
                                informationspersonnelles lorsque vous interagissez avec celle-ci.
                                Cependant, elle pourra lessupprimer sur demande, en contactant l’adresse
                                courriel suivante : moonodeinc@gmail.Vous pouvez également demander à
                                moonode d’accéder à vos informationspersonnelles et d’en obtenir une
                                copie. <br /><br />
                                5.Délégué à la protection des données

                                moonode nomme ses employés comme délégué à la
                                protection des données. <br /><br />
                                6.Nom d’utilisateur et mot de passe

                                Vous êtes responsable de maintenir la confidentialité de vos informations
                                de connexion.Ainsi, vous ne devez, en aucun cas, partager ces informations
                                à quiconque. Pour votrepropre sécurité, vous devez choisir un mot de passe
                                unique ayant un fort niveau desécurité, vous déconnecter après chaque
                                utilisation, et ne pas vous connecter à l’aided’un réseau sans fil public
                                ou sur un ordinateur public. Dans l’éventualité où laconfidentialité de
                                vos informations de connexion était compromise, vous devez avisermoonode
                                sans délai, afin qu’elle puisse vous assister à la rétablir. moonode,
                                ainsi queses administrateurs, dirigeants, agents, mandataires, employés,
                                filiales et sociétés affiliées ne peuvent en aucun cas être tenus
                                responsables des dommages résultantd’une utilisation non autorisée de vos
                                informations de connexion par un tiers. <br /><br />
                                7.Respect des lois

                                En tout temps, moonode respecte les lois en toutes matières. Ainsi,
                                moonode respectetoutes les règles de la Loi canadienne anti- pourriel et
                                la Loi sur la protection desrenseignements personnels et les documents
                                électroniques. <br /><br />
                                8.Analyse

                                La plateforme conserve les informations suivantes de vos visites: a) des
                                informationsafin d’améliorer l’engagement des utilisateurs de moonode,
                                indiquant d’où et quandvous visitez la plateforme et combien de temps vous
                                y restez; b) des informations survotre adresse IP pour la sécurité de
                                moonode et du serveur. <br /><br />
                                9.Cookies

                                La plateforme utilise des cookies afin d’enregistrer le nombre de fois où
                                vous avez visitémoonode et comment vous avez interagi avec celle-ci à
                                chaque fois. Votre utilisationcontinue de la plateforme signifie que vous
                                consentez à ces cookies. Un cookie est unfichier texte qui est envoyé par
                                le serveur d’un site sur votre disque dur et qui ne peutêtre lu et
                                interprété que par le serveur du dit-site. Aucune information personnelle
                                n’eststockée dans le cookie qui serait susceptible de vous identifier. Les
                                cookies aident laplateforme à comprendre comment vous interagissez avec
                                celle-ci, à connaître vospréférences et donc à améliorer votre expérience
                                de navigation. Si vous ne voulez pasde cookie, ajustez simplement les
                                paramètres de votre navigateur web afin de lesdésactiver, par contre, cela
                                pourrait avoir une influence sur votre expérience denavigation. <br /><br />
                                10.Divulgation d’informations personnelles

                                moonode ne vendra, ne cédera ou ne transférera jamais les informations
                                personnellesqu’elle recueille de vous. Cependant, moonode n’est pas
                                responsable de la divulgationde vos informations personnelles par des
                                tiers, dont ceux visés à l’article 3.b) ci-dessus,notamment lorsque vous
                                achetez ou vous vous abonnez à l’un de nos services. Ainsi,vous acceptez
                                d’être liés aux politiques de confidentialité et aux conditions
                                d’utilisationdes fournisseurs tiers. moonode ne fait aucune promesse,
                                garantie, engagement oureprésentation relativement à la manière dont vos
                                informations personnelles seronttraitées par ces fournisseurs tiers.{ " " }

                                11.Internet et cyberattaques

                                L’internet ouvert et sujet à l’hameçonnage d’informations. Bien que
                                moonode fait tout enson pouvoir pour éviter de telles situations, elle ne
                                peut garantir que les informationsque vous lui fournissez ne seront pas
                                interceptées par des tiers. Ainsi, bien quemoonode adopte des mesures afin
                                de respecter votre vie privée et protéger vosinformations personnelles,
                                elle ne peut garantir qu’aucune cyberattaque ne surviendra.Dans le cas où
                                la confidentialité du tout ou d’une partie de vos informationspersonnelles
                                serait compromise, moonode vous en informera et fera tout en son
                                pouvoirafin de corriger la situation le plus rapidement possible. <br /><br />
                                12.Modification de vos informations personnelles. <br /><br />
                                Vous devez vous assurer que les informations détenues par moonode sont à
                                jour. Ainsi,veuillez contacter moonodeinc@gmail.com pour aviser de toute
                                modification dans vosinformations personnelles. <br /><br />
                                13.Modification de la politique de confidentialité

                                moonode peut, en tout temps et sans préavis, modifier, compléter ou
                                remplacer sapolitique de confidentialité, à son entière discrétion. En
                                accédant à moonode suite à unemodification de la politique de
                                confidentialité, cela signifie que vous l’acceptez telle quemodifiée.{ " " }
                                14.Lois applicables

                                Toute réclamation relative à moonode sera régie par les lois applicables
                                dans laprovince du Québec, nonobstant tout principe relatif au conflit des
                                lois. <br /><br />
                                15.Élection de domicile

                                Toute réclamation ou poursuite judiciaire relative à moonode ou à cette
                                politique deconfidentialité devra être introduite dans le district
                                judiciaire de Montréal, province duQuébec, Canada, à l’exclusion de tout
                                autre district judiciaire. <br /><br />
                                16.Questions? <br /><br />
                                Si vous avez des questions ou des préoccupations, n’hésitez pas à les
                                soumettre àl’adresse suivante: moonodeinc@gmail.com. <br /><br />
                            </p>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default Index;
