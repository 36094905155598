import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import Post from '../posts/feedPosts';
import FatawasEntity from '../posts/fatawasEntity';
import Prayer from '../../containers/dashboard/prayerWdiget/Index';
import HijriDate from './sections/hijriDate'
import FastDays from './sections/fastDays'
import DikrOfTheDay from './sections/dikrOfTheDay'
import UserWelcome from './sections/userWelcome'
import Ads from './sections/ads';
import { useTranslation } from 'react-i18next';
import { isMobile, isTablet } from '../../utils/device';
export const Dashboard = ( { feed, qa: { loadingQuestion, questions }, onGetFeed, routes, user: { user }, addLike, settheFeed, ahadith, whitedays } ) => {
    const { t } = useTranslation();
    const onChangeLike = async ( index, postsId, postNbr ) => {
        const newPosts = [ ...feed ];
        let idx = newPosts[ index ].likes.map( item => item.user.toString() ).indexOf( user.id );
        if ( idx >= 0 ) {
            newPosts[ index ].likes.splice( idx, 1 );
        } else {
            newPosts[ index ].likes.unshift( { id: "fakeide", user: user.id } );
        }
        await addLike( postsId, postNbr );
        settheFeed( newPosts );
    };

    return (
        <Fragment>
            <div className="row row-sm" >
                <div className="col-sm-12 col-lg-12 col-xl-8">
                    <UserWelcome user={ user } />
                    <div className="row row-sm" style={ { margin: isMobile() ? "" : "4rem" } } >
                        <HijriDate />
                        <FastDays whitedays={ whitedays } />
                        <DikrOfTheDay ahadith={ ahadith } />
                    </div>
                    <div>
                        { feed.slice( 0, 100 ).map( ( i, index ) => (
                            <div key={ index } style={ { margin: isMobile() ? "" : "4rem" } }>
                                { ( !i.deleted ) && ( i.type === "public" ) && <Post feed={ i } show={ true } onChangeLike={ onChangeLike } index={ index } user={ user } /> }
                            </div>
                        ) ) }
                        <div style={ { display: 'flex', justifyContent: 'center', marginTop: '1rem' } }>
                            <button className="btn btn-primary" onClick={ () => onGetFeed() }>{ t( 'common.seemore' ) }</button>
                        </div>

                    </div>
                </div>
                { isMobile() || isTablet() ?
                    null
                    : <div className="sticky-top" style={ { marginLeft: "10px", alignContent: "center", height: "45rem", width: "28%", top: "5rem", zIndex: -1 } }>
                        <div className="card custom-card" style={ { alignItems: "center", width: "100%", height: "70%", borderRadius: "2rem" } }>
                            <div style={ { width: "90%", height: "50rem", } }>
                                <Prayer show={ false } isText={ true } text={ "Adhan Montreal" } />
                            </div>
                        </div>
                        {/* <Ads /> */ }
                    </div> }

            </div>
        </Fragment >
    )
}

const mapStateToProps = ( state ) => ( {

} )

export default connect( mapStateToProps, {} )( Dashboard )
