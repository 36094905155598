
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const ShortComment = ( { comment: shortDesc = '', length } ) => {
    const { t } = useTranslation();
    const [ isExpanded, setIsExpanded ] = useState( false )
    const [ number, setNumber ] = useState( 10 )

    const handleToggle = () => {
        setIsExpanded( !isExpanded )
    }
    return (
        <div >
            <div className="uk-text-small">
                { shortDesc ? shortDesc
                    .replace( /(?:https?|ftp):\/\/[\n\S]+/g, "" )
                    .slice( 0, isExpanded ? length : 100 )
                    .split( "\n" )
                    .map( ( p, index ) => {
                        return (
                            <p key={ index } >
                                { p }
                            </p>
                        );
                    } ) : null }
                { shortDesc.length > 10 && !isExpanded ?
                    <a href={ null } className=" mr-1" onClick={ ( e ) => { e.preventDefault(); handleToggle() } }>{ isExpanded ? "-" : t( "common.seemore" ) }</a> : null }
            </div>
        </div >
    )
}

export default ShortComment;