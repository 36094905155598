import React, { useState, useEffect, Fragment } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types'
// import Index from "../containers/layout/Index";
import PrivateRoute from "../utils/PrivateRoute";

// import PushNotification from '../containers/pushNotification/PushNotification';
// import Alert from '../components/alerts/Alert';

//Routes
import Terms from '../components/terms/Index';
import Adhkarm from '../components/adhkar/Adhkarm';
import Adhkars from '../components/adhkar/Adhkars';
import Dashboard from '../containers/dashboard/Index';
import Navigation from '../containers/navigation/Index';
import Prayer from '../containers/admin/Prayer/Index';
import CalendarUi from '../containers/admin/Calendar/CalendarUi';
import Calendar from '../containers/admin/Calendar/Index';
import Roles from '../containers/admin/Roles/Index';
import Settings from '../containers/admin/Settings/Index';
import Newsletter from '../containers/admin/NewsLetter/Index';
import Television from '../containers/admin/Television/Index';
import TelevisionAdhan from '../containers/admin/Television/Adhan/Index';
import Donation from '../containers/admin/Donation/Index';
import Entity from '../containers/entity/Index';
import Mosques from '../containers/mosques/Index';
import Profile from '../containers/profile/index';
import WidgetRoute from '../containers/widgetRoute/Index';
import SharedLink from '../containers/share/Index';
import Privacy from '../containers/privacy/Index';
import CreateEntity from '../containers/create/entity/Index';
import ConfirmEmail from '../containers/confirmMail/Index';
import EmailConfirmation from '../containers/confirmMail/register/Index';

//landing & sign in
import Landing from '../containers/landing/Index';
import Qr from '../components/landing/qr';
import AboutUs from '../components/landing/IndexAboutUs';
import ContactUs from '../components/landing/IndexContactUs';
import Raspberry from '../components/landing/IndexRaspberry';
import SignUp from '../containers/login/SignUp';
import SignIn from '../containers/login/SignIn';
import PassReset from '../containers/login/PassReset';
import PassToken from '../containers/login/PassToken';
import Alert from '../containers/alert/Index';
import store from "../store";
import FourHundredFour from "../components/404/Index";

const Index = ( { } ) => {
    let isAuth = store.getState().auth.isAuthenticated;

    const [ hidden, setHidden ] = useState( false );

    const [ hiddenMobile, setHiddenMobile ] = useState( false );



    const onHideSideBar = () => {
        setHidden( !hidden );
    }
    return (
        <Fragment>
            <Switch>
                <Route exact path="/:token/HhQj7itZ6R6wTvOJqoI9" component={ WidgetRoute } />
                <Route exact path="/qr" component={ Qr } />
                <Route exact path="/calendarui" component={ CalendarUi } />
                <div className={ `main-body leftmenu ${ hidden ? "main-sidebar-hide" : "" } ${ hiddenMobile ? "main-sidebar-show" : "" }` } style={ { zoom: "85%" } } >
                    {/* <PushNotification /> */ }
                    <Alert />
                    { isAuth ?
                        <Navigation
                            onHideSideBar={ onHideSideBar }
                            setHiddenMobile={ setHiddenMobile }
                            hiddenMobile={ hiddenMobile }
                            hidden={ hidden }
                            isAuthenticated={ isAuth }
                            user={ store.getState().auth.user } >
                            <Route exact path="/" component={ Landing } />
                            <Route exact path="/terms" component={ Terms } />
                            <PrivateRoute exact path="/dashboard" component={ Dashboard } />
                            <PrivateRoute exact path="/mosques" component={ Mosques } />
                            <PrivateRoute exact path="/admin/prayer/:token" component={ Prayer } />
                            <PrivateRoute exact path="/admin/calendar/:token" component={ Calendar } />
                            <PrivateRoute exact path="/admin/roles/:token" component={ Roles } />
                            <Route exact path="/adhkarm" component={ Adhkarm } />
                            <Route exact path="/adhkars" component={ Adhkars } />
                            <PrivateRoute exact path="/admin/settings/:token" component={ Settings } />
                            <PrivateRoute exact path="/admin/newsletter/:token" component={ Newsletter } />
                            <PrivateRoute exact path="/admin/television/:token" component={ Television } />
                            <PrivateRoute exact path="/admin/television/visuals/:token" component={ TelevisionAdhan } />
                            <PrivateRoute exact path="/admin/Donation/:token" component={ Donation } />
                            <PrivateRoute exact path="/entity/:token" component={ Entity } />
                            <PrivateRoute exact path="/profile" component={ Profile } />
                            <PrivateRoute exact path="/create/entity" component={ CreateEntity } />
                            <Route exact path="/shared/:id/:nbr" component={ SharedLink } />

                        </Navigation>
                        :
                        <>
                            <Route exact path="/" component={ Landing } />
                            <Route exact path="/email/:token" component={ ConfirmEmail } />
                            <Route exact path="/confirm/email" component={ EmailConfirmation } />
                            <Route exact path="/signup" component={ SignUp } />
                            <Route exact path="/signin" component={ SignIn } />
                            <Route exact path="/resetpassword" component={ PassReset } />
                            <Route exact path="/change/password/:token" component={ PassToken } />
                            <Route exact path="/terms" component={ Terms } />
                            <Route exact path="/privacy/policy" component={ Privacy } />
                            <Route exact path="/shared/:id/:nbr" component={ SharedLink } />
                            <Route exact path="/aboutus" component={ AboutUs } />
                            <Route exact path="/contactus" component={ ContactUs } />
                            <Route exact path="/raspberry" component={ Raspberry } />
                        </>

                    }

                </div>
                <Redirect to={ '/dashboard' } />

            </Switch>
        </Fragment>
    )
}

Index.propTypes = {

}

export default Index
