import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux';


//actions
import { confirmUserMail } from '../../action/auth';

const ConfirmEmail = ( { confirmUserMail, match } ) => {

    useEffect( () => {
        confirmUserMail( match.params.token );
    }, [ confirmUserMail ] );

    const redirect = () => {
        setTimeout( () => {
            window.location.replace( `/signin` );
        }, 4000 );
    };

    return (
        <Fragment>
            <div className="content-bg-wrap bg-landing">{ redirect() }</div>
        </Fragment>
    )
}


const mapStateToProps = state => ( {
    alerts: state.alert
} );

export default connect( mapStateToProps, { confirmUserMail } )( ConfirmEmail );
