import React from 'react'
import { connect } from 'react-redux'
import SummerNote from './SummerNote';
import '../../Prayer/subSections/style.css'
import Dropzone from "react-dropzone";
import { useTranslation } from 'react-i18next';
export const CovidRole = ( {
    onChangeContent,
    onChange,
    setFileNames,
    fileNames,
    handleDrop,
    onSubmit,
    loading,
    formData,
    setformData,
    onButtonClick,
    inputEl,
} ) => {
    const { t } = useTranslation();
    const fileChangedHandler = async e => {
        if ( e ) {
            setFileNames( e.target.files );

        }
    };
    return (

        <div className="container-fluid elementToFadeInAndOut">
            <div className="inner-body">
                <div className="row row-sm" >
                    <div className="col-lg-12 col-md-12">
                        <div className="card custom-card">
                            <div className="row row-sm" >
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="table-responsive border userlist-table">
                                                <div className="row row-sm" >
                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="card custom-card">
                                                            <div className="card-body">
                                                                <div className="form-group">
                                                                    <small className="main-content-label mb-1">{ t( "website.newsLetterTitle" ) } </small>
                                                                    <br />
                                                                    <input
                                                                        name="title"
                                                                        value={ formData.title }
                                                                        onChange={ e => onChange( e ) }
                                                                        label="0"
                                                                        type="text"
                                                                        className="form-control input-lg"
                                                                        style={ { color: "#000" } }
                                                                    />
                                                                    <br />
                                                                    <small className="main-content-label mb-1">{ t( "website.newsLetterContent" ) }  </small>
                                                                    <br />
                                                                    <SummerNote
                                                                        onChangeContent={ onChangeContent }
                                                                        setformData={ setformData }
                                                                        formData={ formData }
                                                                    />
                                                                    <br />

                                                                    <button className='dropzone'
                                                                        onClick={ () => onButtonClick() }
                                                                    >{ t( "website.newsLetterImport" ) }</button>
                                                                    { fileNames.length > 0 && <div>
                                                                        <strong> 🌄 : { fileNames.length }</strong>
                                                                    </div> }
                                                                    <div style={ { float: "left", marginLeft: "90%" } }>
                                                                        { loading ? <div className="spinner-grow" style={ { width: "5rem", height: "5rem", marginLeft: "40%" } } role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                                        </div> : <div >
                                                                            <button type="button" className="btn ripple btn-dark btn-block" onClick={ ( e ) => onSubmit( e ) } >{ t( "landing.sendmail" ).slice( 0, 7 ) }</button>
                                                                        </div> }
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <input
                type="file"
                id="files"
                name="files"
                style={ { display: "none" } }
                onChange={ fileChangedHandler }
                ref={ inputEl }
                multiple
            />
        </div>



    )
}

const mapStateToProps = ( state ) => ( {

} )

const mapDispatchToProps = {

}

export default connect( mapStateToProps, mapDispatchToProps )( CovidRole )
