import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { isMobile, isTablet } from '../../utils/device';
import OwlCarousel from 'react-owl-carousel';
import saoudi from '../../assets/flags/saoudi.png';
import england from '../../assets/flags/england.png';
import french from '../../assets/flags/french.png';
import pakistan from '../../assets/flags/pakistan.png';
import bangladesh from '../../assets/flags/bangladesh.jpeg';
import './css/icofont.min.css'
import './css/owl.carousel.min.css'
import './css/bootstrap.min.css'
import './css/aos.min.css'
import './css/responsive.min.css'
import './css/style.min.css'

export const Index = ( { props } ) => {

    const { t, i18n } = useTranslation();
    const onChangeLanguage = ( language ) => {
        i18n.changeLanguage( language )
        localStorage.setItem( "lang", language )
    }
    return (
        <div className="page_wrapper">

            <div class="page_wrapper">

                <div id="preloader">

                    <div id="loader">
                        <img alt="avatar" src="https://general.eu-central-1.linodeobjects.com/basics/platforms/moonode_logo.png" style={ { height: '130px', width: '130px' } } />
                    </div>
                </div>

                <header>

                    <div className="container">

                        <nav className="navbar navbar-expand-lg">
                            <a className="navbar-brand" href="#">
                                <img src="https://general.eu-central-1.linodeobjects.com/basics/platforms/moonode_logo.png" style={ { height: isMobile() ? '45px' : '30px', width: isMobile() ? '45px' : '30px' } } alt="image" />
                            </a>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon">
                                    <div className="toggle-wrap">
                                        <span className="toggle-bar"></span>
                                    </div>
                                </span>
                            </button>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item active">
                                        <a className="nav-link" href="/">{ t( 'application.home' ) }</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/aboutus">{ t( 'common.about' ) }</a>
                                    </li>
                                    <li className="nav-item has_dropdown">
                                        <a className="nav-link" href="#">{ t( 'profile.language' ) }</a>
                                        <span className="drp_btn">⤵</span>
                                        <div className={ `${ isMobile() ? "" : "sub_menu" }` }>
                                            <ul>
                                                <li> <a style={ { cursor: "pointer" } } onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'ar' ) } } className="dropdown-item d-flex ">
                                                    <span className="avatar  mr-3 align-self-center bg-transparent"><img src={ saoudi } alt="img" /></span>
                                                    <div className="d-flex">
                                                        <span className="mt-2">{ t( "website.arabic" ) }</span>
                                                    </div>
                                                </a></li>
                                                <li> <a style={ { cursor: "pointer" } } onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'en' ) } } className="dropdown-item d-flex">
                                                    <span className="avatar  mr-3 align-self-center bg-transparent"><img src={ england } alt="img" /></span>
                                                    <div className="d-flex">
                                                        <span className="mt-2">{ t( "website.english" ) }</span>
                                                    </div>
                                                </a></li>
                                                <li> <a style={ { cursor: "pointer" } } onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'fr' ) } } className="dropdown-item d-flex">
                                                    <span className="avatar mr-3 align-self-center bg-transparent"><img src={ french } alt="img" /></span>
                                                    <div className="d-flex">
                                                        <span className="mt-2">{ t( "website.french" ) }</span>
                                                    </div>
                                                </a></li>
                                                <li> <a style={ { cursor: "pointer" } } onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'ur' ) } } className="dropdown-item d-flex">
                                                    <span className="avatar mr-3 align-self-center bg-transparent"><img src={ pakistan } alt="img" /></span>
                                                    <div className="d-flex">
                                                        <span className="mt-2">{ t( "website.urdu" ) }</span>
                                                    </div>
                                                </a></li>
                                                <li> <a style={ { cursor: "pointer" } } onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'bn' ) } } className="dropdown-item d-flex">
                                                    <span className="avatar mr-3 align-self-center bg-transparent"><img src={ bangladesh } alt="img" /></span>
                                                    <div className="d-flex">
                                                        <span className="mt-2">{ t( "website.bengali" ) }</span>
                                                    </div>
                                                </a></li>
                                            </ul>
                                        </div>
                                    </li>

                                    <li className="nav-item has_dropdown">
                                        <a className="nav-link" href="#">{ t( 'landingSite.blog' ) }</a>
                                        <span className="drp_btn">⤵</span>
                                        <div className={ `${ isMobile() ? "" : "sub_menu" }` }>
                                            <ul>
                                                <li><a style={ { marginLeft: isMobile() ? "30px" : "" } } href="/raspberry">Raspberry</a></li>

                                            </ul>
                                        </div>
                                    </li>


                                    <li className="nav-item">
                                        <a className="nav-link" href="/contactus">{ t( 'application.contactUs' ) }</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link dark_btn" href="/signin">{ t( 'landing.signin' ) }</a>
                                    </li>
                                </ul>
                            </div>
                        </nav>

                    </div>

                </header>

                <div class="bred_crumb">
                    <div class="container">
                        <span class="banner_shape1"> <img src={ require( '../../assets/img/mashaAllah-actif.png' ) } style={ { width: "40px", height: "40px" } } alt="image" /> </span>
                        <span class="banner_shape2"> <img src={ require( '../../assets/img/mashaAllah-actif.png' ) } style={ { width: "40px", height: "40px" } } alt="image" /> </span>
                        <span class="banner_shape3"> <img src={ require( '../../assets/img/mashaAllah-actif.png' ) } style={ { width: "40px", height: "40px" } } alt="image" /> </span>

                        <div class="bred_text">
                            <h1>{ t( "application.aboutUs" ) }</h1>
                            <ul>
                                <li><a href="/">{ t( "application.home" ) }</a></li>
                                <li><span>»</span></li>
                                <li><a href="/aboutus">{ t( "application.aboutUs" ) }</a></li>
                            </ul>
                        </div>
                    </div>
                </div>


                <section class="row_am app_solution_section">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="app_text">
                                    <div class="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                                        <h2><span>{ t( "application.theTeam" ) } { " " } <br /> </span>{ t( 'application.whoWeAre' ) } ?
                                        </h2>
                                    </div>
                                    <h3 data-aos="fade-up" data-aos-duration="1500">
                                        { t( 'application.aboutUsDesc' ) }
                                    </h3>

                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                <br />
                <br />

                <section class="row_am why_we_section" data-aos="fade-in">
                    <div class="why_inner">
                        <div class="container">
                            <div class="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                                <h2><span>                                        Moonode
                                </span>  { t( 'application.whatIsMoonode' ) }</h2>
                                <p>{ t( "application.ourService" ) } : { t( "application.howMuch" ) }</p>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-lg-4">
                                    <div class="why_box" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                                        <div class="icon">
                                            <img src={ require( "./images/mobile.jpg" ) } alt="image" />
                                        </div>
                                        <div class="text">
                                            <h3>  { t( 'website.application' ) }</h3>
                                            <p>
                                                { t( 'application.moonPhone' ) }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-4">
                                    <div class="why_box" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="200">
                                        <div class="icon">
                                            <img src={ require( "./images/website.png" ) } alt="image" />
                                        </div>
                                        <div class="text">
                                            <h3>     { t( 'common.adhanWebSite' ) }</h3>
                                            <p>{ t( 'application.moonWebsite' ) }</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-4">
                                    <div class="why_box" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                                        <div class="icon">
                                            <img src={ require( "./images/Tv.png" ) } alt="image" style={ { width: "65%" } } />
                                        </div>
                                        <div class="text">
                                            <h3>                    { t( 'landingSite.tv1' ) }</h3>
                                            <p>{ t( 'application.moonTv' ) }

                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <br />
                <br />
                <br />
                <br />
                <br />
            </div>
        </div>
    )
}

const mapStateToProps = ( state ) => ( {

} )

const mapDispatchToProps = {

}

export default connect( mapStateToProps, mapDispatchToProps )( Index )
