import React from 'react';
import confirmEmail from './confirm.jpeg';

const EmailConfirmation = () => {
    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh', // to fill the entire viewport height
            textAlign: 'center',
        },
        message: {
            fontSize: '24px',
            marginBottom: '20px',
        },
        image: {
            maxWidth: '300px', // adjust as needed
            width: '100%',
            marginBottom: '20px', // adding a margin to separate from the box
        },
        spamBox: {
            backgroundColor: 'orange',
            padding: '10px 20px',
            borderRadius: '8px',
            color: 'white',
            fontSize: '16px',
            maxWidth: '80%', // Ensuring the box doesn't get too wide
            textAlign: 'center',
        }
    };

    return (
        <div style={ styles.container }>
            <div style={ styles.message }>
                Confirm your email
            </div>
            <img style={ styles.image } src={ confirmEmail } alt="Confirm Email Illustration" />
            <div style={ styles.spamBox }>
                Check your spam in case you can't find the registration email
            </div>
        </div>
    );
}

export default EmailConfirmation;
