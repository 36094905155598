import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

export const Index = ( { user, logoutUser, onChange, bio, onSave, saving } ) => {
    const { t } = useTranslation();



    return (
        <div>
            <div className="main-content-body tab-pane p-4 border-top-0 active" id="edit">
                <div className="card-body border">
                    <div className="mb-4 main-content-label">{ t( "application.general" ) }</div>
                    <form className="form-horizontal">
                        <div className="form-group ">
                            <div className="row row-sm" >
                                <div className="col-md-3">
                                    <label className="form-label">{ t( "common.name" ) }</label>
                                </div>
                                <div className="col-md-9">
                                    <input type="text"
                                        className="form-control"
                                        placeholder={ t( "common.name" ) }
                                        name="name"
                                        onChange={ ( e ) => onChange( e ) }
                                        value={ bio.name }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group ">
                            <div className="row row-sm" >
                                <div className="col-md-3">
                                    <label className="form-label">{ t( "common.handle" ) }</label>
                                </div>
                                <div className="col-md-9">
                                    <input type="text"
                                        className="form-control"
                                        placeholder={ t( "common.handle" ) }
                                        name="handle"
                                        onChange={ ( e ) => onChange( e ) }
                                        value={ bio.handle }
                                    />
                                </div>
                                <br />
                                { saving ? <div className="spinner-grow" style={ { width: "5rem", height: "5rem", marginLeft: "40%" } } role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> :
                                    <div className="col-md-9" style={ { float: "right" } }>
                                        <button type="button" className="btn btn-primary btn-sm" onClick={ () => onSave() }>{ t( "common.save" ) }</button>
                                    </div> }
                            </div>

                        </div>
                    </form>
                </div>

            </div>
        </div>
    )
}

const mapStateToProps = ( state ) => ( {

} )

const mapDispatchToProps = {

}

export default connect( mapStateToProps, mapDispatchToProps )( Index )
