import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { getSpecialPosts } from '../../../action/entity'
import EntityPost from '../../../components/posts/entityPosts';
import { addLike, tvChange, privacyChange, deletePost } from '../../../action/post';
import EmptyPosts from '../../../components/empty/emptyPosts';

export const Index = ( { getSpecialPosts, isAdmin, tvChange, privacyChange, deletePost, post: { specialPostsLink, specialPostsAudio, specialPostsVideo, specialPostsImage, specialPostsPdf, specialPostsTv, postEntityId, specialPostLoading }, codeEntity, auth: { user }, addLike, picture, name, postsType } ) => {

    useEffect( () => {
        getSpecialPosts( codeEntity );
    }, [ getSpecialPosts ] );


    const onChangeLike = async ( index, postsId, postNbr, specialPostEntity ) => {
        let x = {};
        const newPosts = Array.isArray( specialPostEntity ) ? [ ...specialPostEntity ] : [];

        if ( newPosts[ index ] && user && user.user ) {
            let idx = newPosts[ index ].likes.map( item => item.user.toString() ).indexOf( user.user.id );
            // ...rest of your code...

            if ( idx >= 0 ) {
                newPosts[ index ].likes.splice( idx, 1 );
            } else {
                newPosts[ index ].likes.unshift( { id: "fakeide", user: user.user.id } );
            }
        }

        await addLike( postsId, postNbr, x );
        // setTheLikes( newPosts );
        getSpecialPosts( codeEntity );
    };
    const onChangeTv = ( index, postsId, postNbr ) => {
        const newPosts = [ ...specialPostsTv ];
        newPosts[ index ].isTv = false;
        // setdynamicPosts( newPosts );
    };

    return (
        <Fragment>
            { specialPostLoading && <div className="spinner-grow" style={ { width: "5rem", height: "5rem" } } role="status">
                <span className="sr-only">Loading...</span>
            </div> }

            { postsType === "tvAds" && !specialPostLoading ?
                <span>

                    { specialPostsTv.length > 0 ? specialPostsTv.map( ( i, index ) => (
                        <div key={ index } style={ { margin: "-1rem" } }>
                            { ( !i.deleted ) && ( i.isTv ) && <EntityPost
                                picture={ picture }
                                name={ name }
                                isAdmin={ isAdmin }
                                deletePost={ deletePost }
                                privacyChange={ privacyChange }
                                tvChange={ tvChange }
                                feed={ i } show={ true }
                                postEntityId={ postEntityId }
                                onChangeLike={ onChangeLike }
                                index={ index }
                                user={ user }
                            >

                            </EntityPost>
                            }
                        </div> ) ) :
                        <EmptyPosts image={ "https://moonode-profile.s3.ca-central-1.amazonaws.com/website/notv.png" } />

                    }
                </span>
                : null }

            { postsType === "audio" && !specialPostLoading ?
                <span>


                    { specialPostsAudio.length > 0 ? specialPostsAudio.slice( 0, 100 ).map( ( i, index ) => (
                        <div key={ index } style={ { margin: "-1rem" } }>
                            { ( !i.deleted ) && ( i.fileType === postsType ) && <EntityPost
                                picture={ picture }
                                name={ name }
                                isAdmin={ isAdmin }
                                deletePost={ deletePost }
                                privacyChange={ privacyChange }
                                tvChange={ tvChange }
                                feed={ i } show={ true }
                                postEntityId={ postEntityId }
                                onChangeLike={ onChangeLike }
                                index={ index }
                                user={ user }
                            >

                            </EntityPost>
                            }
                        </div> ) ) : <EmptyPosts image={ "https://moonode-profile.s3.ca-central-1.amazonaws.com/website/noaudio.png" } /> }
                </span>
                : null }

            { postsType === "video" && !specialPostLoading ?
                <span>


                    { specialPostsVideo.length > 0 ? specialPostsVideo.slice( 0, 100 ).map( ( i, index ) => (
                        <div key={ index } style={ { margin: "-1rem" } }>
                            { ( !i.deleted ) && ( i.fileType === postsType ) && <EntityPost
                                picture={ picture }
                                name={ name }
                                isAdmin={ isAdmin }
                                deletePost={ deletePost }
                                privacyChange={ privacyChange }
                                tvChange={ tvChange }
                                feed={ i } show={ true }
                                postEntityId={ postEntityId }
                                onChangeLike={ onChangeLike }
                                index={ index }
                                user={ user }
                            >
                            </EntityPost>
                            }
                        </div> ) ) :
                        <EmptyPosts image={ "https://moonode-profile.s3.ca-central-1.amazonaws.com/website/novideo.png" } />

                    }


                </span>
                : null }
            { postsType === "pdf" && !specialPostLoading ?
                <span>


                    { specialPostsPdf.length > 0 ? specialPostsPdf.slice( 0, 100 ).map( ( i, index ) => (
                        <div key={ index } style={ { margin: "-1rem" } }>
                            { ( !i.deleted ) && ( i.fileType === postsType ) && <EntityPost
                                picture={ picture }
                                name={ name }
                                isAdmin={ isAdmin }
                                deletePost={ deletePost }
                                privacyChange={ privacyChange }
                                tvChange={ tvChange }
                                feed={ i } show={ true }
                                postEntityId={ postEntityId }
                                onChangeLike={ onChangeLike }
                                index={ index }
                                user={ user }
                            >

                            </EntityPost>
                            }
                        </div> ) ) :
                        <EmptyPosts image={ "https://moonode-profile.s3.ca-central-1.amazonaws.com/website/nodocument.png" } />

                    }

                </span>
                : null }

            { postsType === "image" && !specialPostLoading ?
                <span>


                    { specialPostsImage.length > 0 ? specialPostsImage.slice( 0, 100 ).map( ( i, index ) => (
                        <div key={ index } style={ { margin: "-1rem" } }>
                            { ( !i.deleted ) && ( i.fileType === postsType ) && <EntityPost
                                picture={ picture }
                                name={ name }
                                isAdmin={ isAdmin }
                                deletePost={ deletePost }
                                privacyChange={ privacyChange }
                                tvChange={ tvChange }
                                feed={ i } show={ true }
                                postEntityId={ postEntityId }
                                onChangeLike={ onChangeLike }
                                index={ index }
                                user={ user }
                            >

                            </EntityPost>
                            }
                        </div> ) ) :
                        <EmptyPosts image={ "https://moonode-profile.s3.ca-central-1.amazonaws.com/website/nopicture.png" } />


                    }

                </span>
                : null }

        </Fragment>


    )
}

const mapStateToProps = ( state ) => ( {
    post: state.post,
    auth: state.auth
} )


export default connect( mapStateToProps, { getSpecialPosts, addLike, tvChange, privacyChange, deletePost, } )( Index )
