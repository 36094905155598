import React, { Fragment, useState } from 'react'

const Pdfs = ( { post } ) => {
    const [ show, setshow ] = useState( false )
    let playlists = [];
    try {
        for ( let index = 0; index < post.pdf.length; index++ ) {
            let pdf = `https://docs.google.com/viewer?url=${ post.pdf[ index ] }&embedded=true`
            playlists.unshift( pdf );
        }
    } catch ( error ) {
        // alert( "🔺oups! error" );
    }

    // https://docs.google.com/gview?url=
    const onClickShow = () => {
        setshow( !show )
    }

    return (
        <div className="fullsizevid" style={ { marginBottom: "20px" } }>
            <div className="embed-video">
                <iframe src={ playlists[ 0 ] }
                    style={ { width: '100%', height: '600px' } } ></iframe>
            </div>
        </div>
    )
}

export default Pdfs;