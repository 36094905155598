import React, { Fragment } from "react";
// import AudioPlayer from "react-responsive-audio-player";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const Audios = ( { post } ) => {
    let playlists = [];

    for ( let index = 0; index < post.audio.length; index++ ) {
        var parser = document.createElement( "a" );
        parser.href = post.audio[ index ];

        playlists.unshift( {
            url: post.audio[ index ],
            title: "titre : " + parser.pathname
                .replace( /(?:https?|ftp):\/\/[\n\S]+/g, "" )
                .replace( /\.[^/.]+$/, "" )
                .replace( "/", "" )

        } );
    }

    return (
        <Fragment>
            <AudioPlayer
                src={ post.audio[ 0 ] }
                onPlay={ e => console.log( "onPlay" ) }
            // other props here
            />
        </Fragment>
    );
};

export default Audios;
