import React from 'react'
import { connect } from 'react-redux'

export const Privacy = ( props ) => {
    return (
        <div>
            <div className="landing-page content-bg-wrap">
                <div className="body-bg-white">
                    <section className="medium-padding120">
                        <div className="container">
                            <ul className="table-careers">
                                <li className="head">
                                    <span></span>
                                    <span style={ { fontSize: '2.5vw' } }>Privacy Policy</span>
                                    <span></span>
                                </li>
                            </ul>
                            <p style={ { textAlign: "justify", fontSize: '1.5vw' } }>
                                - English <br /><br />

                                Effective Date: { new Date().getFullYear() }<br />

                                This Privacy Policy describes the policies and procedures of Moonode Inc. ("We", "Us", "Our") on the collection, use, security, and disclosure of personal information about you on Moonode (the "App"), as well as the services, features, content, or applications offered by us. It also describes your choices regarding use, access, and correction of your personal information.
                                <br /><br />
                                1. Scope
                                This Privacy Policy applies to personal information processed by us, including on our websites, mobile applications, television, and other online or offline offerings. We are committed to protecting your privacy across all our platforms and ensuring transparency in how we handle your data.
                                <br /><br />
                                2. Information We Collect
                                We do not actively collect any personal or sensitive data from our users. Our App functions as a passive digital signage application providing information to its users. When you interact with our mobile application, television, or website, we may collect non-personal information such as device type, operating system, and general usage statistics to improve our services and ensure a better user experience. Rest assured, your privacy is of utmost importance to us.
                                <br /><br />
                                3. How We Use the Information We Collect
                                As our App does not actively collect personal or sensitive data, no data is used or shared with third-party entities. However, we may use the non-personal information we collect, such as device type and usage statistics, to analyze trends, enhance our products and services, and improve overall user experience across our platforms. We strive to provide you with the best possible service and ensure that your data is utilized responsibly.
                                <br /><br />
                                4. How We Share the Information We Collect
                                Given that we do not collect personal or sensitive data, we do not share any such data with third parties. However, we may share non-personal information, such as aggregated usage statistics and trends, with our partners, advertisers, or other third parties for analytical or marketing purposes. These collaborations aim to provide you with a more personalized and engaging experience.
                                <br /><br />
                                5. Your Choices and Rights
                                We acknowledge your right to exercise control over your personal data. Depending on the applicable laws in your jurisdiction, you may have the right to access, update, correct, or delete the information we hold about you. If you wish to exercise these rights or have any questions regarding your personal data, please contact us using the provided contact details in this Privacy Policy. We are committed to respecting your preferences and safeguarding your data rights.
                                <br /><br />
                                6. Data Security
                                We implement industry-standard security measures designed to protect the security of all information we process. We utilize encryption, firewalls, and other security technologies to safeguard your data. However, please also remember that we cannot guarantee that the internet itself is 100% secure. Therefore, while we strive to protect your personal information, we cannot guarantee its absolute security. We continuously monitor and update our security practices to stay ahead of emerging threats.
                                <br /><br />
                                7. Data Retention and Deletion
                                We do not retain any personal or sensitive data. Therefore, we do not have a data retention or deletion policy. However, non-personal information, such as usage statistics, may be retained for analytical purposes and improving our services. We ensure that such retained information is anonymous and does not identify any individual. Your privacy is paramount, and we take all necessary precautions to maintain the confidentiality of your information.
                                <br /><br />
                                8. Children's Information
                                Our App, mobile application, television, and website are not directed to children under the age of 13, and we do not knowingly collect personal data from children without obtaining parental consent. If we become aware that we have collected personal information from a child without parental consent, we will take steps to remove that information from our systems. We are committed to protecting the privacy and safety of children online.
                                <br /><br />
                                9. Changes to this Privacy Policy


                                We may update this Privacy Policy from time to time to reflect changes in our practices, technology, legal requirements, and other factors. If we do, we will update the "effective date" at the top of the Privacy Policy and notify you of any material changes through our platforms or other means as required by applicable law. We encourage you to review this Privacy Policy periodically to stay informed about how we handle your information.
                                <br /><br />
                                10. How to Contact Us
                                If you have any questions or comments about this Privacy Policy or our practices, or if you would like to exercise your rights and choices, please contact us at [admin@moonode.com]. We are dedicated to addressing any concerns or inquiries you may have regarding your privacy and ensuring a transparent and secure experience for all our users. Your feedback is valuable to us as we strive to provide the highest level of privacy protection.
                            </p>
                            <br />

                            <hr />
                            <br />
                            <p style={ { textAlign: "justify", fontSize: '1.5vw' } }>
                                Français <br /> <br />

                                Date d'entrée en vigueur : { new Date().getFullYear() }<br /><br />

                                Politique de confidentialité<br /><br />


                                Cette politique de confidentialité décrit les politiques et procédures de Moonode Inc. ("Nous", "Notre") concernant la collecte, l'utilisation, la sécurité et la divulgation des informations personnelles vous concernant sur Moonode (l'"Application"), ainsi que les services, fonctionnalités, contenus ou applications proposés par nous. Elle décrit également vos choix en matière d'utilisation, d'accès et de correction de vos informations personnelles.
                                <br /><br />
                                1. Champ d'application
                                Cette politique de confidentialité s'applique aux informations personnelles traitées par nous, y compris sur nos sites web, applications mobiles et autres offres en ligne ou hors ligne. Nous nous engageons à protéger votre vie privée sur l'ensemble de nos plateformes.
                                <br /><br />
                                2. Informations que nous collectons
                                Nous ne collectons pas activement de données personnelles ou sensibles auprès de nos utilisateurs. Notre application fonctionne comme une application d'affichage numérique passif fournissant des informations à ses utilisateurs. Lorsque vous interagissez avec notre application mobile, notre télévision ou notre site web, nous pouvons collecter des informations non personnelles telles que le type d'appareil, le système d'exploitation et des statistiques d'utilisation générales afin d'améliorer nos services et de garantir une meilleure expérience utilisateur.
                                <br /><br />
                                3. Utilisation des informations que nous collectons
                                Étant donné que notre application ne collecte pas activement de données personnelles ou sensibles, aucune donnée n'est utilisée ou partagée avec des tiers. Cependant, nous pouvons utiliser les informations non personnelles que nous collectons, telles que le type d'appareil et les statistiques d'utilisation, pour analyser les tendances, améliorer nos produits et services, et améliorer l'expérience utilisateur globale sur nos plateformes.
                                <br /><br />
                                4. Partage des informations que nous collectons
                                Étant donné que nous ne collectons pas de données personnelles ou sensibles, nous ne partageons pas de telles données avec des tiers. Cependant, nous pouvons partager des informations non personnelles, telles que des statistiques agrégées d'utilisation et des tendances, avec nos partenaires, annonceurs ou autres tiers à des fins d'analyse ou de marketing. Ces collaborations visent à vous offrir une expérience plus personnalisée et engageante.
                                <br /><br />
                                5. Vos choix et vos droits
                                Nous reconnaissons votre droit à exercer un contrôle sur vos données personnelles. Selon les lois applicables dans votre juridiction, vous pourriez avoir le droit d'accéder, de mettre à jour, de corriger ou de supprimer les informations que nous détenons à votre sujet. Si vous souhaitez exercer ces droits ou avoir des questions concernant vos données personnelles, veuillez nous contacter en utilisant les coordonnées fournies dans cette politique de confidentialité. Nous nous engageons à respecter vos préférences et à protéger vos droits en matière de données
                                <br /><br />
                                6. Sécurité des données
                                Nous mettons en œuvre des mesures de sécurité conformes aux normes de l'industrie pour protéger la sécurité de toutes les informations que nous traitons. Nous utilisons le chiffrement, les pare-feu et d'autres technologies de sécurité pour protéger vos données. Cependant, veuillez également garder à l'esprit que nous ne pouvons garantir que l'internet lui-même est 100% sécurisé. Par conséquent, bien que nous nous efforcions de protéger vos informations personnelles, nous ne pouvons garantir leur sécurité absolue.
                                <br /><br />
                                7. Conservation et suppression des données
                                Nous ne conservons pas de données personnelles ou sensibles. Par conséquent, nous n'avons pas de politique de conservation ou de suppression des données. Cependant, des informations non personnelles, telles que des statistiques d'utilisation, peuvent être conservées à des fins d'analyse et d'amélioration de nos services. Nous veillons à ce que ces informations conservées soient anonymes et ne permettent pas d'identifier un individu. Votre vie privée est primordiale, et nous prenons toutes les précautions nécessaires pour maintenir la confidentialité de vos informations.
                                <br /><br />
                                8. Informations concernant les enfants
                                Notre application, notre application mobile, notre télévision et notre site web ne s'adressent pas aux enfants. Si nous constatons que nous avons collecté des informations personnelles auprès d'un enfant sans le consentement parental, nous prendrons des mesures pour supprimer ces informations de nos systèmes. Nous nous engageons à protéger la vie privée et la sécurité des enfants en ligne.
                                <br /><br />
                                9. Modifications de cette politique de confidentialité
                                Nous pouvons mettre à jour cette politique de confidentialité de temps à autre pour refléter les changements dans nos pratiques, notre technologie, nos obligations légales et d'autres facteurs. Le cas échéant, nous mettrons à jour la "date d'entrée en vigueur" en haut de la politique de confidentialité et vous informerons de tout changement important via nos plateformes ou d'autres moyens, conformément à la loi applicable. Nous vous encourageons à consulter régulièrement cette politique de confidentialité pour vous tenir informé de notre traitement de vos informations.
                                <br /><br />
                                10. Comment nous contacter
                                Si vous avez des questions ou des commentaires concernant cette politique de confidentialité ou nos pratiques, ou si vous souhaitez exercer vos droits et choix,
                                veuillez nous contacter aux coordonnées suivantes : [admin@moonode.com]. Nous nous engageons à répondre à toutes les préoccupations ou questions que vous pourriez avoir concernant votre vie privée et à garantir une expérience transparente et sécurisée pour tous nos utilisateurs. Vos commentaires sont précieux pour nous alors que nous nous efforçons de vous offrir le plus haut niveau de protection de la vie privée.
                            </p>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ( state ) => ( {} )

const mapDispatchToProps = {}

export default connect( mapStateToProps, mapDispatchToProps )( Privacy )