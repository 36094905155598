import React, { Fragment, useState, useEffect, useContext, useRef } from 'react'
import { connect } from 'react-redux'
import { UserProfileContext } from "../../../Context.js";
import { MoonodeLogo } from '../../../utils/Base64Logos'

import Calendar from '../../../components/admin/Calendar/Index';
import { getEntity, getCalendar, getCalendarAdhan } from "../../../action/entity";

export const Index = ( { match, getEntity, getCalendar, getCalendarAdhan, entity: { entityProfil, loading, entity } } ) => {
    const { userProfile } = useContext( UserProfileContext );
    const [ localLoading, setLocalLoading ] = useState( false )
    /***    
    * First thing first.
    */
    useEffect( () => {
        if ( userProfile ) {
            const { codeEntity, profile } = userProfile;
            const { token } = match.params;
            if ( ( encodeURI( codeEntity ).localeCompare( token ) === 0 ) && ( profile.isRole || profile.isAdmin ) ) {
                getEntity( codeEntity );
            } else {
                alert( "🟥" )
                window.location.replace( "/dashboard" );
            }
        }
        return () => {
        }
    }, [ loading, userProfile ] );

    const islamicMonthsLong = [ "Muḥarram | ",
        "Ṣafar  | ",
        "Rabīʿ al-awwal | ",
        "Rabīʿ al-thānī | ",
        "Jumādá al-ūlá | ",
        "Jumādá al-ākhirah | ",
        "Rajab | ",
        "Shaʿbān | ",
        "Ramaḍān | ",
        "Shawwāl | ",
        "Dhū al-Qaʿdah | ",
        "Dhū al-Ḥijjah | " ]

    const islamicMonthsShort = [ "Muhh", "Safr", "Rabi", "Rab2", "Jum1", "Jum2", "Rajb", "Shbn", "Rmdn", "Shwl", "Qa3d", "DhuH" ];

    //ADHAN + IQAMA
    const [ choosenMonth, setchoosenMonth ] = useState( null )
    const [ choosenMonthShort, setchoosenMonthShort ] = useState( null )
    const [ choosenMonthLong, setchoosenMonthLong ] = useState( null )
    //ADHAN 
    const [ choosenMonthAdhan, setchoosenMonthAdhan ] = useState( null )
    const [ choosenMonthShortAdhan, setchoosenMonthShortAdhan ] = useState( null )
    const [ choosenMonthLongAdhan, setchoosenMonthLongAdhan ] = useState( null )


    const [ choosenYear, setchoosenYear ] = useState( '' )
    const [ entityLogoBase64, setentityLogoBase64 ] = useState( MoonodeLogo() )
    const [ body, setbody ] = useState(
        [
            [ 'Feb', 'R', 'Adhan Fajr', 'Adhan Dhuhr', 'Adhan Asr', 'Adhan Maghrib', 'Adhan Isha', { text: 'Iqama Fajr', style: 'underlineCol', fillColor: '#fcb45c' }, { text: 'Iqama Dhuhr', style: 'underlineCol', fillColor: '#fcb45c' }, { text: 'Iqama Asr', style: 'underlineCol', fillColor: '#fcb45c' }, { text: 'Iqama Maghrib', style: 'underlineCol', fillColor: '#fcb45c' }, { text: 'Iqama Isha', style: 'underlineCol', fillColor: '#fcb45c' } ],
            [ '03', '01', 'row223', 'row224', 'row225', 'row226', 'row227', 'row228', 'row229', 'row2210', 'row221', 'row221' ],
            [ '03', '01', 'row223', 'row224', 'row225', 'row226', 'row227', 'row228', 'row229', 'row2210', 'row221', 'row221' ],
            [ '03', '01', 'row223', 'row224', 'row225', 'row226', 'row227', 'row228', 'row229', 'row2210', 'row221', 'row221' ],

        ]
    )
    const [ bodyAdhan, setbodyAdhan ] = useState(
        [
            [ 'Feb', 'R', 'Adhan Fajr', 'Adhan Dhuhr', 'Adhan Asr', 'Adhan Maghrib', 'Adhan Isha', ],
            [ '03', '01', 'row223', 'row224', 'row225', 'row226', 'row227' ],
            [ '03', '01', 'row223', 'row224', 'row225', 'row226', 'row227' ],
            [ '03', '01', 'row223', 'row224', 'row225', 'row226', 'row227' ],

        ]
    )
    const onChangeMonthAdhan = ( e ) => {
        setchoosenMonthAdhan( e.target.value );
        setchoosenMonthShortAdhan( islamicMonthsShort[ e.target.value ] );
        setchoosenMonthLongAdhan( islamicMonthsLong[ e.target.value ] );
    }

    const onChangeMonth = ( e ) => {
        setchoosenMonth( e.target.value );
        setchoosenMonthShort( islamicMonthsShort[ e.target.value ] );
        setchoosenMonthLong( islamicMonthsLong[ e.target.value ] );
    }

    const onChangeYear = ( e ) => {
        setchoosenYear( e.target.value );
    }

    useEffect( () => {
        try {
            async function getData ( params ) {
                setLocalLoading( true )
                if ( entityProfil && choosenMonth ) {
                    let data = {};
                    data.one = entityProfil;
                    data.two = entity.entity;
                    let body = await getCalendar( data, choosenMonth, choosenYear );
                    if ( body ) {
                        setbody( body.body.res );
                        setentityLogoBase64( body.body.image );
                    }
                }
                setLocalLoading( false )
            }
            getData();
        } catch ( err ) {
            console.log( err )
        }
    }, [ choosenMonth, choosenMonthShort ] )

    useEffect( () => {
        async function getDataAdhan ( params ) {
            setLocalLoading( true )
            if ( entityProfil && choosenMonthAdhan ) {
                let data = {};
                data.one = entityProfil;
                data.two = entity.entity;
                let body = await getCalendarAdhan( data, choosenMonthAdhan, choosenYear );
                if ( body ) {
                    setbodyAdhan( body.body.res );
                    setentityLogoBase64( body.body.image );

                }
            }
            setLocalLoading( false )
        }
        getDataAdhan();
    }, [ choosenMonthAdhan, choosenMonthShortAdhan ] )

    return (
        loading ? null : <Calendar
            choosenMonthAdhan={ choosenMonthAdhan }
            choosenMonthShortAdhan={ choosenMonthShortAdhan }
            onChangeMonthAdhan={ onChangeMonthAdhan }
            choosenMonth={ choosenMonth }
            choosenMonthShort={ choosenMonthShort }
            islamicMonthsLong={ islamicMonthsLong }
            islamicMonthsShort={ islamicMonthsShort }
            choosenMonthLong={ choosenMonthLong }
            choosenMonthLongAdhan={ choosenMonthLongAdhan }
            setchoosenMonth={ setchoosenMonth }
            onChangeMonth={ onChangeMonth }
            choosenYear={ choosenYear }
            onChangeYear={ onChangeYear }
            entityLogoBase64={ entityLogoBase64 }
            setentityLogoBase64={ setentityLogoBase64 }
            body={ body }
            bodyAdhan={ bodyAdhan }
            name={ entity.entity.name }
            entityProfil={ entityProfil }
            localLoading={ localLoading }
        />

    )
}



const mapStateToProps = ( state ) => ( {
    entity: state.entity
} )


export default connect( mapStateToProps, { getEntity, getCalendar, getCalendarAdhan } )( Index )
