import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { isMobile, isTablet } from '../../../utils/device';

function Index ( { onChangeData, data, onChangePhone, createEntity } ) {
    const { t } = useTranslation();
    return (
        <div>
            <div className="main-content side-content pt-0">

                <div className="container-fluid" style={ { marginLeft: isMobile() ? "" : "70px" } }>
                    <div className="inner-body">

                        <div className="page-header">
                            <div>
                                <h2 className="main-content-title tx-24 mg-b-5">Checkout</h2>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="#">Ecommerce</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Checkout</li>
                                </ol>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card custom-card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-xl-6 mx-auto">
                                                <div className="checkout-steps wrapper">
                                                    <div id="checkoutsteps">
                                                        <h4>Create mosque</h4>
                                                        <section>
                                                            <form className="needs-validation" noValidate="">
                                                                <h5 className="text-start mb-2">{ t( "common.mosque" ) } Information</h5>
                                                                <p className="mb-4 text-muted tx-13 ms-0 text-start">Lorem Ipsum has been the industry's standard dummy text ever since</p>
                                                                <div className="row">
                                                                    <div className="col-md-6 mb-3">
                                                                        <label name="firstName">{ t( "common.name" ) } { t( "common.mosque" ) }</label>
                                                                        <input
                                                                            className="form-control"
                                                                            type="text"
                                                                            name="name"
                                                                            placeholder={ data.name ? data.name : "nom de la mosquée" }
                                                                            onChange={ e => onChangeData( e ) }
                                                                        />

                                                                    </div>
                                                                    <div className="col-md-6 mb-3">
                                                                        <label name="lastName">{ t( "common.website" ) }</label>
                                                                        <input className="form-control"
                                                                            type="text"
                                                                            name="website"
                                                                            placeholder={ data.website ? data.website : "le site de la mosquée" }
                                                                            onChange={ e => onChangeData( e ) }
                                                                        />

                                                                    </div>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <div className="row">
                                                                        <div className="col-md-6 mb-3">
                                                                            <label name="firstName">{ t( "common.address" ) } # </label>
                                                                            <input
                                                                                className="form-control"
                                                                                type="text"
                                                                                name="address"
                                                                                placeholder={
                                                                                    data.address ? data.address : "le numéro et le nom de la rue"
                                                                                }
                                                                                onChange={ e => onChangeData( e ) }
                                                                            />
                                                                            <div className="invalid-feedback">Valid first name is required.</div>
                                                                        </div>
                                                                        <div className="col-md-6 mb-3">
                                                                            <label name="lastName">{ t( "common.city" ) }</label>
                                                                            <input className="form-control"
                                                                                type="text"
                                                                                name="city"
                                                                                id="exampleCity"
                                                                                placeholder={ data.city ? data.city : t( "common.city" ) }
                                                                                onChange={ e => onChangeData( e ) }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label name="address2">{ t( "common.postalcol" ) } <span className="text-muted"></span>
                                                                    </label>
                                                                    <input

                                                                        className="form-control"
                                                                        type="text"
                                                                        name="zip"
                                                                        placeholder={ data.zip ? data.zip : t( "common.postalcol" ) }
                                                                        maxLength={ 6 }
                                                                        onChange={ e => onChangeData( e ) }
                                                                    />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label name="address2">{ t( "common.phone" ) }
                                                                    </label>
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        name="phone"
                                                                        placeholder="Téléphone"
                                                                        maxLength={ 12 }
                                                                        value={ data.phone }
                                                                        onChange={ e => onChangePhone( e ) }
                                                                    />
                                                                </div>
                                                                <hr className="mb-4" />
                                                                <button className="btn btn-primary btn-lg btn-block" type="submit" onClick={ ( e ) => { e.preventDefault(); createEntity( data ) } }> { t( "common.add" ) }</button>
                                                            </form>
                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

Index.propTypes = {

}

export default Index

