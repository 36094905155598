import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { getSearch } from '../../../action/search';
import { afterChoosing } from '../../../action/entity';

export const Index = ( { profile, getSearch, search: { result, searchLoading } } ) => {
    const { t } = useTranslation();
    const [ state, setstate ] = useState( {
        image: "https://general.eu-central-1.linodeobjects.com/basics/platforms/moonode_logo.png",
        text: t( "common.choosemosque" )
    } )
    const [ selectedValue, setSelectedValue ] = useState( "" );

    useEffect( () => {
        getSearch();
        return () => {
        }
    }, [] )

    const onChange = async ( itemValue ) => {
        setSelectedValue( itemValue.target.value );
        let array = result[ 0 ]
        for ( let index = 0; index < result[ 0 ].length; index++ ) {
            if ( array[ index ].id === selectedValue ) {
                setstate( state => ( {
                    ...state,
                    image: array[ index ].logo,
                    text: array[ index ].name
                } ) )
            }
        }
        await anotherChange( itemValue.target.value );

    }

    const anotherChange = async ( selectedValue ) => {
        let array = result[ 0 ]
        for ( let index = 0; index < result[ 0 ].length; index++ ) {
            if ( array[ index ].id === selectedValue ) {
                setstate( state => ( {
                    ...state,
                    image: array[ index ].logo,
                    text: array[ index ].name
                } ) )
            }
        }
    }
    const onSave = async () => {
        afterChoosing( selectedValue );

    }
    const onChoose = () => {
        if ( selectedValue === "n/a" ) {
            alert( "Desolé mauvais choix" );
        } else {
            window.confirm( t( "common.choosemosque" ) ) ? onSave() : alert( "🤷‍♂️" )
        }

    }

    return (
        <div>
            <div class="main-content-body tab-pane p-4 border-top-0" id="settings">
                <div class="card-body border" data-select2-id="12">
                    <form class="form-horizontal" data-select2-id="11">
                        <div class="mb-4 main-content-label">{ t( "questions.app-ask-select-mosque" ) }</div>
                        <div class="form-group " data-select2-id="108">
                            <div class="row" data-select2-id="107">
                                <div class="col-md-3">
                                    <label class="form-label">{ state.text }</label>
                                </div>
                                <div class="col-md-9" data-select2-id="106">
                                    <select class="form-control select2" data-select2-id="5" tabindex="-1" aria-hidden="true" onChange={ ( e ) => onChange( e ) }>
                                        { result[ 0 ].map( mosque => <option key={ mosque.id } value={ mosque.id }>{ mosque.name }</option> ) }
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-9" style={ { float: "right" } }>
                                <br />
                                <button type="button" className="btn btn-primary btn-sm" onClick={ () => onChoose() } disabled={ profile.isImam || profile.isAdmin || profile.isRole }>{ t( "common.save" ) }</button>
                                <br />


                            </div>


                        </div>
                        <div class="form-group " data-select2-id="108">
                            <div class="row" data-select2-id="107">
                                <img
                                    src={ state.image }
                                    style={ { height: "200px", width: "200px", borderRadius: "100px" } }
                                />
                            </div>
                        </div>
                        { ( profile.isImam || profile.isAdmin || profile.isRole ) && <div class="mb-4 main-content-label" style={ { color: "red" } }>{ t( "website.adminChangeMosque" ) }</div> }
                    </form>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ( state ) => ( {
    search: state.search,

} )


export default connect( mapStateToProps, { getSearch } )( Index )
