import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { getUserNotifications, getUserNotifications2, makeThemSeen } from '../../action/userNotification';
import { formatDistanceToNow } from 'date-fns'
import { enCA } from 'date-fns/locale'
import { useTranslation } from 'react-i18next';
export const Index = ( { children, notification, getUserNotifications, getUserNotifications2, makeThemSeen, auth,
    userNotification: {
        userNotifications,
        nbrNotSeen,
        seen,
        registrationMobileId,
        userNotificationLoading },
} ) => {
    const { t } = useTranslation();
    const [ isSeen, setisSeen ] = useState( seen )
    useEffect( () => {
        setisSeen( seen );
    }, [ seen ] )

    // ------------------ Getting notification Section ------------------ //

    useEffect( () => {

        if ( auth.isAuthenticated ) {
            getUserNotifications()
        }

    }, [ auth.isAuthenticated ] )

    // ------------------ Getting notification Section ------------------ //

    const onClickSeen = () => {

        if ( !seen ) {
            makeThemSeen();
            setisSeen( true );
        }

    }

    return (
        <Fragment>
            <a className="nav-link icon" href="" onClick={ ( e ) => { e.preventDefault(); onClickSeen() } }>
                <i className="fe fe-bell header-icons"></i>
                { nbrNotSeen > 0 && <span className="badge badge-danger nav-link-badge" >{ nbrNotSeen }</span> }
            </a>
            { notification && <div className="dropdown-menu" style={ { height: "auto", maxHeight: "30rem", overflowX: "hidden", zIndex: 1 } }>
                <div className="header-navheading">
                    <p className="main-notification-text"> { t( "common.activity" ) }
                    </p>
                </div>
                <div className="main-notification-list">
                    <div style={ { width: "100%" } }>
                        <div className="vtimeline" style={ { width: "100%", position: "left" } }>
                            {
                                userNotifications.slice( 0, 30 ).map( ( i, index ) => (
                                    <div key={ index } className={ `timeline-wrapper ${ index % 2 ? '' : 'timeline-inverted' } timeline-wrapper-primary` } onClick={ () => window.location.replace( i.theLink ) } style={ { cursor: "pointer" } }>
                                        <div className="timeline-badge"></div>
                                        <div className="timeline-panel" >
                                            <div className="timeline-heading">
                                                <h6 className="timeline-title">{ i.title }</h6>
                                            </div>
                                            <div className="timeline-body">
                                                <p>{ i.info }</p>
                                            </div>
                                            <div className="timeline-footer d-flex align-items-center flex-wrap">
                                                <span className="ml-md-auto"><i className="fe fe-calendar text-muted mr-1"></i>{ formatDistanceToNow( new Date( i.date ), { locale: enCA } ) }</span>
                                            </div>
                                        </div>
                                    </div>
                                ) ) }
                        </div>

                    </div>
                </div>
                {/* <div className="dropdown-footer">
                    <a href="#">View All Notifications</a>
                </div> */}
            </div> }
        </Fragment>
    )
}

const mapStateToProps = state => ( {
    auth: state.auth,
    userNotification: state.userNotification,
} );


export default connect( mapStateToProps, { getUserNotifications, getUserNotifications2, makeThemSeen } )( Index )
