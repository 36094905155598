import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import tvExemple3 from "../../../../assets/comming.jpeg";

export const Index = ( props ) => {

    const youTubeVideosPrayer =
        [ 'https://youtu.be/1nnS4jZ9lvA', // en
            'https://youtu.be/cwvfWZMuIME', // fr
            'https://youtu.be/s02xWQ35LJU', // ar
            'https://youtu.be/KtXBfdY6hZw', // Ur
            'https://youtu.be/qLftp95Q5yk'  // bn
        ]
    const youTubeVideosJumuas =
        [ 'https://youtu.be/8sRU6mNjLLQ', // en
            'https://youtu.be/bvGK28m5RK4', // fr
            'https://youtu.be/Nu3TjqKXuEw', // ar
            'https://youtu.be/im98mXEzPx8', // Ur
            'https://youtu.be/W6bQpiq0B6I'  // bn
        ]
    const youTubeVideosAdhan =
        [ 'https://youtu.be/Vv_oOJdECxk', // en
            'https://youtu.be/wN1szWKOxNo', // fr
            'https://youtu.be/3w0YidqWT2s', // ar
            'https://youtu.be/j0rG3v0RzNw', // Ur
            'https://youtu.be/3LdqOMSYkUk'  // bn
        ]
    const youTubeVideosCsv =
        [ 'https://youtu.be/TWPCPsWQkBE', // en
            'https://youtu.be/GV7u-4xxPr0', // fr
            'https://youtu.be/3w0YidqWT2s', // ar
            'https://youtu.be/mKIEv9KYm_A', // Ur
            'https://youtu.be/Vj90afXTV-Y'  // bn
        ]
    const youTubeVideosWidget =
        [ 'https://youtu.be/4qy6yTtvn4k', // en
            'https://youtu.be/MJqzJ-mFGrg', // fr
            'https://youtu.be/mL4_QkNqaWg', // ar
            'https://youtu.be/bTI3WnuJwi8', // Ur
            'https://youtu.be/B0rsa1KQxOg'  // bn
        ]
    const { t } = useTranslation();



    const [ setPrayerVideo, setsetPrayerVideo ] = useState( youTubeVideosPrayer[ 0 ] );
    const [ setJumuaVideo, setsetJumuaVideo ] = useState( youTubeVideosJumuas[ 0 ] );
    const [ setCsvVideo, setsetCsvVideo ] = useState( youTubeVideosCsv[ 0 ] );
    const [ setAdhanVideo, setsetAdhanVideo ] = useState( youTubeVideosAdhan[ 0 ] );
    const [ setWidgetVideo, setsetWidgetVideo ] = useState( youTubeVideosWidget[ 0 ] );

    const [ active, setActive ] = useState( {
        en: true,
        fr: false,
        ar: false,
        ur: false,
        bn: false
    } );
    const [ video, setVideo ] = useState( {
        prayer: true,
        jumua: false,
        csv: false,
        adhan: false,
        widget: false
    } );
    const changeSubOptionsPrayer = ( active ) => {
        switch ( active ) {
            case 'english':
                setActive( { ...active, en: true } );
                setsetPrayerVideo( youTubeVideosPrayer[ 0 ] )
                setsetJumuaVideo( youTubeVideosJumuas[ 0 ] )
                setsetAdhanVideo( youTubeVideosAdhan[ 0 ] )
                setsetCsvVideo( youTubeVideosCsv[ 0 ] )
                setsetWidgetVideo( youTubeVideosWidget[ 0 ] )
                break;
            case 'french':
                setActive( { ...active, fr: true } );
                setsetPrayerVideo( youTubeVideosPrayer[ 1 ] )
                setsetJumuaVideo( youTubeVideosJumuas[ 1 ] )
                setsetAdhanVideo( youTubeVideosAdhan[ 1 ] )
                setsetCsvVideo( youTubeVideosCsv[ 1 ] )
                setsetWidgetVideo( youTubeVideosWidget[ 1 ] )
                break;
            case 'arabic':
                setActive( { ...active, ar: true } );
                setsetPrayerVideo( youTubeVideosPrayer[ 2 ] )
                setsetJumuaVideo( youTubeVideosJumuas[ 2 ] )
                setsetAdhanVideo( youTubeVideosAdhan[ 2 ] )
                setsetCsvVideo( youTubeVideosCsv[ 2 ] )
                setsetWidgetVideo( youTubeVideosWidget[ 2 ] )
                break;
            case 'urdu':
                setActive( { ...active, ur: true } );
                setsetPrayerVideo( youTubeVideosPrayer[ 3 ] )
                setsetJumuaVideo( youTubeVideosJumuas[ 3 ] )
                setsetAdhanVideo( youTubeVideosAdhan[ 3 ] )
                setsetCsvVideo( youTubeVideosCsv[ 3 ] )
                setsetWidgetVideo( youTubeVideosWidget[ 3 ] )
                break;
            case 'bengali':
                setActive( { ...active, bn: true } );
                setsetPrayerVideo( youTubeVideosPrayer[ 4 ] )
                setsetJumuaVideo( youTubeVideosJumuas[ 4 ] )
                setsetAdhanVideo( youTubeVideosAdhan[ 4 ] )
                setsetCsvVideo( youTubeVideosCsv[ 4 ] )
                setsetWidgetVideo( youTubeVideosWidget[ 4 ] )
                break;

            default:
                break;
        }

    }

    //change the tutorial video
    const changeWhichVideo = ( params ) => {
        switch ( params ) {
            case 'prayer':
                setVideo( {
                    prayer: true,
                    jumua: false,
                    csv: false,
                    adhan: false,
                    widget: false
                } );
                break;
            case 'jumua':
                setVideo( {
                    prayer: false,
                    jumua: true,
                    csv: false,
                    adhan: false,
                    widget: false
                } );
                break;
            case 'csv':
                setVideo( {
                    prayer: false,
                    jumua: false,
                    csv: true,
                    adhan: false,
                    widget: false
                } );
                break;
            case 'adhan':
                setVideo( {
                    prayer: false,
                    jumua: false,
                    csv: false,
                    adhan: true,
                    widget: false
                } );
                break;
            case 'widget':
                setVideo( {
                    prayer: false,
                    jumua: false,
                    csv: false,
                    adhan: false,
                    widget: true
                } );
                break;
            default:
                setVideo( {
                    prayer: true,
                    jumua: false,
                    csv: false,
                    adhan: false,
                    widget: false
                } );
                break;
        }
    }
    const linkifyYouTubeURLs = ( text ) => {
        var re = /https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*?[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/ig;
        return text.toString().replace( re, 'http://www.youtube.com/embed/$1' );
    }
    // get the video with the appropriate language
    const theVideo = () => {
        let url = "https://www.youtube.com/@moonode4724";
        if ( video.prayer ) {
            url = setPrayerVideo;
        }
        if ( video.adhan ) {
            url = setAdhanVideo;
        }
        if ( video.csv ) {
            url = setCsvVideo;

        }
        if ( video.widget ) {
            url = setWidgetVideo;

        }
        if ( video.jumua ) {
            url = setJumuaVideo;

        }

        return linkifyYouTubeURLs( url );

    }

    return (
        <div className="col-xl-4 col-lg-12 d-none d-xl-block custom-leftnav">
            <div className="main-content-left-components">
                <div className="card custom-card">
                    <div className="card-body component-item">
                        <nav className="nav flex-column">
                            <a className={ `nav-link ${ video.prayer ? "active" : "" }` } onClick={ ( e ) => { e.preventDefault(); changeWhichVideo( 'prayer' ) } } href="!#">{ t( "website.changePrayer" ) }</a>
                            <a className={ `nav-link ${ video.jumua ? "active" : "" }` } onClick={ ( e ) => { e.preventDefault(); changeWhichVideo( 'jumua' ) } } href="!#">{ t( "website.activateJumua" ) }</a>
                            <a className={ `nav-link ${ video.csv ? "active" : "" }` } onClick={ ( e ) => { e.preventDefault(); changeWhichVideo( 'csv' ) } } href="!#">{ t( "website.ActivateCsv" ) }</a>
                            <a className={ `nav-link ${ video.adhan ? "active" : "" }` } onClick={ ( e ) => { e.preventDefault(); changeWhichVideo( 'adhan' ) } } href="!#">{ t( "website.editAdhan" ) }</a>
                            <a className={ `nav-link ${ video.widget ? "active" : "" }` } onClick={ ( e ) => { e.preventDefault(); changeWhichVideo( 'widget' ) } } href="!#">{ t( "website.exportWidget" ) }</a>
                        </nav>
                    </div>
                </div>
                <div className="card custom-card" style={ { width: "130%" } }>
                    <div className="card-header custom-card-header border-bottom-0">
                        <h6 className="main-content-label mb-0">{ t( "website.explainVideo" ) }</h6>
                    </div>
                    <div className="example">
                        <nav className="nav main-nav flex-column flex-md-row justify-content-center">
                            <a onClick={ ( e ) => { e.preventDefault(); setActive( { ...active, ur: false } ); changeSubOptionsPrayer( 'english' ) } } className={ `nav-link ${ active.en ? "active" : "" }` } style={ active.en ? {
                                backgroundColor: "#FABF0F",
                                padding: "5px",
                                borderRadius: "5px",
                                cursor: "pointer"
                            } : { cursor: "pointer" } } >{ t( "website.english" ) }</a>
                            <a onClick={ ( e ) => { e.preventDefault(); setActive( { ...active, en: false } ); changeSubOptionsPrayer( 'french' ) } } className={ `nav-link ${ active.fr ? "active" : "" }` } style={ active.fr ? {
                                backgroundColor: "#FABF0F",
                                padding: "5px",
                                borderRadius: "5px",
                                cursor: "pointer"
                            } : { cursor: "pointer" } }  >{ t( "website.french" ) }</a>
                            <a onClick={ ( e ) => { e.preventDefault(); setActive( { ...active, fr: false } ); changeSubOptionsPrayer( 'arabic' ) } } className={ `nav-link ${ active.ar ? "active" : "" }` } style={ active.ar ? {
                                backgroundColor: "#FABF0F",
                                padding: "5px",
                                borderRadius: "5px",
                                cursor: "pointer"
                            } : { cursor: "pointer" } }  >{ t( "website.arabic" ) }</a>
                            <a onClick={ ( e ) => { e.preventDefault(); setActive( { ...active, ar: false } ); changeSubOptionsPrayer( 'urdu' ) } } className={ `nav-link ${ active.ur ? "active" : "" }` } style={ active.ur ? {
                                backgroundColor: "#FABF0F",
                                padding: "5px",
                                borderRadius: "5px",
                                cursor: "pointer"
                            } : { cursor: "pointer" } }  >{ t( "website.urdu" ) }</a>
                            <a onClick={ ( e ) => { e.preventDefault(); setActive( { ...active, ur: false } ); changeSubOptionsPrayer( 'bengali' ) } } className={ `nav-link ${ active.bn ? "active" : "" }` } style={ active.bn ? {
                                backgroundColor: "#FABF0F",
                                padding: "5px",
                                borderRadius: "5px",
                                cursor: "pointer"
                            } : { cursor: "pointer" } }  >{ t( "website.bengali" ) }</a>
                        </nav>
                    </div>
                    <div className="card-body">
                        <div className="border">
                            <iframe


                                style={ {
                                    border: "0",
                                    height: "25vh",
                                    left: "0",
                                    position: "inherit",
                                    top: "30%",
                                    width: "100%"
                                } }
                                sandbox="allow-same-origin allow-scripts allow-forms allow-presentation"
                                allowFullScreen="allowfullscreen"
                                mozallowfullscreen="mozallowfullscreen"
                                msallowfullscreen="msallowfullscreen"
                                oallowfullscreen="oallowfullscreen"
                                webkitallowfullscreen="webkitallowfullscreen"
                                src={ `${ theVideo() }` }
                            >
                            </iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = ( state ) => ( {

} )

const mapDispatchToProps = {

}

export default connect( mapStateToProps, mapDispatchToProps )( Index )
