import React from 'react'
import { Card, Collapse } from "react-bootstrap"

function Index ( { open, sharedLink } ) {
    return (
        <Card.Footer className="text-muted">
            <Collapse in={ open }>
                <div id="example-collapse-text">
                    <div className="uk-position-relative uk-visible-toggle uk-light" tabIndex="-1" style={ { display: "flex", justifyContent: "space-between" } }>
                        <li>
                            <a
                                href={ null }
                                onClick={ () => { window.open( `https://api.whatsapp.com/send?text=Moonode! votre communauté ${ sharedLink }` ) } }
                                className="btn btn-primary btn-sm"
                                data-action="share/whatsapp/share"
                                style={ { cursor: "pointer", color: "black", fontSize: "1.2rem" } }>
                                <i class="fab fa-whatsapp"></i>
                                WhatsApp
                            </a>
                        </li>
                        <li>
                            < a href={ null } onClick={ () => { window.open( `https://twitter.com/intent/tweet?text=${ sharedLink }` ) } } className="btn btn-primary btn-sm" style={ { cursor: "pointer", color: "black", fontSize: "1.2rem" } }>
                                <i class="fab fa-twitter"></i>
                                Twitter </a>
                        </li>
                        <li>
                            <a href={ null }
                                onClick={ () => { window.open( `https://www.facebook.com/sharer/sharer.php?u=${ sharedLink }` ) } }
                                className="btn btn-primary btn-sm" style={ { cursor: "pointer", color: "black", fontSize: "1.2rem" } }>
                                <i class="fab fa-facebook-f"></i>
                                Facebook </a>

                        </li>
                        <li>

                            <a
                                href={ null }
                                onClick={ () => { window.open( `https://www.linkedin.com/sharing/share-offsite/?url=${ sharedLink }` ) } }
                                className="btn btn-primary btn-sm" style={ { cursor: "pointer", color: "black", fontSize: "1.2rem" } } >
                                <i class="fab fa-linkedin-in"></i>
                                LinkedIn </a>
                        </li>
                    </div>
                </div>

            </Collapse>

        </Card.Footer>
    )
}

export default Index
