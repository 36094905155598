import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';

import './css/icofont.min.css'
import './css/owl.carousel.min.css'
import './css/bootstrap.min.css'
import './css/aos.min.css'
import './css/responsive.min.css'
import './css/style.min.css'

export const Index = ( { props } ) => {
    const { t, i18n } = useTranslation();

    return (
        <div className="page_wrapper" style={ { margint: "100px" } } >
            <div id="preloader">

                <div id="loader">
                    <img alt="avatar" src="https://general.eu-central-1.linodeobjects.com/basics/platforms/moonode_logo.png" style={ { height: '130px', width: '130px' } } />
                </div>
            </div>

            <div className="container">
                <section className="row_am free_app_section" id="getstarted">
                    <div className="container">
                        <div className="free_app_inner" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="free_text">
                                        <div className="section_title">
                                            <h2>{ t( 'landingSite.downloadTitle' ) }</h2>
                                            <p>{ t( "landingSite.downloadBody" ) }</p>
                                        </div>
                                        <ul className="app_btn">
                                            <li>
                                                <a href="https://apps.apple.com/ca/app/moonode/id1508859104" target="_blank">
                                                    <img src={ require( "./images/appstore_blue.png" ) } alt="image" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://play.google.com/store/apps/details?id=com.moonode.moonode&hl=en&gl=US" target="_blank">
                                                    <img src={ require( "./images/googleplay_blue.png" ) } alt="image" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="free_img">
                                        <img src={ require( "./images/download-screen01.png" ) } alt="image" />
                                        <img className="mobile_mockup" src={ require( "./images/download-screen02.png" ) } alt="image" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </section>




            </div>
        </div >
    )
}

const mapStateToProps = ( state ) => ( {

} )

const mapDispatchToProps = {

}

export default connect( mapStateToProps, mapDispatchToProps )( Index )
