import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { isMobile, isTablet } from '../../utils/device';
import OwlCarousel from 'react-owl-carousel';
import saoudi from '../../assets/flags/saoudi.png';
import england from '../../assets/flags/england.png';
import french from '../../assets/flags/french.png';
import pakistan from '../../assets/flags/pakistan.png';
import bangladesh from '../../assets/flags/bangladesh.jpeg';
import './css/icofont.min.css'
import './css/owl.carousel.min.css'
import './css/bootstrap.min.css'
import './css/aos.min.css'
import './css/responsive.min.css'
import './css/style.min.css'

export const Index = ( { props } ) => {

    const { t, i18n } = useTranslation();
    const onChangeLanguage = ( language ) => {
        i18n.changeLanguage( language )
        localStorage.setItem( "lang", language )
    }
    return (
        <div className="page_wrapper">

            <div class="page_wrapper">

                <div id="preloader">

                    <div id="loader">
                        <img alt="avatar" src="https://general.eu-central-1.linodeobjects.com/basics/platforms/moonode_logo.png" style={ { height: '130px', width: '130px' } } />
                    </div>
                </div>

                <header>

                    <div className="container">

                        <nav className="navbar navbar-expand-lg">
                            <a className="navbar-brand" href="#">
                                <img src="https://general.eu-central-1.linodeobjects.com/basics/platforms/moonode_logo.png" style={ { height: isMobile() ? '45px' : '30px', width: isMobile() ? '45px' : '30px' } } alt="image" />
                            </a>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon">
                                    <div className="toggle-wrap">
                                        <span className="toggle-bar"></span>
                                    </div>
                                </span>
                            </button>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item active">
                                        <a className="nav-link" href="/">{ t( 'application.home' ) }</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/aboutus">{ t( 'common.about' ) }</a>
                                    </li>
                                    <li className="nav-item has_dropdown">
                                        <a className="nav-link" href="#">{ t( 'profile.language' ) }</a>
                                        <span className="drp_btn">⤵</span>
                                        <div className={ `${ isMobile() ? "" : "sub_menu" }` }>
                                            <ul>
                                                <li> <a style={ { cursor: "pointer" } } onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'ar' ) } } className="dropdown-item d-flex ">
                                                    <span className="avatar  mr-3 align-self-center bg-transparent"><img src={ saoudi } alt="img" /></span>
                                                    <div className="d-flex">
                                                        <span className="mt-2">{ t( "website.arabic" ) }</span>
                                                    </div>
                                                </a></li>
                                                <li> <a style={ { cursor: "pointer" } } onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'en' ) } } className="dropdown-item d-flex">
                                                    <span className="avatar  mr-3 align-self-center bg-transparent"><img src={ england } alt="img" /></span>
                                                    <div className="d-flex">
                                                        <span className="mt-2">{ t( "website.english" ) }</span>
                                                    </div>
                                                </a></li>
                                                <li> <a style={ { cursor: "pointer" } } onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'fr' ) } } className="dropdown-item d-flex">
                                                    <span className="avatar mr-3 align-self-center bg-transparent"><img src={ french } alt="img" /></span>
                                                    <div className="d-flex">
                                                        <span className="mt-2">{ t( "website.french" ) }</span>
                                                    </div>
                                                </a></li>
                                                <li> <a style={ { cursor: "pointer" } } onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'ur' ) } } className="dropdown-item d-flex">
                                                    <span className="avatar mr-3 align-self-center bg-transparent"><img src={ pakistan } alt="img" /></span>
                                                    <div className="d-flex">
                                                        <span className="mt-2">{ t( "website.urdu" ) }</span>
                                                    </div>
                                                </a></li>
                                                <li> <a style={ { cursor: "pointer" } } onClick={ ( e ) => { e.preventDefault(); onChangeLanguage( 'bn' ) } } className="dropdown-item d-flex">
                                                    <span className="avatar mr-3 align-self-center bg-transparent"><img src={ bangladesh } alt="img" /></span>
                                                    <div className="d-flex">
                                                        <span className="mt-2">{ t( "website.bengali" ) }</span>
                                                    </div>
                                                </a></li>
                                            </ul>
                                        </div>
                                    </li>

                                    <li className="nav-item has_dropdown">
                                        <a className="nav-link" href="#">{ t( 'landingSite.blog' ) }</a>
                                        <span className="drp_btn">⤵</span>
                                        <div className={ `${ isMobile() ? "" : "sub_menu" }` }>
                                            <ul>
                                                <li><a style={ { marginLeft: isMobile() ? "30px" : "" } } href="blog-list.html">Raspberry</a></li>
                                            </ul>
                                        </div>
                                    </li>


                                    <li className="nav-item">
                                        <a className="nav-link" href="/contactus">{ t( 'application.contactUs' ) }</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link dark_btn" href="/signin">{ t( 'landing.signin' ) }</a>
                                    </li>
                                </ul>
                            </div>
                        </nav>

                    </div>

                </header>

                <div class="bred_crumb">
                    <div class="container">
                        <span class="banner_shape1"> <img src={ require( '../../assets/img/mashaAllah-actif.png' ) } style={ { width: "40px", height: "40px" } } alt="image" /> </span>
                        <span class="banner_shape2"> <img src={ require( '../../assets/img/mashaAllah-actif.png' ) } style={ { width: "40px", height: "40px" } } alt="image" /> </span>
                        <span class="banner_shape3"> <img src={ require( '../../assets/img/mashaAllah-actif.png' ) } style={ { width: "40px", height: "40px" } } alt="image" /> </span>

                        <div class="bred_text">
                            <h1>{ t( "landingSite.raspberry" ) }</h1>
                            <ul>
                                <li><a href="/">{ t( "application.home" ) }</a></li>
                                <li><span>»</span></li>
                                <li><a href="/aboutus">{ t( "landingSite.raspberry" ) }</a></li>
                            </ul>
                        </div>
                    </div>
                </div>


                <br />
                <br />
                <br />
                {/* <!-- Contact Us Section Start --> */ }

                <section class="blog_detail_section">
                    <div class="container">
                        <div class="blog_inner_pannel">
                            <div class="review">
                                <span>{ t( 'landingSite.raspberry' ) }</span>
                                <span>29/12/2022</span>
                            </div>
                            <div class="section_title">
                                <h2>{ t( 'landingSite.raspberryBody' ) }</h2>
                            </div>
                            <div class="main_img">
                                <img src={ require( './images/blog_detail_main.png' ) } alt="image" />
                            </div>
                            <div class="info">
                                <section class="row_am app_solution_section">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="app_text">
                                                    <div class="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                                                        <h2><span >{ t( 'landingSite.installation' ) }</span> </h2>
                                                        <br />
                                                    </div>
                                                    <br />  <br /><br />
                                                    <p>{ t( 'landingSite.rbLists' ) }</p>
                                                    <ul>
                                                        <li><p> <span ><i class="fas fa-certificate"></i></span> { t( 'landingSite.raspberry' ) }
                                                            <a href='https://www.amazon.ca/Vemico-Raspberry-Starter-Heatsinks-Screwdriver/dp/B09WXRCYL4/ref=sr_1_1_sspa?keywords=raspberry+pi+4&qid=1672350220&sprefix=rasp%2Caps%2C72&sr=8-1-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUEzUzZDTU1PWlNWT0I3JmVuY3J5cHRlZElkPUEwOTI1NzMwMzVBVjVLRVYzWEozJmVuY3J5cHRlZEFkSWQ9QTAyNzAwOTgxRDFGRjNRT0JHM0FNJndpZGdldE5hbWU9c3BfYXRmJmFjdGlvbj1jbGlja1JlZGlyZWN0JmRvTm90TG9nQ2xpY2s9dHJ1ZQ==' target={ "_blank" }>{ " " } Link </a>
                                                        </p></li>
                                                        <li><p><span ><i class="fas fa-certificate"></i></span> { t( 'landingSite.sdCard' ) }</p></li>
                                                        <li><p><span ><i class="fas fa-certificate"></i></span>  { t( 'landingSite.keyboard' ) }</p></li>
                                                        <li><p><span ><i class="fas fa-certificate"></i></span>  { t( 'landingSite.mouse' ) }</p></li>
                                                        <li><p><span ><i class="fas fa-certificate"></i></span>  { t( 'landingSite.rsImager' ) } <a href='https://www.raspberrypi.com/software/' target={ "_blank" }>{ t( 'landingSite.downlaodHere' ) }</a></p></li>
                                                        <li><p><span ><i class="fas fa-certificate"></i></span>  { t( 'landingSite.rbRTC' ) } <a href=' https://www.amazon.ca/Module-Precision-Hwclock-Raspberry-Battery/dp/B07QBPV263/ref=sr_1_4_sspa?keywords=ds3231+real+time+clock+module&qid=1672350190&sprefix=Real+Time+Clock+Module+%2Caps%2C80&sr=8-4-spons&psc=1&smid=AYAQCA6J7ASI3&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUE2Mlg4NDVMNUs5VjcmZW5jcnlwdGVkSWQ9QTA5MTAwNjdHSUg3QzFLRThMSDMmZW5jcnlwdGVkQWRJZD1BMDA3NjQ3NEY3UDdaNlFNOFY4UCZ3aWRnZXROYW1lPXNwX2F0ZiZhY3Rpb249Y2xpY2tSZWRpcmVjdCZkb05vdExvZ0NsaWNrPXRydWU=' target={ "_blank" }>Link</a></p></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="app_images" data-aos="fade-in" data-aos-duration="1500">
                                                    <ul>
                                                        <li style={ { marginBottom: '-25%' } }><img src={ require( "./images/abt_03.png" ) } alt="" /></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                            </div>

                            <div class="info">
                                <h5>{ t( 'landingSite.rbG1' ) }</h5>
                            </div>
                            <div class="main_img" style={ { marginLeft: "15%" } }>
                                <img src={ require( './images/rs1.png' ) } alt="image" />
                            </div>
                            <div class="info">
                                <h6>{ t( 'landingSite.rbG2' ) }</h6>
                            </div>
                            <div class="main_img" style={ { marginLeft: "15%" } }>
                                <img src={ require( './images/rs2.png' ) } alt="image" />
                            </div>
                            <div class="info">
                                <h6>{ t( 'landingSite.rbG3' ) }</h6>
                            </div>
                            <div class="main_img" style={ { marginLeft: "15%" } }>
                                <img src={ require( './images/rs3.png' ) } alt="image" />
                            </div>
                            <div class="info">
                                <h6>{ t( 'landingSite.rbG4' ) }</h6>
                            </div>
                            <div class="main_img" style={ { marginLeft: "15%" } }>
                                <img src={ require( './images/rs4.png' ) } alt="image" />
                            </div>
                            <div class="info">
                                <h6>{ t( 'landingSite.rbG5' ) }</h6>
                            </div>
                            <div class="main_img" style={ { marginLeft: "15%" } }>
                                <img src={ require( './images/rs5.png' ) } alt="image" />
                            </div>
                            <div class="info">
                                <h6>{ t( 'landingSite.rbG6' ) }</h6>
                            </div>
                            <div class="main_img" style={ { marginLeft: "15%" } }>
                                <img src={ require( './images/rs5.jpg' ) } alt="image" />
                            </div>
                            <div class="info">
                                <h6>{ t( 'landingSite.rbG7' ) }</h6>
                            </div>
                            <div class="main_img" style={ { marginLeft: "15%" } }>
                                <img src={ require( './images/rs6.jpg' ) } alt="image" />
                            </div>
                            <div class="info">
                                <h6>{ t( 'landingSite.rbG8' ) }</h6>
                                <p><ul>
                                    <li>
                                        <h4>- xscreensaver </h4>
                                        <p>
                                            { t( 'landingSite.rbG9' ) }
                                        </p>
                                    </li>
                                    <br />
                                    <li>
                                        <h4>- xfce4-power-manager </h4>

                                        <p>{ t( 'landingSite.rbG10' ) }</p>
                                    </li>
                                </ul>
                                </p>
                            </div>
                            <div class="info">
                                <h6>{ t( 'landingSite.rbG12' ) }</h6>
                                <div className="example">
                                    <h6>{ t( "landingSite.rgb21" ) }</h6>
                                </div>
                                <figure className="highlight clip-widget" id="badge1"><pre>
                                    <code style={ { color: "red" } }>
                                        apt-get install xscreensaver
                                    </code>
                                </pre>
                                    <div className="clipboard-icon" data-clipboard-target="#badge1"
                                    ><i className="fa fa-clipboard"></i></div>

                                </figure>
                            </div>
                            <div class="info">
                                <h6>{ t( 'landingSite.rbG12' ) }</h6>
                                <div className="example">
                                    <h6>{ t( "landingSite.rbG13" ) }</h6>
                                </div>
                            </div>
                            <div class="main_img" style={ { marginLeft: "15%" } }>
                                <img src={ require( './images/rs7.png' ) } alt="image" />
                            </div>
                            <div class="info">
                                <h6>{ t( 'landingSite.rgb22' ) }</h6>
                            </div>
                            <div class="main_img" style={ { marginLeft: "15%" } }>
                                <img src={ require( './images/rs8.jpg' ) } alt="image" />
                            </div>
                            <div class="info">
                                <h6>{ t( 'landingSite.rbG14' ) }</h6>
                                <div className="example">
                                    <h6>{ t( "landingSite.rgb21" ) }</h6>
                                </div>
                                <figure className="highlight clip-widget" id="badge1"><pre>
                                    <code style={ { color: "red" } }>
                                        apt-get install xfce4-power-manager
                                    </code>
                                </pre>
                                    <div className="clipboard-icon" data-clipboard-target="#badge1"
                                    ><i className="fa fa-clipboard"></i></div>

                                </figure>
                                <div class="main_img" style={ { marginLeft: "15%" } }>
                                    <img src={ require( './images/rs8.jpg' ) } alt="image" />
                                </div>

                            </div>
                            <div class="info">
                                <h6>{ t( 'landingSite.rbG15' ) }</h6>
                            </div>
                            <div class="main_img" style={ { marginLeft: "15%" } }>
                                <img src={ require( './images/rs9.png' ) } alt="image" />
                            </div>
                            <div class="info">
                                <h6>{ t( 'landingSite.rbG16' ) }</h6>
                            </div>
                            <div class="main_img" style={ { marginLeft: "15%" } }>
                                <img src={ require( './images/rs10.png' ) } alt="image" />
                            </div>
                            <div class="info">
                                <h6>{ t( 'landingSite.rbG17' ) }</h6>
                                <div className="example">
                                    <h6>{ t( "landingSite.rgb21" ) }</h6>

                                </div>
                                <figure className="highlight clip-widget" id="badge1"><pre>
                                    <code style={ { color: "red" } }>
                                        sudo touch /etc/chromium-browser/customizations/01-disable-update-check;echo CHROMIUM_FLAGS="$&#123;CHROMIUM_FLAGS&#125; --check-for-update-interval=31536000" | sudo tee /etc/chromium-browser/customizations/01-disable-update-check
                                    </code>
                                </pre>
                                    <div className="clipboard-icon" data-clipboard-target="#badge1"
                                    ><i className="fa fa-clipboard"></i></div>

                                </figure>
                            </div>
                            <div class="info">
                                <h6>{ t( 'landingSite.rbG18' ) }</h6>
                                <div className="example">
                                    <h6>{ t( "landingSite.rgb21" ) }</h6>
                                </div>
                                <figure className="highlight clip-widget" id="badge1"><pre>
                                    <code style={ { color: "red" } }>
                                        cd /etc/xdg/lxsession/LXDE-pi/autostart
                                    </code>
                                </pre>
                                    <div className="clipboard-icon" data-clipboard-target="#badge1"
                                    ><i className="fa fa-clipboard"></i></div>

                                </figure>

                            </div>
                            <div class="main_img" style={ { marginLeft: "15%" } }>
                                <img src={ require( './images/rs11.png' ) } alt="image" />
                            </div>
                            <div class="info">
                                <h6>{ t( 'landingSite.rbG19' ) }</h6>

                                <figure className="highlight clip-widget" id="badge1"><pre>
                                    <code style={ { color: "red" } }>
                                        @chromium-browser --kiosk ****
                                    </code>
                                </pre>
                                    <div className="clipboard-icon" data-clipboard-target="#badge1"
                                    ><i className="fa fa-clipboard"></i></div>

                                </figure>

                            </div>

                            <div class="info">
                                <h6>{ t( 'landingSite.rbG20' ) }</h6>
                                <div className="example">
                                    <h6>{ t( "landingSite.rgb21" ) }</h6>
                                </div>
                                <figure className="highlight clip-widget" id="badge1"><pre>
                                    <code style={ { color: "red" } }>
                                        reboot
                                    </code>
                                </pre>
                                    <div className="clipboard-icon" data-clipboard-target="#badge1"
                                    ><i className="fa fa-clipboard"></i></div>

                                </figure>

                            </div>
                            <div class="info">
                                <h6>🥳🎉🕌</h6>
                                <div class="main_img" >
                                    <img src={ require( './images/Tv2.png' ) } alt="image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Contact Us Section End --> */ }
                <footer>
                    <div className="top_footer" id="contact">

                        <div className="container">

                            <div className="row">

                                <span className="banner_shape1"> <img src={ require( '../../assets/img/mashaAllah-actif.png' ) } alt="image" /> </span>
                                <span className="banner_shape2"> <img src={ require( '../../assets/img/mashaAllah-actif.png' ) } alt="image" /> </span>
                                <span className="banner_shape3"> <img src={ require( '../../assets/img/mashaAllah-actif.png' ) } alt="image" /> </span>


                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="abt_side">
                                        <div className="logo"> <img src="https://general.eu-central-1.linodeobjects.com/basics/platforms/moonode_logo.png" alt="image" /></div>
                                        <ul>
                                            <li><a href="mailto:admin@moonode.com" style={ { color: "#3A3A3A" } } >admin@moonode.com</a></li>
                                            <li><a href="https://wa.me/qr/ZEHYBW5XNPG5B1" style={ { color: "#3A3A3A" } } > WhatsApp</a></li>
                                        </ul>
                                        <ul className="social_media">
                                            <a href="https://www.facebook.com/Moonodeapp/" target="_blank" ><i className="fab fa-facebook" style={ { color: "#3A3A3A", backgroundColor: "transparent", fontSize: "2.89em" } }></i></a>
                                            <a href="https://www.youtube.com/channel/UCRJpB4Cr1lsHua1pbhfDQRQ" target="_blank"  ><i className="fab fa-youtube" style={ { color: "#3A3A3A", backgroundColor: "transparent", fontSize: "2.89em" } }></i></a>
                                            <a href="https://twitter.com/moonode_app" target="_blank" ><i className="fab fa-twitter" style={ { color: "#3A3A3A", backgroundColor: "transparent", fontSize: "2.89em" } }></i></a>
                                            <a href="https://www.instagram.com/moonode.app/?hl=en" target="_blank" ><i className="fab fa-instagram" style={ { color: "#3A3A3A", backgroundColor: "transparent", fontSize: "2.89em" } }></i></a>
                                        </ul>
                                    </div>
                                </div>


                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="links">
                                        <h3>{ t( "landingSite.usefulLink" ) }</h3>
                                        <ul>
                                            <li><a href="/" style={ { color: "#3A3A3A" } }>Home</a></li>
                                            <li><a href="/aboutus" style={ { color: "#3A3A3A" } }>About us</a></li>
                                            <li><a href="/contactus" style={ { color: "#3A3A3A" } }>Contact us</a></li>
                                        </ul>
                                    </div>
                                </div>


                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="links">
                                        <h3>{ t( "landingSite.helpNsupport" ) }</h3>

                                        <ul>
                                            <li><a href="/terms" style={ { color: "#3A3A3A" } }>Terms & conditions</a></li>
                                            <li><a href="/terms" style={ { color: "#3A3A3A" } }>Privacy policy</a></li>
                                        </ul>
                                    </div>
                                </div>


                                <div className="col-lg-2 col-md-6 col-12">
                                    <div className="try_out">
                                        <h3>Moonode</h3>
                                        <ul className="app_btn">
                                            <li>
                                                <a href="https://apps.apple.com/ca/app/moonode/id1508859104" target="_blank">
                                                    <img src={ require( "./images/appstore_blue.png" ) } alt="image" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://play.google.com/store/apps/details?id=com.moonode.moonode&hl=en&gl=US" target="_blank">
                                                    <img src={ require( "./images/googleplay_blue.png" ) } alt="image" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="bottom_footer">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <p>© Copyrights { new Date().getUTCFullYear() }. All rights reserved.</p>
                                </div>
                                <div className="col-md-6">
                                    <p className="developer_text">{ t( 'landingSite.designedWith' ) } <a href="https://en.wikipedia.org/wiki/Montreal" target="blank">Montreal</a></p>
                                </div>
                            </div>

                        </div>

                    </div>


                    <div className="go_top">
                        <span><img src={ require( "./images/go_top.png" ) } alt="image" /></span>
                    </div>
                </footer>
            </div >
        </div >
    )
}

const mapStateToProps = ( state ) => ( {

} )

const mapDispatchToProps = {

}

export default connect( mapStateToProps, mapDispatchToProps )( Index )
